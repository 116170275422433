import React, { useContext } from "react";
import { Button } from "@material-ui/core";
import classNames from "classnames";
import { useTranslation } from "infrastructure/hooks";
import styles from "./actions.module.scss";
import BanReason from "./components/ban-reason";
import { observer } from "mobx-react";
import { UserDetailsStore } from "../../user-details-store";
import { UsersContext } from "../../../../users-store";
import { useNavigation } from "utils/router-utils";
import { QUERY_AUTHOR_ID } from "utils/constants";
import { USER_TYPE_ADMIN, USER_TYPE_SUPER_ADMIN } from "database/constants";

interface Props {
    userDetailsStore: UserDetailsStore;
}

const Actions = ({ userDetailsStore }: Props) => {
    const { goToWithQuery } = useNavigation();
    const { getString } = useTranslation();

    const {
        activeUser,
        isBanDialogOpen,
        updateIsUserAdmin,
        unbanUser,
        verifyUser,
        setBanDialogOpen,
        setActiveUser
    } = userDetailsStore;

    const usersStore = useContext(UsersContext);

    const {
        currentAdmin
    } = usersStore;

    if (!activeUser) {
        return null;
    }

    const canUpdateUserAccountType = currentAdmin &&
        currentAdmin.accountType === USER_TYPE_SUPER_ADMIN &&
        activeUser.accountType !== USER_TYPE_SUPER_ADMIN;

    const handleUpdateIsUserBanned = async () => {
        if (!activeUser.banned)
            setBanDialogOpen(true);
        else {
            await unbanUser();
        }
    }

    const handleViewPosts = () => {
        goToWithQuery("/admin/posts", [{
            key: QUERY_AUTHOR_ID,
            value: activeUser.id
        }]);
        setActiveUser(null);
    }

    return (
        <div className={styles.actionsWrapper}>
            <h3>{getString("actions")}</h3>
            <div className={styles.actions}>
                {!activeUser.verified && (
                    <Button
                        className={classNames(styles.actionButton, styles.verifyUser)}
                        onClick={() => verifyUser()}>
                        {getString("verify")}
                    </Button>
                )}
                <Button
                    className={classNames(styles.actionButton, styles.banUser)}
                    onClick={handleUpdateIsUserBanned}>
                    {getString(activeUser.banned ? "unban" : "ban")}
                </Button>
                {canUpdateUserAccountType && (
                    <Button
                        className={classNames(styles.actionButton, styles.makeUserAdmin)}
                        onClick={() => updateIsUserAdmin()}>
                        {getString(activeUser.accountType === USER_TYPE_ADMIN ?
                            "deactivateAdminRole" : "activateAdminRole"
                        )}
                    </Button>
                )}
                <Button
                    className={classNames(styles.actionButton, styles.viewUserPosts)}
                    onClick={handleViewPosts}>
                    {getString("viewUserPosts")}
                </Button>
            </div>
            <BanReason
                open={isBanDialogOpen}
                onClose={() => setBanDialogOpen(false)}
                userDetailsStore={userDetailsStore} />
        </div>
    )
}

export default observer(Actions);