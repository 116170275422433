import { makeAutoObservable } from "mobx";
import { createContext } from "react";

export class LoadingOverlayStore {
    public activeLoadings: number = 0;

    constructor() {
        makeAutoObservable(this);
    }

    public set = (show: boolean) => {
        if (show) {
            return ++this.activeLoadings;
        }

        if (--this.activeLoadings < 0) {
            this.activeLoadings = 0;
        }
    }
}

export const loadingOverlayStore = new LoadingOverlayStore();
export const LoadingOverlayContext = createContext(loadingOverlayStore);