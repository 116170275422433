import React, { useState, ChangeEvent } from "react";
import { FormControlLabel, TextField } from "@material-ui/core";
import classNames from "classnames";
import styles from "./numeric-input.module.scss";
import { runInAction } from "mobx";

interface Props {
    onChange: (value: number | null) => void;
    defaultValue?: number;
    className?: string;
    errorMessage?: string;
    step?: number;
    min?: number;
    max?: number;
    label?: string;
    required?: boolean;
    placeholder?: string;
    value?: number | null;
    useAction?: boolean;
}

const NumericInput = ({
    onChange,
    defaultValue,
    className,
    errorMessage,
    step,
    min,
    max,
    label,
    required,
    placeholder,
    value,
    useAction
}: Props) => {
    const [numericValue, setNumericValue] = useState<number | string>(defaultValue ?? "");

    const isControlled = value !== undefined;
    const valueToUse = isControlled ? value ?? "" : numericValue;
    const onChangeWrapper = useAction ? 
        (value: number|null) => runInAction(() => onChange(value)) : onChange;

    const setValue = (newValue: number | string) => {
        isControlled || setNumericValue(newValue);
        onChangeWrapper(newValue === "" ? null : Number(newValue));
    }

    const handleChange = (event: ChangeEvent<HTMLInputElement>) =>
        setValue(event.target.value);

    const handleBlur = () => {
        const numberValue = Number(valueToUse);

        if (min !== undefined && numberValue < min) {
            setValue(min);
        } else if (max !== undefined && numberValue > max) {
            setValue(max);
        }
    }

    return (
        <FormControlLabel
            className={classNames(styles.numericInput, className, {
                [styles.withLabel]: label
            })}
            control={
                <TextField
                    value={valueToUse}
                    error={!!errorMessage || (required && valueToUse === "")}
                    helperText={errorMessage}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    label={placeholder}
                    inputProps={{
                        step,
                        min,
                        max,
                        type: "number"
                    }} />
            }
            label={label}
            labelPlacement="start" />
    );
}

export default NumericInput;