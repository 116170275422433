import { Button } from "@material-ui/core"
import { useTranslation } from "infrastructure/hooks"
import { openTermsAndConditions } from "infrastructure/services"
import React from "react"

const TermsAndConditionsButton = () => {
    const { getString } = useTranslation();

    return (
        <Button size="small" variant="outlined" onClick={openTermsAndConditions}>
            {getString("termsAndConditions")}
        </Button>
    )
}

export default TermsAndConditionsButton;