import { IconButton, Tooltip, Typography } from "@material-ui/core";
import { NotificationsOutlined } from "@material-ui/icons";
import classNames from "classnames";
import { useTranslation } from "infrastructure/hooks";
import { observer } from "mobx-react";
import React, { useEffect, useRef } from "react";
import { useContext } from "react"
import { NotificationsContext } from "../notifications-store";
import styles from "./notifications-button.module.scss";

const NotificationsButton = () => {
    const { getString } = useTranslation();
    const anchorElement = useRef(null);
    const {
        isPaneOpen,
        setIsPaneOpen,
        getUnseenCount,
        setButtonReference
    } = useContext(NotificationsContext);

    const unseenCount = getUnseenCount();

    useEffect(() => {
        setButtonReference(anchorElement);
    }, [anchorElement, setButtonReference]);

    return (
        <Tooltip title={getString("notifications")}>
			<IconButton 
                ref={anchorElement}
                className={classNames(styles.button, {
                    [styles.selected]: isPaneOpen
                })}
                color={isPaneOpen ? "primary" : "secondary"}
                onClick={() => setIsPaneOpen(!isPaneOpen)}>
                <NotificationsOutlined />
                <Typography variant="caption" className={classNames(styles.unseenDot, {
                    [styles.visible]: unseenCount
                })}>{unseenCount < 100 && unseenCount}</Typography>
			</IconButton>
		</Tooltip>
    );
}

export default observer(NotificationsButton);