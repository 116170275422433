import { makeAutoObservable } from "mobx";
import { createContext } from "react";
import { EMAIL_REGEX, PASSWORD_REGEX, PHONE_NUMBER_REGEX } from "utils/constants";
import { EMPTY_ACCOUNT } from "../types";
import { User } from "database/types";
import { translationService } from "infrastructure/services";

export class SignupStore {
    public account: User = EMPTY_ACCOUNT;
    public emailError: string = "";
    public password: string = "";
    public passwordError: string = "";
    public confirmPassword: string = "";
    public confirmPasswordError: string = "";
    public firstNameError: string = "";
    public lastNameError: string = "";
    public companyNameError: string = "";
    public phoneNumberError: string = "";
    public uniqueIdentificationCodeError: string = "";
    public agreeToTermsAndConditions: boolean = false;

    constructor() {
        makeAutoObservable(this);
    }

    public validateFields = () => {
        this.validateEmail();
        this.validatePassword();
        this.validateConfirmPassword();
        this.validatePhoneNumber();
        this.validateFirstName();
        this.validateLastName();
    }

    public setAccountType = (isIndividual: boolean) => {
        this.resetFields(isIndividual);
        this.validateEmail();
        this.validatePassword();
        this.validateConfirmPassword();
        this.validatePhoneNumber();
        if (isIndividual) {
            this.validateFirstName();
            this.validateLastName();
        } else {
            this.validateCompanyName();
            this.validateUniqueIdentificationCode();
        }
    }

    public setEmail = (email: string) => {
        this.account.email = email;
        this.validateEmail();
    }

    public setPassword = (password: string) => {
        this.password = password;
        this.validatePassword();
    }

    public setConfirmPassword = (confirmPassword: string) => {
        this.confirmPassword = confirmPassword;
        this.validateConfirmPassword();
    }

    public setFirstName = (firstName: string) => {
        this.account.firstName = firstName;
        this.validateFirstName();
    }

    public setLastName = (lastName: string) => {
        this.account.lastName = lastName;
        this.validateLastName();
    }

    public setCompanyName = (companyName: string) => {
        this.account.companyName = companyName;
        this.validateCompanyName();
    }

    public setPhoneNumber = (phoneNumber: string) => {
        this.account.phoneNumber = phoneNumber;
        this.validatePhoneNumber();
    }

    public setUniqueIdentificationCode = (uniqueIdentificationCode: string) => {
        this.account.uniqueIdentificationCode = uniqueIdentificationCode;
        this.validateUniqueIdentificationCode();
    }

    public setAgreement = (agreed: boolean) => {
        this.agreeToTermsAndConditions = agreed;
    }

    public resetFields = (isIndividual = true) => {
        this.account = EMPTY_ACCOUNT;
        this.emailError = "";
        this.password = "";
        this.passwordError = "";
        this.confirmPassword = "";
        this.confirmPasswordError = "";
        this.firstNameError = "";
        this.lastNameError = "";
        this.companyNameError = "";
        this.phoneNumberError = "";
        this.uniqueIdentificationCodeError = "";
        this.agreeToTermsAndConditions = false;
        this.account.isIndividual = isIndividual;
    }

    public validateEmail = () => {
        if (!this.account.email) {
            this.emailError = translationService.getString("emailRequired");
            return;
        }
        if (!EMAIL_REGEX.test(this.account.email.toLowerCase())) {
            this.emailError = translationService.getString("emailMustBeValid");
            return;
        }
        this.emailError = "";
    }

    public validatePassword = () => {
        if (!this.password) {
            this.passwordError = translationService.getString("passwordRequired");
            return;
        }
        if (!PASSWORD_REGEX.test(this.password)) {
            this.passwordError = translationService.getString("passwordMustBeValid");
            return;
        }
        this.passwordError = "";
    }

    public validateConfirmPassword = () => {
        if (!this.confirmPassword) {
            this.confirmPasswordError = translationService.getString("confirmPasswordRequired");
            return;
        }
        if (this.confirmPassword !== this.password) {
            this.confirmPasswordError = translationService.getString("confirmPasswordMustBeValid");
            return;
        }
        this.confirmPasswordError = "";
    }

    public validateFirstName = () => {
        if (!this.account.firstName) {
            this.firstNameError = translationService.getString("firstNameRequired");
            return;
        }
        this.firstNameError = "";
    }

    public validateLastName = () => {
        if (!this.account.lastName) {
            this.lastNameError = translationService.getString("lastNameRequired");
            return;
        }
        this.lastNameError = "";
    }

    public validateCompanyName = () => {
        if (!this.account.companyName) {
            this.companyNameError = translationService.getString("companyNameRequired");
            return;
        }
        this.companyNameError = "";
    }

    public validatePhoneNumber = () => {
        if (!this.account.phoneNumber) {
            this.phoneNumberError = translationService.getString("phoneNumberRequired");
            return;
        }
        if (!PHONE_NUMBER_REGEX.test(this.account.phoneNumber)) {
            this.phoneNumberError = translationService.getString("phoneNumberMustBeValid");
            return;
        }
        this.phoneNumberError = "";
    }

    public validateUniqueIdentificationCode = () => {
        if (!this.account.uniqueIdentificationCode) {
            this.uniqueIdentificationCodeError = translationService.getString("uniqueIdentificationCodeRequired");
            return;
        }
        this.uniqueIdentificationCodeError = "";
    }

    public isSignupValid = () => !this.emailError && !this.passwordError && !this.confirmPasswordError &&
        !this.firstNameError && !this.lastNameError && !this.companyNameError && !this.phoneNumberError &&
        !this.uniqueIdentificationCodeError && this.agreeToTermsAndConditions;

    public reset = () => {
        this.resetFields();
    }

}

export const signupStore = new SignupStore();
export const SignupContext = createContext(signupStore);