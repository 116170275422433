import React from "react";
import { useTranslation } from "infrastructure/hooks";
import { observer } from "mobx-react";
import { SingleSelectFilter } from "components";
import { TranslationStringKey } from "utils/translation/types";
import { VehiclesListStore } from "../vehicles-list-store";

interface Props {
    store: VehiclesListStore;
}

const FilterOwner = ({ store }: Props) => {
    const { getString } = useTranslation();

    const { selectedOwner } = store;

    return (
        <SingleSelectFilter
            onItemRender={value => getString(value as TranslationStringKey)}
            onSelect={selected => selectedOwner.selected = selected}
            value={selectedOwner.selected}
            values={selectedOwner.all}
            useAction
            label={getString("privateOrFirm")} />
    );
}

export default observer(FilterOwner);