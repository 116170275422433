import { createContext } from "react";
import { runInAction, makeAutoObservable } from "mobx";
import { MINIMUM_IMAGES, MAXIMUM_IMAGES } from "utils/constants";
import { createPost } from "../api";
import { getLocationAsync } from "utils/thread-utils";
import { uploadImage } from "database/storage";
import { generatePostDocument } from "database/api/post";
import { setPost as updatePost } from "database/api/post";
import { getLocationText, Location } from "utils/location-utils";
import { isUrl } from "utils/string-utils";
import { getCurrentTimeFormatted } from "utils/date-utils";
import { EMPTY_VEHICLE_POST, VehicleFormPost, VehiclePost } from "../types";
import { loadingService } from "infrastructure/services";

export class VehiclesFormStore {
    post: VehicleFormPost = EMPTY_VEHICLE_POST;
    isConfirmationOpen: boolean = false;
    initialLatitude: number = 0;
    initialLongitude: number = 0; 

    constructor() {
        makeAutoObservable(this);
    }

    public setPost = (post: VehicleFormPost) => {
        this.post = post;
        this.initialLatitude = post.latitude;
        this.initialLongitude = post.longitude;
    }

    public openConfirmation = () => this.isConfirmationOpen = true;

    public closeConfirmation = () => this.isConfirmationOpen = false;

    public arePostDetailsValid = () => (this.post.descriptionText?.en || this.post.descriptionText?.ro) &&
        (!this.post.hasBidding || this.post.biddingEndDate) &&
        this.post.images.length >= MINIMUM_IMAGES && this.post.images.length <= MAXIMUM_IMAGES &&
        this.post.latitude && this.post.longitude && this.post.mainImage && this.post.price &&
        (this.post.titleText?.en || this.post.titleText?.ro) && this.areCharacteristicsValid();

    public areCharacteristicsValid = () => this.post.brand && this.post.model &&
        this.post.fabricationYear && this.post.mileage && this.post.engineCapacity &&
        this.post.condition && this.post.fuel.length && this.post.body && this.post.color &&
        this.post.gearbox && this.post.owner;

    public savePost = async () => {
        loadingService.set(true);

        const postDocument = generatePostDocument();
        const locationDetails = await getLocationAsync({ ...this.post });
        const { country, county, city } = locationDetails;

        await createPost({
            ...(this.post as VehiclePost),
            ...await this.uploadImages(postDocument.id),
            country,
            county,
            city,
            location: getLocationText(this.trimZoneAndStreet(locationDetails), true)
        }, postDocument);
        loadingService.set(false);
    }

    public updatePost = async () => {
        loadingService.set(true);

        this.post.lastUpdateDate = new Date();

        if (this.post.latitude !== this.initialLatitude ||
            this.post.longitude !== this.initialLongitude) {
            const locationDetails = await getLocationAsync({ ...this.post });
            const { country, county, city } = locationDetails;

            this.post.country = country;
            this.post.county = county;
            this.post.city = city;
            this.post.location = getLocationText(this.trimZoneAndStreet(locationDetails), true);
        }

        await this.uploadNewImages();
        await updatePost(this.post);
        loadingService.set(false);
    }

    public setOwner = (owner: string) => {
        this.post.owner = owner;
    }

    public reset = () => {
        this.post = EMPTY_VEHICLE_POST;
        this.isConfirmationOpen = false;
    }

    private uploadImages = async (id: string) => {
        const mainImage = await uploadImage(id, this.post.mainImage);
        const images = await Promise.all(this.post.images.map((image, index) =>
            uploadImage(`${id}_${index}`, image)
        ));

        return {
            mainImage,
            images
        };
    }

    private uploadNewImages = async () => {
        const date = getCurrentTimeFormatted();
        const id = this.post.id;

        let mainImage = this.post.mainImage;
        if (!isUrl(mainImage)) {
            mainImage = await uploadImage(`${id}_${date}`, this.post.mainImage);
        }

        const images = await Promise.all(this.post.images.map((image, index) =>
            isUrl(image) ? image : uploadImage(`${id}_${index}_${date}`, image)));

        runInAction(() => {
            this.post.mainImage = mainImage;
            this.post.images = images;
        });
    }

    private trimZoneAndStreet = (locationDetails: Location): Location => ({
      ...locationDetails,
      zone: "",
      street: ""
    })
}

export const vehicleFormStore = new VehiclesFormStore();
export const VehicleFormContext = createContext(vehicleFormStore);