import React, { useRef } from "react";
import classNames from "classnames";
import { FormControlLabel, Select, InputLabel, MenuItem } from "@material-ui/core";
import styles from "./single-select.module.scss";
import { runInAction } from "mobx";

interface Props<T> {
    value: T;
    values: T[] | readonly string[];
    onSelect: (key: T) => void;
    onItemRender: (key: T) => JSX.Element | string;
    className?: string;
    required?: boolean;
    errorMessage?: string;
    label?: string;
    placeholder?: string;
    secondaryColor?: boolean;
    startImage?: JSX.Element;
    disabled?: boolean;
    useAction?: boolean;
}

let count = 0;

const SingleSelect = <T extends number | string>({
    value,
    values,
    onSelect,
    onItemRender,
    className,
    required,
    errorMessage,
    label,
    placeholder,
    secondaryColor,
    startImage,
    disabled,
    useAction
}: Props<T>) => {
    const counter = useRef(count++);
    const uniqueId = `single-select-dropdown-id-${counter.current}`;

    const onSelectWrapper = useAction ? 
        (key: T) => runInAction(() => onSelect(key)) : onSelect;

    return (
        <FormControlLabel
            className={classNames(styles.singleSelect, className, {
                [styles.withLabel]: label
            })}
            control={<>
                <InputLabel id={uniqueId}>{placeholder}</InputLabel>
                <Select
                    labelId={uniqueId}
                    displayEmpty
                    value={value}
                    color={secondaryColor ? "secondary" : "primary"}
                    error={!!errorMessage || (required && value === "")}
                    onChange={event => onSelectWrapper(event.target.value as T)}
                    startAdornment={startImage}
                    disabled={disabled}>
                    {values.map(value => (
                        <MenuItem key={value} value={value}>
                            {onItemRender(value as T)}
                        </MenuItem>
                    ))}
                </Select>
            </>}
            label={label}
            labelPlacement="start" />
    );
}

export default SingleSelect;