import classNames from "classnames";
import React, { PropsWithChildren } from "react";
import { Image } from "..";
import styles from "./main-image.module.scss";

interface Props {
    image: string;
    onClickPrevious: () => void;
    onClickNext: () => void;
    className?: string;
    onClickImage?: () => void;
}

const MainImage = ({
    image,
    onClickPrevious,
    onClickNext,
    className,
    onClickImage,
    children
}: PropsWithChildren<Props>) => (
    <div className={classNames(styles.selectedImage, className)}>
        <div
            className={classNames(styles.previousNextImage, styles.left)}
            onClick={onClickPrevious}>
            <Image src="https://img.icons8.com/ios/50/000000/circled-chevron-left.png" />
        </div>
        <Image
            src={image}
            className={classNames(styles.mainImage, {
                [styles.clickable]: onClickImage
            })}
            onClick={onClickImage} />
        <div
            className={classNames(styles.previousNextImage, styles.right)}
            onClick={onClickNext}>
            <Image src="https://img.icons8.com/ios/50/000000/circled-chevron-right.png" />
        </div>
        {children}
    </div>
);

export default MainImage;