import React from "react";
import { RealEstateListStore } from "../real-estate-list-store";
import { useTranslation } from "infrastructure/hooks";
import { observer } from "mobx-react";
import { BooleanSelect } from "components";

interface Props {
    store: RealEstateListStore;
}

const FilterElevator = ({ store }: Props) => {
    const { getString } = useTranslation();

    return (
        <BooleanSelect
            onSelect={value => store.withElevator = value}
            value={store.withElevator}
            useAction
            label={getString("elevator")} />
    );
}

export default observer(FilterElevator);