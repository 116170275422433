import React, { PropsWithChildren, useState } from 'react';
import { useScrollTopCallback } from '../../utils/ui-utils';
import { CSSTransition } from 'react-transition-group';
import './transitions.scss';

interface Props {
    classNames: string;
    timeout: number;
    selector?: string;
}

const ScrollTopTransition = ({ selector, classNames, timeout, children }: PropsWithChildren<Props>) => {

    const [active, setActive] = useState(false);

    useScrollTopCallback(selector, () => setActive(true));

    return (
        <CSSTransition in={active} timeout={timeout} classNames={classNames}>
            {children}
        </CSSTransition>
    );
}

export default ScrollTopTransition;