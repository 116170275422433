import { Button, Menu, MenuItem } from "@material-ui/core";
import { MoreVert } from "@material-ui/icons";
import React, { useState } from "react";
import { useTranslation } from "infrastructure/hooks";
import { Action } from "./types";

interface Props {
    actions: Action[]
}

const ActionsSelection = ({ actions }: Props) => {
    const { getString } = useTranslation();
    const [anchorEl, setAnchorEl] = useState<Element | null>(null);

    const handleClose = () => setAnchorEl(null);

    return <>
        <Button
            variant="contained"
            color="secondary"
            aria-haspopup="true"
            endIcon={<MoreVert />}
            onClick={event => setAnchorEl(event?.currentTarget)}>
            {getString("actions")}
        </Button>
        <Menu
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}>
                {actions.map((action, index) => (
                    <MenuItem
                        key={index}
                        onClick={() => {
                            handleClose();
                            action.onClick();
                        }}>
                        {getString(action.label)}
                    </MenuItem>
                ))}
        </Menu>
    </>;
}

export default ActionsSelection;