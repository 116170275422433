import React, { useEffect, useContext, ChangeEvent } from "react";
import Dropdown from "../../components/dropdown/index";
import { PostsContext } from "./posts-store";
import { observer } from "mobx-react";
import { useTranslation } from "infrastructure/hooks";
import styles from "./posts.module.scss";
import sharedStyles from "./../../admin.module.scss";
import classNames from "classnames";
import { PostsGrid } from "../../../categories/components/posts-grid";
import { useNavigation } from "utils/router-utils";
import { QUERY_AUTHOR_ID } from "utils/constants";
import { ActionsSelection, ExtendBiddingDeadlines } from "./components";

const Posts = () => {
    const { getString } = useTranslation();
    const { getQueryValue } = useNavigation();

    const postsStore = useContext(PostsContext);

    const {
        getFilteredPosts,
        categoryOptionIndex,
        operationOptionIndex,
        activeOptionIndex,
        verifiedOptionIndex,
        activeOptions,
        operationOptions,
        verifiedOptions,
        categoryOptions,
        searchValue,
        isExtendBiddingsOpen,
        fetchPosts,
        setCategoryOptionIndex,
        setOperationOptionIndex,
        setActiveOptionIndex,
        setVerifiedOptionIndex,
        setSearchValue,
        openExtendBiddings,
        closeExtendBiddings
    } = postsStore;

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => setSearchValue(event.target.value);

    useEffect(() => {
        fetchPosts(getQueryValue(QUERY_AUTHOR_ID));
    }, [fetchPosts, getQueryValue]);

    const getPostCategory = (postId: string) => {
        const post = getFilteredPosts().find(post => post.id === postId);
        return post?.category;
    }

    return <>
        <div className={styles.adminPostsWrapper}>
            <div className={sharedStyles.header}>
                <div className={classNames(sharedStyles.searchBar, sharedStyles.miraCard)}>
                    <input
                        type="text"
                        value={searchValue}
                        onChange={handleChange}
                        placeholder={`${getString("search")}...`}
                    />
                </div>
                <Dropdown
                    onChange={(newValue: number) => setCategoryOptionIndex(newValue)}
                    value={categoryOptionIndex}
                    items={categoryOptions} />
                <Dropdown
                    onChange={(newValue: number) => setOperationOptionIndex(newValue)}
                    value={operationOptionIndex}
                    items={operationOptions} />
                <Dropdown
                    onChange={(newValue: number) => setActiveOptionIndex(newValue)}
                    value={activeOptionIndex}
                    items={activeOptions} />
                <Dropdown
                    onChange={(newValue: number) => setVerifiedOptionIndex(newValue)}
                    value={verifiedOptionIndex}
                    items={verifiedOptions} />
                <ActionsSelection actions={[{
                    label: "extendBiddingDeadlines",
                    onClick: openExtendBiddings
                }]} />
            </div>
            <PostsGrid
                className={styles.postsGrid}
                posts={getFilteredPosts()}
                providePostCategory={postId => getPostCategory(postId) || ""}
            />
        </div>
        <ExtendBiddingDeadlines isOpen={isExtendBiddingsOpen} onClose={closeExtendBiddings} />
    </>;
}

export default observer(Posts);