import React from "react";
import { RealEstateListStore } from "../real-estate-list-store";
import { observer } from "mobx-react";
import { RangePicker } from "components";
import { useTranslation } from "infrastructure/hooks";

interface Props {
    store: RealEstateListStore;
}

const FilterStories = ({ store }: Props) => {
    const { getString } = useTranslation();

    const { rangeStories, setStoriesRange } = store;

    return (
        <RangePicker 
            minValue={rangeStories.min}
            maxValue={rangeStories.max}
            min={rangeStories.lowest}
            max={rangeStories.greatest}
            label={getString("totalStories")}
            useAction
            onChangeMin={min => rangeStories.min = min}
            onChangeMax={max => rangeStories.max = max}
            onChangeMinMax={setStoriesRange} />
    );
}

export default observer(FilterStories);