import { observer } from "mobx-react";
import React from "react";
import { useContext } from "react";
import NotificationPopupElement from "./notification-popup-element";
import { NotificationPopupContext } from "./notification-popup-store";
import styles from "./notification-popup.module.scss";

const NotificationPopup = () => {
    const { notificationStack, onClick } = useContext(NotificationPopupContext);

    return (
        <div className={styles.popupContainer}>
            {notificationStack.map(notification => (
                <NotificationPopupElement 
                    key={notification.id} 
                    notification={notification} 
                    onClick={({ id }) => onClick(id)} />
            ))}
        </div>
    );
};

export default observer(NotificationPopup);