import React from "react";
import { observer } from "mobx-react";
import {
    partitioningOptions,
    floorOptions,
    balconiesTypeOptions,
    purposeOptions
} from "../../real-estate/types";
import { NumericInput, SingleSelect, MultiSelect, BoldText } from "components";
import { useTranslation } from "infrastructure/hooks";
import { getMomentAfterYears } from "utils/date-utils";
import { TranslationStringKey } from "utils/translation/types";
import { fill } from "utils/array-utils";
import { runInAction } from "mobx";
import { Checkbox } from "@material-ui/core";
import styles from "./for-rent-form-characteristics.module.scss";
import { ForRentFormPost } from "../types";

interface Props {
    post: ForRentFormPost
}

const tenYearsAhead = getMomentAfterYears(10).get("y");

const ForRentAddCharacteristics = ({ post }: Props) => {
    const { getString } = useTranslation();

    const handleFloorRender = (value: string) => {
        if (floorOptions.includes(value)) {
            return getString(value as TranslationStringKey);
        }
        return value;
    }

    const handleNumberOfBalconiesChange = (value: number | null) => runInAction(() => {    
        post.numberOfBalconies = value;

        if (!value)
            post.balconiesType = [];
    });

    return (
        <div className={styles.forRentAddCharacteristicsWrapper}>
            <div className={styles.characteristicWrapper}>
                <BoldText>{getString("constructionYear")}</BoldText>
                <NumericInput
                    required
                    value={post.constructionYear}
                    useAction
                    onChange={value => post.constructionYear = value}
                    max={tenYearsAhead}
                    min={1850} />
            </div>
            <div className={styles.characteristicWrapper}>
                <BoldText>{getString("renovationYear")}</BoldText>
                <NumericInput
                    required
                    value={post.renovationYear}
                    useAction
                    onChange={value => post.renovationYear = value}
                    max={tenYearsAhead}
                    min={post.constructionYear || 1850} />
            </div>
            <div className={styles.characteristicWrapper}>
                <BoldText>{getString("partitioning")}</BoldText>
                <SingleSelect
                    required
                    values={partitioningOptions}
                    value={post.partitioning}
                    useAction
                    onItemRender={key => getString(key as TranslationStringKey)}
                    onSelect={key => post.partitioning = key} />
            </div>
            <div className={styles.characteristicWrapper}>
                <BoldText>{getString("floor")}</BoldText>
                <div className={styles.floorPicker}>
                    <SingleSelect
                        required
                        useAction
                        values={[
                            ...floorOptions,
                            ...(fill(Math.min(post.levels ?? 38, 38), 1) as unknown as string[])
                        ]}
                        value={post.floor}
                        onItemRender={handleFloorRender}
                        onSelect={value => post.floor = value} />
                        {post.floor !== "house" && (
                            <div className={styles.levelsInput}>
                                /
                                <NumericInput
                                    required
                                    onChange={value => post.levels = value}
                                    value={post.levels}
                                    min={0}
                                    max={40}
                                    placeholder={getString("totalStories")} />
                            </div>
                        )}
                </div>
            </div>
            <div className={styles.characteristicWrapper}>
                <BoldText>{getString("numberOfRooms")}</BoldText>
                <NumericInput
                    required
                    useAction
                    value={post.numberOfRooms}
                    onChange={value => post.numberOfRooms = value}
                    max={30}
                    min={1} />
            </div>
            <div className={styles.characteristicWrapper}>
                <BoldText>{getString("numberOfBedrooms")}</BoldText>
                <NumericInput
                    required
                    useAction
                    value={post.numberOfBedrooms}
                    onChange={value => post.numberOfBedrooms = value}
                    max={30}
                    min={1} />
            </div>
            <div className={styles.characteristicWrapper}>
                <BoldText>{getString("numberOfBathrooms")}</BoldText>
                <NumericInput
                    required
                    useAction
                    value={post.numberOfBathrooms}
                    onChange={value => post.numberOfBathrooms = value}
                    max={10}
                    min={1} />
            </div>
            <div className={styles.characteristicWrapper}>
                <BoldText>{getString("numberOfBathroomsWithWindow")}</BoldText>
                <NumericInput
                    required
                    useAction
                    value={post.numberOfBathroomsWithWindow}
                    onChange={value => post.numberOfBathroomsWithWindow = value}
                    max={post.numberOfBathrooms || 10}
                    min={0} />
            </div>
            <div className={styles.characteristicWrapper}>
                <BoldText>{getString("numberOfBalconies")}</BoldText>
                <NumericInput
                    required
                    useAction
                    value={post.numberOfBalconies}
                    onChange={handleNumberOfBalconiesChange}
                    max={10}
                    min={0} />
            </div>
            {!!post.numberOfBalconies && (
                <div className={styles.characteristicWrapper}>
                    <BoldText>{getString("balconiesType")}</BoldText>
                    <MultiSelect
                        required
                        useAction
                        values={balconiesTypeOptions}
                        selectedValues={post.balconiesType}
                        onItemRender={key => getString(key as TranslationStringKey)}
                        onSelect={keys => post.balconiesType = keys} />
                </div>
            )}
            <div className={styles.characteristicWrapper}>
                <BoldText>{getString("purpose")}</BoldText>
                <SingleSelect
                    required
                    useAction
                    values={purposeOptions}
                    value={post.purpose}
                    onItemRender={key => getString(key as TranslationStringKey)}
                    onSelect={key => post.purpose = key} />
            </div>
            <div className={styles.characteristicWrapper}>
                <BoldText>{getString("usableArea")} (m<sup>2</sup>)</BoldText>
                <NumericInput
                    required
                    useAction
                    value={post.surface}
                    onChange={value => post.surface = value}
                    max={10000}
                    min={1} />
            </div>
            <div className={styles.characteristicWrapper}>
                <BoldText>{getString("parkingSpace")}</BoldText>
                <Checkbox
                    color="primary"
                    checked={post.parkingSpace}
                    onChange={event => 
                        runInAction(() => post.parkingSpace = event.target.checked)
                    } />
            </div>
            <div className={styles.characteristicWrapper}>
                <BoldText>{getString("hasElevator")}</BoldText>
                <Checkbox
                    color="primary"
                    checked={post.hasElevator}
                    onChange={event => 
                        runInAction(() => post.hasElevator = event.target.checked)
                    } />
            </div>
            <div className={styles.characteristicWrapper}>
                <BoldText>{getString("petFriendly")}</BoldText>
                <Checkbox
                    color="primary"
                    checked={post.petFriendly}
                    onChange={event => 
                        runInAction(() => post.petFriendly = event.target.checked)
                    } />
            </div>
        </div>
    );
}

export default observer(ForRentAddCharacteristics);