import { BidRange } from "../types";

export default class BidRanges {
    private ranges: BidRange[] = [{
        min: 1,
        value: 1,
        max: 100
    }, {
        min: 101,
        value: 5,
        max: 1000
    }, {
        min: 1001,
        value: 10,
        max: 10000
    }, {
        min: 10001,
        value: 50,
        max: 100000
    }, {
        min: 100001,
        value: 100,
        max: 500000
    }, {
        min: 500001,
        value: 500,
        max: 1000000
    }, {
        min: 1000001,
        value: 1000,
        max: 10000000
    }];

    public getClosestPrice = (forPrice: number) =>
        forPrice - forPrice % this.getRangeValue(forPrice);

    public getLowerPrice = (price: number) => price - this.getRangeValue(price);

    public getHigherPrice = (price: number) => price + this.getRangeValue(price + 1);
    
    private getRangeValue = (price: number) =>
        this.ranges.find(({ min, max }) => min <= price && price <= max)!.value;
}