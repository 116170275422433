import { USER_TYPE_USER } from "database/constants";
import { User } from "../../database/types";

interface ComplexRuleValue<T> {
    message: string;
    value: T;
}

type RuleValue<T> = T | ComplexRuleValue<T>;

export interface FieldWithValidationRules<FormModel> {
	name: keyof FormModel;
	required?: RuleValue<boolean>;
	minLength?: RuleValue<number>;
	maxLength?: RuleValue<number>;
	validate?: (value: number | string | boolean) => boolean | string;
	pattern?: RuleValue<RegExp>;
}

export interface FormValidationRules<FormModel> {
	[fieldName: string]: FieldWithValidationRules<FormModel>;
}

export const EMPTY_ACCOUNT: User = {
    id: "",
    email: "",
    isIndividual: true,
    firstName: "",
    lastName: "",
    companyName: "",
    phoneNumber: "",
    uniqueIdentificationCode: "",
    accountType: USER_TYPE_USER,
    verified: true,
    banned: false,
    bidsIds: [],
    createdPostsIds: [],
    joinDate: new Date(),
    banReasonId: "",
};