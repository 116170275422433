import React from "react";
import BanDialog from "./ban-dialog";
import ConfirmDialogService from "./confirm-dialog";
import LoadingOverlay from "./loading-overlay";
import TermsAndConditions from "./terms-and-conditions";
import Toast from "./toast";
import Notifications from "./notifications";
import NotificationsPane from "./notifications/notifications-pane";
import NotificationPopup from "./notifications/notification-popup";

const Services = () => <>
    <TermsAndConditions />
    <Notifications />
    <NotificationPopup />
    <NotificationsPane />
    <BanDialog />
    <ConfirmDialogService />
    <LoadingOverlay />
    <Toast />
</>;

export default Services;