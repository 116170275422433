import { LanguageType } from "./translation/types";

const QUERY_PARAM_SEPARATOR = "&";
const QUERY_KEY_VALUE_SEPARATOR = "=";

interface QueryParam {
    key: string;
    value: string;
}

export const getAppendedQueryParams = (queryParams: string, addedQueryParams: QueryParam[]): string => {
    const currentQueryParams = getCurrentQueryParamsExcluding(queryParams, addedQueryParams.map(x => x.key));

    const queryParamsString = `?${currentQueryParams
            .concat(addedQueryParams)
            .map(param => `${param.key}${QUERY_KEY_VALUE_SEPARATOR}${param.value}`)
            .join(QUERY_PARAM_SEPARATOR)}`;
          
    return queryParamsString;
}

const getCurrentQueryParamsExcluding = (currentParams: string, excluding: string[]) => {
    const queryParams = currentParams
        .substring(1)
        .split(QUERY_PARAM_SEPARATOR).map<QueryParam>(query => {
            const tokens = query.split(QUERY_KEY_VALUE_SEPARATOR);

            return {
                key: tokens[0],
                value: tokens[1] as LanguageType
            }
        })
        .filter(param => param.key.length > 0)
        .filter(option => !excluding.includes(option.key));

    return queryParams;
}