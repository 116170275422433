import { translationService } from "infrastructure/services";
import { makeAutoObservable } from "mobx";
import { createContext } from "react";
import { EMAIL_REGEX } from "utils/constants";

export class ForgotPasswordStore {
    public email: string = "";
    public emailError: string = "";

    constructor() {
        makeAutoObservable(this);
    }

    public setEmail = (email: string) => {
        this.email = email;
        this.validateEmail();
    };

    public validateEmail = () => {
        if (!this.email) {
            this.emailError = translationService.getString("emailRequired");
            return;
        }
        if (!EMAIL_REGEX.test(this.email.toLowerCase())) {
            this.emailError = translationService.getString("emailMustBeValid");
            return;
        }
        this.emailError = "";
    }

    public reset = () => {
        this.email = "";
        this.emailError = "";
    }

}

export const forgotPasswordStore = new ForgotPasswordStore();
export const ForgotPasswordContext = createContext(forgotPasswordStore);