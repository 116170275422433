import { action, makeAutoObservable } from "mobx";
import { createContext } from "react";
import { EMPTY_GET_STRING_FUNCTION, GetStringFunction } from "utils/translation/types";

export class TranslationStore {
    private needsUpdate: boolean = true;
    public getString: GetStringFunction = EMPTY_GET_STRING_FUNCTION;

    constructor() {
        makeAutoObservable(this, {
            initialize: action
        });
    }

    public initialize = (getString: GetStringFunction) => {
        if (!this.needsUpdate) {
            return;
        }
        this.needsUpdate = false;
        this.getString = getString;
    }

    public markForUpdate = () => this.needsUpdate = true;
} 

export const translationStore = new TranslationStore();
export const TranslationContext = createContext(translationStore);