import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from "@material-ui/core";
import { useUserDetails } from "infrastructure/services";
import React from "react";
import { auth } from "database/firebase";
import { CONTACT_PHONE, CONTACT_EMAIL } from "utils/constants";
import { useTranslation } from "infrastructure/hooks";
import SpanMessage from "./span-message";


const BanDialog = () => {
    const { getString, getText } = useTranslation(); 
    const { user } = useUserDetails();

    return (
        <Dialog open={!!user?.banned}>
            <DialogTitle>
                {getString("accountIsBanned")}
            </DialogTitle>
            <DialogContent>
                <SpanMessage>{getString("yourAccountIsBannedAndYouCannotUse")}</SpanMessage>
                <SpanMessage isBold>&nbsp;{getString("siteName")}</SpanMessage>
                <SpanMessage isLowerCase>, {getString("reason")}:&nbsp;</SpanMessage>
                <SpanMessage isBold>{getText(user?.banReason, getString("unknown"))}</SpanMessage>
                <SpanMessage>. {getString("ifYouThinkThisIsAMistake")}&nbsp;</SpanMessage>
                <SpanMessage isBold>{CONTACT_PHONE}</SpanMessage>
                <SpanMessage isLowerCase>&nbsp;{getString("orEmail")}&nbsp;</SpanMessage>
                <SpanMessage isBold>{CONTACT_EMAIL}</SpanMessage>
                <SpanMessage>.</SpanMessage>
            </DialogContent>
            <DialogActions>
                <Button onClick={auth.signOut}>{getString("logout")}</Button>
            </DialogActions>
        </Dialog>
    );
}

export default BanDialog;