import { TranslationStringKey } from "./translation/types";
import { useTranslation } from "infrastructure/hooks";
import { useCallback } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { QUERY_PARAMETER_LANGUAGE } from "./translation/constants";

export interface Path {
    url: string;
    label: TranslationStringKey;
}

interface UrlWithQuery {
    pathname?: string;
    search?: string;
}

interface Query {
    key: string;
    value: string | number | boolean;
}

export interface Navigation {
    goTo: (url: string, useLanguage?: "" | "ro" | "en") => void;
    goToWithQuery: (url: string, queries: Query[], useLanguage?: "" | "ro" | "en") => void;
    goHome: (useLanguage?: "" | "ro" | "en") => void;
    goBack: () => void;
    getUrlWithLanguageQuery: (url: string) => UrlWithQuery;
    getQueryValue: (key: string) => string | null;
}

export const getBaseUrl = (path: string) =>
    path.split("/")[1];

export const arePathsSimilar = (pathA: string, pathB: string) =>
    getBaseUrl(pathA) === getBaseUrl(pathB);

export const useNavigation = (): Navigation => {
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const { getCurrentLanguageCode } = useTranslation();
    
    const goTo = (url: string, useLanguage: "" | "ro" | "en" = "") =>
        goToWithQuery(url, [], useLanguage);

    const goToWithQuery = (url: string, queries: Query[], useLanguage?: "" | "ro" | "en") => {
        const stringQuery = queries.length ? queries.map(({ key, value }) =>
            `${key}=${value}&`
        ) : "";

        const languageQueryValue = useLanguage ? useLanguage : getCurrentLanguageCode();

        navigate({
            pathname: url,
            search: `?${stringQuery}${QUERY_PARAMETER_LANGUAGE}=${languageQueryValue}`
        });
    }

    const goHome = (useLanguage: "" | "ro" | "en" = "") => goTo("/", useLanguage);

    const getUrlWithLanguageQuery = (url: string) => ({
        pathname: url,
        search: `?${QUERY_PARAMETER_LANGUAGE}=${getCurrentLanguageCode()}`
    });
    
    const getQueryValue = useCallback((key: string) =>
        searchParams ? searchParams.get(key) : null,
        [searchParams]
    );

    return {
        goTo,
        goToWithQuery,
        goHome,
        goBack: () => navigate(-1),
        getUrlWithLanguageQuery,
        getQueryValue,
    }
}