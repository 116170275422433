import React, {
    useEffect,
    useState,
    useRef,
    useCallback
} from "react";
import styles from "./bid-input.module.scss";
import classNames from "classnames";
import { Button } from "@material-ui/core";
import { useTranslation } from "infrastructure/hooks";
import { getFormattedPrice } from "utils/number-utils";
import { Bid } from "database/types";
import BidRanges from "./bid-ranges";
import { sleep } from "utils/thread-utils";

interface Props {
    wantedPrice: number;
    bids: Bid[];
    onBid: (price: number) => void;
    className?: string;
    minPrice?: number;
    maxPrice?: number;
}

const bidRanges = new BidRanges();

const BidInput = ({ wantedPrice, bids, onBid, className, minPrice, maxPrice }: Props) => {
    const { getString } = useTranslation();

    const minBid = bids.length ? bidRanges.getHigherPrice(bids[bids.length - 1].value) : (minPrice ?? 1);

    const [price, setPrice] = useState(
        bids.length ? minBid : bidRanges.getClosestPrice(wantedPrice)
    );

    const mouseDown = useRef(false);

    const lower = useCallback(() => (
        price > minBid && setPrice(price => bidRanges.getLowerPrice(price))
    ), [price, minBid, setPrice]);

    const raise = useCallback(() => (
        (!maxPrice || price < maxPrice) && setPrice(price => bidRanges.getHigherPrice(price))
    ), [price, maxPrice, setPrice]);

    useEffect(() => {
        const handleKeyDown = (event: KeyboardEvent) => {
            const { key } = event;

            if (key === "ArrowDown") {
                lower();
                event.preventDefault();
            } else if (key === "ArrowUp") {
                raise();
                event.preventDefault();
            }
        }

        window.addEventListener("keydown", handleKeyDown);

        return () => window.removeEventListener("keydown", handleKeyDown);
    });

    const decrementPrice = useCallback(async () => {
        while (mouseDown.current) {
            lower();
            await sleep(150);
        }
    }, [mouseDown, lower]);

    const incrementPrice = useCallback(async () => {
        while (mouseDown.current) {
            raise();
            await sleep(150);
        }
    }, [mouseDown, raise]);

    return (
        <div className={classNames(styles.bidInputWrapper, className)}>
            <div className={styles.selector}>
                <Button
                    className={styles.button}
                    variant="outlined"
                    color="secondary"
                    onMouseDown={() => { mouseDown.current = true; decrementPrice()}}
                    onMouseUp={() => mouseDown.current = false} >
                    -
                </Button>
                <div className={styles.price}>
                    {getFormattedPrice(price)}
                </div>
                <Button
                    className={styles.button}
                    variant="outlined"
                    color="secondary"
                    onMouseDown={() => { mouseDown.current = true; incrementPrice()}}
                    onMouseUp={() => mouseDown.current = false} >
                    +
                </Button>
            </div>
            <Button className={styles.submit} color="secondary" onClick={() => onBid(price)}>
                {getString("send")}
            </Button>
        </div>
    );
}

export default BidInput;