import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Typography
} from "@material-ui/core";
import { DatePicker } from "@material-ui/pickers";
import { Moment } from "moment";
import React, { useState } from "react";
import { NumericInput } from "components";
import { extendBiddingPeriod } from "database/api/post";
import { getCurrentMoment, getMomentAfterDays } from "utils/date-utils";
import { useTranslation } from "infrastructure/hooks";
import styles from "./extend-bidding-deadlines.module.scss";

interface Props {
    isOpen: boolean;
    onClose: () => void;
}

const momentAfter90Days = getMomentAfterDays(90);

const ExtendBiddingDeadlines = ({ isOpen, onClose }: Props) => {
    const { getString } = useTranslation();
    const [daysToExpire, setDaysToExpire] = useState<number | null>(0);
    const [newDate, setNewDate] = useState<Moment | null>(momentAfter90Days);

    const handleExtend = () => {
        if (newDate === null || daysToExpire === null) {
            return;
        }
        extendBiddingPeriod(newDate.toDate(), daysToExpire);
        onClose();
    }

    return (
        <Dialog open={isOpen} onClose={onClose}>
            <DialogTitle>{getString("extendBiddingDeadlines")}</DialogTitle>
            <DialogContent className={styles.dialogContent}>
                <Typography>{getString("extendAllPostsBiddingsThatWillExpire")}</Typography>
                <NumericInput
                    value={daysToExpire}
                    onChange={setDaysToExpire}
                    className={styles.daysInput}
                    min={0}
                    max={30}
                    required />
                <Typography className={styles.lowerCase}>{getString("daysUntil")}</Typography>
                <DatePicker
                    value={newDate}
                    onChange={setNewDate}
                    className={styles.dateInput}
                    minDate={getCurrentMoment()}
                    maxDate={momentAfter90Days}
                    variant="inline"
                    format="DD.MM.yyyy"
                    required />
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>
                    {getString("close")}
                </Button>
                <Button onClick={handleExtend} color="primary" disabled={daysToExpire === null}>
                    {getString("extend")}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default ExtendBiddingDeadlines;