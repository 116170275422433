import React from "react";
import { useTranslation } from "infrastructure/hooks";
import { Dialog, DialogTitle, DialogContentText, DialogActions, Button } from "@material-ui/core";
import { SlideUpAnimation } from "../..";
import styles from "./confirm-dialog.module.scss";

interface Props {
    open: boolean;
    onCancel: () => void;
    onConfirm: () => void;
    confirmMessage: string;
    description: string;
}

const ConfirmDialog = ({ open, onCancel, onConfirm, description, confirmMessage }: Props) => {
    const { getString } = useTranslation();

    return (
        <Dialog open={open} onClose={onCancel} TransitionComponent={SlideUpAnimation}>
            <DialogTitle>{getString("areYouSure")}</DialogTitle>
            <DialogContentText className={styles.dialogConfirmText}>{description}</DialogContentText>
            <DialogActions>
                <Button onClick={onCancel}>{getString("cancel")}</Button>
                <Button color="primary" onClick={onConfirm}>
                    {confirmMessage}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default ConfirmDialog;