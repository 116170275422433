import React, {useContext} from "react";
import TableRow from "./table-row";
import { useTranslation } from "infrastructure/hooks";
import UserDetails from "../user-details";
import { UsersStore } from "../../users-store";
import { observer } from "mobx-react";
import { NotFoundMessage } from "components";
import styles from "./table.module.scss";
import { UserDetailsContext } from "../user-details/user-details-store";

interface Props {
    usersStore: UsersStore;
}

const Table = ({ usersStore }: Props) => {
    const { getString } = useTranslation();

    const {
        getFilteredUsers
    } = usersStore;

    const userDetailsStore = useContext(UserDetailsContext);

    const {
        setActiveUser
    } = userDetailsStore;

    const filteredUsers = getFilteredUsers();

    return <>
        <UserDetails userDetailsStore={userDetailsStore} />
        {filteredUsers.length ? (
            <div className={styles.tableWrapper}>
                <table>
                    <thead>
                        <tr>
                            <th>email</th>
                            <th>{getString("firstName")}</th>
                            <th>{getString("lastName")}</th>
                            <th>{getString("userType")}</th>
                            <th>{getString("accountType")}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {filteredUsers.map(user => (
                            <TableRow onClick={() => setActiveUser(user)} user={user} key={user.id} />
                        ))}
                    </tbody>
                </table>
            </div>
        ) : (
            <NotFoundMessage message={getString("noUsers")} />
        )}
    </>
}

export default observer(Table);
