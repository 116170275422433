import { toJS } from "mobx";

export const contains = (stringToTest: string | undefined, key: string) =>
    !!stringToTest && normalize(stringToTest).includes(normalize(key));

export const normalize = (text: string) => replaceDiacritics(text.trim().toLowerCase());

export const replaceDiacritics = (text: string) =>
    toJS(text).replace(/[ăâ]/, "a")
        .replace(/[ĂÂ]/g, "A")
        .replace(/î/g, "i")
        .replace(/Î/g, "I")
        .replace(/ș/g, "s")
        .replace(/Ș/g, "S")
        .replace(/ț/g, "t")
        .replace(/Ț/g, "T")

export const splitIntoKeywords = (text: string) =>
    (text && text.split(" ").filter(key => key)) || [];

export const searchFieldsWithKeywords = (keywords: string[], ...fields: (string | undefined)[]) => {
    if (!keywords || !keywords.length) {
        return true;
    }

    return keywords.some(key => fields.some(field => contains(field, key)));
}

export const searchFields = (searchText: string, ...fields: (string | undefined)[]) => {
    const keywords = splitIntoKeywords(searchText);

    return searchFieldsWithKeywords(keywords, ...fields);
}

export const isUrl = (text: string) => /http./.test(text);

