import { observer } from "mobx-react";
import React from "react";
import { RangePicker } from "components";
import { useTranslation } from "infrastructure/hooks";
import { VehiclesListStore } from "../vehicles-list-store";

interface Props {
    store: VehiclesListStore;
}

const FilterEngineCapacity = ({ store }: Props) => {
    const { getString } = useTranslation();

    const { rangeEngineCapacity, setEngineCapacityRange } = store;

    return (
        <RangePicker
            minValue={rangeEngineCapacity.min}
            maxValue={rangeEngineCapacity.max}
            min={rangeEngineCapacity.lowest}
            max={rangeEngineCapacity.greatest}
            label={<>{getString("engineCapacity")}&nbsp;(cm<sup>3</sup>/kW)</>}
            onChangeMin={min => rangeEngineCapacity.min = min}
            onChangeMax={max => rangeEngineCapacity.max = max}
            useAction
            onChangeMinMax={setEngineCapacityRange} />
    )
}

export default observer(FilterEngineCapacity);