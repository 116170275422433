import { makeAutoObservable } from "mobx";
import { createContext } from "react";

export class TermsAndConditionsStore {
    public isOpen: boolean = false;

    constructor() {
        makeAutoObservable(this);
    }

    public setIsOpen = (isOpen: boolean) => this.isOpen = isOpen;
}

export const termsAndConditionsStore = new TermsAndConditionsStore();
export const TermsAndConditionsContext = createContext(termsAndConditionsStore);

export const openTermsAndConditions = () => termsAndConditionsStore.setIsOpen(true);