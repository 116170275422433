import { loadingService } from "infrastructure/services";
import { observer } from "mobx-react";
import React from "react";
import { useContext, useEffect } from "react";
import { auth } from "database/firebase";
import { isNotEmpty, sortBy } from "utils/array-utils";
import { CATEGORY_VEHICLES } from "utils/constants";
import { isInRange } from "utils/number-utils";
import { searchFields } from "utils/string-utils";
import { useTranslation } from "infrastructure/hooks";
import { PostsFilterBar, PostsGrid } from "../../components/posts-grid";
import {
    Range,
    MultiSelect,
    SORT_BY_ASCENDING_DATE,
    SORT_BY_DESCENDING_DATE,
    SORT_BY_DESCENDING_PRICE,
    SingleSelect
} from "../../types";
import { VehiclePost } from "../types";
import VehiclesListFilters from "./vehicles-list-filters";
import { VehiclesListContext } from "./vehicles-list-store";

const isValueInRange = (num: number, range: Range) =>
    isInRange(num, range.min, range.max);

const isValueInSelected = (value: string, select: MultiSelect) =>
    !select.selected.length || select.selected.includes(value);

const isValueSelected = (value: string, select: SingleSelect) =>
    !select.selected || select.selected === value;

const areValuesInSelected = (values: string[], select: MultiSelect) =>
    !select.selected.length || select.selected.every(value => values.includes(value));
    
const getPostPrice = (post: VehiclePost) => isNotEmpty(post.bids) ?
    post?.bids![post?.bids!.length - 1].value : post.price;

const VehiclesList = () => {
    const { getText } = useTranslation();

    const vehiclesStore = useContext(VehiclesListContext);

    const {
        allPosts,
        fetchPosts,

        search,
        sortingBy,
        filterMyPosts,
        priceRange,
        selectedCounties,
        selectedCities,
        selectedBrands,
        selectedModels,
        rangeFabricationYear,
        rangeMileage,
        rangeEngineCapacity,
        selectedCondition,
        selectedFuels,
        selectedBodies,
        selectedColors,
        selectedGearbox,
        selectedOwner
    } = vehiclesStore;

    useEffect(() => {
        fetchPosts();
    }, [fetchPosts]);

    const onlyInTheCurrentLanguage = (post: VehiclePost) =>
        getText(post.titleText) && getText(post.descriptionText);

    const searchPost = (post: VehiclePost) => searchFields(
        search,
        post.id,
        post.titleText!.en,
        post.titleText!.ro,
        post.descriptionText!.en,
        post.descriptionText!.ro
    );

    const filterPost = (post: VehiclePost) => {
        const price = getPostPrice(post);

        return filterMyPosts === (post.createdByUserId === auth.getCurrentUser()?.uid) &&
            isValueInRange(price, priceRange) &&
            isValueInSelected(post.county, selectedCounties) &&
            isValueInSelected(post.city, selectedCities) &&
            isValueInSelected(post.brand, selectedBrands) &&
            isValueInSelected(post.model, selectedModels) &&
            isValueInRange(post.fabricationYear, rangeFabricationYear) &&
            isValueInRange(post.mileage, rangeMileage) &&
            isValueInRange(post.engineCapacity, rangeEngineCapacity) &&
            isValueInSelected(post.condition, selectedCondition) &&
            areValuesInSelected(post.fuel, selectedFuels) &&
            isValueInSelected(post.body, selectedBodies) &&
            isValueInSelected(post.color, selectedColors) &&
            isValueSelected(post.gearbox, selectedGearbox) &&
            isValueSelected(post.owner, selectedOwner);
    }

    const searchAndFilterPost = (post: VehiclePost) => 
        onlyInTheCurrentLanguage(post) && searchPost(post) && filterPost(post);

    const filteredProducts = sortBy(allPosts.filter(searchAndFilterPost), post =>
        sortingBy === SORT_BY_ASCENDING_DATE || sortingBy === SORT_BY_DESCENDING_DATE ?
            post.createDate : getPostPrice(post),
        sortingBy === SORT_BY_DESCENDING_DATE || sortingBy === SORT_BY_DESCENDING_PRICE
    );

    return <>
        <PostsFilterBar store={vehiclesStore}>
            <VehiclesListFilters store={vehiclesStore} />
        </PostsFilterBar>
        {!loadingService.activeLoadings && (
            <PostsGrid
                hide={!!loadingService.activeLoadings}
                posts={filteredProducts}
                postCategory={CATEGORY_VEHICLES} />
        )}
    </>;
}

export default observer(VehiclesList);