import { Typography, Button } from "@material-ui/core";
import classNames from "classnames";
import { Image, BoldText } from "components";
import { AuctionNotificationType } from "database/types";
import { useTranslation } from "infrastructure/hooks";
import React from "react";
import { useSearchParams, NavLink } from "react-router-dom";
import { QUERY_SEARCH } from "utils/constants";
import styles from "./auction-notification-element.module.scss";

interface Props {
    notification: AuctionNotificationType;
    isAdmin: boolean;
    compact?: boolean;
}

const AuctionNotificationElement = ({ notification, isAdmin, compact }: Props) => {
    const { getString, isRomanian } = useTranslation();
    const [searchParams] = useSearchParams();

    const lastName = isAdmin ? ` ${notification.userLastName}` : "";
    
    const initialQuery = searchParams.toString();
    searchParams.set(QUERY_SEARCH, notification.userId);
    const userQuery = searchParams.toString();
    
    return <>
        <Typography variant="h6" className={styles.small}>
            {notification.userFirstName}{lastName} {getString("bidOn")} {getString("aPost")}
        </Typography>
        <div className={styles.postDetails}>
            <Image src={notification.postMainImage} className={classNames({
                [styles.compact]: compact
            })} />
            <div className={styles.postTitleAndBid}>
                <Typography variant="h6" className={styles.ellipsis}>
                    {isRomanian() ? notification.postTitleRo : notification.postTitleEn}
                </Typography>
                <BoldText variant="h5">€{notification.amount}</BoldText>
            </div>
        </div>
            <div className={styles.auctionActions}>
                {isAdmin && (
                    <NavLink to={`/admin/users?${userQuery}`}>
                        <Button color="primary">{getString("seeBidder")}</Button>
                    </NavLink>
                )}
                <NavLink to={`/${notification.postCategory}/${notification.postId}?${initialQuery}`}>
                    <Button color="primary">{getString("seePost")}</Button>
                </NavLink>
            </div>
    </>;
}

export default AuctionNotificationElement;