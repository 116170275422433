import SoundBoy from "@goodboydigital/soundboy";
import { observer } from "mobx-react";
import { useContext, useEffect } from "react";
import useUserDetails from "../user-details";
import { NotificationPopupContext } from "./notification-popup/notification-popup-store";
import { NotificationsContext } from "./notifications-store";

const SOUND_NOTIFICATION = "notification";

SoundBoy.registerSound({
    id: SOUND_NOTIFICATION,
    src: "sounds/notification.mp3",
    preload: true
})

const playNotificationSound = () => SoundBoy.playSfx(SOUND_NOTIFICATION);

const Notifications = () => {
    const { subscribe, reset } = useContext(NotificationsContext);
    const { add } = useContext(NotificationPopupContext);
    const { user } = useUserDetails();

    useEffect(() => {
        if (user) {
            return subscribe(user.id, notification => {
                add(notification);
                if (user.withNotificationSound ?? true) {
                    playNotificationSound();
                }
            });
        } else {
            reset();
        }
    }, [subscribe, reset, add, user]);

    return null;
};

export default observer(Notifications);