import React from "react";
import {
	Checkbox as MaterialCheckbox,
	CheckboxProps as MaterialCheckboxProps,
	FormControlLabel
} from "@material-ui/core";

interface CheckboxProps extends MaterialCheckboxProps {
    value: boolean;
		className?: string;
    helperText?: string;
		label?: string;
}

const Checkbox = ({ value, helperText, label, className, ...rest }: CheckboxProps) => (
	<FormControlLabel
		control={
			<MaterialCheckbox checked={value} {...rest} />
		}
		className={className}
		label={label} />
);

export default Checkbox;
