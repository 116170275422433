import { createContext } from 'react';
import { runInAction, makeAutoObservable } from "mobx";
import { User, EMPTY_TEXT } from "database/types";
import { createText, deleteText } from "database/api/text";
import { setUser } from "database/api/user";
import { loadingService } from 'infrastructure/services';
import { USER_TYPE_ADMIN, USER_TYPE_USER } from 'database/constants';

export class UserDetailsStore {
    isBanDialogOpen: boolean = false;
    activeUser: User | null = null;

    constructor() {
        makeAutoObservable(this);
    }

    public setBanDialogOpen = (isOpen: boolean) => this.isBanDialogOpen = isOpen;

    public setActiveUser = (user: User | null) => {
        this.activeUser = user;
        if (this.activeUser)
            this.activeUser.banReason = EMPTY_TEXT;
    }

    public verifyUser = async () => {
        this.activeUser!.verified = true;
        loadingService.set(true);
        await setUser(this.activeUser!);
        loadingService.set(false);
    }

    public banUser = async () => {
        loadingService.set(true);
        const activeUser = this.activeUser!;
        activeUser.banned = true;
        activeUser.banReasonId = (await createText(activeUser!.banReason!)).id;
        
        await setUser(activeUser);

        runInAction(() => {
            this.activeUser = activeUser;
        });
        
        loadingService.set(false);
    }

    public unbanUser = async () => {
        loadingService.set(true);
        const activeUser = this.activeUser;
        await deleteText(activeUser!.banReasonId);
        activeUser!.banReasonId = "";
        activeUser!.banned = false;

        await setUser(this.activeUser!);

        runInAction(() => {
            this.activeUser = activeUser;
        });
        loadingService.set(false);
    }

    public updateIsUserAdmin = async () => {
        loadingService.set(true);
        this.activeUser!.accountType = this.activeUser!.accountType === USER_TYPE_ADMIN ?
            USER_TYPE_USER : USER_TYPE_ADMIN;
        await setUser(this.activeUser!);
        loadingService.set(false);
    }
}

export const userDetailsStore = new UserDetailsStore();
export const UserDetailsContext = createContext(userDetailsStore);