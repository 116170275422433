import React, { useState } from "react";
import styles from "./section-bids-for-post.module.scss";
import { BoldText, NotFoundMessage, SearchInput } from "components";
import { Bid, BasePost } from "database/types";
import { getFormattedPrice } from "utils/number-utils";
import { formatMoment, getMomentFromTime } from "utils/date-utils";
import { Button } from "@material-ui/core";
import { useTranslation } from "infrastructure/hooks";
import { searchFieldsWithKeywords, splitIntoKeywords } from "utils/string-utils";
import { NavLink, useSearchParams } from "react-router-dom";
import { QUERY_SEARCH } from "utils/constants";

interface Props {
    post: BasePost;
    bids: Bid[];
    isSuperAdmin: boolean;
}

const SectionBidsForPost = ({ bids, post, isSuperAdmin }: Props) => {
    const { getString } = useTranslation();
    const [searchParams] = useSearchParams();

    const [search, setSearch] = useState("");

    const getUserButton = (userId: string) => {
        searchParams.set(QUERY_SEARCH, userId);
        const userQuery = searchParams.toString();

        return (
            <NavLink to={`/admin/users?${userQuery}`}>
                <Button className={styles.userButton}>
                    {getString("seeBidder")}
                </Button>
            </NavLink>
       );
    }

    const getBidItem = (bid: Bid) => (
        <div key={bid.id} className={styles.bidRow}>
            {isSuperAdmin && (
                <h5>{getUserButton(bid.byUserId)}</h5>
            )}
            <h5>{formatMoment(getMomentFromTime(bid.time))}</h5>
            <h5>{getFormattedPrice(bid.value)}</h5>
        </div>
    );

    const keywords = splitIntoKeywords(search);

    const filteredBids = keywords.length ?
        bids.filter(bid => searchFieldsWithKeywords(keywords, bid.byUserId)) : bids;

    return (
        <div className={styles.bidsForPostWrapper}>
            <BoldText variant="h5">
                {getString("bidsForPost")} ({`${getString("askingPrice")}: 
                ${getFormattedPrice(post.price)}`})
            </BoldText>
            {isSuperAdmin && (
                <SearchInput
                    className={styles.search}
                    placeholder="searchByUserId"
                    value={search}
                    onSearch={setSearch} />
            )}
            {!bids.length ? (
                <NotFoundMessage message={getString("noBidsForPost")}/>
            ) : !filteredBids.length ? (
                <NotFoundMessage message={getString("noBidsForUserId", search)}/>
            ) : (
                <div className={styles.bidsWrapper}>
                    <div className={styles.bidsHeader}>
                        {isSuperAdmin && (
                            <h4>{getString("user")}</h4>
                        )}
                        <h4>{getString("bidDate")}</h4>
                        <h4>{getString("value")}</h4>
                    </div>
                    <div className={styles.bidsItems}>
                        {filteredBids.slice().reverse().map(bid => getBidItem(bid))}
                    </div>
                </div>
            )}
        </div>
    )
};

export default SectionBidsForPost;