import React from "react";
import { useTranslation } from "infrastructure/hooks";
import { observer } from "mobx-react";
import { SingleSelectFilter } from "components";
import { TranslationStringKey } from "utils/translation/types";
import { VehiclesListStore } from "../vehicles-list-store";

interface Props {
    store: VehiclesListStore;
}

const FilterGearbox = ({ store }: Props) => {
    const { getString } = useTranslation();

    const { selectedGearbox } = store;

    return (
        <SingleSelectFilter
            onSelect={selected => selectedGearbox.selected = selected}
            value={selectedGearbox.selected}
            values={selectedGearbox.all}
            onItemRender={value => getString(value as TranslationStringKey)}
            useAction
            label={getString("gearbox")} />
    );
}

export default observer(FilterGearbox);