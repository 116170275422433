import { Partner } from "./types";

export const PARTNER_TBI_MONEY: Partner = {
    link: "https://online.tbicredit.ro/credit-auto?gclid=Cj0KCQiA2ZCOBhDiARIsAMRfv9KTUOdrlp28qdbpd5uW4YhazTKD33Zb_RjKyl4WiaBC51_qZ0sUMGsaAmDPEALw_wcB",
    logo: "https://online.tbicredit.ro/assets/img/tbi-money.svg",
    name: "TBI Money"
}

const PARTNER_KIWI_FINANCE: Partner = {
    link: "https://www.kiwifinance.ro/",
    logo: "https://www.kiwifinance.ro/wp-content/uploads/2022/02/Kiwi-Finance-Logo.png",
    name: "KIWI Finance"
}

export const ALL_PARTNERS: Partner[] = [PARTNER_TBI_MONEY, PARTNER_KIWI_FINANCE];