import { FIRESTORE_USERS, USER_TYPE_SUPER_ADMIN, USER_TYPE_USER } from "../constants";
import { User, BasePost } from "../types";
import { toJS } from "mobx";
import { getPost } from "./post";
import { getText } from "./text";
import { getBidsForUser } from "./bid";
import getFirestoreFetch from "database/firestore-fetch";

const firestoreUsers = getFirestoreFetch<User>(FIRESTORE_USERS);

export const getAllUsers = () => firestoreUsers.getAll();

export const getUser = async (userId?: string) => {
    if (!userId) {
        return null;
    }
    return await firestoreUsers.get(userId);
}

export const getUserWithText = async (userId?: string) => {
    let user = await getUser(userId);

    if (user?.banReasonId) {
        user = await includeUserBanReason(user);
    }

    return user;
}

export const includeUserProducts = async (user: User) => {
    const posts = user.createdPostsIds.map(postId => getPost(postId) as Promise<BasePost>);
    user.createdPosts = await Promise.all(posts);

    return user;
}

export const includeUserBids = async (user: User) => {
    user.bids = await getBidsForUser(user.id);

    return user;
}

export const includeUserBanReason = async (user: User) => {
    user.banReason = await getText(user.banReasonId);

    return user;
}

export const setUser = (user: User) => firestoreUsers.set(user.id, stripUser(user));

export const createUser = async (user: User) => {
    user.joinDate = new Date();
    return await setUser(user);
};

export const isUserWithEmail = async (email: string) => {
    const { length } = await firestoreUsers.getAll({
        property: "email", 
        condition: "==", 
        value: email
    });

    return length > 0;
};

export const isAdmin = (user: User | null | undefined) => !!user && user.accountType !== USER_TYPE_USER;

export const isSuperAdmin = (user: User | null | undefined) => !!user && user.accountType === USER_TYPE_SUPER_ADMIN;

export const getAllSuperAdmins = () => firestoreUsers.getAll({
    property: "accountType",
    condition: "==",
    value: USER_TYPE_SUPER_ADMIN
});

const stripUser = (user: User) => {
    const strippedUser = toJS(user);

    delete strippedUser.bids;
    delete strippedUser.createdPosts;
    delete strippedUser.banReason;
    
    return strippedUser;
}