import React, { useState, useEffect } from "react";
import {
	Slide,
	useScrollTrigger,
	AppBar,
	Toolbar,
	Tabs,
	Tab,
	CircularProgress,
} from "@material-ui/core";
import {
	AccountCircleOutlined,
	Add,
	SupervisorAccountOutlined,
	ExitToApp,
	SettingsOutlined
} from "@material-ui/icons";
import { arePathsSimilar, Path, useNavigation } from "utils/router-utils";
import { auth } from "database/firebase";
import SettingsPage from "pages/settings";
import RealEstateForm from "pages/categories/real-estate/real-estate-form";
import ForRentForm from "pages/categories/for-rent/for-rent-form";
import { useTranslation } from "infrastructure/hooks";
import { useLocation } from "react-router-dom";
import { useAddDialog } from "utils/infrastructure-utils";
import { observer } from "mobx-react";
import {
	TAB_INDEX_FOR_RENT,
	TAB_INDEX_VEHICLES,
	TAB_INDEX_REAL_ESTATE
} from "utils/constants";
import { NavigationBarButton } from "../components";
import styles from "./navigation-bar.module.scss";
import ChangeLanguageSelect from "../change-language-select";
import VehiclesForm from "pages/categories/vehicles/vehicles-form";
import { NotificationsButton, useUserDetails } from "infrastructure/services";

interface Props {
	paths: Path[];
}

const NavigationBar = ({ paths }: Props) => {
	const trigger = useScrollTrigger();
	const { getString } = useTranslation();
	const { goTo, goHome } = useNavigation();
	const { pathname } = useLocation();
	const { isInitialized, user, isUserAdmin, isUserSuperAdmin } = useUserDetails();

	const {
		isRealEstateAddVisible,
		isForRentAddVisible,
		isVehiclesAddVisible,
		closeRealEstateAdd,
		closeForRentAdd,
		closeVehiclesAdd,
		openRealEstateAdd,
		openForRentAdd,
		openVehiclesAdd
	} = useAddDialog();

	const isUserVerified = !!user && user.verified;

	const selectLink = () => {
		const foundIndex = paths.findIndex((path) =>
			arePathsSimilar(pathname, path.url)
		);

		if (foundIndex < 0) {
			return 0;
		}

		return foundIndex;
	};

	const [selectedTabIndex, setSelectedTabIndex] = useState(selectLink());
	const [areSettingsOpen, setSettingsOpen] = useState(false);

	const handleTabClick = (_: any, newIndex: number) => {
		setSelectedTabIndex(newIndex);
		goTo(paths[newIndex].url);
	};

	const handleLogin = () => {
		goTo("/login");
	};

	const handleLogout = async () => {
		await auth.signOut();
		goHome();
	};

	const handleSettings = () => {
		setSettingsOpen(true);
	};

	const navigateToAdminPage = () => {
		goTo(`/admin${isUserSuperAdmin ? "" : "/posts"}`);
	};

	const handleAdd = () => {
		if (!isUserVerified) {
			return;
		}
		switch (selectedTabIndex) {
			case TAB_INDEX_REAL_ESTATE:
				return openRealEstateAdd();
			case TAB_INDEX_FOR_RENT:
				return openForRentAdd();
			case TAB_INDEX_VEHICLES:
				return openVehiclesAdd();
		}
	};

	useEffect(() => {
		const foundIndex = paths.findIndex((path) =>
			arePathsSimilar(pathname, path.url)
		);

		if (foundIndex >= 0) {
			setSelectedTabIndex(foundIndex);
		}
	}, [paths, pathname]);

	const showAdd = isUserVerified && (
		selectedTabIndex === TAB_INDEX_REAL_ESTATE ||
		selectedTabIndex === TAB_INDEX_FOR_RENT ||
		selectedTabIndex === TAB_INDEX_VEHICLES);

	return <>
		<Slide appear={false} direction="down" in={!trigger}>
			<AppBar>
				<div className={styles.navigationBar}>
					<Tabs
						scrollButtons="auto"
						variant="scrollable"
						value={selectedTabIndex}
						onChange={handleTabClick}>
						{paths.map((path, i) => (
							<Tab key={i} label={getString(path.label)} />
						))}
					</Tabs>
					<div className={styles.buttons}>
						{isInitialized ? <>
							<NavigationBarButton
								tooltip={getString("addPost")}
								onClick={handleAdd}
								hidden={!showAdd}>
								<Add />
							</NavigationBarButton>
							<ChangeLanguageSelect />
							{auth.getCurrentUser() ? <>
								<NotificationsButton />
								{isUserAdmin && (
									<NavigationBarButton
										tooltip={getString("adminPage")}
										onClick={navigateToAdminPage}>
										<SupervisorAccountOutlined />
									</NavigationBarButton>
								)}
								<NavigationBarButton
									tooltip={getString("settings")}
									onClick={handleSettings}>
									<SettingsOutlined />
								</NavigationBarButton>
								<NavigationBarButton
									tooltip={getString("logout")}
									onClick={handleLogout}>
									<ExitToApp />
								</NavigationBarButton>
							</> : (
								<NavigationBarButton
									tooltip={getString("login")}
									onClick={handleLogin}>
									<AccountCircleOutlined />
								</NavigationBarButton>
							)}
						</> : (
							<CircularProgress
								className={styles.spinner}
								color="secondary" />
						)}
					</div>
				</div>
			</AppBar>
		</Slide>
		<Toolbar className={styles.dummyToolbar} />
		<SettingsPage
			isDisplayed={areSettingsOpen}
			onClose={() => setSettingsOpen(false)} />
		<RealEstateForm
			isOpen={isRealEstateAddVisible}
			onClose={closeRealEstateAdd} />
		<ForRentForm
			isOpen={isForRentAddVisible}
			onClose={closeForRentAdd} />
		<VehiclesForm
			isOpen={isVehiclesAddVisible}
			onClose={closeVehiclesAdd} />
	</>
};

export default observer(NavigationBar);
