import React from "react";
import { CharacteristicItemProps, CharacteristicItem } from "./characteristic-item";
import { NotFoundMessage } from "components";
import { useTranslation } from "infrastructure/hooks";
import styles from "./characteristic-grid.module.scss";

interface Props {
    characteristics: CharacteristicItemProps[];
}

const CharacteristicGrid = ({ characteristics }: Props) => {
    const { getString } = useTranslation();

    return (
        <div className={styles.characteristicGridWrapper}>
            {characteristics.length ? characteristics.map(characteristic => (
                <CharacteristicItem
                    key={characteristic.name}
                    {...characteristic} />
            )) : (
                <NotFoundMessage
                    message={getString("noCharacteristicsFoundForTheSearchedKeywords")} />
            )}
        </div>
    );
}

export default CharacteristicGrid;