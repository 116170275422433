import { getUser, setUser, getUserWithText } from "./user";
import { auth } from "../firebase";
import { EMPTY_ACCOUNT } from "pages/account/types";
import { User } from "../types";
import { USER_TYPE_ADMIN, USER_TYPE_SUPER_ADMIN } from "database/constants";

export const getAccountForCurrentUser = async () => {
    try {
        return getUser(auth?.getCurrentUser()?.uid);
    } catch {
        return EMPTY_ACCOUNT;
    }
}

export const getAccountForCurrentUserWithText = async () => {
    try {
        return getUserWithText(auth?.getCurrentUser()?.uid);
    } catch {
        return EMPTY_ACCOUNT;
    }
}

export const setAccountEmail = async (account: User, withPassword: string) => {
    await auth.signInWithEmailAndPassword(auth.getCurrentUser()?.email || "", withPassword);
    await auth.getCurrentUser()?.updateEmail(account.email);
    await setUser(account);
}

export const setAccountPassword = async (oldPassword: string, newPassword: string) => {
    await auth.signInWithEmailAndPassword(auth.getCurrentUser()?.email || "", oldPassword);
    await auth.getCurrentUser()?.updatePassword(newPassword);
};

export const setAccountDetails = async (newAccountDetails: User) => await setUser(newAccountDetails);

export const isAccountAdmin = async (user?: User) => {
    try {
        const currentUser = user || await getUser(auth.getCurrentUser()?.uid);
        if (!currentUser) {
            return false;
        }

        const { accountType } = currentUser;
        return accountType === USER_TYPE_ADMIN || accountType === USER_TYPE_SUPER_ADMIN;
    } catch {
        return false;
    }
}

export const isAccountVerified = async () => {
    try {
        const user = await getUser(auth.getCurrentUser()?.uid);

        return user?.verified ?? false;
    } catch {
        return false;
    }
}