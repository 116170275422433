import React, { useContext, useEffect } from "react";
import { useTranslation } from "infrastructure/hooks";
import { FullscreenDialog, CloseFormDialog } from "components";
import PostForm from "../../components/post-form";
import { observer } from "mobx-react";
import ForRentAddCharacteristics from "./for-rent-form-characteristics";
import { toJS } from "mobx";
import { ForRentFormContext } from "./for-rent-form-store";
import { EMPTY_FOR_RENT_POST, ForRentPost } from "../types";

const MINIMUM_PRICE = 10;
const PRICE_STEP = 5;

interface Props {
    isOpen: boolean;
    onClose: () => void;
    forRentPost?: ForRentPost | null;
    onSubmit?: (post: ForRentPost) => void;
}

const ForRentForm = ({ isOpen, onClose, forRentPost, onSubmit}: Props) => {
    const { getString, isEnglish } = useTranslation();

    const forRentStore = useContext(ForRentFormContext);

    const {
        post,
        isConfirmationOpen,
        areCharacteristicsValid,
        arePostDetailsValid,

        updatePost,
        setPost,
        openConfirmation,
        closeConfirmation,
        savePost,
        reset
    } = forRentStore;

    useEffect(() => {
        if (forRentPost) {
            setPost(toJS(forRentPost));
        } else {
            setPost(EMPTY_FOR_RENT_POST);
        }
    }, [forRentPost, setPost]);

    const handleSaveClick = async () => {
        if (forRentPost) {
            await updatePost();
            onSubmit && onSubmit(post as ForRentPost);
        } else {
            await savePost();
        }
        onClose();
    }

    toJS(post);

    const title = isEnglish() ? forRentPost?.titleText?.en : forRentPost?.titleText?.ro;

    return (
        <FullscreenDialog
            open={isOpen} 
            onClose={openConfirmation}
            title={forRentPost ? getString("editThePost", title ?? "") : getString("addPostForRenting")}>
            <PostForm
                isSaveDisabled={!arePostDetailsValid()}
                minimumPrice={MINIMUM_PRICE}
                post={post}
                isEdit={!!forRentPost}
                onSaveClick={handleSaveClick}
                priceStep={PRICE_STEP}
                areCharacteristicsValid={!!areCharacteristicsValid()}
                isVisible={isOpen}>
                <ForRentAddCharacteristics post={post} />
            </PostForm>
            <CloseFormDialog
                open={isConfirmationOpen}
                onCancel={closeConfirmation}
                onConfirm={() => {
                    closeConfirmation();
                    onClose();
                    reset();
                }} />
        </FullscreenDialog>
    );
}

export default observer(ForRentForm);