import { Tooltip } from "@material-ui/core";
import classNames from "classnames";
import React from "react";
import { Image } from "../../../components";
import { Partner } from "../types";
import styles from "./partner-item.module.scss";

interface Props {
    partner: Partner;
    small?: boolean;
}

const PartnerItem = ({ partner, small }: Props) => {
    const { logo, name, link } = partner;
    
    return (
        <Tooltip title={name}>
            <a
                className={styles.partnerItem}
                href={link}
                target="_blank"
                rel="noreferrer">
                <Image
                    src={logo}
                    className={classNames(styles.partnerImage, {
                        [styles.small]: small
                    })} />
            </a>
        </Tooltip>
    );
}

export default PartnerItem;