import React, { useState, useRef } from "react";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { Popper, Grow, ClickAwayListener } from "@material-ui/core";
import styles from "./dropdown.module.scss";
import sharedStyles from "../../admin.module.scss";
import classNames from "classnames";

interface Props {
    items: string[];
    onChange: (index: number) => void,
    value: number,
}

const Dropdown = ({ items, onChange, value }: Props) => {

    const [isMenuOpen, setMenuOpen] = useState(false);
    const ref = useRef(null);

    return <>
        <div className={styles.dropdown}>
            <div
                ref={ref}
                onClick={() => setMenuOpen(true)}
                className={sharedStyles.miraCard}
            >
                <h4>{items[value]}</h4>
                <ArrowDropDownIcon className={sharedStyles.downArrow} />
            </div>
        </div>
        <Popper
            className={styles.popper}
            open={isMenuOpen}
            anchorEl={ref.current}
            role={undefined}
            transition
            disablePortal
        >
            {({ TransitionProps, placement }) => (
                <Grow {...TransitionProps}
                    style={{
                        transformOrigin: placement === "bottom" ? "center top" : "center bottom"
                    }}
                >
                    <div>
                        <ClickAwayListener onClickAway={() => setMenuOpen(false)}>
                            <div className={styles.dropdownItems}>
                                {items.map((it, index) => (
                                    <div
                                        key={index}
                                        onClick={() => {
                                            onChange(index);
                                            setMenuOpen(false);
                                        }}
                                        className={classNames({
                                            [styles.active]: index === value
                                        })}
                                    >
                                        {it}
                                    </div>
                                ))}
                            </div>
                        </ClickAwayListener>
                    </div>
                </Grow>
            )}
        </Popper>
    </>
}

export default Dropdown;
