import React from "react";
import { Route, Routes } from "react-router-dom";
import { NavigationBar } from "..";
import ForRentCategory from "pages/categories/for-rent";
import RealEstateCategory from "pages/categories/real-estate";
import VehiclesCategory from "pages/categories/vehicles";
import { Home } from "pages/home";
import { PATH_FOR_RENT, PATH_REAL_ESTATE, PATH_VEHICLES } from "utils/constants";
import { paths } from "./constants";

const CategoriesNavigationRoutes = () => <>
    <NavigationBar paths={paths} />
    <Routes>
        <Route path={`${PATH_REAL_ESTATE}/*`} element={<RealEstateCategory />} />
        <Route path={`${PATH_FOR_RENT}/*`} element={<ForRentCategory />} />
        <Route path={`${PATH_VEHICLES}/*`} element={<VehiclesCategory />} />
        <Route path="/" element={<Home />} />
    </Routes>
</>;

export default CategoriesNavigationRoutes;