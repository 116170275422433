import { ENVIRONMENT } from "../utils/constants"
import { firestoreCollection } from "./firebase";

const EXTENSION_ENVIRONMENT_DEVELOPMENT = "Dev";
const EXTENSION_ENVIRONMENT_TEST = "Test";

export const getCollection = (collection: string) => {
    if (ENVIRONMENT === "development") {
        return collection + EXTENSION_ENVIRONMENT_DEVELOPMENT;
    }
    if (ENVIRONMENT === "test") {
        return collection + EXTENSION_ENVIRONMENT_TEST;
    }
    return collection;
}

export const getFirestoreCollection = (collection: string) =>
    firestoreCollection(getCollection(collection));