import React, { useState } from "react";
import SpeedIcon from "@material-ui/icons/Speed";
import {
    RateReview as RateReviewIcon,
    Description as DescriptionIcon,
    People as PeopleIcon,
    ArrowBack as ArrowBackIcon,
    Menu as MenuIcon,
    Close as CloseIcon
} from "@material-ui/icons"
import { NavLink } from "react-router-dom";
import LightTooltip from "./light-tooltip";
import { IconButton } from "@material-ui/core";
import { useTranslation } from "infrastructure/hooks";
import { useNavigation } from "utils/router-utils";
import styles from "./admin-navigation.module.scss";
import classNames from "classnames"
import { useUserDetails } from "infrastructure/services";

const Navigation = () => {
    const { getString } = useTranslation();
    const { getUrlWithLanguageQuery, goHome } = useNavigation();
    const { isUserSuperAdmin } = useUserDetails();

    const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);

    return <>
        <div
            className={classNames(styles.navigation, {
                [styles.active]: isMobileMenuOpen
            })}>
            <div className={styles.navigationHeader}>
                <LightTooltip
                    title={getString("back")}
                    className={styles.tooltip}
                    onClick={() => goHome()}>
                    <IconButton>
                        <ArrowBackIcon />
                    </IconButton>
                </LightTooltip>
                <h1>Mira Auction</h1>
                <LightTooltip
                    title={getString("closeMenu")}
                    className={classNames(styles.tooltip, styles.mobileNavigationClose)}
                    onClick={() => setMobileMenuOpen(false)}>
                    <IconButton>
                        <CloseIcon />
                    </IconButton>
                </LightTooltip>
            </div>
            <div className={styles.navigationListWrapper}>
                {isUserSuperAdmin && (
                    <NavLink
                        to={getUrlWithLanguageQuery("/admin")}
                        className={({ isActive }) => isActive ? styles.active : ""}
                        end
                        onClick={() => setMobileMenuOpen(false)}>
                        <SpeedIcon className={styles.icon} />
                        {getString("overview")}
                    </NavLink>
                )}
                {isUserSuperAdmin && (
                    <NavLink
                        to={getUrlWithLanguageQuery("/admin/users")}
                        className={({ isActive }) => isActive ? styles.active : ""}
                        onClick={() => setMobileMenuOpen(false)}>
                        <PeopleIcon className={styles.icon} />
                        {getString("users")}
                    </NavLink>
                )}
                <NavLink
                    to={getUrlWithLanguageQuery("/admin/posts")}
                    className={({ isActive }) => isActive ? styles.active : ""}
                    onClick={() => setMobileMenuOpen(false)}>
                    <DescriptionIcon className={styles.icon} />
                    {getString("posts")}
                </NavLink>
                <NavLink
                    to={getUrlWithLanguageQuery("/admin/reviews")}
                    className={({ isActive }) => isActive ? styles.active : ""}
                    onClick={() => setMobileMenuOpen(false)}>
                    <RateReviewIcon className={styles.icon} />
                    {getString("reviews")}
                </NavLink>
            </div>
        </div>
        <LightTooltip title={getString("menu")}>
            <IconButton
                className={styles.mobileNavigation}
                onClick={() => setMobileMenuOpen(!isMobileMenuOpen)}>
                <MenuIcon />
            </IconButton>
        </LightTooltip>
        <LightTooltip title={getString("back")}>
            <IconButton
                className={classNames(styles.mobileNavigation, styles.back)}
                onClick={() => goHome()}>
                <ArrowBackIcon />
            </IconButton>
        </LightTooltip>
    </>
}

export default Navigation;