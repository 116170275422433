import { observer } from "mobx-react";
import React, { useContext, useEffect } from "react";
import { useParams } from "react-router";
import { useTranslation } from "infrastructure/hooks";
import { GetStringFunction, TranslationStringKey } from "utils/translation/types";
import PostDetails from "../../components/post-details";
import { CharacteristicItemProps } from "../../components/post-details/components/characteristic-item";
import { VehiclePost } from "../types";
import VehiclesForm from "../vehicles-form";
import { VehiclesDetailsContext } from "./vehicles-details-store";

const getEngineCapacityType = (fuel: string[]) => {
    if (fuel.length === 1 && fuel[0] === "electric") {
        return <>kW</>;
    }
    return <>cm<sup>3</sup></>;
}

const mapPostToCharacteristicItems =
    (getString: GetStringFunction, post: VehiclePost | null): CharacteristicItemProps[] => {
        if (post === null) {
            return [];
        }

        return [{
            icon: "https://img.icons8.com/ios/50/000000/car--v1.png",
            name: "brand",
            value: getString(post.brand as TranslationStringKey)
        }, {
            icon: "https://img.icons8.com/ios/50/000000/tesla-model-x.png",
            name: "model",
            value: post.model
        }, {
            icon: "https://img.icons8.com/ios/50/000000/car-production.png",
            name: "fabricationYear",
            value: post.fabricationYear
        }, {
            icon: "https://img.icons8.com/ios/50/000000/speedometer.png",
            name: "mileage",
            value: post.mileage,
            valueElement: <>{post.mileage} km</>
        }, {
            icon: "https://img.icons8.com/ios/50/000000/engine.png",
            name: "engineCapacity",
            value: post.engineCapacity,
            valueElement: <>{post.engineCapacity} {getEngineCapacityType(post.fuel)}</>
        }, {
            icon: "https://img.icons8.com/ios/50/000000/automatic-car-wash.png",
            name: "condition",
            value: getString(post.condition as TranslationStringKey)
        }, {
            icon: "https://img.icons8.com/ios/50/000000/gas-station.png",
            name: "fuel",
            value: post.fuel.map(value => getString(value as TranslationStringKey)).join(" + ")
        }, {
            icon: "https://img.icons8.com/ios/50/000000/ground-transportation.png",
            name: "body",
            value: getString(post.body as TranslationStringKey)
        }, {
            icon: "https://img.icons8.com/ios/50/000000/paint-palette.png",
            name: "color",
            value: getString(post.color as TranslationStringKey)
        }, {
            icon: "https://img.icons8.com/ios/50/000000/gear-stick.png",
            name: "gearbox",
            value: getString(post.gearbox as TranslationStringKey)
        }, {
            icon: "https://img.icons8.com/ios/50/000000/gender-neutral-user.png",
            name: "privateOrFirm",
            value: getString(post.owner as TranslationStringKey)
        }];
    }

const VehiclesDetails = () => {
    const { id } = useParams<{ id: string }>();
    const { getString } = useTranslation();

    const vehiclesDetailsStore = useContext(VehiclesDetailsContext);

    const { post, isVehicleEditVisible, setVehicleEditVisibility, setPost } = vehiclesDetailsStore;

    useEffect(() => {
        vehiclesDetailsStore.fetchPost(id!);

        return vehiclesDetailsStore.reset();
    }, [vehiclesDetailsStore, id]);

    return <>
        <PostDetails
            onOpenEdit={() => setVehicleEditVisibility(true)}
            store={vehiclesDetailsStore}
            characteristics={mapPostToCharacteristicItems(getString, post)}
            maxBid={1000000}
            minBid={500} 
            loanReminder />
        {post && (
            <VehiclesForm
                onClose={() => setVehicleEditVisibility(false)}
                isOpen={isVehicleEditVisible}
                vehiclePost={isVehicleEditVisible ? post : null}
                onSubmit={setPost} />
        )}
    </>;
}

export default observer(VehiclesDetails);