import {
    CATEGORY_REAL_ESTATE,
    PATH_REAL_ESTATE,
    CATEGORY_FOR_RENT,
    PATH_FOR_RENT,
    SITE_NAME_KEY,
    BASE_PATH,
    CATEGORY_VEHICLES,
    PATH_VEHICLES
} from "../../utils/constants";
import { Path } from "../../utils/router-utils";

export const paths: Path[] = [{
    label: SITE_NAME_KEY,
    url: BASE_PATH
}, {
    label: CATEGORY_REAL_ESTATE,
    url: PATH_REAL_ESTATE
}, {
    label: CATEGORY_FOR_RENT,
    url: PATH_FOR_RENT
}, {
    label: CATEGORY_VEHICLES,
    url: PATH_VEHICLES
}];