import React, { useEffect } from "react";
import { includeUserBids } from "database/api/user";
import { observer } from "mobx-react";
import { useTranslation } from "infrastructure/hooks";
import { FullscreenDialog } from "components";
import styles from "./user-details.module.scss";
import GeneralInformation from "./sections/general-information";
import UserBiddings from "./sections/user-biddings";
import Actions from "./sections/actions";
import { UserDetailsStore } from "./user-details-store";
import { loadingService } from "infrastructure/services";

interface Props {
    userDetailsStore: UserDetailsStore,
}

const UserDetails = ({ userDetailsStore }: Props) => {

    const {
        activeUser,
        setActiveUser
    } = userDetailsStore;

    const { getString } = useTranslation();

    useEffect(() => {
        let mounted = true;

        const fetchBids = async () => {
            if (activeUser && !activeUser.bids) {
                loadingService.set(true);
                await includeUserBids(activeUser);
                loadingService.set(false);
            }
        }

        if (mounted) {
            fetchBids();
        }

        return () => {
            mounted = false;
        }
    }, [activeUser]);

    return (
        <FullscreenDialog title={getString("userDetails")} open={!!activeUser} onClose={() => setActiveUser(null)}>
            {!!activeUser && <>
                <div className={styles.userDetailsWrapper}>
                    <GeneralInformation user={activeUser} />
                    <UserBiddings user={activeUser} />
                </div>
                <Actions userDetailsStore={userDetailsStore}/>
            </>}
        </FullscreenDialog>
    )
};

export default observer(UserDetails);
