import { auth } from "../firebase";
import { FIRESTORE_POSTS } from "../constants";
import { BasePost } from "../types";
import { getText, createText, setText } from "./text";
import { getBidForPost } from "./bid";
import { getUser, setUser } from "./user";
import { toJS } from "mobx";
import { getFirestoreCollection } from "../utils";
import { getMomentFromTime, hasPassedMoment } from "utils/date-utils";

export interface PostOptions {
    category?: string;
    showAvailable?: boolean;
    showActive?: boolean;
    withBidding?: boolean;
}

export const firestorePosts = getFirestoreCollection(FIRESTORE_POSTS);

export const generatePostDocument = () => firestorePosts.generate();

export const getPost = async (postId: string, category?: string) => {
    const post = (await firestorePosts.doc(postId).get()).data() as BasePost | null;

    if (!post) {
        return null;
    }

    if (!category) {
        return post;
    }

    return post.category === category ? post : null;
}

export const getAllPosts = async (options?: PostOptions) => {
    const posts: BasePost[] = [];
    let query: any = firestorePosts;

    if (options) {
        const { category, showAvailable, showActive, withBidding } = options;

        if (category) {
            query = query.where("category", "==", category);
        }

        if (showAvailable) {
            query = query.where("isDeleted", "==", false);
                //.where("isActive", "==", true);
        }

        if (showActive) {
            query = query.where("isActive", "==", true);
        }

        if (withBidding) {
            query = query.where("hasBidding", "==", true);
        }
    }

    (await query.get()).forEach((doc: any) => posts.push(doc.data()));

    return posts;
}

export const includeText = async (post: BasePost) => {
    post.titleText = await getText(post.titleTextId);
    post.descriptionText = await getText(post.descriptionTextId);

    return post;
}

export const includeBid = async (post: BasePost) => {
    const bids = await getBidForPost(post.id);

    try {
        post.bids = post.bidsIds.map(bidId => bids.find(bid => bid.id === bidId)!);
    } catch {
        post.bids = [];
    }

    return post;
}

export const getAllPostsWithText = async (posts?: BasePost[], options?: PostOptions) => {
    posts || (posts = await getAllPosts(options));

    const postsWithText = posts.map(post => includeText(post));

    return await Promise.all(postsWithText);
}

export const getAllPostsWithBids = async (posts?: BasePost[], options?: PostOptions) => {
    posts || (posts = await getAllPosts(options));

    const postsWithBids = posts.map(post => includeBid(post));

    return await Promise.all(postsWithBids);
}

export const getPostWithText = async (postId: string) => {
    let post = await getPost(postId);

    if (!post) {
        return null;
    }

    post = await includeText(post);

    return post;
}

export const setPost = async (post: BasePost) => {
    post.titleText && await setText(post.titleText);
    post.descriptionText && await setText(post.descriptionText);
    
    await firestorePosts.doc(post.id).set(stripPost(post));
}
export const createPost = async (post: BasePost, generatedPostIdDocument = generatePostDocument()) => {
    const timestamp = new Date();

    post.id = generatedPostIdDocument.id;
    post.createDate = timestamp;
    post.lastUpdateDate = timestamp;
    post.createdByUserId = auth.getCurrentUser()?.uid!;

    if (!post.createdByUser) {
        const user = await getUser(auth.getCurrentUser()?.uid);
        if (user) {
            post.createdByUser = user;
        }
    }

    post.createdByUser?.createdPostsIds.push(post.id);

    await setUser(post.createdByUser!);
    post.titleTextId = (await createText(post.titleText!)).id;
    post.descriptionTextId = (await createText(post.descriptionText!)).id;
    await generatedPostIdDocument.set(stripPost(post));

    return post;
}

export const extendBiddingPeriod = async (date: Date, forExpiringDays = 15) => {
    const posts = await getAllPosts({ showAvailable: true, showActive: true, withBidding: true });
    posts.filter(post => post.isActive && hasPassedMoment(getMomentFromTime(post.biddingEndDate).add(-forExpiringDays, "d")))
        .forEach(post => firestorePosts.doc(post.id).set({
            ...post,
            biddingEndDate: date
        }));
}

const stripPost = (post: BasePost) => {
    const strippedPost = toJS(post);

    delete strippedPost.createdByUser;
    delete strippedPost.descriptionText;
    strippedPost.biddingEndDate || delete strippedPost.biddingEndDate;
    delete strippedPost.titleText;
    delete strippedPost.bids;

    return strippedPost;
}