import React from "react";
import {
	CssBaseline,
	ThemeProvider,
	createTheme
} from "@material-ui/core";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import MiraAuction from "mira-auction";
import { Services } from "infrastructure/services";
import { BrowserRouter } from "react-router-dom";

const App = () => {
	const theme = createTheme({
		palette: {
			primary: {
				main: "#eaa524",
				contrastText: "#fff"
			},
			secondary: {
				main: "#fafafa"
			}
		}
	});

	return (
		<MuiPickersUtilsProvider utils={MomentUtils}>
			<CssBaseline />
			<ThemeProvider theme={theme}>
    			<BrowserRouter>
					<MiraAuction />
					<Services />
    			</BrowserRouter>
			</ThemeProvider>
		</MuiPickersUtilsProvider>
	);
}

export default App;
