import React from "react";
import { RealEstateListStore } from "../real-estate-list-store";
import { useTranslation } from "infrastructure/hooks";
import { observer } from "mobx-react";
import { MultiSelect } from "components";
import { TranslationStringKey } from "utils/translation/types";

interface Props {
    store: RealEstateListStore;
}

const FilterPurposes = ({ store }: Props) => {
    const { getString } = useTranslation();

    const { selectedPurposes } = store;

    return (
        <MultiSelect
            onItemRender={value => getString(value as TranslationStringKey)}
            onSelect={selected => selectedPurposes.selected = selected}
            selectedValues={selectedPurposes.selected}
            values={selectedPurposes.all}
            useAction
            label={getString("purpose")} />
    );
}

export default observer(FilterPurposes);