import React from "react";
import { useTranslation } from "infrastructure/hooks";
import { observer } from "mobx-react";
import { BooleanSelect } from "components";
import { ForRentListStore } from "./for-rent-list-store";

interface Props {
    store: ForRentListStore;
}

const FilterPetFriendly = ({ store }: Props) => {
    const { getString } = useTranslation();

    return (
        <BooleanSelect
            onSelect={value => store.withPetFriendly = value}
            value={store.withPetFriendly}
            useAction
            label={getString("petFriendly")} />
    );
}

export default observer(FilterPetFriendly);