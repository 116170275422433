import React from "react";
import { Slide } from "@material-ui/core";
import { TransitionProps } from "@material-ui/core/transitions";

export const SlideUpAnimation = React.forwardRef(
    (props: TransitionProps & { children?: React.ReactElement<any, any> }, ref: React.Ref<unknown>) =>
        <Slide direction="up" ref={ref} {...props} />
);

export const SlideDownAnimation = React.forwardRef(
    (props: TransitionProps & { children?: React.ReactElement<any, any> }, ref: React.Ref<unknown>) =>
        <Slide direction="down" ref={ref} {...props} />
);
