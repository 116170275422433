import { Divider, IconButton, Paper, Typography } from "@material-ui/core";
import React, { useContext } from "react";
import styles from "./notifications-pane.module.scss";
import { BoldText } from "components";
import { CloseOutlined } from "@material-ui/icons";
import { NotificationsContext } from "../notifications-store";
import { observer } from "mobx-react";
import NotificationElement from "../notification-element";
import Popper from "components/popper";
import { useTranslation } from "infrastructure/hooks";

const NotificationsPane = () => {
    const { getString } = useTranslation();
    const {
        notifications,
        isPaneOpen,
        setIsPaneOpen,
        buttonReference,
        markNotificationRead
    } = useContext(NotificationsContext);

    return (
        <Popper 
            className={styles.popper}
            anchorEl={buttonReference.current} 
            open={isPaneOpen} 
            onClose={() => setIsPaneOpen(false)}>
            <Paper className={styles.pane} elevation={24}>
                <div className={styles.header}>
                    <BoldText variant="h5">{getString("notifications")}</BoldText>
                    <IconButton 
                        className={styles.closeButton} 
                        size="small" 
                        onClick={() => setIsPaneOpen(false)}>
                        <CloseOutlined />
                    </IconButton>
                </div>
                <div className={styles.notificationsContainer}>
                    {notifications.length ? notifications.map((notification, index) => <>
                        <NotificationElement 
                            key={notification.id} 
                            notification={notification} 
                            onHover={({ userNotificationId }) => markNotificationRead(userNotificationId)} />
                        {index < notifications.length - 1 && (
                            <Divider key={index} />
                        )}
                    </>) : (
                        <Typography className={styles.noNotifications}>{getString("noNotificationsYet")}</Typography>
                    )}
                </div>
            </Paper>
        </Popper>
    );
}

export default observer(NotificationsPane);