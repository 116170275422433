import { loadingService } from "infrastructure/services";
import { makeAutoObservable, runInAction } from "mobx";
import { createContext } from "react";
import { intersect, isNotEmpty, sortBy, unique } from "utils/array-utils";
import { getMomentAfterYears } from "utils/date-utils";
import {
    Range,
    getDefaultRange,
    MultiSelect,
    EMPTY_MULTI_SELECT,
    SORT_BY_DESCENDING_DATE,
    SingleSelect,
    EMPTY_SINGLE_SELECT
} from "../../types";
import { getAllPostsWithBids, getAllPostsWithText } from "../api";
import {
    bodyOptions,
    brandOptionsMutable,
    colorOptions,
    conditionOptions,
    fuelOptions,
    gearboxOptions,
    ownerOptions,
    VehiclePost
} from "../types";

const oneYearAhead = getMomentAfterYears(10).get("y");

export class VehiclesListStore {
    public allPosts: VehiclePost[] = [];
    public search: string = "";
    public sortingBy: string = SORT_BY_DESCENDING_DATE;
    public filterMyPosts: boolean = false;
    public priceRange: Range = getDefaultRange(1, 1000000);
    public selectedCounties: MultiSelect = EMPTY_MULTI_SELECT;
    public selectedCities: MultiSelect = EMPTY_MULTI_SELECT;
    public selectedBrands: MultiSelect = EMPTY_MULTI_SELECT;
    public selectedModels: MultiSelect = EMPTY_MULTI_SELECT;
    public rangeFabricationYear: Range = getDefaultRange(1900, oneYearAhead);
    public rangeMileage: Range = getDefaultRange(1, 1000000);
    public rangeEngineCapacity: Range = getDefaultRange(1, 5000);
    public selectedCondition: MultiSelect = EMPTY_MULTI_SELECT;
    public selectedFuels: MultiSelect = EMPTY_MULTI_SELECT;
    public selectedBodies: MultiSelect = EMPTY_MULTI_SELECT;
    public selectedColors: MultiSelect = EMPTY_MULTI_SELECT;
    public selectedGearbox: SingleSelect = EMPTY_SINGLE_SELECT;
    public selectedOwner: SingleSelect = EMPTY_SINGLE_SELECT;

    constructor() {
        makeAutoObservable(this);
    }

    public fetchPosts = async () => {
        loadingService.set(true);
        let posts = await getAllPostsWithText();
        posts = await getAllPostsWithBids(posts);

        runInAction(() => {
            this.allPosts = posts;
            this.initializeFilters();
        });
        loadingService.set(false);
    }
 
    public setPriceRange = (min: number, max: number, setBounds: boolean = false) =>
        this.setRange(this.priceRange, min, max, setBounds);
 
    public setFabricationYearRange = (min: number, max: number, setBounds: boolean = false) =>
        this.setRange(this.rangeFabricationYear, min, max, setBounds);
 
    public setMileageRange = (min: number, max: number, setBounds: boolean = false) =>
        this.setRange(this.rangeMileage, min, max, setBounds);
 
    public setEngineCapacityRange = (min: number, max: number, setBounds: boolean = false) =>
        this.setRange(this.rangeEngineCapacity, min, max, setBounds);

    private setRange = (to: Range, min: number, max: number, setBounds: boolean = false) => {
        to.min = min;
        to.max = max;

        if (setBounds) {
            to.lowest = min;
            to.greatest = max;
        }
    }

    protected clearRange = (range: Range) => {
        range.min = range.lowest;
        range.max = range.greatest;
    }

    public clearFilters = () => {
        this.clearRange(this.priceRange);
        this.selectedCounties.selected = [];
        this.selectedCities.selected = [];
        this.selectedBrands.selected = [];
        this.selectedModels.selected = [];
        this.clearRange(this.rangeFabricationYear);
        this.clearRange(this.rangeMileage);
        this.clearRange(this.rangeEngineCapacity);
        this.selectedCondition.selected = [];
        this.selectedFuels.selected = [];
        this.selectedBodies.selected = [];
        this.selectedColors.selected = [];
        this.selectedGearbox.selected = undefined;
        this.selectedOwner.selected = undefined;
    }

    protected initializeFilters = () => {
        this.initializePriceRange();
        this.selectedCounties.all = sortBy(unique(this.allPosts.map(post => post.county)));
        this.selectedCities.all = sortBy(unique(this.allPosts.map(post => post.city)));
        this.selectedBrands.all =
            intersect(brandOptionsMutable, this.allPosts, post => post.brand);
        this.initializeFabricationYearRange();
        this.initializeMileageRange();
        this.initializeEngineCapacityRange();
        this.selectedCondition.all = conditionOptions;
        this.selectedFuels.all = fuelOptions;
        this.selectedBodies.all = intersect(bodyOptions, this.allPosts, post => post.body);
        this.selectedColors.all = intersect(colorOptions, this.allPosts, post => post.color);
        this.selectedGearbox.all = gearboxOptions;
        this.selectedOwner.all = ownerOptions;
    }

    private initializePriceRange = () => {
        const prices = this.allPosts.map(post => isNotEmpty(post.bids) ?
            post?.bids![post?.bids!.length - 1].value : post.price
        );
        this.setPriceRange(Math.min(...prices), Math.max(...prices), true);
    }

    private initializeFabricationYearRange = () => {
        const fabricationYears = this.allPosts.map(post => post.fabricationYear);
        this.setFabricationYearRange(
            Math.min(...fabricationYears),
            Math.max(...fabricationYears),
            true
        );
    }

    private initializeMileageRange = () => {
        const mileages = this.allPosts.map(post => post.mileage);
        this.setMileageRange(
            Math.min(...mileages),
            Math.max(...mileages),
            true
        );
    }

    private initializeEngineCapacityRange = () => {
        const engineCapacities = this.allPosts.map(post => post.engineCapacity);
        this.setEngineCapacityRange(
            Math.min(...engineCapacities),
            Math.max(...engineCapacities),
            true
        );
    }
}

export const vehiclesListStore = new VehiclesListStore();
export const VehiclesListContext = createContext(vehiclesListStore);