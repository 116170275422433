import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";
import { useTranslation } from "infrastructure/hooks";
import React from "react";
import { LANGUAGE_CODE_EN, LANGUAGE_CODE_RO } from "utils/translation/constants";

const LanguageDropdown = () => {
    const { getString, getCurrentLanguageCode, changeLanguageTo } = useTranslation();

    const currentLanguageCode = getCurrentLanguageCode();

    const handleLanguageChange = (event: React.ChangeEvent<{ value: any }>) => {
        const option = event.target.value;
        if (option === currentLanguageCode) {
            return;
        }

        changeLanguageTo(option);
    };

    return (
        <FormControl size="small" variant="outlined">
            <InputLabel>{getString("language")}</InputLabel>
            <Select
                value={currentLanguageCode}
                onChange={handleLanguageChange}
                labelWidth={currentLanguageCode === LANGUAGE_CODE_RO ? 48 : 72}>
                <MenuItem value={LANGUAGE_CODE_RO}>Română</MenuItem>
                <MenuItem value={LANGUAGE_CODE_EN}>English</MenuItem>
            </Select>
        </FormControl>
    );
}

export default LanguageDropdown;