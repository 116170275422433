import React from "react";
import { 
    Card, 
    CardContent,
    Button, 
    Typography, 
    TextField
} from "@material-ui/core";
import { auth, PERSISTENCE_LOCAL } from "database/firebase";
import { useTranslation } from "infrastructure/hooks";
import { useNavigation } from "utils/router-utils";
import { useRunOnce } from "utils/ui-utils";
import styles from "./login.module.scss";
import useForm from "hooks/use-form";
import { LoginRules, getDefaultLoginFormModel } from "./login.rules";
import Field from "components/field";
import Checkbox from "components/checkbox";
import { loadingService, toastService } from "infrastructure/services";
import BottomControls from "../components/bottom-controls";

const LoginPage = () => {
    const { getString } = useTranslation();
    const { goTo, goHome } = useNavigation();
    const { handleSubmit, control, reset } = useForm();


    const handleForgotPasswordClick = () => {
        goTo("/forgotPassword");
    }

    const handleCreateClick = () => {
        goTo("/signup");
    }

    const handleLoginClick = async ({ email, password, remainLoggedIn }: any) => {
        loadingService.set(true);
        try {
            if (remainLoggedIn) {
                await auth.setPersistence(PERSISTENCE_LOCAL);
            }
            await auth.signInWithEmailAndPassword(email, password);
            goHome();
        } catch {
            toastService.error(getString("loginEmailAndPasswordError"));
        };
        loadingService.set(false);
    }
    const onLogin = handleSubmit(handleLoginClick);

    useRunOnce(() => {
        if (auth.getCurrentUser()) {
            goHome();
            return null;
        }
        reset(getDefaultLoginFormModel());
    });

    const Header = () => <>
        <Typography className={styles.textSiteName} variant="h5" gutterBottom>
            {getString("siteName")}
        </Typography>
        <Typography variant="h5" gutterBottom>
            {getString("login")}
        </Typography>
    </>;

    const InputFields = () => <>
        <Field
            control={control}
            name={LoginRules.email.name}
            rules={LoginRules.email}
            component={TextField}
            className={styles.emailField}
            label="Email"
            type="email"
            autoComplete="email"
            variant="outlined"
            onKeyPress={(event: { key: string; }) => event.key === "Enter" && onLogin()} />
        <Field
            control={control}
            name={LoginRules.password.name}
            rules={LoginRules.password}
            component={TextField}
            className={styles.passwordField}
            label={getString("password")}
            type="password"
            autoComplete="current-password"
            variant="outlined"
            onKeyPress={(event: { key: string; }) => event.key === "Enter" && onLogin() } />
    </>;

    const AccountButtons = () => <>
        <div className={styles.wrapperForgotPassword}>
            <Button
                size="small" 
                onClick={handleForgotPasswordClick}>
                {getString("forgotPassword")}
            </Button>
        </div>
        <Field
            control={control}
            name={LoginRules.remainLoggedIn.name}
            component={Checkbox}
            color="primary"
            className={styles.checkAgreement}
            label={getString("remainLoggedIn")} />

        <div className={styles.groupSignupLogin}>
            <Button
                variant="outlined" 
                color="primary" 
                onClick={handleCreateClick}>
                {getString("createAccount")}
            </Button>
            <Button
                variant="contained" 
                color="primary" 
                onClick={onLogin} >
                {getString("login")}
            </Button>
        </div>
    </>;

    const FormContainer = () => (
        <Card variant="outlined">
            <CardContent className={styles.mainCard}>
                <Header />
                <InputFields />
                <AccountButtons />
            </CardContent>
        </Card>
    );

    return (
        <div className={styles.centeringContainer}>
            <FormContainer/>
            <BottomControls />
        </div>
    );
}

export default LoginPage;