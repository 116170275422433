import React from "react";
import { auth } from "database/firebase";
import { useTranslation } from "infrastructure/hooks";
import { Typography, CircularProgress } from "@material-ui/core";
import {
    getMomentFromTime,
    formatMoment,
    getTimeUntilMoment
} from "utils/date-utils";
import { BoldText, BidInput } from "components";
import { Bid, BasePost } from "database/types";
import { getFormattedPrice } from 'utils/number-utils';
import styles from "./section-bidding.module.scss";

interface Props {
    store: {
        post: BasePost;
        areBidsLoading: boolean;
        bids: Bid[];
        placeBid: (bid: number) => void;
    }
    minPrice: number;
    maxPrice: number;
}

const SectionBidding = ({ store, minPrice, maxPrice }: Props) => {
    const { getString } = useTranslation();

    const { post, areBidsLoading, bids, placeBid } = store;

    const lastBid = bids.length ? bids[bids.length - 1] : null;
    const lastPrice = lastBid?.value || 0;
    const postMoment = getMomentFromTime(post.biddingEndDate);

    return areBidsLoading ? (
        <div className={styles.bidsLoadingProgress}>
            <CircularProgress />
        </div>
    ) : <>
        {lastPrice < post.price && (
            <BoldText color="primary" variant="h5" align="center">
                {getString("askingPrice")}: {getFormattedPrice(post.price)}
            </BoldText>
        )}
        {!!lastPrice && (
            <BoldText color="primary" variant="h5" align="center">
                {getString("bidFrom")}: {getFormattedPrice(lastPrice)}
            </BoldText>
        )}
        <Typography variant="subtitle1" align="center">
            {getString("thePostIsAvailableUntil")} {formatMoment(postMoment)}
            <br />
            ({getTimeUntilMoment(postMoment, getString)})
        </Typography>
        <BoldText align="center">
            {getString("totalBidsMadeForThisPost")}: {bids.length}
        </BoldText>
        {auth.getCurrentUser() ? (
            <BidInput
                className={styles.bidsWrapper}
                wantedPrice={post.price}
                minPrice={minPrice}
                maxPrice={maxPrice}
                bids={bids}
                onBid={placeBid} />
        ) : (
            <Typography align="center" color="error">
                {getString("youMustBeLoggedInToPLaceAnOffer")}
            </Typography>
        )}
    </>;
};

export default SectionBidding;