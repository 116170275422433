import { User } from "database/types";
import { makeAutoObservable, runInAction } from "mobx";
import { createContext } from "react";
import * as AccountApi from "database/api/account";
import { isAdmin, isSuperAdmin } from "database/api/user";
import { auth } from "database/firebase";

export class UserDetailsStore {
    public user: User | null | undefined;

    constructor() {
        makeAutoObservable(this);
    }

    public isUserAdmin = () => isAdmin(this.user);

    public isUserSuperAdmin = () => isSuperAdmin(this.user);

    public listenToUserChanges = () => {
        auth.onAuthStateChanged(async (authUser) => {
            if (authUser && !this.user) {
                this.loadUser();
            } else if (!authUser) {
                this.clearUserAccount();
            }
        });
    }

    public setWithNotificationSound = (withSound: boolean) => {
        if (this.user) {
            this.user.withNotificationSound = withSound; 
        }
    }

    private loadUser = async () => {
        const user = await AccountApi.getAccountForCurrentUserWithText();

        runInAction(() => {
            this.user = user;
        });
    }

    private clearUserAccount = () => this.user = null;
}

export const userDetailsStore = new UserDetailsStore();
export const UserDetailsContext = createContext(userDetailsStore);

export const getUserDetails = () => userDetailsStore.user;
export const setWithNotificationSound = (withSound: boolean) => 
    userDetailsStore.setWithNotificationSound(withSound);