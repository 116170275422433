import { getLocation } from "./location-utils";
import { Coordinates, Location } from "./location-utils";

export const sleep = async (millisecconds: number) =>
    new Promise(resolve => setTimeout(resolve, millisecconds));

export const setTimeoutAsync = async <T>(callback: () => T, delay: number): Promise<T> =>
    new Promise(resolve => setTimeout(() => resolve(callback()), delay));

export const getLocationAsync = async (coordinates: Coordinates): Promise<Location> =>
    new Promise(resolve => getLocation(coordinates, resolve));

