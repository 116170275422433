import React, { PropsWithChildren } from "react";
import { Typography } from "@material-ui/core";
import classNames from "classnames";
import styles from "./bold-text.module.scss";

interface Props {
    variant?: "body1" | "body2" | "button" | "caption" | "h1" | "h2" | "h3" | "h4" | "h5" | "h6" |
        "inherit" | "overline" | "srOnly" | "subtitle1" | "subtitle2";
    color?: "inherit" | "initial" | "primary" | "secondary" | "textPrimary" | "textSecondary" | "error";
    align?: "inherit" | "left" | "center" | "right" | "justify";
    className?: string;
}

const BoldText = ({ variant, color, align, children, className }: PropsWithChildren<Props>) => (
    <Typography
        className={classNames(styles.boldText, className)}
        variant={variant}
        color={color}
        align={align}>
        {children}
    </Typography>
);

export default BoldText;