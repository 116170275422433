import React from "react";
import { RealEstateListStore } from "../real-estate-list-store";
import { observer } from "mobx-react";
import { RangePicker } from "components";
import { useTranslation } from "infrastructure/hooks";

interface Props {
    store: RealEstateListStore;
}

const FilterConstructionYear = ({ store }: Props) => {
    const { getString } = useTranslation();

    const { rangeConstructionYear, setConstructionYearRange } = store;

    return (
        <RangePicker 
            minValue={rangeConstructionYear.min}
            maxValue={rangeConstructionYear.max}
            min={rangeConstructionYear.lowest}
            max={rangeConstructionYear.greatest}
            label={getString("constructionYear")}
            useAction
            onChangeMin={min => rangeConstructionYear.min = min}
            onChangeMax={max => rangeConstructionYear.max = max}
            onChangeMinMax={setConstructionYearRange} />
    );
}

export default observer(FilterConstructionYear);