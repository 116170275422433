import { createContext } from "react";
import { runInAction, makeAutoObservable } from "mobx";
import { Maintenance } from "database/types";
import { getMaintenanceStatus } from "database/api/application";

export class MiraAuctionStore {
    public maintenanceStatus: Maintenance | null = null;

    constructor() {
        makeAutoObservable(this);
    }

    public loadMaintenanceStatus = async () => {
        const maintenanceStatus = await getMaintenanceStatus();
        
        runInAction(() => this.maintenanceStatus = maintenanceStatus);
        
        return this.isUnderMaintenance();
    }

    public isUnderMaintenance = () =>
        !!this.maintenanceStatus?.isUnderMaintenance;
}

export const miraAuctionStore = new MiraAuctionStore();
export const MiraAuctionContext = createContext(miraAuctionStore);