import React from "react";
import { RealEstateListStore } from "../real-estate-list-store";
import { useTranslation } from "infrastructure/hooks";
import { observer } from "mobx-react";
import { MultiSelect } from "components";

interface Props {
    store: RealEstateListStore;
}

const FilterCounties = ({ store }: Props) => {
    const { getString } = useTranslation();

    const { selectedCounties } = store;

    return (
        <MultiSelect
            onItemRender={value => value}
            onSelect={selected => selectedCounties.selected = selected}
            selectedValues={selectedCounties.selected}
            values={selectedCounties.all}
            useAction
            label={getString("county")} />
    );
}

export default observer(FilterCounties);