import { IconButton, Tooltip } from "@material-ui/core";
import React, { PropsWithChildren } from "react";

interface Props {
	tooltip: string;
	onClick: () => void;
	className?: string;
	hidden?: boolean;
}

const NavigationBarButton = ({
	tooltip,
	onClick,
	className,
	children,
	hidden = false,
}: PropsWithChildren<Props>) => {
	if (hidden) return null;
	return (
		<Tooltip title={tooltip} className={className}>
			<IconButton color="secondary" onClick={onClick}>
				{children}
			</IconButton>
		</Tooltip>
	);
};

export default NavigationBarButton;
