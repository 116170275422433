import { Accordion, AccordionDetails, AccordionSummary, Typography } from "@material-ui/core";
import classNames from "classnames";
import React, { PropsWithChildren } from "react";
import { useTranslation } from "infrastructure/hooks";
import styles from "../post-form.module.scss";

interface Props {
    step: number;
    stepsNumber: number;
    error: boolean;
}

const CharacteristicsSection = ({ step, stepsNumber, error, children }: PropsWithChildren<Props>) => {

    const { getString } = useTranslation();

    return (
        <Accordion className={styles.section}>
            <AccordionSummary>
                <div className={styles.sectionHeader}>
                    <Typography className={styles.sectionLabel} variant="h6">
                        {getString("characteristics")}
                    </Typography>
                    <div
                        className={classNames(styles.stepWrapper, {
                            [styles.valid]: !error
                        })}>
                        <Typography className={styles.sectionLabel} variant="h6">
                            {`${step} / ${stepsNumber}`}
                        </Typography>
                    </div>
                </div>
            </AccordionSummary>
            <AccordionDetails>
                <div className={styles.sectionContent}>
                    {children}
                </div>
            </AccordionDetails>
        </Accordion>
    )
}

export default CharacteristicsSection;