import React from "react";
import { SingleSelect } from "..";

interface Props {
    value: string | undefined;
    onSelect: (value: string | undefined) => void;
    values: string[];
    onItemRender: (value: string) => JSX.Element | string;
    label?: string;
    useAction?: boolean;
}

const SingleSelectFilter = ({
    value,
    onSelect,
    values,
    onItemRender,
    label,
    useAction
}: Props) => {
    const handleSelect = (selected: string) => {
        if (selected === "all") {
            onSelect(undefined);
            return;
        }
        onSelect(selected);
    }

    const getValue = () => {
        if (value === undefined) {
            return "all";
        }
        return value;
    }

    return (
        <SingleSelect
            onItemRender={onItemRender}
            onSelect={handleSelect}
            value={getValue()}
            values={["all", ...values]}
            label={label}
            useAction={useAction} />
    );
}

export default SingleSelectFilter;