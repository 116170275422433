import React from "react";
import { useTranslation } from "infrastructure/hooks";
import { observer } from "mobx-react";
import { MultiSelect } from "components";
import { TranslationStringKey } from "utils/translation/types";
import { VehiclesListStore } from "../vehicles-list-store";

interface Props {
    store: VehiclesListStore;
}

const FilterBodies = ({ store }: Props) => {
    const { getString } = useTranslation();

    const { selectedBodies } = store;

    return (
        <MultiSelect
            onItemRender={value => getString(value as TranslationStringKey)}
            onSelect={selected => selectedBodies.selected = selected}
            selectedValues={selectedBodies.selected}
            values={selectedBodies.all}
            useAction
            label={getString("body")} />
    );
}

export default observer(FilterBodies);