import React from "react";
import styles from "./not-found-message.module.scss";

interface Props {
    message: string;
}

const NotFoundMessage = ({ message }: Props) => (
    <div className={styles.notFoundWrapper}>
        <h2 className={styles.notFound}>{message}</h2>
    </div>
);

export default NotFoundMessage;