import { EMPTY_BASE_POST } from "../types";
import { TranslationStringKey } from "utils/translation/types";
import { BasePost } from "database/types";
import { CATEGORY_REAL_ESTATE } from "utils/constants";

export interface RealEstatePost extends BasePost {
    country: string;
    county: string;
    city: string;
    zone: string;
    street: string;
    constructionYear: number;
    renovationYear: number;
    partitioning: string;
    floor: string;
    levels: number;
    numberOfRooms: number;
    numberOfBedrooms: number;
    numberOfBathrooms: number;
    numberOfBathroomsWithWindow: number;
    numberOfBalconies: number;
    balconiesType: string[];
    purpose: string;
    surface: number;
    parkingSpace: boolean;
    hasElevator: boolean;
}

export interface RealEstateFormPost extends BasePost {
    country: string;
    county: string;
    city: string;
    zone: string;
    street: string;
    constructionYear: number | null;
    renovationYear: number | null;
    partitioning: string;
    floor: string;
    levels: number | null;
    numberOfRooms: number | null;
    numberOfBedrooms: number | null;
    numberOfBathrooms: number | null;
    numberOfBathroomsWithWindow: number | null;
    numberOfBalconies: number | null;
    balconiesType: string[];
    purpose: string;
    surface: number | null;
    parkingSpace: boolean;
    hasElevator: boolean;
}

export const EMPTY_REAL_ESTATE_POST: RealEstateFormPost = {
    ...EMPTY_BASE_POST,
    country: "",
    county: "",
    city: "",
    zone: "",
    street: "",
    category: CATEGORY_REAL_ESTATE,
    constructionYear: null,
    renovationYear: null,
    partitioning: "",
    floor: "",
    levels: null,
    numberOfRooms: null,
    numberOfBedrooms: null,
    numberOfBathrooms: null,
    numberOfBathroomsWithWindow: null,
    numberOfBalconies: null,
    balconiesType: [],
    purpose: "",
    surface: null,
    parkingSpace: false,
    hasElevator: false
}

export const partitioningOptions: TranslationStringKey[] =
    ["house", "duplex", "circular", "separated", "semiSeparated", "studio"];
export const floorOptions: string[] = ["house", "semiBasement", "groundFloor"];
export const purposeOptions: TranslationStringKey[] = ["housing", "office", "commercialSpace"];
export const balconiesTypeOptions: TranslationStringKey[] = ["interior", "exterior", "terrace"];