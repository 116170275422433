import { createContext } from "react";
import { createPost } from "../api";
import { getLocationAsync } from "utils/thread-utils";
import { generatePostDocument } from "database/api/post";
import { EMPTY_FOR_RENT_POST, ForRentFormPost, ForRentPost } from "../types";
import { RealEstateFormStore } from "../../real-estate/real-estate-form/real-estate-form-store";
import { getLocationText } from "utils/location-utils";
import { action } from "mobx";
import { loadingService } from "infrastructure/services";

export class ForRentFormStore extends RealEstateFormStore {
    post: ForRentFormPost = EMPTY_FOR_RENT_POST;

    public savePost = action(async () => {
        loadingService.set(true);

        const postDocument = generatePostDocument();
        const locationDetails = await getLocationAsync({ ...this.post });
        const { country, county, city, zone, street } = locationDetails;

        await createPost({
            ...(this.post as ForRentPost),
            ...await this.uploadImages(postDocument.id),
            country,
            county,
            city,
            zone,
            street,
            location: getLocationText(locationDetails, true)
        }, postDocument);
        loadingService.set(false);
    });

    public reset = action(() => {
        this.post = EMPTY_FOR_RENT_POST;
        this.isConfirmationOpen = false;
    });
}

export const forRentFormStore = new ForRentFormStore();
export const ForRentFormContext = createContext(forRentFormStore);