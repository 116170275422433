import { toJS } from "mobx";
import { observer } from "mobx-react";
import React, { useContext, useEffect } from "react";
import { CloseFormDialog, FullscreenDialog } from "components";
import { useTranslation } from "infrastructure/hooks";
import { EMPTY_VEHICLE_POST, VehicleFormPost, VehiclePost } from "../types";
import VehicleCharacteristics from "./vehicles-characteristics";
import { VehicleFormContext } from "./vehicles-form-store";
import PostForm from "../../components/post-form";

const MINIMUM_PRICE = 100;
const PRICE_STEP = 100;

interface Props {
    isOpen: boolean;
    onClose: () => void;
    vehiclePost?: VehicleFormPost | null;
    onSubmit?: (post: VehiclePost) => void;
}

const VehiclesForm = ({ vehiclePost, isOpen, onClose, onSubmit }: Props) => {
    const { getString, isEnglish } = useTranslation();
    
    const vehiclesStore = useContext(VehicleFormContext);

    const {
        post,
        isConfirmationOpen,
        areCharacteristicsValid,
        arePostDetailsValid,

        setPost,
        openConfirmation,
        closeConfirmation,
        savePost,
        updatePost,
        setOwner,
        reset
    } = vehiclesStore;

    useEffect(() => {
        if (vehiclePost) {
            setPost(toJS(vehiclePost));
        } else {
            setPost(EMPTY_VEHICLE_POST);
        }
    }, [vehiclePost, setPost]);

    const handleSaveClick = async () => {
        if (vehiclePost) {
            await updatePost();
            onSubmit && onSubmit(post as VehiclePost);
        } else {
            await savePost();
        }
        onClose();
    }

    toJS(post);

    const title = isEnglish() ? vehiclePost?.titleText?.en : vehiclePost?.titleText?.ro;
    
    return (
        <FullscreenDialog
            open={isOpen}
            onClose={openConfirmation}
            title={vehiclePost ? getString("editThePost", title ?? "") : getString("addPostForVehicles")}>
            <PostForm
                isSaveDisabled={!arePostDetailsValid()}
                minimumPrice={MINIMUM_PRICE}
                post={post}
                onSaveClick={handleSaveClick}
                priceStep={PRICE_STEP}
                isEdit={!!vehiclePost}
                areCharacteristicsValid={!!areCharacteristicsValid()}
                isVisible={isOpen}
                enableBidding>
                <VehicleCharacteristics post={post} setOwner={setOwner} />
            </PostForm>
            <CloseFormDialog
                open={isConfirmationOpen}
                onCancel={closeConfirmation}
                onConfirm={() => {
                    closeConfirmation();
                    onClose();
                    reset();
                }} />
        </FullscreenDialog>
    );
}

export default observer(VehiclesForm);