import React from "react";
import { Routes, Route } from "react-router-dom";
import RealEstateList from "./real-estate-list";
import RealEstateDetails from "./real-estate-details";

const RealEstateCategory = () => (
    <Routes>
        <Route path="/:id" element={<RealEstateDetails />} />
        <Route path="/" element={<RealEstateList />} />
    </Routes>
);

export default RealEstateCategory;