import { observer } from "mobx-react";
import React from "react";
import { RangePicker } from "components";
import { useTranslation } from "infrastructure/hooks";
import { VehiclesListStore } from "../vehicles-list-store";

interface Props {
    store: VehiclesListStore;
}

const FilterMileage = ({ store }: Props) => {
    const { getString } = useTranslation();

    const { rangeMileage, setMileageRange } = store;

    return (
        <RangePicker
            minValue={rangeMileage.min}
            maxValue={rangeMileage.max}
            min={rangeMileage.lowest}
            max={rangeMileage.greatest}
            label={getString("mileage")}
            onChangeMin={min => rangeMileage.min = min}
            onChangeMax={max => rangeMileage.max = max}
            useAction
            onChangeMinMax={setMileageRange} />
    )
}

export default observer(FilterMileage);