import { ConfirmDialog } from "components";
import { observer } from "mobx-react";
import React from "react";
import { useContext } from "react";
import { ConfirmDialogContext } from "./confirm-dialog-store";

const ConfirmDialogService = () => {
    const {
        isOpen,
        description,
        confirmMessage,
        handleConfirm,
        reset
    } = useContext(ConfirmDialogContext);

    return (
        <ConfirmDialog 
            open={isOpen} 
            description={description} 
            confirmMessage={confirmMessage}
            onCancel={reset}
            onConfirm={handleConfirm} />
    )
}

export default observer(ConfirmDialogService);