export interface User {
    id: string;
    email: string;
    isIndividual: boolean;
    firstName: string;
    lastName: string;
    companyName: string;
    phoneNumber: string;
    uniqueIdentificationCode: string;
    accountType: string;
    verified: boolean;
    createdPostsIds: string[];
    createdPosts?: BasePost[];
    joinDate: Date;
    bidsIds: string[];
    bids?: Bid[];
    banned: boolean;
    banReason?: Text;
    banReasonId: string;
    withNotificationSound?: boolean;
}

export interface BasePost {
    approvedByUserId: string;
    approvedByUser?: User;
    biddingEndDate?: Date;
    bidsIds: string[];
    bids?: Bid[];
    category: string;
    createDate: Date;
    createdByUserId: string;
    createdByUser?: User;
    descriptionTextId: string;
    descriptionText?: Text;
    hasBidding: boolean;
    id: string;
    images: string[];
    isActive: boolean;
    isDeleted: boolean;
    isVerified: boolean;
    lastUpdateDate: Date;
    latitude: number;
    location: string;
    longitude: number;
    mainImage: string;
    price: number;
    titleTextId: string;
    titleText?: Text;
    youtubeUrl?: string;
    panoramicViewUrl?: string;
}

export interface Bid {
    id: string;
    forPostId: string; 
    forPost?: BasePost;
    byUserId: string; 
    byUser?: User;
    value: number;
    time: Date; 
}

export interface Text {
    id: string;
    ro: string;
    en: string;
}

export const EMPTY_TEXT: Text = {
    id: "",
    ro: "",
    en: "",
}

export interface Maintenance {
    isUnderMaintenance: boolean;
    startDate: Date;
    endDate: Date;
}

export enum NotificationType {
    BASE = "base",
    AUCTION = "auction"
}

export interface BaseNotificationType {
    id?: string;
    type: NotificationType;
    timestamp: Date;
}

export interface AuctionNotificationType extends BaseNotificationType {
    userId: string;
    userFirstName: string;
    userLastName: string;
    postId: string;
    postCategory: string;
    postTitleRo: string;
    postTitleEn: string;
    postMainImage: string;
    amount: number;
}

export interface UserNotificationType {
    id: string;
    isAdmin: boolean;
    isSeen: boolean;
    targetUserId: string;
    notificationId: string;
}

export interface StateNotificationType extends BaseNotificationType {
    userNotificationId: string;
    isSeen: boolean;
    isAdmin: boolean;
}