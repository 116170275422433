import { SnackbarProvider, useSnackbar } from "notistack";
import React from "react";
import { useContext, useEffect } from "react";
import { ToastContext } from "./toast-store";

const ToastInjection = () => {
    const { enqueueSnackbar } = useSnackbar();
    const { setEnqueue } = useContext(ToastContext);

    useEffect(() => {
        setEnqueue(enqueueSnackbar);
    }, [enqueueSnackbar, setEnqueue]);

    return null;
}

const Toast = () => (
    <SnackbarProvider
        anchorOrigin={{
            vertical: "top",
            horizontal: "center"
        }}>
        <ToastInjection />
    </SnackbarProvider>
);

export default Toast;