import { User } from "database/types";
import { useContext } from "react";
import { useRunOnce } from "utils/ui-utils";
import { UserDetailsContext } from "./user-details-store";

const useUserDetails = () => {
    const userDetailsStore = useContext(UserDetailsContext);

    const { user, listenToUserChanges, isUserAdmin, isUserSuperAdmin } = userDetailsStore;

    useRunOnce(listenToUserChanges);

    return ({ 
        isInitialized: user !== undefined,
        user: user as (User | null), 
        isUserAdmin: isUserAdmin(), 
        isUserSuperAdmin: isUserSuperAdmin() 
    });
}

export default useUserDetails;