import React, { useContext } from "react";
import { 
    Card,
    CardContent,
    Button,
    Typography,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    TextField,
    FormControlLabel,
    Checkbox
} from "@material-ui/core";
import { SignupContext } from "./signup-store";
import { observer } from "mobx-react";
import { auth, sendEmailVerification } from "database/firebase";
import { isUserWithEmail, createUser } from "database/api/user";
import { useTranslation } from "infrastructure/hooks";
import { useNavigation } from "utils/router-utils";
import { useRunOnce } from "utils/ui-utils";
import styles from "./signup.module.scss";
import { loadingService, toastService } from "infrastructure/services";
import BottomControls from "../components/bottom-controls";

const SignupPage = () => {
    const { getString } = useTranslation();
    const { goHome } = useNavigation();

    const signupStore = useContext(SignupContext);

    const {
        account,
        password,
        confirmPassword,
        agreeToTermsAndConditions,

        emailError,
        passwordError,
        confirmPasswordError,
        firstNameError,
        lastNameError,
        phoneNumberError,
        companyNameError,
        uniqueIdentificationCodeError,

        validateFields,
        setAccountType,
        setEmail,
        setPassword,
        setConfirmPassword,
        setFirstName,
        setLastName,
        setPhoneNumber,
        setCompanyName,
        setUniqueIdentificationCode,
        setAgreement,

        isSignupValid,
        reset
    } = signupStore;

    useRunOnce(() => {
        if (auth.getCurrentUser()) {
            goHome();
        } else {
            setTimeout(() => {
                setAccountType(true);
            }, 100);
            validateFields();
        }
    });


    const {
        isIndividual,
        email,
        firstName,
        lastName,
        phoneNumber,
        companyName,
        uniqueIdentificationCode
    } = account;

    const handleCreateClick = async () => {
        loadingService.set(true);
        const isEmailTaken = await isUserWithEmail(email);
        if (isEmailTaken) {
            loadingService.set(false);
            toastService.error(getString("emailTaken"));
            return;
        }
        try {
            const { user } = await auth.createUserWithEmailAndPassword(email, password);
            account.id = user?.uid || "";
            await createUser(account);
            user && await sendEmailVerification(user);
            alert(getString("verificationEmailSent"));
            reset();
            goHome();
        } catch (error) {
            toastService.error(getString("somethingWentWrong"));
        }
        loadingService.set(false);
    }

    const headerText = <>
        <Typography className={styles.textSiteName} variant="h5" gutterBottom>
            {getString("siteName")}
        </Typography>
        <Typography variant="h5" gutterBottom>
            {getString("createAccount")}
        </Typography>
    </>;

    const inputFields = <>
        <FormControl variant="outlined" className={styles.dropdownAccountType}>
            <InputLabel>{getString("accountType")}</InputLabel>
            <Select
                value={isIndividual ? 1 : 0}
                onChange={event => setAccountType(!!event.target.value)}
                labelWidth={96}>
                <MenuItem value={0}>{getString("legalIdentity")}</MenuItem>
                <MenuItem value={1}>{getString("individual")}</MenuItem>
            </Select>
        </FormControl>
        <div className={styles.fields}>
            <TextField
                autoFocus
                required
                error={!!emailError}
                helperText={emailError}
                className={styles.inputField}
                label="Email"
                type="email"
                autoComplete="email"
                variant="outlined"
                value={email}
                onChange={event => setEmail(event.target.value)} />
            <TextField
                required
                error={!!passwordError}
                helperText={passwordError}
                className={styles.inputField}
                label={getString("password")}
                type="password"
                autoComplete="new-password"
                variant="outlined"
                value={password}
                onChange={event => setPassword(event.target.value)} />
            <TextField
                required
                error={!!confirmPasswordError}
                helperText={confirmPasswordError}
                className={styles.inputField}
                label={getString("confirmationPassword")}
                type="password"
                autoComplete="new-password"
                variant="outlined"
                value={confirmPassword}
                onChange={event => setConfirmPassword(event.target.value)} />
            {isIndividual ? <>
                <TextField
                    required
                    error={!!firstNameError}
                    helperText={firstNameError}
                    className={styles.inputField}
                    label={getString("firstName")}
                    type="text"
                    autoComplete="given-name"
                    variant="outlined"
                    value={firstName}
                    onChange={event => setFirstName(event.target.value)} />
                <TextField
                    required
                    error={!!lastNameError}
                    helperText={lastNameError}
                    className={styles.inputField}
                    label={getString("lastName")}
                    type="text"
                    autoComplete="family-name"
                    variant="outlined"
                    value={lastName}
                    onChange={event => setLastName(event.target.value)} />
            </> : (
                <TextField
                    required
                    error={!!companyNameError}
                    helperText={companyNameError}
                    className={styles.inputField}
                    label={getString("companyName")}
                    type="text"
                    autoComplete="organization"
                    variant="outlined"
                    value={companyName}
                    onChange={event => setCompanyName(event.target.value)} />
            )}
            <TextField
                required
                error={!!phoneNumberError}
                helperText={phoneNumberError}
                className={styles.inputField}
                label={getString("phoneNumber")}
                type="tel"
                autoComplete="tel-local"
                variant="outlined"
                value={phoneNumber}
                onChange={event => setPhoneNumber(event.target.value)}
            />
            {!isIndividual && (
                <TextField
                    required
                    error={!!uniqueIdentificationCodeError}
                    helperText={uniqueIdentificationCodeError}
                    className={styles.inputField}
                    label={getString("uniqueIdentificationCode")}
                    type="number"
                    variant="outlined"
                    value={uniqueIdentificationCode}
                    onChange={event => setUniqueIdentificationCode(event.target.value)} />
            )}
        </div>
    </>;

    const accountButtons = <>
        <FormControlLabel
            className={styles.checkAgreement}
            control={
                <Checkbox
                    checked={agreeToTermsAndConditions}
                    onChange={(_, checked) => setAgreement(checked)}
                    color="primary" />
            }
            label={getString("agreeToTermsAndConditions")} />
        <Button 
            variant="contained" 
            color="primary" 
            onClick={handleCreateClick}
            disabled={!isSignupValid()}>
            {getString("createAccount")}
        </Button>
    </>;

    const card = (
        <Card variant="outlined">
            <CardContent className={styles.mainCard}>
                {headerText}
                {inputFields}
                {accountButtons}
            </CardContent>
        </Card>
    );

    return (
        <div className={styles.centeringContainer}>
            {card}
            <BottomControls />
        </div>
    );
}

export default observer(SignupPage);