import React from "react";
import { Route, Routes } from "react-router-dom";
import { ForgotPasswordPage, LoginPage, SignupPage } from "pages/account";
import Admin from "pages/admin";
import CategoriesNavigationRoutes from "./categories-navigation-routes";

const NavigationRoutes = () => (
    <Routes>
        <Route path="/login" element={<LoginPage />} />
        <Route path="/signup" element={<SignupPage />} />
        <Route path="/forgotPassword" element={<ForgotPasswordPage />} />
        <Route path="/admin/*" element={<Admin />} />
        <Route path="*" element={<CategoriesNavigationRoutes />} />
    </Routes>
);

export default NavigationRoutes;