import React from "react";
import { RealEstateListStore } from "../real-estate-list-store";
import { observer } from "mobx-react";
import { RangePicker } from "components";
import { useTranslation } from "infrastructure/hooks";

interface Props {
    store: RealEstateListStore;
}

const FilterRenovationYear = ({ store }: Props) => {
    const { getString } = useTranslation();

    const { rangeRenovationYear, setRenovationYearRange } = store;

    return (
        <RangePicker 
            minValue={rangeRenovationYear.min}
            maxValue={rangeRenovationYear.max}
            min={rangeRenovationYear.lowest}
            max={rangeRenovationYear.greatest}
            label={getString("renovationYear")}
            useAction
            onChangeMin={min => rangeRenovationYear.min = min}
            onChangeMax={max => rangeRenovationYear.max = max}
            onChangeMinMax={setRenovationYearRange} />
    );
}

export default observer(FilterRenovationYear);