import React from "react";
import HouseIcon from "@material-ui/icons/House";
import HomeWorkIcon from "@material-ui/icons/HomeWork";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import { useTranslation } from "infrastructure/hooks";
import { ScrollTopTransition } from "components";
import sharedStyles from "../home.module.scss";
import styles from "./our-help.module.scss";
import classNames from "classnames";
import { useNavigation } from "utils/router-utils";

const OurHelp = () => {

    const { getString } = useTranslation();
    const { goTo } = useNavigation();

    return (
        <div className={sharedStyles.section}>
            <h1 className={sharedStyles.header}>
                {getString("ourHelpHeader")}
            </h1>
            <ScrollTopTransition selector=".help-item-1" timeout={200} classNames="fade-in">
                <div
                    onClick={() => goTo("/realEstate")}
                    className={classNames(
                        sharedStyles.hoverableCard, styles.helpItem, "help-item-1"
                    )}>
                    <div className={styles.iconWrapper}>
                        <HouseIcon className={styles.helpIcon} />
                    </div>
                    <h2 className={styles.title}>{getString("buyingAHomeTitle")}</h2>
                    <h3 className={styles.description}>
                        {getString("buyingAHomeDescription", 50)}
                    </h3>
                    <ArrowForwardIcon className={styles.navigateIcon} />
                </div>
            </ScrollTopTransition>
            <ScrollTopTransition selector=".help-item-3" timeout={400} classNames="fade-in">
                <div 
                    onClick={() => goTo("/forRent")}
                    className={classNames(
                        sharedStyles.hoverableCard, styles.helpItem, "help-item-3"
                    )}>
                    <div className={styles.iconWrapper}>
                        <HomeWorkIcon className={styles.helpIcon} />
                    </div>
                    <h2 className={styles.title}>{getString("rentingAHomeTitle")}</h2>
                    <h3 className={styles.description}>{getString("rentingAHomeDescription")}</h3>
                    <ArrowForwardIcon className={styles.navigateIcon} />
                </div>
            </ScrollTopTransition>
        </div>
    )
}

export default OurHelp;