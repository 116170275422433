import { BasePost } from "database/types";
import { EMPTY_BASE_POST } from "../types";
import { TranslationStringKey } from "utils/translation/types";
import { CATEGORY_VEHICLES } from "utils/constants";

export interface VehiclePost extends BasePost {
    country: string;
    county: string;
    city: string;
    brand: string;
    model: string;
    fabricationYear: number;
    mileage: number;
    engineCapacity: number;
    condition: string;
    fuel: string[];
    body: string;
    color: string;
    gearbox: string;
    owner: string;
}

export interface VehicleFormPost extends BasePost {
    country: string;
    county: string;
    city: string;
    brand: string;
    model: string;
    fabricationYear: number | null;
    mileage: number | null;
    engineCapacity: number | null;
    condition: string;
    fuel: string[];
    body: string;
    color: string;
    gearbox: string;
    owner: string;
}

export const EMPTY_VEHICLE_POST: VehicleFormPost = {
    ...EMPTY_BASE_POST,
    country: "",
    county: "",
    city: "",
    category: CATEGORY_VEHICLES,
    brand: "",
    model: "",
    fabricationYear: null,
    mileage: null,
    engineCapacity: null,
    condition: "",
    fuel: [],
    body: "",
    color: "",
    gearbox: "",
    owner: ""
}

export const brandOptions = [
    "abarth",
    "aixam",
    "alfaRomeo",
    "other",
    "aro",
    "astonMartin",
    "audi",
    "bentley",
    "bmw",
    "buick",
    "cadillac",
    "chevrolet",
    "chrysler",
    "citroen",
    "cupra",
    "dacia",
    "daewoo",
    "daihatsu",
    "dodge",
    "ds",
    "ferrari",
    "fiat",
    "ford",
    "gmc",
    "honda",
    "hummer",
    "hyundai",
    "infiniti",
    "isuzu",
    "iveco",
    "jaguar",
    "jeep",
    "kia",
    "lada",
    "lamborghini",
    "lancia",
    "landRover",
    "lexus",
    "lincoln",
    "maserati",
    "maybach",
    "mazda",
    "mclaren",
    "mercedesBenz",
    "mini",
    "mitsubishi",
    "nissan",
    "oldsmobile",
    "opel",
    "peugeot",
    "pontiac",
    "porsche",
    "renault",
    "rollsRoyce",
    "rover",
    "saab",
    "saturn",
    "seat",
    "skoda",
    "smart",
    "ssangyong",
    "subaru",
    "suzuki",
    "tata",
    "tesla",
    "toyota",
    "volkswagen",
    "volvo",
    "wartburg"
] as const;

export const brandOptionsMutable = [...brandOptions];

export type brandOptionsType = typeof brandOptions[number];

export const modelOptions: { [key in brandOptionsType]: string[]; } = {
    abarth: ["500"],
    aixam: ["City", "Coupe", "Crossline", "e-City", "e-Truck", "Miniauto", "Scouty R"],
    alfaRomeo: [
        "146", "147", "156", "159", "Giulia", "Giulietta", "GT", "GTV", "Mito", "Spider", "Stelvio"
    ],
    other: [],
    aro: ["10", "IMS", "Spartana"],
    astonMartin: ["DB11", "DBS", "DBX", "Rapide", "V8 Vantage", "V12 Vantage"],
    audi: [
        "80",
        "90",
        "100",
        "A1",
        "A2",
        "A3",
        "A4",
        "A4 Allroad",
        "A5",
        "A6",
        "A6 Allroad",
        "A7",
        "A8",
        "Cabriolet",
        "e-tron",
        "Q2",
        "Q3",
        "Q5",
        "Q7",
        "Q8",
        "Quattro",
        "R8",
        "RS2",
        "RS3",
        "RS4",
        "RS5",
        "RS6",
        "RS7",
        "RS Q3",
        "RS Q8",
        "S3",
        "S4",
        "S5",
        "S6",
        "S7",
        "S8",
        "SQ5",
        "SQ7",
        "SQ8",
        "TT",
        "TT RS",
        "TT S",
    ],
    bentley: ["Bentayga", "Continental", "Flying Spur", "Mulliner", "Mulsanne"],
    bmw: [
        "i3",
        "i8",
        "iX3",
        "M1",
        "M2",
        "M3",
        "M4",
        "M5",
        "M6",
        "M760",
        "M850",
        "Series 1",
        "Series 2",
        "Series 3",
        "Series 4",
        "Series 5",
        "Series 6",
        "Series 7",
        "Series 8",
        "X1",
        "X2",
        "X3",
        "X3 M",
        "X4",
        "X4 M",
        "X5",
        "X5 M",
        "X6",
        "X6 M",
        "X7",
        "Z3",
        "Z4",
        "Z4 M",
    ],
    buick: ["Regal", "Riviera"],
    cadillac: ["CLS", "CTS", "Deville", "Eldorado", "Escalade"],
    chevrolet: [
        "Aveo",
        "Camaro",
        "Captiva",
        "Corvette",
        "Cruze",
        "Epica",
        "Kalos",
        "Malibu",
        "Matiz",
        "Nubira",
        "Orlando",
        "Silverado",
        "Spark",
        "Trax"
    ],
    chrysler: [
        "300C",
        "Concorde",
        "Crossfire",
        "Grand Voyager",
        "New Yorker",
        "PT Cruiser",
        "Saratoga",
        "Sebring",
        "Vision",
        "Voyager"
    ],
    citroen: [
        "Berlingo",
        "C-Crosser",
        "C-Elysée",
        "C-ZERO",
        "C1",
        "C2",
        "C3",
        "C3 AIRCROSS",
        "C3 Picasso",
        "C3 Pluriel",
        "C4",
        "C4 Aircross",
        "C4 Cactus",
        "C4 Grand Picasso",
        "C4 Grand Space Tourer",
        "C4 Picasso",
        "C5",
        "C5 Aircross",
        "C6",
        "C8",
        "DS3",
        "DS4",
        "DS5",
        "Evasion",
        "Jumper",
        "Jumpy",
        "Nemo",
        "SpaceTourer",
        "XM",
        "Xsara",
        "Xsara Picasso"
    ],
    cupra: ["Formentor", "Leon"],
    dacia: [
        "1300",
        "1400",
        "Altul",
        "Dokker",
        "Duster",
        "Lodgy",
        "Logan",
        "Logan Stepway",
        "Logan Van",
        "Pick Up",
        "Sandero",
        "Sandero Stepway"
    ],
    daewoo: ["Cielo", "Kalos", "Matiz", "Nubira", "Tico"],
    daihatsu: ["Cuore", "Feroza", "Rocky", "Sirion", "Terios", "YRV"],
    dodge: ["Avenger", "Caliber", "Challenger", "Journey", "Magnum", "Nitro", "RAM"],
    ds: ["DS 3 Crossback", "DS 5", "DS 7 Crossback"],
    ferrari: [
        "360", "488 GTB", "599", "812", "F8", "GTC4Lusso", "Portofino", "Roma", "SF90 Stradale"
    ],
    fiat: [
        "124",
        "500",
        "500L",
        "500X",
        "Albea",
        "Bravo",
        "Croma",
        "Doblo",
        "Ducato",
        "Freemont",
        "Fullback",
        "Grande Punto",
        "Linea",
        "Marea",
        "Multipla",
        "Palio",
        "Panda",
        "Punto",
        "Qubo",
        "Scudo",
        "Sedici",
        "Stilo",
        "Tipo",
        "Ulysse"
    ],
    ford: [
        "B-MAX",
        "Bronco",
        "C-MAX",
        "C-Max Grand",
        "Cougar",
        "Courier",
        "EcoSport",
        "EDGE",
        "Escort",
        "Explorer",
        "F150",
        "Fiesta",
        "Flex",
        "Focus",
        "Focus C-Max",
        "Fusion",
        "Galaxy",
        "GRAND C-MAX",
        "GT",
        "KA",
        "Ka+",
        "Kuga",
        "Model A Coupe",
        "Mondeo",
        "Mustang",
        "Mustang Mach-E",
        "Puma",
        "Ranger",
        "S-Max",
        "Thunderbird",
        "Tourneo",
        "Tourneo Connect",
        "Tourneo Courier",
        "Tourneo Custom",
        "Transit",
        "Transit Connect",
        "Transit Custom"],
    gmc: ["Acadia", "Sierra", "Terrain", "Yukon"],
    honda: [
        "Accord",
        "Civic",
        "CR-V",
        "CR-Z",
        "CRX",
        "FR-V",
        "Honda e",
        "HR-V",
        "Insight",
        "Jazz",
        "Legend",
        "Ridgeline"
    ],
    hummer: ["Bullbar", "H2", "H3"],
    hyundai: [
        "Accent",
        "Atos",
        "Coupe",
        "Elantra",
        "Galloper",
        "Getz",
        "Grand Santa Fe",
        "i10",
        "i20",
        "i30",
        "i40",
        "IONIQ",
        "ix20",
        "ix35",
        "ix55",
        "KONA",
        "Santa Fe",
        "Terracan",
        "Tucson",
        "Veloster"
    ],
    infiniti: ["FX 30", "FX 35", "Q30", "Q50", "Q70", "QX30", "QX70", "QX80"],
    isuzu: ["D-Max"],
    iveco: ["Massif"],
    jaguar: ["E-Pace", "F-Pace", "F-Type", "I-Pace", "S-Type", "X-Type", "XE", "XF", "XJ"],
    jeep: [
        "Cherokee",
        "Commander",
        "Compass",
        "Gladiator",
        "Grand Cherokee",
        "Patriot",
        "Renegade",
        "Wrangler"
    ],
    kia: [
        "Carens",
        "Ceed",
        "Niro",
        "Optima",
        "Picanto",
        "Pro Ceed",
        "Rio",
        "Sorento",
        "Soul",
        "Sportage",
        "Stonic",
        "Venga",
        "XCeed"
    ],
    lada: ["Niva"],
    lamborghini: ["Aventador", "Huracan", "URUS"],
    lancia: ["Delta", "Flavia", "Lybra", "Musa", "Thesis", "Ypsilon"],
    landRover: [
        "Defender",
        "Discovery",
        "Discovery Sport",
        "Freelander",
        "Range Rover",
        "Range Rover Evoque",
        "Range Rover Sport",
        "Range Rover Velar"
    ],
    lexus: [
        "CT",
        "LC 500",
        "LC 500h",
        "ES",
        "GS",
        "GX",
        "IS",
        "LS",
        "LX",
        "NX",
        "RC",
        "RX",
        "UX"
    ],
    lincoln: ["Aviator", "Continental", "MKC", "MKX", "Navigator", "Town Car"],
    maserati: ["Coupe", "Ghibli", "GranTurismo", "Levante", "MC20", "Quattroporte"],
    maybach: ["62", "S 500 4Matic", "S 560 4Matic"],
    mazda: [
        "2",
        "3",
        "5",
        "6",
        "CX-3",
        "CX-5",
        "CX-7",
        "CX-9",
        "CX-30",
        "Demio",
        "MPV",
        "MX-5",
        "MX-30",
        "RX-8",
        "Tribute"
    ],
    mclaren: ["570S", "GT"],
    mercedesBenz: [
        "190",
        "380",
        "608",
        "A",
        "AMG",
        "B",
        "C",
        "CE",
        "Citan",
        "CL",
        "CLA",
        "CLC",
        "CLK",
        "CLS",
        "E",
        "EQA",
        "EQC",
        "EQS",
        "G",
        "GL",
        "GLA",
        "GLB",
        "GLC",
        "GLC Coupe",
        "GLE",
        "GLE Coupe",
        "GLK",
        "GLS",
        "ML",
        "R",
        "S",
        "SL",
        "SLC",
        "SLK",
        "SLR",
        "SLS",
        "Sprinter",
        "V",
        "Vaneo",
        "Viano",
        "Vito",
        "W115",
        "W123",
        "W124",
        "W126",
        "W201",
        "X"],
    mini: ["Clubman", "Cooper", "Cooper S", "Countryman", "ONE", "Paceman"],
    mitsubishi: [
        "ASX",
        "Carisma",
        "Colt",
        "Eclipse-Cross",
        "Grandis",
        "i-MiEV",
        "L200",
        "Lancer",
        "Outlander",
        "Pajero",
        "Pajero Pinin",
        "Space Star"
    ],
    nissan: [
        "350 Z",
        "Almera",
        "GT-R",
        "Juke",
        "LEAF",
        "Micra",
        "Murano",
        "Navara",
        "Note",
        "Pathfinder",
        "Patrol",
        "Pixo",
        "Primastar",
        "Primera",
        "Pulsar",
        "Qashqai",
        "Qashqai+2",
        "Terrano",
        "Tiida",
        "X-Trail"
    ],
    oldsmobile: [],
    opel: [
        "Adam",
        "Agila",
        "Ampera",
        "Antara",
        "Ascona",
        "Astra",
        "Combo",
        "Corsa",
        "Crossland",
        "Frontera",
        "Grandland X",
        "Insignia",
        "Karl",
        "Meriva",
        "Mokka",
        "Movano",
        "Omega",
        "Signum",
        "Tigra",
        "Vectra",
        "Vivaro",
        "Zafira"],
    peugeot: [
        "107",
        "108",
        "205",
        "206",
        "207",
        "208",
        "301",
        "307",
        "308",
        "406",
        "407",
        "508",
        "607",
        "807",
        "1007",
        "2008",
        "3008",
        "4007",
        "4008",
        "5008",
        "Bipper",
        "Boxer",
        "Expert",
        "iON",
        "Partner",
        "RCZ",
        "Traveller"
    ],
    pontiac: ["Solstice"],
    porsche: [
        "911",
        "918 Spyder",
        "924",
        "Boxster",
        "Cayenne",
        "Cayman",
        "Macan",
        "Panamera",
        "Taycan"
    ],
    renault: [
        "4",
        "12",
        "Arkana",
        "Captur",
        "Clio",
        "Espace",
        "Express",
        "Fluence",
        "Grand Espace",
        "Grand Scenic",
        "Kadjar",
        "Kangoo",
        "Koleos",
        "Laguna",
        "Latitude",
        "Master",
        "Megane",
        "Modus",
        "Scenic",
        "Scenic RX4",
        "Symbol",
        "Talisman",
        "Trafic",
        "Twingo",
        "Twizy",
        "ZOE"
    ],
    rollsRoyce: ["Cullinan", "Ghost", "Phantom", "Silver Spur", "Wraith"],
    rover: ["75"],
    saab: ["9-3", "9-5", "9000"],
    saturn: [],
    seat: [
        "Alhambra",
        "Altea",
        "Altea XL",
        "Arona",
        "Arosa",
        "Ateca",
        "Cordoba",
        "Exeo",
        "Ibiza",
        "Leon",
        "Malaga",
        "Mii",
        "Tarraco",
        "Toledo"
    ],
    skoda: [
        "Citigo",
        "Fabia",
        "Kamiq",
        "Karoq",
        "Kodiaq",
        "Octavia",
        "Praktik",
        "Rapid",
        "Roomster",
        "Scala",
        "Superb",
        "Yeti"
    ],
    smart: ["Forfour", "Fortwo", "Roadster"],
    ssangyong: ["Actyon", "Korrando", "Kyron", "Musso", "Rexton", "Rodius", "Tivoli"],
    subaru: ["B9 Tribeca", "Forester", "Impreza", "Justy", "Legacy", "Outback", "WRX STI", "XV"],
    suzuki: [
        "Across",
        "Alto",
        "Baleno",
        "Celerio",
        "Grand Vitara",
        "Ignis",
        "Jimny",
        "Kizashi",
        "Liana",
        "Samurai",
        "Splash",
        "Swace",
        "Swift",
        "SX4",
        "SX4 S-Cross",
        "Vitara",
        "Wagon R+",
        "XL7"
    ],
    tata: ["Indica"],
    tesla: ["Model 3", "Model S", "Model X", "Model Y", "Roadster"],
    toyota: [
        "4Runner",
        "Auris",
        "Avensis",
        "Aygo",
        "C-HR",
        "Camry",
        "Corolla",
        "Corolla Verso",
        "Highlander",
        "Hilux",
        "iQ",
        "Land Cruiser",
        "Previa",
        "Prius",
        "Prius+",
        "Proace",
        "RAV4",
        "Tundra",
        "Urban Cruiser",
        "Verso",
        "Yaris"
    ],
    volkswagen: [
        "Amarok",
        "ARTEON",
        "Atlas",
        "Beetle",
        "Bora",
        "Caddy",
        "California",
        "Caravelle",
        "Crafter",
        "Eos",
        "Fox",
        "Golf",
        "Golf Plus",
        "Golf Sportsvan",
        "ID.3",
        "Jetta",
        "Kafer",
        "Lupo",
        "Multivan",
        "New Beetle",
        "Passat",
        "Passat Alltrack",
        "Passat CC",
        "Phaeton",
        "Polo",
        "Scirocco",
        "Sharan",
        "T-Cross",
        "T-ROC",
        "Tiguan",
        "Touareg",
        "Touran",
        "Transporter",
        "up!"
    ],
    volvo: [
        "C30",
        "C70",
        "S40",
        "S60",
        "S80",
        "S90",
        "V40",
        "V50",
        "V60",
        "V70",
        "V90",
        "XC 40",
        "XC 60",
        "XC 70",
        "XC 90"
    ],
    wartburg: []
}

export const conditionOptions: TranslationStringKey[] = ["used", "new"];

export const fuelOptions: TranslationStringKey[] = ["petrol", "diesel", "lpg", "electric"];

export const bodyOptions: TranslationStringKey[] = [
    "cabrio",
    "berline",
    "coupe",
    "pickup",
    "hatchback",
    "break",
    "offRoad",
    "minibus",
    "monovolum",
    "suv"
];

export const colorOptions: TranslationStringKey[] = [
    "white",
    "black",
    "gray",
    "silver",
    "red",
    "blue",
    "brown",
    "green",
    "beige",
    "orange",
    "gold",
    "yellow",
    "purple"
];

export const gearboxOptions: TranslationStringKey[] = ["manual", "automatic"];

export const ownerOptions: TranslationStringKey[] = ["private", "firm"];