import React, { useState, useRef, FunctionComponent, SVGProps } from "react";
import { NavigationBarButton } from "../components";
import { useTranslation } from "infrastructure/hooks";
import {Grid, Paper} from "@material-ui/core";
import styles from "./change-language-select.module.scss";
import classnames from "classnames";
import { RomaniaFlagIcon, USFlagIcon } from "assets/icons";
import Popper from "components/popper";
import { LanguageType } from "utils/translation/types";
import { LANGUAGE_CODE_EN, LANGUAGE_CODE_RO } from "utils/translation/constants";

interface LanguageOption {
    label: string;
    languageCode: LanguageType;
    icon: FunctionComponent<SVGProps<SVGSVGElement>>;
}

const languages: LanguageOption[] = [
	{ label: "Română", languageCode: LANGUAGE_CODE_RO, icon: RomaniaFlagIcon },
	{ label: "English", languageCode: LANGUAGE_CODE_EN, icon: USFlagIcon },
];

const ChangeLanguageSelect = () => {
	const { getCurrentLanguageCode, getString, changeLanguageTo } = useTranslation();
	const [isOpen, setOpen] = useState(false);
	const anchorEl = useRef(null);

	const renderOptions = () =>
		languages.map((option, index) => {
			const Icon = option.icon;

			return (
				<Grid
					alignItems="center"
					key={index}
					container
					item
					xs={12}
					onClick={() => {
						changeLanguageTo(option.languageCode as LanguageType);
						setOpen(false);
					}}
					className={classnames({
						[styles.languageOption]: true,
						[styles.activeOption]: getCurrentLanguageCode() === option.languageCode,
					})}>
					<Grid item>
						<Icon className={styles.languageIcon} />
					</Grid>
					<Grid item>
						{option.label}
					</Grid>
				</Grid>
			);
		});

	const renderActiveLanguageIcon = () => {
		const Icon = languages.find((lang) => lang.languageCode === getCurrentLanguageCode())?.icon;
		return Icon && (
			<Icon className={classnames(styles.languageIcon, styles.active)} />
		);
	};

	return <>
		<Grid ref={anchorEl}>
			<NavigationBarButton
				tooltip={getString("changeLanguage")}
				onClick={() => setOpen(!isOpen)}>
				{renderActiveLanguageIcon()}
				<span className={styles.languageCode}>
					{getCurrentLanguageCode().toUpperCase()}
				</span>
			</NavigationBarButton>
		</Grid>
		<Popper
			anchorEl={anchorEl.current}
			className={styles.popper}
			onClose={() => setOpen(false)}
			open={isOpen}>
			<Paper>
				<Grid
					item
					container
					direction="column"
					justifyContent="center"
					className={styles.optionsContainer}>
					{renderOptions()}
				</Grid>
			</Paper>
		</Popper>
	</>
};

export default ChangeLanguageSelect;
