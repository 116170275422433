import * as PostApi from "../../../database/api/post";
import { CATEGORY_VEHICLES } from "../../../utils/constants";
import { VehiclePost } from "./types";

const options: PostApi.PostOptions = {
    category: CATEGORY_VEHICLES,
    showAvailable: true
}

export const getPost = (postId: string) =>
    PostApi.getPost(postId, CATEGORY_VEHICLES) as Promise<VehiclePost | null>;

export const getAllPosts = () =>
    PostApi.getAllPosts(options) as Promise<VehiclePost[]>;

export const getAllPostsWithText = (posts?: VehiclePost[]) =>
    PostApi.getAllPostsWithText(posts, options) as Promise<VehiclePost[]>;

export const getAllPostsWithBids = (posts?: VehiclePost[]) =>
    PostApi.getAllPostsWithBids(posts, options) as Promise<VehiclePost[]>;

export const getPostWithText = (postId: string) => 
    PostApi.getPostWithText(postId) as Promise<VehiclePost | null>;

export const createPost = (post: VehiclePost, postDocument = PostApi.generatePostDocument()) =>
    PostApi.createPost(post, postDocument);