import { openTermsAndConditions, useUserDetails } from "infrastructure/services";
import React from "react";
import { auth } from "database/firebase";
import { useNavigation } from "utils/router-utils";
import { useTranslation } from "infrastructure/hooks";
import { scrollToTop } from "utils/ui-utils";
import styles from "./footer.module.scss";

const Footer = () => {
    const { getString } = useTranslation();
    const { goTo } = useNavigation();
    const { user } = useUserDetails();

    return (
        <div className={styles.footer}>
            <div className={styles.nav}>
                <div className={styles.appName}><span>Mira</span> Auction</div>
                <div className={styles.navItems}>
                    <span onClick={() => scrollToTop()}>{getString("home")}</span>
                    {!!user ? (
                        <span onClick={() => auth.signOut()}>{getString("logout")}</span>
                    ) : <>
                        <span onClick={() => goTo("/login")}>{getString("login")}</span>
                        <span onClick={() => goTo("/signup")}>{getString("createAccount")}</span>
                    </>}
                    <span onClick={() => goTo("/realEstate")}>{getString("realEstate")}</span>
                    <span onClick={() => goTo("/forRent")}>{getString("forRent")}</span>
                </div>
            </div>
            <div className={styles.copyrightTerms}>
                <span className={styles.copyright}>&copy; Copyright Mira Auction 2020</span>
                <div className={styles.terms}>
                    <span onClick={openTermsAndConditions}>{getString("termsAndConditions")}</span>
                </div>
            </div>
        </div>
    );
}

export default Footer;