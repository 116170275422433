import React from "react";
import { observer } from "mobx-react";
import {
    FilterPrice,
    FilterCounties,
    FilterCities,
    FilterBrands,
    FilterModels,
    FilterFabricationYear,
    FilterMileage,
    FilterEngineCapacity,
    FilterCondition,
    FilterFuels,
    FilterBodies,
    FilterColors,
    FilterGearbox,
    FilterOwner,
} from "./filter-components";
import styles from "./vehicles-list-filters.module.scss";
import { VehiclesListStore } from "./vehicles-list-store";

interface Props {
    store: VehiclesListStore;
}

const VehiclesListFilters = ({ store }: Props) => (
    <div className={styles.vehiclesListFiltersWrapper}>
        <FilterPrice store={store} />
        <FilterCounties store={store} />
        <FilterCities store={store} />
        <FilterBrands store={store} />
        <FilterModels store={store} />
        <FilterFabricationYear store={store} />
        <FilterMileage store={store} />
        <FilterEngineCapacity store={store} />
        <FilterCondition store={store} />
        <FilterFuels store={store} />
        <FilterBodies store={store} />
        <FilterColors store={store} />
        <FilterGearbox store={store} />
        <FilterOwner store={store} />
    </div>
);

export default observer(VehiclesListFilters);