import { createContext } from "react";
import { runInAction, makeObservable, observable, action } from "mobx";
import {
    RealEstatePost,
    partitioningOptions,
    floorOptions,
    balconiesTypeOptions,
    purposeOptions
} from "../types";
import { getMomentAfterYears } from "utils/date-utils";
import { getAllPostsWithBids, getAllPostsWithText } from "../api";
import { isNotEmpty, unique, sortBy, intersect } from "utils/array-utils";
import {
    Range,
    MultiSelect,
    EMPTY_MULTI_SELECT,
    getDefaultRange,
    SORT_BY_DESCENDING_DATE
} from "../../types";
import { loadingService } from "infrastructure/services";

const tenYearsAhead = getMomentAfterYears(10).get("y");

export class RealEstateListStore {
    public allPosts: RealEstatePost[] = [];
    public search: string = "";
    public sortingBy: string = SORT_BY_DESCENDING_DATE;
    public filterMyPosts: boolean = false;
    public priceRange: Range = getDefaultRange(1, 1000000);
    public selectedCounties: MultiSelect = EMPTY_MULTI_SELECT;
    public selectedCities: MultiSelect = EMPTY_MULTI_SELECT;
    public selectedZones: MultiSelect = EMPTY_MULTI_SELECT;
    public rangeConstructionYear: Range = getDefaultRange(1850, tenYearsAhead);
    public rangeRenovationYear: Range = getDefaultRange(1850, tenYearsAhead);
    public selectedPartitions: MultiSelect = EMPTY_MULTI_SELECT;
    public selectedFloors: MultiSelect = EMPTY_MULTI_SELECT;
    public rangeStories: Range = getDefaultRange(0, 30);
    public rangeRooms: Range = getDefaultRange(0, 30);
    public rangeBedrooms: Range = getDefaultRange(0, 30);
    public rangeBathrooms: Range = getDefaultRange(0, 10);
    public rangeBathroomsWithWindow: Range = getDefaultRange(0, 10);
    public rangeBalconies: Range = getDefaultRange(0, 10);
    public selectedBalconyTypes: MultiSelect = EMPTY_MULTI_SELECT;
    public selectedPurposes: MultiSelect = EMPTY_MULTI_SELECT;
    public rangeArea: Range = getDefaultRange(1, 10000);
    public withParkingSpace?: boolean = undefined;
    public withElevator?: boolean = undefined;

    constructor() {
        makeObservable(this, {
            allPosts: observable,
            search: observable,
            sortingBy: observable,
            filterMyPosts: observable,
            priceRange: observable,
            selectedCounties: observable,
            selectedCities: observable,
            selectedZones: observable,
            rangeConstructionYear: observable,
            rangeRenovationYear: observable,
            selectedPartitions: observable,
            selectedFloors: observable,
            rangeStories: observable,
            rangeRooms: observable,
            rangeBedrooms: observable,
            rangeBathrooms: observable,
            rangeBathroomsWithWindow: observable,
            rangeBalconies: observable,
            selectedBalconyTypes: observable,
            selectedPurposes: observable,
            rangeArea: observable,
            withParkingSpace: observable,
            withElevator: observable,
            setPriceRange: action,
            setConstructionYearRange: action,
            setRenovationYearRange: action,
            setStoriesRange: action,
            setRoomsRange: action,
            setBedroomsRange: action,
            setBathroomsRange: action,
            setBathroomsWithWindowRange: action,
            setBalconiesRange: action,
            setAreaRange: action
        });
    }

    public fetchPosts = action(async () => {
        loadingService.set(true);
        let posts = await getAllPostsWithText();
        posts = await getAllPostsWithBids(posts);
        
        runInAction(() => {
            this.allPosts = posts;
            this.initializeFilters();
        });
        loadingService.set(false);
    });
 
    public setPriceRange = (min: number, max: number, setBounds: boolean = false) =>
        this.setRange(this.priceRange, min, max, setBounds);
 
    public setConstructionYearRange = (min: number, max: number, setBounds: boolean = false) =>
        this.setRange(this.rangeConstructionYear, min, max, setBounds);
 
    public setRenovationYearRange = (min: number, max: number, setBounds: boolean = false) =>
        this.setRange(this.rangeRenovationYear, min, max, setBounds);
 
    public setStoriesRange = (min: number, max: number, setBounds: boolean = false) =>
        this.setRange(this.rangeStories, min, max, setBounds);
 
    public setRoomsRange = (min: number, max: number, setBounds: boolean = false) =>
        this.setRange(this.rangeRooms, min, max, setBounds);
 
    public setBedroomsRange = (min: number, max: number, setBounds: boolean = false) =>
        this.setRange(this.rangeBedrooms, min, max, setBounds);
 
    public setBathroomsRange = (min: number, max: number, setBounds: boolean = false) =>
        this.setRange(this.rangeBathrooms, min, max, setBounds);
 
    public setBathroomsWithWindowRange = (min: number, max: number, setBounds: boolean = false) =>
        this.setRange(this.rangeBathroomsWithWindow, min, max, setBounds);
 
    public setBalconiesRange = (min: number, max: number, setBounds: boolean = false) =>
        this.setRange(this.rangeBalconies, min, max, setBounds);
 
    public setAreaRange = (min: number, max: number, setBounds: boolean = false) =>
        this.setRange(this.rangeArea, min, max, setBounds);

    public clearFilters = action(() => {
        this.clearRange(this.priceRange);
        this.selectedCounties.selected = [];
        this.selectedCities.selected = [];
        this.selectedZones.selected = [];
        this.clearRange(this.rangeConstructionYear);
        this.clearRange(this.rangeRenovationYear);
        this.selectedPartitions.selected = [];
        this.selectedFloors.selected = [];
        this.clearRange(this.rangeStories);
        this.clearRange(this.rangeRooms);
        this.clearRange(this.rangeBedrooms);
        this.clearRange(this.rangeBathrooms);
        this.clearRange(this.rangeBathroomsWithWindow);
        this.clearRange(this.rangeBalconies);
        this.selectedBalconyTypes.selected = [];
        this.selectedPurposes.selected = [];
        this.clearRange(this.rangeArea);
        this.withParkingSpace = undefined;
        this.withElevator = undefined;
    });

    private setRange = (to: Range, min: number, max: number, setBounds: boolean = false) => {
        to.min = min;
        to.max = max;

        if (setBounds) {
            to.lowest = min;
            to.greatest = max;
        }
    }

    protected clearRange = (range: Range) => {
        range.min = range.lowest;
        range.max = range.greatest;
    }

    protected initializeFilters = () => {
        this.initializePriceRange();
        this.selectedCounties.all = sortBy(unique(this.allPosts.map(post => post.county)));
        this.selectedCities.all = sortBy(unique(this.allPosts.map(post => post.city)));
        this.selectedZones.all = sortBy(unique(this.allPosts.map(post => post.zone)));
        this.initializeConstructionYearRange();
        this.initializeRenovationYearRange();
        this.selectedPartitions.all = intersect(partitioningOptions, this.allPosts,
            post => post.partitioning);
        this.selectedFloors.all = [
            ...intersect(floorOptions, this.allPosts, post => post.floor),
            ...sortBy(unique(this.allPosts.filter(post => !floorOptions.includes(post.floor))
                .map(post => post.floor)))
        ];
        this.initializeStoriesRange();
        this.initializeRoomsRange();
        this.initializeBedroomsRange();
        this.initializeBathroomsRange();
        this.initializeBathroomsWithWindowRange();
        this.initializeBalconiesRange();
        this.selectedBalconyTypes.all = balconiesTypeOptions;
        this.selectedPurposes.all = intersect(purposeOptions, this.allPosts, post => post.purpose);
        this.initializeAreaRange();
    }

    private initializePriceRange = () => {
        const prices = this.allPosts.map(post => isNotEmpty(post.bids) ?
            post?.bids![post?.bids!.length - 1].value : post.price
        );
        this.setPriceRange(Math.min(...prices), Math.max(...prices), true);
    }

    private initializeConstructionYearRange = () => {
        const constructionYears = this.allPosts.map(post => post.constructionYear);
        this.setConstructionYearRange(
            Math.min(...constructionYears),
            Math.max(...constructionYears),
            true
        );
    }

    private initializeRenovationYearRange = () => {
        const renovationYears = this.allPosts.map(post => post.renovationYear);
        this.setRenovationYearRange(
            Math.min(...renovationYears),
            Math.max(...renovationYears),
            true
        );
    }

    private initializeStoriesRange = () => {
        const stories = this.allPosts.map(post => post.levels);
        this.setStoriesRange(Math.min(...stories), Math.max(...stories), true);
    }

    private initializeRoomsRange = () => {
        const rooms = this.allPosts.map(post => post.numberOfRooms);
        this.setRoomsRange(Math.min(...rooms), Math.max(...rooms), true);
    }

    private initializeBedroomsRange = () => {
        const bedrooms = this.allPosts.map(post => post.numberOfBedrooms);
        this.setBedroomsRange(Math.min(...bedrooms), Math.max(...bedrooms), true);
    }

    private initializeBathroomsRange = () => {
        const bathrooms = this.allPosts.map(post => post.numberOfBathrooms);
        this.setBathroomsRange(Math.min(...bathrooms), Math.max(...bathrooms), true);
    }

    private initializeBathroomsWithWindowRange = () => {
        const bathroomsWithWindow = this.allPosts.map(post => post.numberOfBathroomsWithWindow);
        this.setBathroomsWithWindowRange(
            Math.min(...bathroomsWithWindow),
            Math.max(...bathroomsWithWindow),
            true);
    }

    private initializeBalconiesRange = () => {
        const balconies = this.allPosts.map(post => post.numberOfBalconies);
        this.setBalconiesRange(Math.min(...balconies), Math.max(...balconies), true);
    }

    private initializeAreaRange = () => {
        const areas = this.allPosts.map(post => post.surface);
        this.setAreaRange(Math.min(...areas), Math.max(...areas), true);
    }
}

export const realEstateListStore = new RealEstateListStore();
export const RealEstateListContext = createContext(realEstateListStore);