import { LinearProgress } from "@material-ui/core";
import { StateNotificationType } from "database/types";
import React from "react";
import { useEffect, useState } from "react";
import NotificationElement from "../../notification-element";
import { POPUP_DURATION } from "../constants";
import styles from "./notification-popup-element.module.scss";

const MAXIMUM_PROGRESS = 100;
const ERROR_MARGIN = 500;
const INTERVAL_TIME = (POPUP_DURATION - ERROR_MARGIN) / MAXIMUM_PROGRESS;

interface Props {
    notification: StateNotificationType;
    onClick?: (notification: StateNotificationType) => void; 
}

const NotificationPopupElement = ({ notification, onClick }: Props) => {
    const [dismissPercent, setDismissPercent] = useState(MAXIMUM_PROGRESS);
    
    useEffect(() => {
        const timer = setInterval(() => {
            setDismissPercent(oldDismissPercent => oldDismissPercent && --oldDismissPercent);
        }, INTERVAL_TIME);

        return () => clearInterval(timer);
    }, []);

    return (
        <div className={styles.container}>
            <LinearProgress className={styles.timerProgress} variant="determinate" value={dismissPercent} />
            <NotificationElement notification={notification} onClick={onClick} compact />
        </div>
    )
}

export default NotificationPopupElement;