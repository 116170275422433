import React from "react";
import { RealEstateListStore } from "./real-estate-list-store";
import { observer } from "mobx-react";
import {
    FilterPrice,
    FilterCounties,
    FilterCities,
    FilterZones,
    FilterConstructionYear,
    FilterRenovationYear,
    FilterPartitions,
    FilterFloors,
    FilterStories,
    FilterRooms,
    FilterBedrooms,
    FilterBathrooms,
    FilterBathroomsWithWindow,
    FilterBalconies,
    FilterBalconyTypes,
    FilterPurposes,
    FilterArea,
    FilterParkingSpace,
    FilterElevator
} from "./filter-components";
import styles from "./real-estate-list-filters.module.scss";

interface Props {
    store: RealEstateListStore;
}

const RealEstateListFilters = ({ store }: Props) => (
    <div className={styles.realEstateListFiltersWrapper}>
        <FilterPrice store={store} />
        <FilterCounties store={store} />
        <FilterCities store={store} />
        <FilterZones store={store} />
        <FilterConstructionYear store={store} />
        <FilterRenovationYear store={store} />
        <FilterPartitions store={store} />
        <FilterFloors store={store} />
        <FilterStories store={store} />
        <FilterRooms store={store} />
        <FilterBedrooms store={store} />
        <FilterBathrooms store={store} />
        <FilterBathroomsWithWindow store={store} />
        <FilterBalconies store={store} />
        <FilterBalconyTypes store={store} />
        <FilterPurposes store={store} />
        <FilterArea store={store} />
        <FilterParkingSpace store={store} />
        <FilterElevator store={store} />
    </div>
);

export default observer(RealEstateListFilters);