import React from "react";
import { Card, Typography } from "@material-ui/core";
import { Image } from "components";
import { getMomentFromTime, getTimeUntilMoment, hasPassedMoment } from "utils/date-utils";
import { useTranslation } from "infrastructure/hooks";
import { getFormattedPrice } from 'utils/number-utils';
import classNames from "classnames";
import styles from "./post-grid-item.module.scss";
import { BasePost } from "database/types";
import { NavLink } from "react-router-dom";

interface Props {
    data: BasePost;
    postCategory: string;
    className?: string;
}

const PostGridItem = ({ data, postCategory, className }: Props) => {
    const { getString, getText } = useTranslation();

    const biddingEndMoment = getMomentFromTime(data.biddingEndDate);
    const isInactive = !data.isActive || (data.hasBidding && hasPassedMoment(biddingEndMoment));

    const verifiedBadge = data.isVerified && (
        <div className={styles.verifiedBadge}>
            <Image src="https://img.icons8.com/material-sharp/20/ffffff/checkmark.png" />
            {getString("verified")}
        </div>
    );

    const displayImage = (
        <div className={styles.imagePost}>
            <Image src={data.mainImage} />
            {isInactive && (
                <div className={styles.soldOverlay}>
                    <span>{getString("sold")}</span>
                </div>
            )}
        </div>
    );

    const location = (
        <div className={styles.location} title={data.location}>
            <Image src="https://img.icons8.com/material-sharp/16/a0a0a0/marker.png" />
            <span>{data.location}</span>
        </div>
    );

    const title = (
        <div className={styles.title}>
            {getText(data.titleText)}
        </div>
    );

    const price = (
        <div className={styles.price}>
            {getString(data.hasBidding ?
                (data.bidsIds.length ? "bidFrom" : "askingPrice") : "price")}: {
                getFormattedPrice((data.hasBidding && data.bidsIds.length) ?
                    Math.max(...data.bids?.map(bid => bid.value)!) : data.price)}
        </div>
    );

    const timeLeft = data.hasBidding && data.biddingEndDate && (
        <div className={styles.timeLeft}>
            <Image src="https://img.icons8.com/material-sharp/20/a0a0a0/property-with-timer.png" />
            <Typography variant="subtitle2">
                {getTimeUntilMoment(biddingEndMoment, getString)}
            </Typography>
        </div>
    );

    return (
        <NavLink className={styles.link} to={`/${postCategory}/${data.id}`} >
            <Card className={classNames(styles.postGridItemContainer, className)}>
                {verifiedBadge}
                {displayImage}
                {location}
                {title}
                <div className={styles.bidDetails}>
                    {price}
                    {timeLeft}
                </div>
            </Card>
        </NavLink>
    );
}

export default PostGridItem;