import React from "react";
import { useTranslation } from "infrastructure/hooks";
import { observer } from "mobx-react";
import { ImageGallery, BoldText } from "components";
import { useParams } from "react-router-dom";
import { Typography, Card, CardContent, Tooltip } from "@material-ui/core";
import { CheckCircle, PinDrop } from "@material-ui/icons";
import { CharacteristicItemProps } from "./components/characteristic-item";
import {
    SectionCharacteristics,
    SectionContactAndPricing,
    SectionBidsForPost,
    SectionPostActions,
    SectionYoutubeVideo,
    SectionPanoramaView
} from "./sections";
import styles from "./post-details.module.scss";
import { PostDetailsStoreType } from "../../types";
import { auth } from "database/firebase";
import { getFormattedPrice } from "utils/number-utils";
import { isAdmin, isSuperAdmin } from "database/api/user";
import classNames from "classnames";
import { getMomentFromTime, hasPassedMoment } from "utils/date-utils";
import { loadingService } from "infrastructure/services";

interface Props {
    store: PostDetailsStoreType
    characteristics: CharacteristicItemProps[];
    minBid?: number;
    maxBid?: number;
    additionalPostDetails?: string | JSX.Element;
    loanReminder?: boolean;
    onOpenEdit: () => void;
}

const PostDetails = ({
    store,
    characteristics,
    minBid,
    maxBid,
    additionalPostDetails,
    loanReminder,
    onOpenEdit
}: Props) => {
    const { getString, getText } = useTranslation();
    const { id } = useParams<any>();

    const { post, user } = store;
    
    const isUserAdmin = isAdmin(user);
    const isCurrentUserAuthor = post?.createdByUserId === auth.getCurrentUser()?.uid;
    const showExtendedDescription = (isUserAdmin || isCurrentUserAuthor) && !post?.hasBidding;
    const isInactive = !post?.isActive ||
        (post?.hasBidding && hasPassedMoment(getMomentFromTime(post.biddingEndDate))) ;

    return <>
        <div className={styles.postDetailsPageContainer}>
            {post && (!post.isDeleted || isUserAdmin) ? (
                <div className={styles.content}>
                    <Card>
                        <CardContent>
                            <Typography className={styles.idDisplay} variant="caption">
                                ID: {id}
                            </Typography>
                            {isUserAdmin || (isCurrentUserAuthor && !isInactive) ? (
                                <SectionPostActions
                                    onOpenEdit={onOpenEdit}
                                    store={store} />
                            ) : post.isVerified && (
                                <Tooltip title={getString("verified")}>
                                    <CheckCircle className={styles.verifiedBadge} />
                                </Tooltip>
                            )}
                            <BoldText color="primary" variant="h5">
                                {getText(post.titleText)}
                            </BoldText>
                            <div className={styles.location}>
                                <PinDrop />
                                <Typography>{post.location}</Typography>
                            </div>
                            <ImageGallery images={[post.mainImage, ...post.images]} />
                            <div className={styles.mainInformation}>
                                {!(isCurrentUserAuthor || isUserAdmin) ? (
                                    <SectionContactAndPricing
                                        className={styles.panelWrapper}
                                        store={{
                                            ...store,
                                            placeBid: store.placeBid,
                                            post
                                        }}
                                        minPrice={minBid}
                                        maxPrice={maxBid}
                                        additionalPostDetails={additionalPostDetails}
                                        loanReminder={loanReminder} />
                                ) : post.hasBidding && (
                                    <div className={styles.panelWrapper}>
                                        <SectionBidsForPost
                                            post={post}
                                            bids={store.bids}
                                            isSuperAdmin={isSuperAdmin(user)} />
                                    </div>
                                )}
                                <div className={classNames(styles.panelWrapper, {
                                    [styles.fullWidth]: showExtendedDescription
                                })}>
                                    <div className={styles.descriptionHeader}>
                                        <BoldText variant="h5">
                                            {getString("description")}
                                        </BoldText>
                                        {showExtendedDescription && (
                                            <BoldText color="primary" variant="h5" align="center">
                                                {getString("price")}: {getFormattedPrice(post.price)}
                                            </BoldText>
                                        )}
                                    </div>
                                    <pre><Typography>{getText(post.descriptionText)}</Typography></pre>
                                </div>
                            </div>
                            <SectionYoutubeVideo videoUrl={post.youtubeUrl} />
                            <SectionPanoramaView panoramaUrl={post.panoramicViewUrl} />
                            <SectionCharacteristics characteristics={characteristics} />
                        </CardContent>
                    </Card>
                </div>
            ) : !!loadingService.activeLoadings || (
                <Typography className={styles.noPostFound} variant="h5">
                    {getString("noPostFoundWithId")}: {id}
                </Typography>
            )}
        </div>
    </>;
}

export default observer(PostDetails);