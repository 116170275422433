import React from "react";
import classNames from "classnames";
import { FormControlLabel, Select, InputLabel, MenuItem, Checkbox } from "@material-ui/core";
import { useTranslation } from "infrastructure/hooks";
import { TranslationStringKey } from "utils/translation/types";
import styles from "./multi-select.module.scss";
import { runInAction } from "mobx";

interface Props<T> {
    selectedValues: T[];
    values: T[];
    onSelect: (keys: T[]) => void;
    onItemRender: (key: T) => JSX.Element | string;
    className?: string;
    required?: boolean;
    errorMessage?: string;
    label?: string;
    placeholder?: string;
    disabled?: boolean;
    useAction?: boolean;
}

const MultiSelect = <T extends number | string>({
    selectedValues,
    values,
    onSelect,
    onItemRender,
    className,
    required,
    errorMessage,
    label,
    placeholder,
    disabled,
    useAction
}: Props<T>) => {
    const { getString } = useTranslation();

    const onSelectWrapper = useAction ? 
        (keys: T[]) => runInAction(() => onSelect(keys)) : onSelect;

    return (
        <FormControlLabel
            className={classNames(styles.multiSelect, className, {
                [styles.withLabel]: label
            })}
            control={<>
                <InputLabel id="single-select-dropdown-id">{placeholder}</InputLabel>
                <Select
                    labelId="dropdown-value-select-id"
                    displayEmpty
                    multiple
                    value={selectedValues}
                    renderValue={selected => (selected as string[])
                        .map(value => getString(value as TranslationStringKey) || value).join(', ')
                    }
                    error={!!errorMessage || (required && !selectedValues.length)}
                    onChange={event => onSelectWrapper(event.target.value as T[])}
                    disabled={disabled}>
                    {values.map(value => (
                        <MenuItem key={value} value={value}>
                            <Checkbox
                                checked={selectedValues && selectedValues.indexOf(value) > -1}
                                color="primary" />
                            {onItemRender(value)}
                        </MenuItem>
                    ))}
                </Select>
            </>}
            label={label}
            labelPlacement="start" />
    );
}

export default MultiSelect;