import React, { useContext, useEffect } from "react";
import { RealEstatePost } from "../types";
import { observer } from "mobx-react";
import { RealEstateDetailsContext } from "./real-estate-details-store";
import PostDetails from "../../components/post-details";
import { useParams } from "react-router-dom";
import { CharacteristicItemProps } from "../../components/post-details/components/characteristic-item";
import { TranslationStringKey } from "utils/translation/types";
import { useTranslation } from "infrastructure/hooks";
import { GetStringFunction } from "utils/translation/types";
import RealEstateForm from "../real-estate-form";

const mapPostToCharacteristicItems = (getString: GetStringFunction, post: RealEstatePost | null) => {
    if (post === null) {
        return [];
    }

    const characteristics: CharacteristicItemProps[] = [{
        icon: "https://img.icons8.com/ios/50/000000/crane.png",
        name: "constructionYear",
        value: post.constructionYear
    }, {
        icon: "https://img.icons8.com/ios/50/000000/shpatel.png",
        name: "renovationYear",
        value: post.renovationYear
    }, {
        icon: "https://img.icons8.com/ios/50/000000/studio-floor-plan.png",
        name: "partitioning",
        value: getString(post.partitioning as TranslationStringKey)
    }, {
        icon: "https://img.icons8.com/ios/50/000000/parking-and-penthouse.png",
        name: "floor",
        value: Number(post.floor) || getString(post.floor as TranslationStringKey)
    }];
    
    post.floor !== "house" && characteristics.push({
        icon: "https://img.icons8.com/ios/50/000000/skyscrapers.png",
        name: "totalStories",
        value: post.levels
    });

    characteristics.push({
        icon: "https://img.icons8.com/ios/50/000000/room.png",
        name: "numberOfRooms",
        value: post.numberOfRooms
    }, {
        icon: "https://img.icons8.com/ios/50/000000/bed.png",
        name: "numberOfBedrooms",
        value: post.numberOfBedrooms
    }, {
        icon: "https://img.icons8.com/ios/50/000000/sink.png",
        name: "numberOfBathrooms",
        value: post.numberOfBathrooms
    }, {
        icon: "https://img.icons8.com/ios/50/000000/closed-window.png",
        name: "numberOfBathroomsWithWindow",
        value: post.numberOfBathroomsWithWindow
    }, {
        icon: "https://img.icons8.com/ios/50/000000/balcony.png",
        name: "numberOfBalconies",
        value: post.numberOfBalconies
    });

    post.numberOfBalconies && characteristics.push({
        icon: "https://img.icons8.com/ios/50/000000/treehouse.png",
        name: "balconiesType",
        value: post.balconiesType.map(value => getString(value as TranslationStringKey)).join(", ")
    });
    
    characteristics.push({
        icon: "https://img.icons8.com/ios/50/000000/output.png",
        name: "purpose",
        value: getString(post.purpose as TranslationStringKey)
    }, {
        icon: "https://img.icons8.com/ios/50/000000/surface.png",
        name: "usableArea",
        value: post.surface,
        valueElement: <>{post.surface} (m<sup>2</sup>)</>
    }, {
        icon: "https://img.icons8.com/ios/50/000000/parking.png",
        name: "parkingSpace",
        value: getString(post.parkingSpace ? "yes" : "no")
    }, {
        icon: "https://img.icons8.com/ios/50/000000/elevator.png",
        name: "elevator",
        value: getString(post.hasElevator ? "yes" : "no")
    });

    return characteristics;
}

const RealEstateDetails = () => {
    const { id } = useParams<any>();
    const { getString } = useTranslation();

    const realEstateDetailsStore = useContext(RealEstateDetailsContext);
    
    const { post, isRealEstateEditVisible, setRealEstateEditVisibility, setPost } =
        realEstateDetailsStore;

    useEffect(() => {
        realEstateDetailsStore.fetchPost(id!);

        return realEstateDetailsStore.reset();
    }, [realEstateDetailsStore, id]);

    return <>
        <PostDetails
            onOpenEdit={() => setRealEstateEditVisibility(true)}
            store={realEstateDetailsStore}
            characteristics={mapPostToCharacteristicItems(getString, post)}
            maxBid={1000000}
            minBid={1000}
            loanReminder />
        {post && (
            <RealEstateForm
                onClose={() => setRealEstateEditVisibility(false)}
                isOpen={isRealEstateEditVisible}
                realEstatePost={isRealEstateEditVisible ? post : null}
                onSubmit={setPost} />
        )}
    </>
}

export default observer(RealEstateDetails);