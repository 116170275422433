import React from "react";
import PartnerItem from "../partner-item";
import { Partner } from "../types";
import styles from "./partner-list.module.scss";

interface Props {
    partners: Partner[];
}

const PartnerList = ({ partners }: Props) => (
    <div className={styles.partnerList}>
        {partners.map((partner, index) => (
            <PartnerItem key={index} partner={partner} />
        ))}
    </div>
);

export default PartnerList;