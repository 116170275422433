import classNames from "classnames";
import React, { PropsWithChildren } from "react";
import styles from "./span-message.module.scss";

interface Props {
    isBold?: boolean;
    isLowerCase?: boolean;
}

const SpanMessage = ({ isBold, isLowerCase, children }: PropsWithChildren<Props>) => (
    <span className={classNames(styles.message, {
        [styles.boldFont]: isBold,
        [styles.lowerCase]: isLowerCase
    })}>
        {children}
    </span>
);

export default SpanMessage;