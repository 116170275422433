export const FIRESTORE_USERS = "users";
export const FIRESTORE_BIDS = "bids";
export const FIRESTORE_TEXTS = "texts";
export const FIRESTORE_POSTS = "posts";
export const FIRESTORE_APPLICATION = "application";
export const FIRESTORE_NOTIFICATIONS = "notifications";
export const FIRESTORE_USER_NOTIFICATIONS = "userNotifications";

export const FIRESTORE_APPLICATION_MAINTENANCE = "maintenance";

export const USER_TYPE_SUPER_ADMIN = "superAdmin";
export const USER_TYPE_ADMIN = "admin";
export const USER_TYPE_USER = "user";