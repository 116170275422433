import React from "react";
import styles from "./bottom-controls.module.scss";
import LanguageDropdown from "../language-dropdown";
import TermsAndConditionsButton from "./terms-and-conditions-button";

const BottomControls = () => (
    <div className={styles.bottomControls}>
        <LanguageDropdown />
        <TermsAndConditionsButton />
    </div>
);

export default BottomControls;