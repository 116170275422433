import { TranslationStringKey } from "./translation/types";

export const EMAIL_REGEX = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const PASSWORD_REGEX = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
export const PHONE_NUMBER_REGEX = /^\d{10}$/;
export const CNP_REGEX = /^\d{13}$/;
export const FIREBASE_ID_REGEX = /^[0-9a-zA-Z]{20}$/;

export const MIN_PRICE = 1;
export const MAX_PRICE = 1000000;

export const SITE_NAME_KEY: TranslationStringKey = "siteName";
export const BASE_PATH = "/";

export const CATEGORY_REAL_ESTATE = "realEstate";
export const PATH_REAL_ESTATE = BASE_PATH + CATEGORY_REAL_ESTATE;

export const CATEGORY_FOR_RENT = "forRent";
export const PATH_FOR_RENT = BASE_PATH + CATEGORY_FOR_RENT;

export const CATEGORY_VEHICLES = "vehicles";
export const PATH_VEHICLES = BASE_PATH + CATEGORY_VEHICLES;

export const MINIMUM_IMAGES = 8;
export const MAXIMUM_IMAGES = 15;

export const CONTACT_EMAIL = "miraauction@gmail.com";
export const CONTACT_PHONE = "0754 751 011";
export const CONTACT_ADDRESS = "Cluj-Napoca, Str. Gruia nr. 28";

export const POST_SECTIONS = 5;
export const EDIT_POST_WITH_BIDDING_SECTIONS = 4;

export const TAB_INDEX_REAL_ESTATE = 1;
export const TAB_INDEX_FOR_RENT = 2;
export const TAB_INDEX_VEHICLES = 3;

export const ENVIRONMENT = process.env.NODE_ENV;

export const QUERY_AUTHOR_ID = "authorId";
export const QUERY_SEARCH = "search";