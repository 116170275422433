import React from "react";
import { useTranslation } from "infrastructure/hooks";
import { ConfirmDialog } from "../..";

interface Props {
    open: boolean;
    onCancel: () => void;
    onConfirm: () => void;
}

const CloseFormDialog = ({ open, onCancel, onConfirm }: Props) => {
    const { getString } = useTranslation();

    return (
        <ConfirmDialog
            open={open}
            onCancel={onCancel}
            onConfirm={onConfirm}
            description={getString("closingPageAwareness")}
            confirmMessage={getString("closeWithoutSaving")}/>
    );
}

export default CloseFormDialog;