import React from "react";
import { RealEstateListStore } from "../real-estate-list-store";
import { observer } from "mobx-react";
import { RangePicker } from "components";
import { useTranslation } from "infrastructure/hooks";

interface Props {
    store: RealEstateListStore;
}

const FilterBalconies = ({ store }: Props) => {
    const { getString } = useTranslation();

    const { rangeBalconies, setBalconiesRange } = store;

    return (
        <RangePicker 
            minValue={rangeBalconies.min}
            maxValue={rangeBalconies.max}
            min={rangeBalconies.lowest}
            max={rangeBalconies.greatest}
            label={getString("numberOfBalconies")}
            useAction
            onChangeMin={min => rangeBalconies.min = min}
            onChangeMax={max => rangeBalconies.max = max}
            onChangeMinMax={setBalconiesRange} />
    );
}

export default observer(FilterBalconies);