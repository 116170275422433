import React from "react";
import { useTranslation } from "infrastructure/hooks";
import { ScrollTopTransition } from "components";
import styles from "./introduction.module.scss";
import sharedStyles from "../home.module.scss";
import classNames from "classnames";

const Introduction = () => {

    const { getString } = useTranslation();

    return (
        <div className={classNames(sharedStyles.section, styles.introduction)}>
            <h1 className={classNames(sharedStyles.header)}>{getString("introductionHeader")}</h1>
            <ScrollTopTransition selector={`.${sharedStyles.text}`} timeout={200} classNames="fade-in">
                <p className={sharedStyles.text}>
                    {getString("introductionText")}
                </p>
            </ScrollTopTransition>
        </div>
    )
}

export default Introduction;