import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    FormControlLabel,
    Switch,
    Typography
} from "@material-ui/core";
import { KeyboardDatePicker } from "@material-ui/pickers";
import classNames from "classnames";
import { runInAction } from "mobx";
import { observer } from "mobx-react";
import { Moment } from "moment";
import React, { useEffect, useState } from "react";
import { NumericInput } from "components";
import { BasePost } from "database/types";
import { MAX_PRICE } from "utils/constants";
import { getMomentAfterDays } from "utils/date-utils";
import { useTranslation } from "infrastructure/hooks";
import sharedStyles from "../post-form.module.scss";
import styles from "./price-section.module.scss";

const minimumBiddingEndDate = getMomentAfterDays(7);
const maximumBiddingEndDate = getMomentAfterDays(91);

interface Props {
    step: number;
    stepsNumber: number;
    post: BasePost;
    minimumPrice: number;
    priceStep: number;
    enableBidding?: boolean;
}

const PriceSection = ({
    step, 
    stepsNumber,
    post,
    minimumPrice,
    priceStep,
    enableBidding
}: Props) => {

    const { getString } = useTranslation();

    const priceError = !post.price ||
        (post.hasBidding && !post.biddingEndDate);
    
    const [biddingEndMoment, setBiddingEndMoment] = useState<Moment | null>(null);
    const [expanded, setExpanded] = useState(false);

    useEffect(() => {
        setBiddingEndMoment(null);
    }, [post]);

    const handleDateChange = (date: Moment | null) => runInAction(() => {
        setBiddingEndMoment(date);
        
        if (date &&
            minimumBiddingEndDate.isSameOrBefore(date) &&
            date.isSameOrBefore(maximumBiddingEndDate)) {
            post.biddingEndDate = date.toDate();
        } else {
            post.biddingEndDate = undefined;
        }
    });

    return (
        <Accordion
            className={sharedStyles.section}
            onChange={() => setExpanded(expanded => !expanded)}>
            <AccordionSummary>
                <div className={sharedStyles.sectionHeader}>
                    <div className={styles.priceSummary}>
                        <Typography className={sharedStyles.sectionLabel} variant="h6">
                            {getString("price")}
                        </Typography>
                        {enableBidding && (
                            <FormControlLabel
                                checked={post.hasBidding}
                                control={
                                    <Switch
                                        color="primary"
                                        onChange={event => 
                                            runInAction(() => 
                                                post.hasBidding = event.target.checked)
                                        } />
                                }
                                label={getString("enableBiddingForThisPost")}
                                labelPlacement="start"
                                onClick={event => expanded && event.stopPropagation()} />
                        )}
                    </div>
                    <div
                        className={classNames(sharedStyles.stepWrapper, {
                            [sharedStyles.valid]: !priceError
                        })}>
                        <Typography className={sharedStyles.sectionLabel} variant="h6">
                            {step} / {stepsNumber}
                        </Typography>
                    </div>
                </div>
            </AccordionSummary>
            <AccordionDetails>
                <div className={sharedStyles.sectionContent}>
                    <div className={styles.priceWrapper}>
                        <NumericInput
                            onChange={price =>post.price = price ?? 0}
                            required
                            useAction
                            value={post.price}
                            max={MAX_PRICE}
                            min={minimumPrice}
                            step={priceStep}
                            label={`${getString(post.hasBidding ? "startingPrice" : "price")}: €`} />
                        {post.hasBidding && (
                            <FormControlLabel
                                control={
                                    <KeyboardDatePicker
                                        format="DD.MM.yyyy"
                                        minDate={minimumBiddingEndDate}
                                        maxDate={maximumBiddingEndDate}
                                        value={biddingEndMoment}
                                        error={post.biddingEndDate === undefined}
                                        onChange={handleDateChange} />
                                }
                                label={`${getString("biddingEndDate")}: `}
                                labelPlacement="start" />
                        )}
                    </div>
                </div>
            </AccordionDetails>
        </Accordion>
    )
}

export default observer(PriceSection);