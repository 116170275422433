import React from "react";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    TextField,
    DialogActions,
    Button
} from "@material-ui/core";
import { useTranslation } from "infrastructure/hooks";
import { observer } from "mobx-react";
import { UserDetailsStore } from "../../../user-details-store";

interface Props {
    open: boolean;
    onClose: () => void;
    userDetailsStore: UserDetailsStore;
}

const BanReason = ({ open, onClose, userDetailsStore }: Props) => {

    const { getString } = useTranslation();

    const {
        activeUser,
        banUser
    } = userDetailsStore;

    const validReasons = (!activeUser?.banReason?.en && !activeUser?.banReason?.ro) ||
        (activeUser?.banReason?.en && activeUser?.banReason?.ro);

    const handleBanUser = async () => {
        if (validReasons) {
            onClose();
            await banUser();
        }
    }

    return (
        <Dialog open={open} onClose={onClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">
                {getString("ban")}
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {getString("provideBanReason")}
                </DialogContentText>
                <TextField
                    autoFocus
                    margin="dense"
                    label={getString("banReasonInRomanian")}
                    value={activeUser?.banReason?.ro}
                    error={!validReasons && !activeUser?.banReason?.ro}
                    onChange={ev => activeUser!.banReason!.ro = ev.target.value}
                    fullWidth
                    multiline
                />
                <TextField
                    margin="dense"
                    label={getString("banReasonInEnglish")}
                    value={activeUser?.banReason?.en}
                    onChange={ev => activeUser!.banReason!.en = ev.target.value}
                    error={!validReasons && !activeUser?.banReason?.en}
                    fullWidth
                    multiline
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary">
                    {getString("cancel")}
                </Button>
                <Button onClick={handleBanUser}>
                    {getString("ban")}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default observer(BanReason);