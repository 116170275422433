import React, { PropsWithChildren } from "react";
import { Dialog, AppBar, Toolbar, IconButton, Typography } from "@material-ui/core";
import { SlideUpAnimation } from "../..";
import { Close } from "@material-ui/icons";
import styles from "./fullscreen-dialog.module.scss";

interface Props {
    open: boolean;
    title: string;
    onClose: () => void;
}

const FullscreenDialog = ({ open, title, onClose, children }: PropsWithChildren<Props>) => (
    <Dialog
        fullScreen
        open={open}
        onClose={onClose}
        TransitionComponent={SlideUpAnimation} >
        <AppBar>
            <Toolbar>
                <IconButton edge="start" color="inherit" onClick={onClose}>
                    <Close />
                </IconButton>
                <Typography variant="h6">{title}</Typography>
            </Toolbar>
        </AppBar>
        <div className={styles.fullscreenDialogPageContainer}>
            <div className={styles.content}>
                {children}
            </div>
        </div>
    </Dialog>
);

export default FullscreenDialog;