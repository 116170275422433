import React from "react";
import { Image } from "components";
import { Maintenance } from "database/types";
import { CONTACT_EMAIL, CONTACT_PHONE } from "utils/constants";
import { getMomentFromTime, formatMoment } from "utils/date-utils";
import { useTranslation } from "infrastructure/hooks";
import styles from "./under-maintenance-page.module.scss";

interface Props {
    maintenance: Maintenance;
}

const formatDate = (date: Date) => formatMoment(getMomentFromTime(date));

const UnderMaintenancePage = ({ maintenance: { startDate, endDate } }: Props) => {
    const { getString } = useTranslation();

    return (
        <div className={styles.container}>
            <Image
                className={styles.rotatingImage}
                src="https://img.icons8.com/ios/128/ffffff/settings--v1.png" />
            <br /><br /><br />
            <h1 className={styles.header}>
                {getString("siteIsUnderMaintenance", getString("siteName"))}
            </h1>
            <br /><br />
            <h2>{getString("siteMaintenanceTimeframe", formatDate(startDate), formatDate(endDate))}</h2>
            <h2>{getString("maintenanceContact", CONTACT_PHONE, CONTACT_EMAIL)}</h2>
        </div>
    );
}

export default UnderMaintenancePage;