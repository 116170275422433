import React, { PropsWithChildren } from "react";
import {
	ClickAwayListener,
	Popper as MaterialPopper,
	Grow
} from "@material-ui/core";

interface Props {
	open: boolean;
	anchorEl: JSX.Element | null;
	onClose: () => void;
	className?: string;
}

const Popper = ({
	open,
	anchorEl,
	className,
	onClose,
	children,
}: PropsWithChildren<Props>) => (
	<MaterialPopper
		open={open}
		anchorEl={anchorEl as any}
		transition
		className={className}>
		{({ TransitionProps }) => (
			<Grow {...TransitionProps}>
				<ClickAwayListener onClickAway={onClose}>
					{children}
				</ClickAwayListener>
			</Grow>
		)}
	</MaterialPopper>
);

export default Popper;
