import { makeAutoObservable, runInAction } from "mobx";
import { createContext } from "react";
import { searchFields } from "utils/string-utils";
import { auth } from "database/firebase";
import { setPost, getAllPosts, getAllPostsWithBids, getAllPostsWithText } from "database/api/post";
import { BasePost } from "database/types";
import { translationService } from "infrastructure/services";
import { loadingService } from "infrastructure/services";

export class PostsStore {
    posts: BasePost[] = [];
    categoryOptions: string[] = [];
    categoryOptionIndex: number = 0;
    operationOptionIndex: number = 0;
    activeOptionIndex: number = 0;
    verifiedOptionIndex: number = 0;
    searchValue: string = "";
    operationOptions: string[] = [];
    activeOptions: string[] = [];
    verifiedOptions: string[] = [];
    isExtendBiddingsOpen: boolean = false;

    constructor() {
        makeAutoObservable(this);
    }

    public setPosts = (posts: BasePost[]) => this.posts = posts;

    public setCategoryOptionIndex = (value: number) => this.categoryOptionIndex = value;

    public setOperationOptionIndex = (value: number) => this.operationOptionIndex = value;

    public setActiveOptionIndex = (value: number) => this.activeOptionIndex = value;

    public setVerifiedOptionIndex = (value: number) => this.verifiedOptionIndex = value;

    public setSearchValue = (value: string) => this.searchValue = value;

    public verifyPost = async (post: BasePost) => {
        post.isVerified = true;
        post.approvedByUserId = auth.getCurrentUser()?.uid!;

        await setPost(post);
    }

    public updateIsPostActive = async (post: BasePost, active: boolean) => {
        post.isActive = active;
        await setPost(post);
    }

    public fetchPosts = async (authorId: string | null) => {
        loadingService.set(true);

        let posts = await getAllPosts();
        posts = await getAllPostsWithBids(posts);
        posts = await getAllPostsWithText(posts);

        runInAction(() => {
            this.posts = posts as BasePost[];
            this.setCategoryOptionIndex(0);
            this.setOptions();
            this.setOperationOptionIndex(0);
            this.setActiveOptionIndex(0);
            this.setVerifiedOptionIndex(0);
            authorId && this.setSearchValue(authorId);
        });
        loadingService.set(false);
    }

    public setOptions = () => {
        this.categoryOptions = [
            translationService.getString("all"), 
            translationService.getString("realEstate"), 
            translationService.getString("forRent")
        ];
        this.operationOptions = [
            translationService.getString("all"),
            translationService.getString("bids"),
            translationService.getString("sales")
        ];
        this.activeOptions = [
            translationService.getString("all"),
            translationService.getString("active"),
            translationService.getString("banned")
        ];
        this.verifiedOptions = [
            translationService.getString("all"),
            translationService.getString("verified"),
            translationService.getString("notVerified")
        ];
    }

    public getFilteredPosts = () => {

        const matchCategoryOption = (post: BasePost) => {
            if (this.categoryOptionIndex === 0)
                return true;
            if (this.categoryOptionIndex === 1 && post.category === "realEstate")
                return true;
            if (this.categoryOptionIndex === 2 && post.category === "forRent")
                return true;
            return false;
        }

        const matchOperationOption = (post: BasePost) => {
            if (!this.operationOptionIndex)
                return true;
            if (this.operationOptionIndex === 1 && post.hasBidding)
                return true;
            if (this.operationOptionIndex === 2 && !post.hasBidding)
                return true;
            return false;
        }

        const matchActiveOption = (post: BasePost) => {
            if (!this.activeOptionIndex)
                return true;
            if (this.activeOptionIndex === 1 && post.isActive)
                return true;
            if (this.activeOptionIndex === 2 && post.isDeleted)
                return true;
            return false;
        }

        const matchVerifiedOption = (post: BasePost) => {
            if (!this.verifiedOptionIndex)
                return true;
            if (this.verifiedOptionIndex === 1 && post.isVerified)
                return true;
            if (this.verifiedOptionIndex === 2 && !post.isVerified)
                return true;
            return false;
        }

        const matchSearch = (post: BasePost) => searchFields(
            this.searchValue,
            post.id,
            post.createdByUserId,
            post.titleText!.ro,
            post.titleText!.en,
            post.descriptionText?.ro,
            post.descriptionText?.en,
            post.price.toString()
        );

        const filteredPosts = this.posts.filter(post =>
            matchCategoryOption(post) &&
            matchActiveOption(post) &&
            matchVerifiedOption(post) &&
            matchOperationOption(post) &&
            matchSearch(post)
        );

        return filteredPosts;
    }

    public openExtendBiddings = () => this.isExtendBiddingsOpen = true;

    public closeExtendBiddings = () => this.isExtendBiddingsOpen = false;
}

export const postsStore = new PostsStore();
export const PostsContext = createContext(postsStore);