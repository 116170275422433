import React from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import LightTooltip from "../../../../../../../components/light-tooltip";
import styles from "../general-information.module.scss";

interface Props {
    userKey: string;
    value: string;
    tooltipTitle?: string;
}

const UserDeailsItem = ({ userKey, tooltipTitle, value }: Props) => (
    <div className={styles.userDetails}>
        <h4 className={styles.userDetailsKey}>{userKey}</h4>
        <CopyToClipboard text={tooltipTitle ? value : ""}>
            {tooltipTitle ? (
                <LightTooltip title={tooltipTitle}>
                    <h4 className={styles.userDetailsValue}>{value}</h4>
                </LightTooltip>
            ) : (
                <h4 className={styles.userDetailsValue}>{value}</h4>
            )}
        </CopyToClipboard>
    </div>
)

export default UserDeailsItem;
