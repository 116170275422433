import React, { useState } from "react";
import { BoldText, SearchInput } from "components";
import { useTranslation } from "infrastructure/hooks";
import { CharacteristicItemProps } from "../components/characteristic-item";
import CharacteristicGrid from "../components/characteristic-grid";
import { searchFieldsWithKeywords, splitIntoKeywords } from "utils/string-utils";
import styles from "./section-characteristics.module.scss";

interface Props {
    characteristics: CharacteristicItemProps[]; 
}

const SectionCharacteristics = ({ characteristics }: Props) => {
    const { getString } = useTranslation();

    const [search, setSearch] = useState("");

    const keywords = splitIntoKeywords(search);

    const searchedCharacteristics = keywords.length ?
        characteristics.filter(characteristic => searchFieldsWithKeywords(
            keywords, getString(characteristic.name), characteristic.value.toString()
        )) : characteristics;

    return <>
        <div className={styles.sectionCharacteristics}>
            <BoldText variant="h5">{getString("characteristics")}</BoldText>
            <SearchInput value={search} onSearch={setSearch} />
        </div>
        <CharacteristicGrid characteristics={searchedCharacteristics} />
    </>;
}

export default SectionCharacteristics;