import React from "react";
import { BoldText } from "components";
import { useTranslation } from "infrastructure/hooks";
import styles from "./section-youtube-video.module.scss";

interface Props {
    videoUrl?: string;
}

const extractVideoId = (videoUrl?: string) => {
    if (!videoUrl) {
        return;
    }
    if (videoUrl.startsWith("https://www.youtube.com/watch?v=")) {
        return videoUrl.substring(32, 43);
    }
    if (videoUrl.startsWith("https://youtu.be/")) {
        return videoUrl.substring(17, 28);
    }
    console.warn(`Could not extract the ID from the URL "${videoUrl}"`)
}

const SectionYoutubeVideo = ({ videoUrl }: Props) => {
    const { getString } = useTranslation();

    const url = extractVideoId(videoUrl);

    if (!url) {
        return null;
    }

    return (
        <div className={styles.container}>
            <BoldText variant="h5">{getString("videoPresentation")}</BoldText>
            <div className={styles.videoWrapper}>
                <iframe
                    title={getString("videoPresentation")}
                    width="896"
                    height="504"
                    src={`https://www.youtube.com/embed/${url}`}
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen />
            </div>
        </div>
    );
}

export default SectionYoutubeVideo;