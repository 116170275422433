import { runInAction } from "mobx";
import React from "react";
import { SingleSelect } from "..";
import { useTranslation } from "infrastructure/hooks";
import { TranslationStringKey } from "utils/translation/types";

interface Props {
    value: boolean | undefined;
    onSelect: (value: boolean | undefined) => void;
    label?: string;
    useAction?: boolean;
}

const BooleanSelect = ({ value, onSelect, label, useAction }: Props) => {
    const { getString } = useTranslation();

    const onSelectWrapper = useAction ? 
        (value: boolean | undefined) => runInAction(() => onSelect(value)) : onSelect;

    const handleSelect = (selected: TranslationStringKey) => {
        if (selected === "all") {
            onSelectWrapper(undefined);
            return;
        }
        onSelectWrapper(selected === "yes");
    }

    const getValue = () => {
        if (value) {
            return "yes";
        }
        if (value === undefined) {
            return "all";
        }
        return "no";
    }

    return (
        <SingleSelect
            onItemRender={value => getString(value as TranslationStringKey)}
            onSelect={handleSelect}
            value={getValue()}
            values={["all", "yes", "no"]}
            label={label} />
    );
}

export default BooleanSelect;