import { Unsubscribe, WhereFilterOp } from "firebase/firestore";
import { getFirestoreCollection } from "./utils";

interface QueryType {
    property: string;
    condition: WhereFilterOp;
    value: any;
}

const getFirestoreFetch = <T>(collection: string) => {
    const firestore = getFirestoreCollection(collection);

    const get = async (id: string) => (await firestore.doc(id).get()).data() as T;

    const getAll = async (query?: QueryType) => {
        const results: T[] = [];

        let request: any = firestore;
        if (query)
            request = request.where(query.property, query.condition, query.value);
        
        (await request.get()).forEach((doc: any) => results.push(doc.data() as T));

        return results;
    }

    const subscribe = (onChange: (entities: T[]) => void, queries?: QueryType[]): Unsubscribe => {
        let request: any = firestore;
        queries?.forEach(({ property, condition, value }) => 
            request = request.where(property, condition, value));

        return request.onSnapshot((snapshot: any) => {
            const results: T[] = [];
            snapshot.docChanges().forEach((change: any) => 
                change.type === "added" && results.push(change.doc.data() as T));
            onChange(results);
        });
    }

    const subscribeAll = (onChange: (entities: T[]) => void, query?: QueryType): Unsubscribe => {
        let request: any = firestore;
        if (query)
            request = request.where(query.property, query.condition, query.value);

        return request.onSnapshot((snapshot: any) => {
            const results: T[] = [];
            snapshot.forEach((doc: any) => results.push(doc.data() as T));
            onChange(results);
        });
    }

    const set = (id: string, entity: T) => firestore.doc(id).set(entity);

    const setProperty = (id: string, properties: any) => firestore.doc(id).update(properties)

    const newIdDocument = () => firestore.generate();

    const create = async (entity: T, idDocument = newIdDocument()) => {
        await idDocument.set(entity);
        
        return idDocument.id;
    }

    return {
        get,
        getAll,
        subscribe,
        subscribeAll,
        set,
        setProperty,
        newIdDocument,
        create
    }
};

export default getFirestoreFetch;