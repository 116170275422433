import React, { useContext, useEffect } from "react";
import { PostsGrid, PostsFilterBar } from "../../components/posts-grid";
import { CATEGORY_FOR_RENT } from "utils/constants";
import { observer } from "mobx-react";
import ForRentListFilters from "./for-rent-list-filters";
import { searchFields } from "utils/string-utils";
import { useTranslation } from "infrastructure/hooks";
import { sortBy } from "utils/array-utils";
import { isInRange } from "utils/number-utils";
import { ForRentListContext } from "./for-rent-list-store";
import { ForRentPost } from "../types";
import { auth } from "database/firebase";
import { 
    SORT_BY_ASCENDING_DATE,
    SORT_BY_DESCENDING_DATE,
    SORT_BY_DESCENDING_PRICE,
    MultiSelect,
    Range
} from "../../types";
import { loadingService } from "infrastructure/services";

const isValueInRange = (num: number, range: Range) =>
    isInRange(num, range.min, range.max);

const isValueInSelected = (value: string, select: MultiSelect) =>
    !select.selected.length || select.selected.includes(value);

const areValuesInSelected = (values: string[], select: MultiSelect) =>
    !select.selected.length || select.selected.some(value => values.includes(value));

const isValueSelected = (value: boolean, select?: boolean) =>
    select === undefined || value === select;

const ForRentList = () => {
    const { getText } = useTranslation();

    const forRentStore = useContext(ForRentListContext);

    const {
        allPosts,
        fetchPosts,

        search,
        sortingBy,
        filterMyPosts,
        priceRange,
        selectedCounties,
        selectedCities,
        selectedZones,
        rangeConstructionYear,
        rangeRenovationYear,
        selectedPartitions,
        selectedFloors,
        rangeStories,
        rangeRooms,
        rangeBedrooms,
        rangeBathrooms,
        rangeBathroomsWithWindow,
        rangeBalconies,
        selectedBalconyTypes,
        selectedPurposes,
        rangeArea,
        withParkingSpace,
        withElevator,
        withPetFriendly
    } = forRentStore;

    useEffect(() => {
        fetchPosts()
    }, [fetchPosts]);

    const onlyInTheCurrentLanguage = (post: ForRentPost) =>
        getText(post.titleText) && getText(post.descriptionText)

    const searchPost = (post: ForRentPost) => searchFields(
        search,
        post.id,
        post.titleText!.en,
        post.titleText!.ro,
        post.descriptionText!.en,
        post.descriptionText!.ro
    );

    const filterPost = (post: ForRentPost) =>
        filterMyPosts === (post.createdByUserId === auth.getCurrentUser()?.uid) &&
        isValueInRange(post.price, priceRange) && 
        isValueInSelected(post.county, selectedCounties) &&
        isValueInSelected(post.city, selectedCities) &&
        isValueInSelected(post.zone, selectedZones) &&
        isValueInRange(post.constructionYear, rangeConstructionYear) &&
        isValueInRange(post.renovationYear, rangeRenovationYear) &&
        isValueInSelected(post.partitioning, selectedPartitions) &&
        isValueInSelected(post.floor, selectedFloors) && (
            post.floor === "house" || isValueInRange(post.levels, rangeStories)
        ) && isValueInRange(post.numberOfRooms, rangeRooms) &&
        isValueInRange(post.numberOfBedrooms, rangeBedrooms) &&
        isValueInRange(post.numberOfBathrooms, rangeBathrooms) &&
        isValueInRange(post.numberOfBathroomsWithWindow, rangeBathroomsWithWindow) &&
        isValueInRange(post.numberOfBalconies, rangeBalconies) && (
            !post.numberOfBalconies ||
            areValuesInSelected(post.balconiesType, selectedBalconyTypes)
        ) && isValueInSelected(post.purpose, selectedPurposes) &&
        isValueInRange(post.surface, rangeArea) &&
        isValueSelected(post.parkingSpace, withParkingSpace) &&
        isValueSelected(post.hasElevator, withElevator) &&
        isValueSelected(post.petFriendly, withPetFriendly);

    const searchAndFilterPost = (post: ForRentPost) => 
        onlyInTheCurrentLanguage(post) && searchPost(post) && filterPost(post);

    const filteredProducts = sortBy(allPosts.filter(searchAndFilterPost), post =>
        sortingBy === SORT_BY_ASCENDING_DATE || sortingBy === SORT_BY_DESCENDING_DATE ?
            post.createDate : post.price,
        sortingBy === SORT_BY_DESCENDING_DATE || sortingBy === SORT_BY_DESCENDING_PRICE
    );

    return <>
        <PostsFilterBar store={forRentStore}>
            <ForRentListFilters store={forRentStore} />
        </PostsFilterBar>
        {!loadingService.activeLoadings && (
            <PostsGrid
                hide={!!loadingService.activeLoadings}
                posts={filteredProducts}
                postCategory={CATEGORY_FOR_RENT} />
        )}
    </>;
}

export default observer(ForRentList);