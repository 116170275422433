import React from "react";
import { useTranslation } from "infrastructure/hooks";
import { observer } from "mobx-react";
import { MultiSelect } from "components";
import { VehiclesListStore } from "../vehicles-list-store";
import { TranslationStringKey } from "utils/translation/types";

interface Props {
    store: VehiclesListStore;
}

const FilterModels = ({ store }: Props) => {
    const { getString } = useTranslation();

    const { selectedBrands, selectedModels } = store;

    return (
        <MultiSelect
            onItemRender={value => getString(value as TranslationStringKey) || value}
            onSelect={selected => selectedModels.selected = selected}
            selectedValues={selectedModels.selected}
            values={selectedModels.all}
            label={getString("model")}
            useAction
            disabled={!selectedBrands.selected.length} />
    );
}

export default observer(FilterModels);