import React, { useState, useContext, useEffect } from "react";
import {
    Typography, 
    Card, 
    CardContent, 
    Accordion,
    AccordionSummary,
    AccordionDetails,
    AccordionActions,
    Button,
    TextField,
    Switch} from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";
import { FullscreenDialog } from "components";
import { SettingContext } from "./settings-store";
import { observer } from "mobx-react";
import { runInAction } from "mobx";
import { useTranslation } from "infrastructure/hooks";
import { useRunOnce } from "utils/ui-utils";
import styles from "./settings-page.module.scss";
import { loadingService, toastService } from "infrastructure/services";
import LanguageDropdown from "pages/account/components/language-dropdown";
import classNames from "classnames";

interface Props {
    isDisplayed: boolean;
    onClose: () => void;
}

const SettingsPage = ({ isDisplayed, onClose }: Props) => {
    const { getString } = useTranslation();
    const settingsStore = useContext(SettingContext);

    const [expanded, setExpanded] = useState(0);

    const { 
        currentAccount, 
        newAccount,
        password,
        confirmPassword,
        oldPassword,

        emailError,
        passwordError,
        confirmPasswordError,
        oldPasswordError,
        firstNameError,
        lastNameError,
        companyNameError,
        phoneNumberError,
        uniqueIdentificationCodeError,

        validateFields,
        setEmail,
        setPassword,
        setConfirmPassword,
        setOldPassword,
        setFirstName,
        setLastName,
        setCompanyName,
        setPhoneNumber,
        setUniqueIdentificationCode,

        saveEmail,
        savePassword,
        saveAccountDetails,
        saveWithNotificationSound,

        resetFields,
        fetchAccountDetails,
    } = settingsStore;

    const handleExpand = (panelId: number) => (_: React.ChangeEvent<{}>, isExpanded: boolean) => {
        const expandCode = isExpanded ? panelId : 0;

        runInAction(() => {
            setExpanded(expandCode);
            if (!expandCode) {
                resetFields();
            }
        })
    };

    const handleCancel = () => setExpanded(0);

    const handleSave = async (saveFunction: () => Promise<void>) => {
        loadingService.set(true);
        try {
            await saveFunction();
        } catch {
            toastService.error("somethingWentWrong");
        }
        setExpanded(0);
        loadingService.set(false);
    }

    useRunOnce(() => validateFields());

    useEffect(() => {
        if (isDisplayed) {
            fetchAccountDetails();
        }
    }, [fetchAccountDetails, isDisplayed]);

    return <>
        <FullscreenDialog
            open={isDisplayed}
            onClose={onClose}
            title={getString("settings")}>
            <div className={styles.settingsType}>
                <Typography variant="h5" gutterBottom>{getString("language")}</Typography>
                <Card>
                    <CardContent className={styles.cardContentLanguage}>
                        <LanguageDropdown />
                    </CardContent>
                </Card>
            </div>
            <div className={styles.settingsType}>
                <Typography variant="h5" gutterBottom>{getString("notifications")}</Typography>
                <Card>
                    <CardContent className={classNames(styles.cardContentLanguage, styles.notificationsContainer)}>
                        <Typography className={styles.expandHeading}>{getString("notificationsSound")}</Typography>
                        <Switch 
                            color="primary" 
                            checked={newAccount.withNotificationSound ?? true} 
                            onChange={({ target }) => saveWithNotificationSound(target.checked)} />
                    </CardContent>
                </Card>
            </div>
            <div className={styles.settingsType}>
                <Typography variant="h5" gutterBottom>{getString("accountDetails")}</Typography>
                <Accordion expanded={expanded === 1} onChange={handleExpand(1)}>
                    <AccordionSummary expandIcon={<ExpandMore />}>
                        <Typography className={styles.expandHeading}>Email</Typography>
                        <Typography className={styles.expandSummary} color="textSecondary">
                            {currentAccount.email}
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails className={styles.panelContentWith2}>
                        <TextField
                            autoFocus
                            required
                            error={!!oldPasswordError}
                            helperText={oldPasswordError}
                            className={styles.inputField}
                            label={getString("currentPassword")}
                            type="password"
                            autoComplete="current-password"
                            variant="outlined"
                            value={oldPassword}
                            onChange={event => setOldPassword(event.target.value)}
                        />
                        <TextField
                            required
                            error={!!emailError}
                            helperText={emailError}
                            className={styles.inputField}
                            label="Email"
                            type="email"
                            autoComplete="email"
                            variant="outlined"
                            value={newAccount.email}
                            onChange={event => setEmail(event.target.value)}
                        />
                    </AccordionDetails>
                    <AccordionActions>
                        <Button size="small" onClick={handleCancel}>{getString("cancel")}</Button>
                        <Button 
                            size="small" 
                            color="primary" 
                            disabled={!!emailError || !oldPassword} 
                            onClick={() => handleSave(saveEmail)}
                        >
                            {getString("save")}
                        </Button>
                    </AccordionActions>
                </Accordion>
                <Accordion expanded={expanded === 2} onChange={handleExpand(2)}>
                    <AccordionSummary expandIcon={<ExpandMore />}>
                        <Typography className={styles.expandHeading}>
                            {getString("password")}
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails className={styles.panelContentWith3}>
                        <TextField
                            autoFocus
                            required
                            error={!!oldPasswordError}
                            helperText={oldPasswordError}
                            className={styles.inputField}
                            label={getString("currentPassword")}
                            type="password"
                            autoComplete="current-password"
                            variant="outlined"
                            value={oldPassword}
                            onChange={event => setOldPassword(event.target.value)}
                        />
                        <TextField
                            required
                            error={!!passwordError}
                            helperText={passwordError}
                            className={styles.inputField}
                            label={getString("password")}
                            type="password"
                            autoComplete="new-password"
                            variant="outlined"
                            value={password}
                            onChange={event => setPassword(event.target.value)}
                        />
                        <TextField
                            required
                            error={!!confirmPasswordError}
                            helperText={confirmPasswordError}
                            className={styles.inputField}
                            label={getString("confirmationPassword")}
                            type="password"
                            autoComplete="new-password"
                            variant="outlined"
                            value={confirmPassword}
                            onChange={event => setConfirmPassword(event.target.value)}
                        />
                    </AccordionDetails>
                    <AccordionActions>
                        <Button size="small" onClick={handleCancel}>{getString("cancel")}</Button>
                        <Button 
                            size="small" 
                            color="primary" 
                            disabled={!oldPassword || !password || !confirmPassword || !!passwordError || !!confirmPasswordError} 
                            onClick={() => handleSave(savePassword)}
                        >
                            {getString("save")}
                        </Button>
                    </AccordionActions>
                </Accordion>
                {currentAccount.isIndividual ? <>
                    <Accordion expanded={expanded === 3} onChange={handleExpand(3)}>
                        <AccordionSummary expandIcon={<ExpandMore />}>
                            <Typography className={styles.expandHeading}>{getString("firstName")}</Typography>
                            <Typography className={styles.expandSummary} color="textSecondary">
                                {currentAccount.firstName}
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <TextField
                                required
                                error={!!firstNameError}
                                helperText={firstNameError}
                                className={styles.inputField}
                                label={getString("firstName")}
                                type="text"
                                autoComplete="given-name"
                                variant="outlined"
                                value={newAccount.firstName}
                                onChange={event => setFirstName(event.target.value)}
                            />
                        </AccordionDetails>
                        <AccordionActions>
                            <Button size="small" onClick={handleCancel}>{getString("cancel")}</Button>
                            <Button 
                                size="small" 
                                color="primary" 
                                disabled={!newAccount.firstName} 
                                onClick={() => handleSave(() => saveAccountDetails(newAccount.firstName))}
                            >
                                {getString("save")}
                            </Button>
                        </AccordionActions>
                    </Accordion>
                    <Accordion expanded={expanded === 4} onChange={handleExpand(4)}>
                        <AccordionSummary expandIcon={<ExpandMore />}>
                            <Typography className={styles.expandHeading}>{getString("lastName")}</Typography>
                            <Typography className={styles.expandSummary} color="textSecondary">
                                {currentAccount.lastName}
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <TextField
                                required
                                error={!!lastNameError}
                                helperText={lastNameError}
                                className={styles.inputField}
                                label={getString("lastName")}
                                type="text"
                                autoComplete="family-name"
                                variant="outlined"
                                value={newAccount.lastName}
                                onChange={event => setLastName(event.target.value)}
                            />
                        </AccordionDetails>
                        <AccordionActions>
                            <Button size="small" onClick={handleCancel}>{getString("cancel")}</Button>
                            <Button 
                                size="small" 
                                color="primary" 
                                disabled={!newAccount.lastName} 
                                onClick={() => handleSave(() => saveAccountDetails(newAccount.lastName))}
                            >
                                {getString("save")}
                            </Button>
                        </AccordionActions>
                    </Accordion>
                </> : (
                    <Accordion expanded={expanded === 5} onChange={handleExpand(5)}>
                        <AccordionSummary expandIcon={<ExpandMore />}>
                            <Typography className={styles.expandHeading}>{getString("companyName")}</Typography>
                            <Typography className={styles.expandSummary} color="textSecondary">{currentAccount.companyName}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <TextField
                                required
                                error={!!companyNameError}
                                helperText={companyNameError}
                                className={styles.inputField}
                                label={getString("companyName")}
                                type="text"
                                autoComplete="organization"
                                variant="outlined"
                                value={newAccount.companyName}
                                onChange={event => setCompanyName(event.target.value)}
                            />
                        </AccordionDetails>
                        <AccordionActions>
                            <Button size="small" onClick={handleCancel}>{getString("cancel")}</Button>
                            <Button 
                                size="small" 
                                color="primary" 
                                disabled={!newAccount.companyName} 
                                onClick={() => handleSave(() => saveAccountDetails(newAccount.companyName))}
                            >
                                {getString("save")}
                            </Button>
                        </AccordionActions>
                    </Accordion>
                )}
                <Accordion expanded={expanded === 6} onChange={handleExpand(6)}>
                    <AccordionSummary expandIcon={<ExpandMore />}>
                        <Typography className={styles.expandHeading}>{getString("phoneNumber")}</Typography>
                        <Typography className={styles.expandSummary} color="textSecondary">{currentAccount.phoneNumber}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <TextField
                            required
                            error={!!phoneNumberError}
                            helperText={phoneNumberError}
                            className={styles.inputField}
                            label={getString("phoneNumber")}
                            type="tel"
                            autoComplete="tel-local"
                            variant="outlined"
                            value={newAccount.phoneNumber}
                            onChange={event => setPhoneNumber(event.target.value)}
                        />
                    </AccordionDetails>
                    <AccordionActions>
                        <Button size="small" onClick={handleCancel}>{getString("cancel")}</Button>
                        <Button 
                            size="small" 
                            color="primary" 
                            disabled={!newAccount.phoneNumber} 
                            onClick={() => handleSave(() => saveAccountDetails(newAccount.phoneNumber))}
                        >
                            {getString("save")}
                        </Button>
                    </AccordionActions>
                </Accordion>
                {!currentAccount.isIndividual && (
                    <Accordion expanded={expanded === 7} onChange={handleExpand(7)}>
                        <AccordionSummary expandIcon={<ExpandMore />}>
                            <Typography className={styles.expandHeading}>{getString("uniqueIdentificationCode")}</Typography>
                            <Typography className={styles.expandSummary} color="textSecondary">{currentAccount.uniqueIdentificationCode}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <TextField
                                required
                                error={!!uniqueIdentificationCodeError}
                                helperText={uniqueIdentificationCodeError}
                                className={styles.inputField}
                                label={getString("uniqueIdentificationCode")}
                                type="number"
                                variant="outlined"
                                value={newAccount.uniqueIdentificationCode}
                                onChange={event => setUniqueIdentificationCode(event.target.value)}
                            />
                        </AccordionDetails>
                        <AccordionActions>
                            <Button size="small" onClick={handleCancel}>{getString("cancel")}</Button>
                            <Button 
                                size="small" 
                                color="primary" 
                                disabled={!newAccount.uniqueIdentificationCode} 
                                onClick={() => handleSave(() => saveAccountDetails(newAccount.uniqueIdentificationCode))}
                            >
                                {getString("save")}
                            </Button>
                        </AccordionActions>
                    </Accordion>
                )}
            </div>
        </FullscreenDialog>
    </>;
}

export default observer(SettingsPage);