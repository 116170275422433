import React from "react";
import classNames from "classnames";
import styles from "./grid.module.scss";

interface Props {
    data: any[];
    errorElement: JSX.Element;
    onRenderItem: (item: any) => JSX.Element;
    className?: string;
}

const Grid = ({ data, errorElement, onRenderItem, className }: Props) => (
    <div className={classNames(styles.grid, className)}>
        {data.length ? data.map(item => onRenderItem(item)) : errorElement}
    </div>
);

export default Grid;