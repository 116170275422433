import moment, { Moment } from 'moment';
import { GetStringFunction } from './translation/types';
import "moment/locale/ro";

export type UnitOfTime = "day" | "hour" | "minute" | "month" | "second" | "year" | "s" | "m" | "h" | "d" | "M" | "y" | "years" | "months" | "week" | "weeks" | "w" | "days" | "hours" | "minutes" | "seconds" | "millisecond" | "milliseconds" | "ms";

export const getRelativeTimeFrom = (date: Date) => moment(date).fromNow();

export const getMomentAfterDays = (days: number, date = moment()) => date.add(days, "d");

export const getDateAfterDays = (days: number, date = new Date()) =>
    getMomentAfterDays(days, moment(date)).toDate();

export const getMomentAfterYears = (years: number, date = moment()) => date.add(years, "y");

export const getDateAfterYears = (years: number, date = new Date()) =>
    getMomentAfterYears(years, moment(date)).toDate();

export const momentUtil = (until: Moment, unitOfTime: UnitOfTime, from = moment()) =>
    until.diff(from, unitOfTime);

export const getTimeUntilMoment = (until: Moment, getString: GetStringFunction) => {
    const seconds = momentUtil(until, "s");
    if (seconds < 0) {
        return getString("expired")
    }

    const days = momentUtil(until, "d");
    if (days) {
        return `${days + 1} ${getString("daysLeft").toLocaleLowerCase()}`;
    }

    const hours = momentUtil(until, "h");
    if (hours) {
        return `${hours + 1} ${getString("hoursLeft").toLocaleLowerCase()}`;
    }

    const minutes = momentUtil(until, "m");
    if (minutes) {
        return `${minutes + 1} ${getString("minutesLeft").toLocaleLowerCase()}`;
    }

    return getString("lessThanOneMinute");
}

export const getTimeUntilDate = (until: Date, getString: GetStringFunction) =>
    getTimeUntilMoment(moment(until), getString);

export const getDateFromTime = (time: any) => new Date(time?.seconds * 1000);

export const getMomentFromTime = (time: any) => moment(time?.seconds * 1000);

export const formatMoment = (date: Moment, format: string = "DD.MM.YYYY, HH:mm") =>
    date.format(format);

export const formatDate = (date: Date) => formatMoment(moment(date));

export const hasPassedMoment = (date: Moment) => date.isBefore(moment());

export const hasPassedDate = (date: Date) => moment(date).isBefore(moment());

export const getCurrentMoment = () => moment();

export const getCurrentTimeFormatted = () => formatMoment(getCurrentMoment(), "YYYY_MM_DD_HH_MM");

export const getDateFromString = (dateAsString: string) => moment(dateAsString).toDate();