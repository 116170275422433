import { BasePost, Bid, User } from "database/types";

export interface PostDetailsStoreType {
    post: BasePost | null;
    user: User | null;
    searchCharacteristics: string;
    bids: Bid[];
    areBidsLoading: boolean;
    setActive: (isActive: boolean) => void;
    setDeleted: (isDeleted: boolean) => void;
    setVerified: (isVerified: boolean) => void;
    placeBid: (bid: number) => void;
}

export const EMPTY_BASE_POST: BasePost = {
    approvedByUserId: "",
    bidsIds: [],
    category: "",
    createDate: new Date(),
    createdByUserId: "",
    descriptionTextId: "",
    descriptionText: {
        id: "",
        en: "",
        ro: ""
    },
    hasBidding: false,
    id: "",
    images: [],
    isActive: true,
    isDeleted: false,
    isVerified: false,
    lastUpdateDate: new Date(),
    latitude: 0,
    location: "",
    longitude: 0,
    mainImage: "",
    price: 0,
    titleTextId: "",
    titleText: {
        id: "",
        en: "",
        ro: ""
    }
};

export interface Range {
    lowest: number;
    min: number;
    max: number;
    greatest: number;
}

export interface MultiSelect {
    all: string[];
    selected: string[];
}

export interface SingleSelect {
    all: string[];
    selected: string | undefined;
}

export const EMPTY_MULTI_SELECT = {
    all: [],
    selected: []
}

export const EMPTY_SINGLE_SELECT = {
    all: [],
    selected: undefined
}

export const getDefaultRange = (min: number, max: number) =>
    ({ lowest: min, min, max, greatest: max });

export const SORT_BY_ASCENDING_DATE = "ascendingDate";
export const SORT_BY_DESCENDING_DATE = "descendingDate";
export const SORT_BY_ASCENDING_PRICE = "ascendingPrice";
export const SORT_BY_DESCENDING_PRICE = "descendingPrice";