export const sortBy = <T>(array: T[], property?: (obj: T) => any, descending?: boolean) => array.slice()
    .sort((objA, objB) => {
        const a = property ? property(objA) : objA;
        const b = property ? property(objB) : objB;

        if (a < b) {
            return descending ? 1 : -1;
        }
        if (a > b) {
            return descending ? -1 : 1;
        }
        return 0;
    });

export const unique = <T>(array: T[], property?: (obj: T) => any) => {
    if (!property) {
        return Array.from(new Set(array));
    }
    return array.filter((obj, index, self) =>
        self.findIndex((subObj) => property(subObj) === property(obj)) === index
    );
}

export const isNotEmpty = <T>(array?: T[]) => array && array.length ? array : undefined;

export const isEmpty = <T>(array?: T[]) => !isNotEmpty(array);

export const fill = (count: number, from: number = 0) =>
    Array.from(Array(count), (_, i) => i + from);

export const intersect = <V, T>(propertyValues: V[], objectArray: T[], selector?: (obj: T) => V) =>
    propertyValues.filter(value =>  selector ? objectArray.some(obj => selector(obj) === value) :
        objectArray.includes(value as any));