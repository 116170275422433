import React from "react";
import { InputAdornment, TextField } from "@material-ui/core";
import { SearchOutlined } from "@material-ui/icons";
import { useTranslation } from "infrastructure/hooks";
import { TranslationStringKey } from "utils/translation/types";
import styles from "./search-input.module.scss";
import classNames from "classnames";

interface Props {
    value: string;
    onSearch: (value: string) => void;
    placeholder?: TranslationStringKey;
    className?: string;
}

const SearchInput = ({ value, onSearch, placeholder, className }: Props) => {
    const { getString } = useTranslation();

    return (
        <TextField
            className={classNames(styles.searchInput, className)}
            placeholder={getString(placeholder || "search")}
            value={value}
            onChange={event => onSearch(event.target.value)}
            InputProps={{
                startAdornment: (
                    <InputAdornment position="start">
                        <SearchOutlined />
                    </InputAdornment>
                )
            }} />
    );
}

export default SearchInput;