import React from "react";
import { Route, Routes } from "react-router-dom";
import VehiclesList from "./vehicles-list";
import VehiclesDetails from "./vehicles-details";

const VehiclesCategory = () => (
    <Routes>
        <Route path="/:id" element={<VehiclesDetails />} />
        <Route path="/" element={<VehiclesList />} />
    </Routes>
);

export default VehiclesCategory;