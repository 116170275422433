import { makeAutoObservable } from "mobx";
import { createContext } from "react";

export class ConfirmDialogStore {
    public isOpen: boolean = false;
    public description: string = "";
    public confirmMessage: string = "";
    private onConfirm: () => void = () => {};

    constructor() {
        makeAutoObservable(this);
    }

    public set = (description: string, confirmMessage: string, onConfirm: () => void) => {
        this.description = description;
        this.confirmMessage = confirmMessage;
        this.onConfirm = onConfirm;
        this.isOpen = true;
    }

    public handleConfirm = () => {
        this.onConfirm();
        this.reset();
    };

    public reset = () => {
        this.isOpen = false;
        this.description = "";
        this.confirmMessage = "";
        this.onConfirm = () => {};
    } 
}

export const confirmDialogStore = new ConfirmDialogStore();
export const ConfirmDialogContext = createContext(confirmDialogStore);

export const needsConfirmation = (description: string, confirmMessage: string, onConfirm: () => void) => 
    confirmDialogStore.set(description, confirmMessage, onConfirm);