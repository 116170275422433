import React from "react";
import { Typography, Slider } from "@material-ui/core";
import { NumericInput } from "..";
import styles from "./range-picker.module.scss";
import { runInAction } from "mobx";

interface Props {
    minValue: number;
    maxValue: number;
    min: number;
    max: number;
    onChangeMin: (value: number) => void;
    onChangeMax: (value: number) => void;
    label?: JSX.Element | string;
    step?: number;
    onChangeMinMax?: (minValue: number, maxValue: number) => void;
    useAction?: boolean;
}

const RangePicker = ({
    minValue,
    maxValue,
    min,
    max,
    onChangeMin,
    onChangeMax,
    label,
    step,
    onChangeMinMax,
    useAction
}: Props) => {
    const handleSliderChange = (newValues: number | number[]) => {
        const values = newValues as number[];

        if (onChangeMinMax) {
            onChangeMinMax(values[0], values[1]);
            return;
        }
        
        onChangeMin(values[0]);
        onChangeMax(values[1]);
    }

    const handleSliderChangeWrapper = useAction ? 
        (newValues: number | number[]) => runInAction(() => handleSliderChange(newValues)) :
        handleSliderChange;

    return (
        <div className={styles.rangeContainer}>
            {label && <Typography>{label}</Typography>}
            <div className={styles.inputs}>
                <NumericInput
                    value={minValue}
                    onChange={value => onChangeMin(value ?? 0)}
                    className={styles.rangeInput}
                    placeholder="Min"
                    step={step}
                    min={min}
                    max={max}
                    useAction={useAction} />
                <Slider
                    className={styles.rangeSlider}
                    min={min}
                    max={max}
                    value={[
                        typeof minValue === "number" ? minValue : 0,
                        typeof maxValue === "number" ? maxValue : 0
                    ]}
                    onChange={(_, values) => handleSliderChangeWrapper(values)} />
                <NumericInput
                    value={maxValue}
                    onChange={value => onChangeMax(value ?? 0)}
                    className={styles.rangeInput}
                    placeholder="Max"
                    step={step}
                    min={min}
                    max={max}
                    useAction={useAction} />
            </div>
        </div>
    );
}

export default RangePicker;