import { StateNotificationType } from "database/types";
import { makeAutoObservable, runInAction } from "mobx";
import { createContext } from "react";
import { notificationsStore } from "../notifications-store";
import { MAXIMUM_NOTIFICATION_POPUPS, POPUP_DURATION } from "./constants";

export class NotificationPopupStore {
    public notificationStack: StateNotificationType[] = [];
    
    constructor() {
        makeAutoObservable(this);
    }

    public add = (notification: StateNotificationType) => {
        this.notificationStack.push(notification);
        this.clearHeadIfFull();
        setTimeout(() => this.removeIfExists(notification.id), POPUP_DURATION);
    }

    public onClick = (notificationId?: string) => {
        notificationsStore.setIsPaneOpen(true);
        this.remove(notificationId);
    }

    private clearHeadIfFull = () => {
        const count = this.notificationStack.length;
        if (count > MAXIMUM_NOTIFICATION_POPUPS) {
            this.notificationStack.splice(0, count - MAXIMUM_NOTIFICATION_POPUPS);
        }
    }

    private removeIfExists = (notificationId?: string) => {
        if (this.notificationStack.find(({ id }) => id === notificationId)) {
            runInAction(() => this.notificationStack.splice(0, 1));
        }
    }

    private remove = (notificationId?: string) => {
        const notificationIndex = this.notificationStack.findIndex(({ id }) => id === notificationId);
        if (notificationIndex > -1) {
            this.notificationStack.splice(notificationIndex, 1);
        }
    }
}

export const notificationPopupStore = new NotificationPopupStore();
export const NotificationPopupContext = createContext(notificationPopupStore);