import React from "react";
import { RealEstateListStore } from "../real-estate-list-store";
import { observer } from "mobx-react";
import { RangePicker } from "components";
import { useTranslation } from "infrastructure/hooks";

interface Props {
    store: RealEstateListStore;
}

const FilterBedrooms = ({ store }: Props) => {
    const { getString } = useTranslation();

    const { rangeBedrooms, setBedroomsRange } = store;

    return (
        <RangePicker 
            minValue={rangeBedrooms.min}
            maxValue={rangeBedrooms.max}
            min={rangeBedrooms.lowest}
            max={rangeBedrooms.greatest}
            label={getString("numberOfBedrooms")}
            useAction
            onChangeMin={min => rangeBedrooms.min = min}
            onChangeMax={max => rangeBedrooms.max = max}
            onChangeMinMax={setBedroomsRange} />
    );
}

export default observer(FilterBedrooms);