import React from "react";
import { useTranslation } from "infrastructure/hooks";
import { Grid, NotFoundMessage } from "components";
import { PostGridItem } from ".";
import classNames from "classnames";
import styles from "./posts-grid.module.scss";
import { BasePost } from "database/types";

interface Props {
    posts: BasePost[];
    hide?: boolean;
    className?: string;
    postCategory?: string;
    providePostCategory?: (postId: string) => string;
}

const PostsGrid = ({ posts, hide, className, postCategory, providePostCategory }: Props) => {
    const { getString } = useTranslation();

    return (
        <Grid
            className={classNames(className, {
                [styles.hide]: hide
            })}
            data={posts}
            onRenderItem={item => (
                <PostGridItem
                    key={item.id}
                    data={item}
                    postCategory={postCategory ?? providePostCategory?.(item.id) ?? ""} />
            )}
            errorElement={(
                <NotFoundMessage message={getString("noPostMatchesSelectedFilters")} />
            )} />
    )
}

export default PostsGrid;
