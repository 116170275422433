import { FIRESTORE_NOTIFICATIONS, FIRESTORE_USER_NOTIFICATIONS } from "database/constants";
import getFirestoreFetch from "database/firestore-fetch";
import {
    AuctionNotificationType,
    BaseNotificationType,
    StateNotificationType,
    UserNotificationType
} from "database/types";
import { sortBy } from "utils/array-utils";
import { getSuperAdminIds } from "./caching/user-caching";

const firestoreNotifications = getFirestoreFetch<BaseNotificationType>(FIRESTORE_NOTIFICATIONS);
const firestoreUserNotifications = getFirestoreFetch<UserNotificationType>(FIRESTORE_USER_NOTIFICATIONS);

export const pushAuctionNotification = 
    async (auctionNotification: AuctionNotificationType, postOwner: string, bidders: string[]) => {
        const id = await createNotification(auctionNotification);
        await createUserNotifications(id, postOwner, bidders);
    }

export const receiveNotifications = (
    currentUserId: string, onNotification: 
    (notifications: StateNotificationType[]) => void) =>
    firestoreUserNotifications.subscribe(async userNotifications => {
        const notifications = await getNotificationsFromUserNotifications(userNotifications);
        onNotification(sortBy(
            combineNotificationsData(notifications, userNotifications), 
            notification => notification.timestamp, true));
    }, [{
        property: "targetUserId",
        condition: "==",
        value: currentUserId
    }]);

export const makeUserNotificationSeen = (id: string) =>
    firestoreUserNotifications.setProperty(id, { isSeen: true });

const createNotification = async (auctionNotification: AuctionNotificationType) => {
    const document = firestoreNotifications.newIdDocument();
    auctionNotification.id = document.id;
    await document.set(auctionNotification);
    
    return auctionNotification.id;
}

const createUserNotifications = async (notificationId: string, postOwner: string, bidders: string[]) => {
    const superAdminIds = await getSuperAdminIds();

    const userNotifications: UserNotificationType[] = superAdminIds.map(id => ({
        id: "",
        notificationId,
        targetUserId: id,
        isAdmin: true,
        isSeen: false
    }));

    if (!superAdminIds.includes(postOwner)) {
        userNotifications.push({
            id: "",
            notificationId,
            targetUserId: postOwner,
            isAdmin: false,
            isSeen: false
        });
    }

    bidders.exclude(superAdminIds).forEach(id => userNotifications.push({
        id: "",
        notificationId,
        targetUserId: id,
        isAdmin: false,
        isSeen: false
    }));

    await Promise.all(userNotifications.map(userNotification => {
        const document = firestoreUserNotifications.newIdDocument();
        userNotification.id = document.id;
        return document.set(userNotification);
    }));
}

const combineNotificationsData = (
    notifications: BaseNotificationType[], 
    userNotifications: UserNotificationType[]) => 
    notifications.mapNotNull(notification => {
        const userNotification = userNotifications.find(userNotification => userNotification.notificationId === notification.id);
        if (!userNotification) {
            return null;
        }

        return combineNotificationData(notification, userNotification);
    });

const combineNotificationData = (
    notification: BaseNotificationType, 
    userNotification: UserNotificationType): StateNotificationType => 
    ({
        ...notification,
        timestamp: notification.timestamp,
        userNotificationId: userNotification.id,
        isSeen: userNotification.isSeen,
        isAdmin: userNotification.isAdmin
    });

const getNotificationsFromUserNotifications = (userNotifications: UserNotificationType[]) => 
    Promise.all(userNotifications.map(({ notificationId }) => firestoreNotifications.get(notificationId)));