import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import * as serviceWorker from "./serviceWorker";

const root = document.getElementById("root");

if (root)
    ReactDOM.createRoot(root)
        .render(<App />);

serviceWorker.unregister();
