import React from "react";
import { useTranslation } from "infrastructure/hooks";
import { observer } from "mobx-react";
import { MultiSelect } from "components";
import { TranslationStringKey } from "utils/translation/types";
import { VehiclesListStore } from "../vehicles-list-store";
import { runInAction } from "mobx";
import { brandOptionsType, modelOptions } from "../../types";

interface Props {
    store: VehiclesListStore;
}

const FilterBrands = ({ store }: Props) => {
    const { getString } = useTranslation();

    const { selectedBrands, selectedModels } = store;

    const handleSelect = (selected: string[]) => runInAction(() => {
        selectedBrands.selected = selected;
        selectedModels.all = selected.flatMap(brand => [...modelOptions[brand as brandOptionsType], "other"]);
    });

    return (
        <MultiSelect
            onItemRender={value => getString(value as TranslationStringKey)}
            onSelect={handleSelect}
            selectedValues={selectedBrands.selected}
            values={selectedBrands.all}
            useAction
            label={getString("brand")} />
    );
}

export default observer(FilterBrands);