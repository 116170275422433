import { observer } from "mobx-react";
import React from "react";
import { RangePicker } from "components";
import { useTranslation } from "infrastructure/hooks";
import { VehiclesListStore } from "../vehicles-list-store";

interface Props {
    store: VehiclesListStore;
}

const FilterFabricationYear = ({ store }: Props) => {
    const { getString } = useTranslation();

    const { rangeFabricationYear, setFabricationYearRange } = store;

    return (
        <RangePicker
            minValue={rangeFabricationYear.min}
            maxValue={rangeFabricationYear.max}
            min={rangeFabricationYear.lowest}
            max={rangeFabricationYear.greatest}
            label={getString("fabricationYear")}
            onChangeMin={min => rangeFabricationYear.min = min}
            onChangeMax={max => rangeFabricationYear.max = max}
            useAction
            onChangeMinMax={setFabricationYearRange} />
    )
}

export default observer(FilterFabricationYear);