import { Accordion, AccordionDetails, AccordionSummary, Typography, Grid } from "@material-ui/core";
import classNames from "classnames";
import { useUserDetails } from "infrastructure/services";
import { runInAction } from "mobx";
import { observer } from "mobx-react";
import React from "react";
import { BasePost } from "database/types";
import { useTranslation } from "infrastructure/hooks";
import sharedStyles from "../post-form.module.scss";
import styles from "./general-information-section.module.scss";

interface Props {
    step: number;
    stepsNumber: number;
    post: BasePost;
}

const GeneralInformationSection = ({ step, stepsNumber, post }: Props) => {
    const { isUserAdmin } = useUserDetails();
    const { getString } = useTranslation();
    
    const isTitleEmpty = !post.titleText?.ro && !post.titleText?.en;
    const isDescriptionEmpty = !post.descriptionText?.ro && !post.descriptionText?.en;

    return (
        <Accordion className={sharedStyles.section}>
            <AccordionSummary>
                <div className={sharedStyles.sectionHeader}>
                    <Typography className={sharedStyles.sectionLabel} variant="h6">
                        {getString("generalInformation")}
                    </Typography>
                    <div
                        className={classNames(sharedStyles.stepWrapper, {
                            [sharedStyles.valid]: !isTitleEmpty && !isDescriptionEmpty
                        })}>
                        <Typography className={sharedStyles.sectionLabel} variant="h6">
                            {`${step} / ${stepsNumber}`}
                        </Typography> 
                    </div>
                </div>
            </AccordionSummary>
            <AccordionDetails>
                <div className={sharedStyles.sectionContent}>
                    <div className={sharedStyles.subsection}>
                        <Typography className={sharedStyles.subsectionLabel} variant="h6">
                            {getString("postTitle")}
                        </Typography>
                        <Grid container className={sharedStyles.subsectionContent}>
                            <Grid item md={6} xs={12}>
                                <input
                                    className={classNames(styles.titleInput, {
                                        [styles.error]: isTitleEmpty
                                    })}
                                    placeholder={getString("romanianTitle")}
                                    value={post.titleText?.ro ?? ""}
                                    onChange={event => 
                                        runInAction(() => post.titleText!.ro = event.target.value)
                                    } />
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <input
                                    className={classNames(styles.titleInput, {
                                        [styles.error]: isTitleEmpty
                                    })}
                                    placeholder={getString("englishTitle")}
                                    value={post.titleText?.en ?? ""}
                                    onChange={event => 
                                        runInAction(() => post.titleText!.en = event.target.value)
                                    } />
                            </Grid>
                        </Grid>
                    </div>
                    <div className={sharedStyles.subsection}>
                        <Typography className={sharedStyles.subsectionLabel} variant="h6">
                            {getString("postDescription")}
                        </Typography>
                        <Grid container className={sharedStyles.subsectionContent}>
                            {(isTitleEmpty || post.titleText?.ro) && (
                                <Grid item md={6} xs={12}>
                                    <textarea
                                        rows={4}
                                        className={classNames(styles.titleInput, {
                                            [styles.error]: isDescriptionEmpty
                                        })}
                                        placeholder={getString("romanianDescription")}
                                        value={post.descriptionText?.ro ?? ""}
                                        onChange={event => 
                                            runInAction(() => 
                                                post.descriptionText!.ro = event.target.value)
                                        } />
                                </Grid>
                            )}
                            {(isTitleEmpty || post.titleText?.en) && (
                                <Grid item md={6} xs={12}>
                                    <textarea
                                        rows={4}
                                        className={classNames(styles.titleInput, {
                                            [styles.error]: isDescriptionEmpty
                                        })}
                                        placeholder={getString("englishDescription")}
                                        value={post.descriptionText?.en ?? ""}
                                        onChange={event => 
                                            runInAction(() => 
                                                post.descriptionText!.en = event.target.value)
                                        } />
                                </Grid>
                            )}
                        </Grid>
                    </div>
                    {isUserAdmin && <>
                        <div className={sharedStyles.subsection}>
                            <Typography className={sharedStyles.subsectionLabel} variant="h6">
                                {getString("youtubeVideoLink")}
                            </Typography>
                            <div className={sharedStyles.subsectionContent}>
                                <input
                                    className={styles.titleInput}
                                    placeholder={getString("youtubeUrl")}
                                    value={post.youtubeUrl ?? ""}
                                    onChange={event => 
                                        runInAction(() => post.youtubeUrl = event.target.value)
                                    } />
                            </div>
                        </div>
                        <div className={sharedStyles.subsection}>
                            <Typography className={sharedStyles.subsectionLabel} variant="h6">
                                {getString("roundmePanoramaLink")}
                            </Typography>
                            <div className={sharedStyles.subsectionContent}>
                                <input
                                    className={styles.titleInput}
                                    placeholder={getString("roundmeUrl")}
                                    value={post.panoramicViewUrl ?? ""}
                                    onChange={event => 
                                        runInAction(() => 
                                            post.panoramicViewUrl = event.target.value)
                                    } />
                            </div>
                        </div>
                    </>}
                </div>
            </AccordionDetails>
        </Accordion>
    );
}

export default observer(GeneralInformationSection);