import React, { useEffect, useState } from "react";
import { Image } from "..";
import styles from "./image-gallery.module.scss";
import { Modal } from "@material-ui/core";
import MainImage from "./main-image";
import ImageCarousel from "./image-carousel";

interface Props {
    images: string[];
}

const ImageGallery = ({ images }: Props) => {
    const [selectedImage, setSelectedImage] = useState(0);
    const [openFullscreen, setOpenFullscreen] = useState(false);

    const lastImageIndex = images.length - 1;

    const handleClickPrevious = () =>
        setSelectedImage(selectedImage => selectedImage ? selectedImage - 1 : lastImageIndex);

    const handleClickNext = () =>
        setSelectedImage(selectedImage => selectedImage === lastImageIndex ? 0 : selectedImage + 1);

    useEffect(() => {
        const handleKeyDown = (event: KeyboardEvent) => {
            const { key } = event;

            if (key === "ArrowLeft") {
                handleClickPrevious();
            } else if (key === "ArrowRight") {
                handleClickNext();
            }
        }

        window.addEventListener("keydown", handleKeyDown);

        return () => window.removeEventListener("keydown", handleKeyDown);
    });

    if (!images.length) {
        console.warn("ImageGallery didn't receive any images so it won't render!");
        return null;
    }

    const handleClickItem = async (index: number) => {
        if (selectedImage === index) {
            return;
        }
        setSelectedImage(index);
    }

    return <>
        <div className={styles.imageGalleryWrapper}>
            <MainImage
                image={images[selectedImage]}
                onClickPrevious={handleClickPrevious}
                onClickNext={handleClickNext}
                onClickImage={() => setOpenFullscreen(true)} />
            <ImageCarousel
                    images={images}
                    selectedImage={selectedImage}
                    onClick={handleClickItem} />
        </div>
        <Modal
            open={openFullscreen}
            onClose={() => setOpenFullscreen(false)}
            className={styles.fullscreenGallery}>
            <div className={styles.fullscreenWrapper}>
                <MainImage
                    className={styles.selectedImage}
                    image={images[selectedImage]}
                    onClickPrevious={handleClickPrevious}
                    onClickNext={handleClickNext}>
                    <div className={styles.counter}>{selectedImage + 1} / {images.length}</div>
                    <div className={styles.closeFullscreen} onClick={() => setOpenFullscreen(false)}>
                        <Image src="https://img.icons8.com/ios/30/ffffff/delete-sign.png"/>
                    </div>
                </MainImage>
                <ImageCarousel
                    images={images}
                    selectedImage={selectedImage}
                    onClick={handleClickItem} />
            </div>
        </Modal>
    </>;
}

export default ImageGallery;