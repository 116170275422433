import React from "react";
import { User } from "database/types";
import { useTranslation } from "infrastructure/hooks";
import { TranslationStringKey } from "utils/translation/types";
import styles from "./table-row.module.scss";

interface Props {
    user: User;
    onClick: () => void;
}

const TableRow = ({ user, onClick }: Props) => {
    const { getString } = useTranslation();

    return (
        <tr className={styles.row} onClick={onClick}>
            <td>{user.email}</td>
            <td>{user.firstName}</td>
            <td>{user.lastName}</td>
            <td>{getString(user.isIndividual ? "individual" : "legalIdentity")}</td>
            <td>{getString(user.accountType as TranslationStringKey)}</td>
        </tr>
    );
}

export default TableRow;