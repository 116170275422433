import React from "react";
import { RealEstateListStore } from "../real-estate-list-store";
import { useTranslation } from "infrastructure/hooks";
import { observer } from "mobx-react";
import { MultiSelect } from "components";
import { TranslationStringKey } from "utils/translation/types";

interface Props {
    store: RealEstateListStore;
}

const FilterBalconyTypes = ({ store }: Props) => {
    const { getString } = useTranslation();

    const { selectedBalconyTypes } = store;

    return (
        <MultiSelect
            onItemRender={value => getString(value as TranslationStringKey)}
            onSelect={selected => selectedBalconyTypes.selected = selected}
            selectedValues={selectedBalconyTypes.selected}
            values={selectedBalconyTypes.all}
            useAction
            label={getString("balconiesType")} />
    );
}

export default observer(FilterBalconyTypes);