import * as PostApi from "../../../database/api/post";
import { CATEGORY_FOR_RENT } from "../../../utils/constants";
import { ForRentPost } from "./types";

const options: PostApi.PostOptions = {
    category: CATEGORY_FOR_RENT,
    showAvailable: true
};

export const getPost = (postId: string) =>
    PostApi.getPost(postId, CATEGORY_FOR_RENT) as Promise<ForRentPost | null>;

export const getAllPosts = () =>
    PostApi.getAllPosts(options) as Promise<ForRentPost[]>;

export const getAllPostsWithText = (posts?: ForRentPost[]) =>
    PostApi.getAllPostsWithText(posts, options) as Promise<ForRentPost[]>;

export const getPostWithText = (postId: string) => 
    PostApi.getPostWithText(postId) as Promise<ForRentPost | null>;

export const createPost = (post: ForRentPost, postDocument = PostApi.generatePostDocument()) =>
    PostApi.createPost(post, postDocument);