import React, {useRef, useState} from "react";
import { BasePost, User } from "database/types";
import { useTranslation } from "infrastructure/hooks";
import { observer } from "mobx-react";
import { Popper, ClickAwayListener } from "@material-ui/core";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import {
    EditOutlined as EditIcon,
    DeleteOutlined as DeleteIcon,
    PlayArrowOutlined,
    PauseOutlined,
    CheckOutlined,
    ClearOutlined
} from "@material-ui/icons";
import classNames from "classnames";
import { isAdmin, isSuperAdmin } from "database/api/user";
import styles from "./section-post-actions.module.scss";
import sharedStyles from "utils/style/shared.module.scss";
import { needsConfirmation } from "infrastructure/services";
import { NavLink, useSearchParams } from "react-router-dom";
import { QUERY_SEARCH } from "utils/constants";

interface Props {
    store: {
        post: BasePost | null;
        user: User | null;
        setActive: (isActive: boolean) => void;
        setDeleted: (isDeleted: boolean) => void;
        setVerified: (isVerified: boolean) => void;
    };
    onOpenEdit: () => void;
}

const SectionPostActions = ({ store, onOpenEdit }: Props) => {
    const { getString } = useTranslation();
    const [searchParams] = useSearchParams();

    const { post, user, setActive, setDeleted, setVerified } = store;

    const [isMenuOpen, setMenuOpen] = useState(false);

    const ref = useRef(null);

    const handleDelete = () => 
        needsConfirmation(getString("deletePostAwareness"), getString("delete"), () => setActive(false));

    if (!post || !user)
        return null;

    searchParams.set(QUERY_SEARCH, post.createdByUserId || "");
    const userQuery = searchParams.toString();

    return (
        <div className={styles.actionsWrapper}>
            {isSuperAdmin(user) ? <>
                <div
                    className={styles.actionsToggle}
                    ref={ref}
                    onClick={() => setMenuOpen(!isMenuOpen)}>
                    {getString("operations")}
                    <ArrowDropDownIcon />
                </div>
                <Popper
                    anchorEl={ref.current}
                    open={isMenuOpen}
                    className={styles.actionsPopupWrapper}>
                    <ClickAwayListener onClickAway={() => setMenuOpen(false)}>
                        <div className={styles.actions}>
                            <NavLink to={`/admin/users?${userQuery}`}>
                                <div
                                    className={styles.actionButton}
                                    onClick={() => setMenuOpen(false)}>
                                    {getString("seeAuthor")}
                                </div>
                            </NavLink>
                            <div
                                className={styles.actionButton}
                                onClick={() => {
                                    setVerified(!post.isVerified);
                                    setMenuOpen(false);
                                }}>
                                {getString(post.isVerified ? "unverify" : "verify")}
                            </div>
                            <div
                                className={styles.actionButton}
                                onClick={() => {
                                    setActive(!post.isActive);
                                    setMenuOpen(false);
                                }}>
                                {getString(post.isActive ? "deactivate" : "activate")}
                            </div>
                            <div
                                className={styles.actionButton}
                                onClick={() => {
                                    setDeleted(!post.isDeleted);
                                    setMenuOpen(false);
                                }}>
                                {getString(post.isDeleted ? "unarchive" : "archive")}
                            </div>
                            <div
                                className={styles.actionButton}
                                onClick={() => { setMenuOpen(false); onOpenEdit() }}>
                                {getString("edit")}
                            </div>
                        </div>
                    </ClickAwayListener>
                </Popper>
            </> : isAdmin(user) ? <>
                <div
                    className={classNames(
                        styles.verifyButton,
                        styles.actionButton,
                        sharedStyles.ripple
                    )}
                    onClick={() => setVerified(!post.isVerified)}>
                        {post.isVerified ? (
                            <ClearOutlined className={styles.actionButtonIcon} />
                        ) : (
                            <CheckOutlined className={styles.actionButtonIcon} />
                        )}
                        {getString(post.isVerified ? "unverify" : "verify")}
                </div>
                <div
                    className={classNames(
                        styles.deactivateButton,
                        styles.actionButton,
                        sharedStyles.ripple
                    )}
                    onClick={() => setActive(!post.isActive)}>
                        {post.isActive ? (
                            <PauseOutlined className={styles.actionButtonIcon} />
                        ) : (
                            <PlayArrowOutlined className={styles.actionButtonIcon} />
                        )}
                        {getString(post.isActive ? "deactivate" : "activate")}
                </div>
                <div
                    className={classNames(
                        styles.themeEditButton,
                        styles.actionButton,
                        sharedStyles.ripple
                    )}
                    onClick={() => onOpenEdit()}>
                        <EditIcon className={styles.actionButtonIcon} />
                        {getString("edit")}
                </div>
            </> : <>
                {!post.isVerified && (
                    <div
                        className={classNames(
                            styles.themeEditButton,
                            styles.actionButton,
                            sharedStyles.ripple
                        )}
                        onClick={() => onOpenEdit()}>
                            <EditIcon className={styles.actionButtonIcon} />
                            {getString("edit")}
                    </div>
                )}
                <div
                    className={classNames(
                        styles.actionButton,
                        styles.deactivateButton,
                        sharedStyles.ripple
                    )}
                    onClick={handleDelete}>
                        <DeleteIcon className={styles.actionButtonIcon} />
                        {getString("delete")}
                </div>
            </>}
        </div>
    )
}

export default observer(SectionPostActions);