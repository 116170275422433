import { initializeApp } from "firebase/app";
import {
	getAuth,
	browserLocalPersistence,
	signInWithEmailAndPassword,
	updateEmail,
	updatePassword,
	sendPasswordResetEmail,
	createUserWithEmailAndPassword,
	onAuthStateChanged,
	NextOrObserver,
	User,
	setPersistence,
	Persistence,
	signOut
} from "firebase/auth";
import {
	collection,
	CollectionReference,
	deleteDoc,
	doc,
	DocumentData,
	DocumentReference,
	getDoc,
	getDocs,
	getFirestore,
	onSnapshot,
	Query,
	query,
	QuerySnapshot,
	setDoc,
	updateDoc,
	where,
	WhereFilterOp
} from "firebase/firestore";
import { getDownloadURL, getStorage, ref, StringFormat, uploadString } from "firebase/storage";

type Observer = (snapshot: QuerySnapshot<DocumentData>) => void;

const app = initializeApp({
	apiKey: "AIzaSyAeD9RCNdLt0K7xnYL6B7IXgU66DB_djWA",
	authDomain: "miraauction.firebaseapp.com",
	databaseURL: "https://miraauction.firebaseio.com",
	projectId: "miraauction",
	storageBucket: "miraauction.appspot.com",
	messagingSenderId: "292906546755",
	appId: "1:292906546755:web:de9434661d67e4938fc6f6",
	measurementId: "G-62LBFWECG8"
});

export const auth = {
	...getAuth(app),
	signInWithEmailAndPassword: (email: string, password: string) => 
		signInWithEmailAndPassword(getAuth(app), email, password),
	sendPasswordResetEmail: (email: string) => sendPasswordResetEmail(getAuth(app), email),
	createUserWithEmailAndPassword: (email: string, password: string) => 
		createUserWithEmailAndPassword(getAuth(app), email, password),
	onAuthStateChanged: (observer: NextOrObserver<User>) => onAuthStateChanged(getAuth(app), observer),
	setPersistence: (persistance: Persistence) => setPersistence(getAuth(app), persistance),
	getCurrentUser: () => {
		const currentUser = getAuth(app).currentUser;

		return currentUser && {
			...currentUser,
			updateEmail: (newEmail: string) => 
				currentUser && updateEmail(currentUser, newEmail),
			updatePassword: (newPassword: string) => 
				currentUser && updatePassword(currentUser, newPassword)
		};
	},
	signOut: () => signOut(getAuth(app))
};

const wrapDocument = (document: DocumentReference<DocumentData>) => ({
	id: document.id,
	get: () => getDoc(document),
	set: (data: any) => setDoc(document, data),
	update: (properties: any) => updateDoc(document, properties),
	delete: () => deleteDoc(document)
});

const wrapQuery = 
	(previousResult: CollectionReference<DocumentData> | Query<DocumentData>) => ({
		get: () => getDocs(previousResult),
		onSnapshot: (observer: Observer) => onSnapshot(previousResult, observer),
		where: (fieldPath: string, operation: WhereFilterOp, value: any) => 
			wrapQuery(query(previousResult, where(fieldPath, operation, value)))
	});

export const firestoreCollection = (path: string, ...pathSegments: string[]) => {
	const localCollection = collection(getFirestore(app), path, ...pathSegments);

	return {
		generate: () => wrapDocument(doc(localCollection)),
		doc: (path?: string, ...pathSegments: string[]) =>
			wrapDocument(doc(localCollection, path, ...pathSegments)),
		where: (fieldPath: string, operation: WhereFilterOp, value: any) => 
			wrapQuery(query(localCollection, where(fieldPath, operation, value))),
		get: () => getDocs(localCollection)
	}
};

export const storageRef = (url: string) => ({ 
	ref: (url: string) => ({
		uploadString: async (value: string, format: StringFormat) => {
			const uploadResult = await uploadString(ref(getStorage(app), url), value, format);

			return {
				...uploadResult,
				getDownloadURL: () => getDownloadURL(uploadResult.ref)
			}
		}
	})
 });

export const PERSISTENCE_LOCAL = browserLocalPersistence;

export { sendEmailVerification } from "firebase/auth";