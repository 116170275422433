import React from "react";
import {
	HelpOutline as HelpOutlineIcon,
	LocationOnOutlined as LocationOnOutlinedIcon,
} from "@material-ui/icons";
import { useTranslation } from "infrastructure/hooks";
import { ScrollTopTransition } from "components";
import styles from "./contact.module.scss";
import sharedStyles from "./../home.module.scss";
import classNames from "classnames";
import {
	CONTACT_EMAIL,
	CONTACT_PHONE,
	CONTACT_ADDRESS,
} from "utils/constants";

const Contact = () => {
	const { getString } = useTranslation();

	return (
		<div className={sharedStyles.section}>
			<h1 className={sharedStyles.header}>
				{getString("contactUsHeader")}
			</h1>
			<ScrollTopTransition
				selector=".contact-item-1"
				timeout={300}
				classNames="fade-in">
				<div
					className={classNames(styles.contactItem, "contact-item-1")} >
					<div className={styles.contactIconWrapper}>
						<HelpOutlineIcon
							className={styles.contactIcon}
							style={{ fontSize: 70 }} />
					</div>
					<h3 className={styles.contactType}>
						{getString("contactUs")}
					</h3>
					<h4 className={styles.assistant}>{getString("officeMiraAuction")}</h4>
					<a href={`tel:${CONTACT_PHONE}`} className={styles.contact}>{CONTACT_PHONE}</a>
					<a href={`mailto:${CONTACT_EMAIL}`} className={styles.contactEmail}>{CONTACT_EMAIL}</a>
				</div>
			</ScrollTopTransition>
			<ScrollTopTransition
				selector=".contact-item-2"
				timeout={400}
				classNames="fade-in" >
				<div className={classNames(styles.contactItem, "contact-item-2")} >
					<div className={styles.contactIconWrapper}>
						<LocationOnOutlinedIcon
							className={styles.contactIcon}
							style={{ fontSize: 70 }}/>
					</div>
					<h3 className={styles.contactType}>
						{getString("address")}
					</h3>
					<h4 className={styles.assistant}>{CONTACT_ADDRESS}</h4>
					<h4 className={styles.assistant}>{getString("schedule")}:</h4>
					<h4 className={styles.contact}>{getString("mondayToSaturday")}: 10:00 - 20:00</h4>
				</div>
			</ScrollTopTransition>
		</div>
	);
};

export default Contact;
