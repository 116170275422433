import { createContext } from "react";
import { runInAction, makeAutoObservable } from "mobx";
import { Bid, User } from "database/types";
import { getPostWithText } from "../api";
import { PostDetailsStoreType } from "../../types";
import { getAllUsers, isAdmin } from "database/api/user";
import { auth } from "database/firebase";
import { setPost } from "database/api/post";
import { ForRentPost } from "../types";
import { loadingService } from "infrastructure/services";

export class ForRentDetailsStore implements PostDetailsStoreType {
    public user: User | null = null;
    public post: ForRentPost | null = null;
    public searchCharacteristics: string = "";
    public isForRentEditVisible: boolean = false;
    public areBidsLoading: boolean = false;
    public bids: Bid[] = [];

    constructor() {
        makeAutoObservable(this);
    }

    public fetchPost = async (postId: string) => {
        loadingService.set(true);
        const post = await getPostWithText(postId) as ForRentPost;

        if (!post) {
            loadingService.set(false);
            return;
        }

        const users = await getAllUsers();
        const user = users.find(user => user.id === auth.getCurrentUser()?.uid);

        if (post.isDeleted && !isAdmin(user)) {
            runInAction(() => {
                this.user = user || null;
            })
            loadingService.set(false);
            return;
        }

        runInAction(() => {
            this.user = user || null;
            this.post = post;
        });
        loadingService.set(false);
    }

    public placeBid = () => {
    }

    public setActive = async (isActive: boolean) => {
        if (!this.post) {
            return;
        }

        loadingService.set(true);
        await setPost({
            ...this.post,
            isActive
        });

        runInAction(() => {
            this.post!.isActive = isActive;
        });
        loadingService.set(false);
    }

    public setDeleted = async (isDeleted: boolean) => {
        if (!this.post) {
            return;
        }

        loadingService.set(true);
        await setPost({
            ...this.post,
            isDeleted
        });
        
        runInAction(() => {
            this.post!.isDeleted = isDeleted;
        });
        loadingService.set(false);
    }

    public setVerified = async (isVerified: boolean) => {
        if (!this.post) {
            return;
        }

        loadingService.set(true);
        await setPost({
            ...this.post,
            isVerified
        });
        
        runInAction(() => {
            this.post!.isVerified = isVerified;
        });
        loadingService.set(false);
    }

    public reset = () => {
        this.post = null;
        this.searchCharacteristics = "";
        this.user = null;
    }

    public setPost = (post: ForRentPost) => this.post = post;
  
    public setForRentEditVisible = (isVisible: boolean) => this.isForRentEditVisible = isVisible;
}

export const forRentDetailsStore = new ForRentDetailsStore();
export const ForRentDetailsContext = createContext(forRentDetailsStore);