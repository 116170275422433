import React from "react";
import {
    SportsVolleyball as SportsVolleyballIcon,
    Help as HelpIcon,
    CheckCircle as CheckCircleIcon
} from "@material-ui/icons"
import { ScrollTopTransition } from "components";
import { useTranslation } from "infrastructure/hooks";
import styles from "./why-choose-us.module.scss";
import sharedStyles from "../home.module.scss";
import classNames from "classnames";

const WhyChooseUs = () => {

    const { getString } = useTranslation();

    return (
        <div className={sharedStyles.section}>
            <h1 className={sharedStyles.header}>
                {getString("whyChooseUsHeader")}
            </h1>
            <ScrollTopTransition selector=".benefit-item-1" timeout={400} classNames="fade-in">
                <div className={classNames(sharedStyles.hoverableCard, styles.benefitItem, "benefit-item-1")}>
                    <div className={styles.icon}>
                        <SportsVolleyballIcon style={{ color: "#084177", fontSize: 120 }}></SportsVolleyballIcon>
                    </div>
                    <h4 className={styles.benefit}>{getString("whyChooseUsOption1Header")}</h4>
                    <p className={styles.benefitDescription}>{getString("whyChooseUsOption1Text")}</p>
                </div>
            </ScrollTopTransition>
            <ScrollTopTransition selector=".benefit-item-3" timeout={600} classNames="fade-in">
                <div className={classNames(sharedStyles.hoverableCard, styles.benefitItem, "benefit-item-3")}>
                    <div className={styles.icon}>
                        <HelpIcon style={{ color: "#fbc490", fontSize: 120 }}></HelpIcon>
                    </div>
                    <h4 className={styles.benefit}>{getString("whyChooseUsOption2Header")}</h4>
                    <p className={styles.benefitDescription}>{getString("whyChooseUsOption2Text")}</p>
                </div>
            </ScrollTopTransition>
            <ScrollTopTransition selector=".benefit-item-4" timeout={800} classNames="fade-in">
                <div className={classNames(sharedStyles.hoverableCard, styles.benefitItem, "benefit-item-4")}>
                    <div className={styles.icon}>
                        <CheckCircleIcon style={{ color: "#cd8d7b", fontSize: 120 }}></CheckCircleIcon>
                    </div>
                    <h4 className={styles.benefit}>{getString("whyChooseUsOption3Header")}</h4>
                    <p className={styles.benefitDescription}>{getString("whyChooseUsOption3Text")}</p>
                </div>
            </ScrollTopTransition>
        </div>
    )
}

export default WhyChooseUs;