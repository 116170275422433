import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@material-ui/core";
import { SlideUpAnimation } from "components";
import { useTranslation } from "infrastructure/hooks";
import React from "react";
import { useContext } from "react";
import { TermsAndConditionsContext } from "./terms-and-conditions-store";
import styles from "./terms-and-conditions-dialog.module.scss";
import { CONTACT_EMAIL } from "utils/constants";
import { observer } from "mobx-react";

const TermsAndConditions = () => {
    const { getString } = useTranslation();
    const { isOpen, setIsOpen } = useContext(TermsAndConditionsContext);

    return (
        <Dialog
            open={isOpen}
            TransitionComponent={SlideUpAnimation}
            keepMounted
            onClose={() => setIsOpen(false)}>
            <DialogTitle>{getString("termsAndConditions")}</DialogTitle>
            <DialogContent dividers>
                <DialogContentText className={styles.dialogText} paragraph>
                    {getString("termsAndConditionsText", CONTACT_EMAIL)}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setIsOpen(false)} color="primary">
                    {getString("close")}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default observer(TermsAndConditions);