import React from "react";
import { useTranslation } from "infrastructure/hooks";
import { observer } from "mobx-react";
import { MultiSelect } from "components";
import { VehiclesListStore } from "../vehicles-list-store";

interface Props {
    store: VehiclesListStore;
}

const FilterCities = ({ store }: Props) => {
    const { getString } = useTranslation();

    const { selectedCities } = store;

    return (
        <MultiSelect
            onItemRender={value => value}
            onSelect={selected => selectedCities.selected = selected}
            selectedValues={selectedCities.selected}
            values={selectedCities.all}
            useAction
            label={getString("city")} />
    );
}

export default observer(FilterCities);