import { TranslationStringKeys } from "./strings-key";

export type TranslationStringKey = keyof typeof TranslationStringKeys;

export type TranslationStrings = {
    [index in TranslationStringKey]: string;
};

export type GetStringFunction = (key: TranslationStringKey, ...values: (string | number)[]) => string;
export const EMPTY_GET_STRING_FUNCTION: GetStringFunction = () => "";

export type LanguageType = "" | "ro" | "en";