import { Accordion, AccordionDetails, AccordionSummary, Typography } from "@material-ui/core";
import classNames from "classnames";
import { runInAction } from "mobx";
import React from "react";
import { BasePost } from "database/types";
import { useTranslation } from "infrastructure/hooks";
import sharedStyles from "../post-form.module.scss";
import { observer } from "mobx-react";
import { LocationPicker } from "components";

interface Props {
    step: number;
    stepsNumber: number;
    post: BasePost;
    isVisible: boolean;
}

const LocationSection = ({ step, post, stepsNumber, isVisible }: Props) => {

    const { getString } = useTranslation();

    const setLocation = (latitude: number, longitude: number) => runInAction(() => {
        post.latitude = latitude;
        post.longitude = longitude;
    });

    const pickerError = !post.latitude || !post.longitude;

    return (
        <Accordion className={sharedStyles.section}>
            <AccordionSummary>
                <div className={sharedStyles.sectionHeader}>
                    <Typography className={sharedStyles.sectionLabel} variant="h6">
                        {getString("location")}
                    </Typography>
                    <div
                        className={classNames(sharedStyles.stepWrapper, {
                            [sharedStyles.valid]: !pickerError
                        })}>
                        <Typography className={sharedStyles.sectionLabel} variant="h6">
                            {`${step} / ${stepsNumber}`}
                        </Typography>
                    </div>
                </div>
            </AccordionSummary>
            <AccordionDetails>
                <div className={sharedStyles.sectionContent}>
                    <div className={sharedStyles.subsection}>
                        <Typography className={sharedStyles.subsectionLabel} variant="h6">
                            {getString("selectExactLocation")}
                        </Typography>
                        <div className={sharedStyles.subsectionContent}>
                            <LocationPicker
                                latitude={post.latitude}
                                longitude={post.longitude}
                                isVisible={isVisible}
                                onPick={setLocation} />
                        </div>
                    </div>
                </div>
            </AccordionDetails>
        </Accordion>
    )
}

export default observer(LocationSection);