import React from "react";
import { RealEstateListStore } from "../real-estate-list-store";
import { useTranslation } from "infrastructure/hooks";
import { observer } from "mobx-react";
import { BooleanSelect } from "components";

interface Props {
    store: RealEstateListStore;
}

const FilterParkingSpace = ({ store }: Props) => {
    const { getString } = useTranslation();

    return (
        <BooleanSelect
            onSelect={value => store.withParkingSpace = value}
            value={store.withParkingSpace}
            useAction
            label={getString("parkingSpace")} />
    );
}

export default observer(FilterParkingSpace);