import { EMAIL_REGEX } from "../../../utils/constants";
import { FormValidationRules } from "../types";

export interface LoginFormModel {
	email: string;
	password: string;
	remainLoggedIn: boolean;
}

export const getDefaultLoginFormModel = (): LoginFormModel => ({
	email: "",
	password: "",
	remainLoggedIn: true,
});

export const LoginRules: FormValidationRules<LoginFormModel> = {
	email: {
		name: "email",
		required: {
			value: true,
			message: "emailRequired",
		},
		pattern: {
			value: EMAIL_REGEX,
			message: "emailMustBeValid",
		},
	},
	password: {
		name: "password",
		required: {
			value: true,
			message: "passwordRequired",
		},
	},
	remainLoggedIn: {
		name: "remainLoggedIn",
	},
};
