/*eslint no-extend-native: ["error", { "exceptions": ["Array"] }]*/

declare global {
    interface Array<T> {
        exclude: (other: Array<T>) => Array<T>;
        mapNotNull: <V>(selector: (entity: T) => V | null) => Array<V>;
        count: <V>(selector: (entity: T) => V) => number;
    }
}

if (!Array.prototype.exclude) {
    Array.prototype.exclude = function (other) {
        return this.filter(element => !other.includes(element));
    }
}

if (!Array.prototype.mapNotNull) {
    Array.prototype.mapNotNull = function (selector) {
        const result: Array<any> = [];
        for (const element of this) {
            const value = selector(element);
            if (value !== null) {
                result.push(value)
            } 
        }
        return result;
    }
}

if (!Array.prototype.count) {
    Array.prototype.count = function (selector) {
        return this.filter(selector).length;
    }
}

export {};