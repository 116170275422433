import React from "react";
import { observer } from "mobx-react";
import { useTranslation } from "infrastructure/hooks";
import {
    bodyOptions,
    brandOptions,
    brandOptionsType,
    colorOptions,
    conditionOptions,
    fuelOptions,
    gearboxOptions,
    modelOptions,
    ownerOptions,
    VehicleFormPost
} from "../types";
import styles from "./vehicles-characteristics.module.scss";
import { BoldText, MultiSelect, NumericInput, SingleSelect } from "components";
import { TranslationStringKey } from "utils/translation/types";
import { getMomentAfterYears } from "utils/date-utils";
import { useRunOnce } from "utils/ui-utils";
import { useUserDetails } from "infrastructure/services";

interface Props {
    post: VehicleFormPost;
    setOwner: (owner: string) => void;
}

const oneYearAhead = getMomentAfterYears(1).get("y");

const getEngineCapacityType = (fuel: string[]) => {
    if (!fuel.length) {
        return <>cm<sup>3</sup>/kW</>;
    }
    if (fuel.length === 1 && fuel[0] === "electric") {
        return <>kW</>;
    }
    return <>cm<sup>3</sup></>;
}

const VehicleCharacteristics = ({ post, setOwner }: Props) => {
    const { user, isUserAdmin } = useUserDetails();

    const { getString } = useTranslation();

    useRunOnce(() => {
        setOwner(user?.isIndividual ? "private" : "firm");
    });

    return (
        <div className={styles.vehiclesCharacteristicsWrapper}>
            <div className={styles.characteristicWrapper}>
                <BoldText>{getString("brand")}</BoldText>
                <SingleSelect
                    required
                    values={brandOptions}
                    value={post.brand}
                    onItemRender={key => getString(key as TranslationStringKey)}
                    useAction
                    onSelect={key => post.brand = key} />
            </div>
            {!!post.brand && 
                <div className={styles.characteristicWrapper}>
                    <BoldText>{getString("model")}</BoldText>
                    <SingleSelect
                        required
                        values={[...modelOptions[post.brand as brandOptionsType], "other"]}
                        value={post.model}
                        onItemRender={model => getString(model as TranslationStringKey) || model}
                        useAction
                        onSelect={model => post.model = model} />
                </div>
            }
            <div className={styles.characteristicWrapper}>
                <BoldText>{getString("fabricationYear")}</BoldText>
                <NumericInput
                    required
                    value={post.fabricationYear}
                    onChange={value => post.fabricationYear = value}
                    min={1900}
                    useAction
                    max={oneYearAhead} />
            </div>
            <div className={styles.characteristicWrapper}>
                <BoldText>{getString("mileage")} (km)</BoldText>
                <NumericInput
                    required
                    value={post.mileage}
                    onChange={value => post.mileage = value}
                    min={1}
                    useAction
                    max={1000000} />
            </div>
            <div className={styles.characteristicWrapper}>
                <BoldText>
                    {getString("engineCapacity")}&nbsp;({getEngineCapacityType(post.fuel)})
                </BoldText>
                <NumericInput
                    required
                    value={post.engineCapacity}
                    onChange={value => post.engineCapacity = value}
                    min={1}
                    useAction
                    max={5000} />
            </div>
            <div className={styles.characteristicWrapper}>
                <BoldText>{getString("condition")}</BoldText>
                <SingleSelect
                    required
                    values={conditionOptions}
                    value={post.condition}
                    onItemRender={key => getString(key as TranslationStringKey)}
                    useAction
                    onSelect={key => post.condition = key} />
            </div>
            <div className={styles.characteristicWrapper}>
                <BoldText>{getString("fuel")}</BoldText>
                <MultiSelect
                    required
                    values={fuelOptions}
                    selectedValues={post.fuel}
                    onItemRender={key => getString(key as TranslationStringKey)}
                    useAction
                    onSelect={keys => post.fuel = keys} />
            </div>
            <div className={styles.characteristicWrapper}>
                <BoldText>{getString("body")}</BoldText>
                <SingleSelect
                    required
                    values={bodyOptions}
                    value={post.body}
                    onItemRender={key => getString(key as TranslationStringKey)}
                    useAction
                    onSelect={key => post.body = key} />
            </div>
            <div className={styles.characteristicWrapper}>
                <BoldText>{getString("color")}</BoldText>
                <SingleSelect
                    required
                    values={colorOptions}
                    value={post.color}
                    onItemRender={key => getString(key as TranslationStringKey)}
                    useAction
                    onSelect={key => post.color = key} />
            </div>
            <div className={styles.characteristicWrapper}>
                <BoldText>{getString("gearbox")}</BoldText>
                <SingleSelect
                    required
                    values={gearboxOptions}
                    value={post.gearbox}
                    onItemRender={key => getString(key as TranslationStringKey)}
                    useAction
                    onSelect={key => post.gearbox = key} />
            </div>
            <div className={styles.characteristicWrapper}>
                <BoldText>{getString("privateOrFirm")}</BoldText>
                <SingleSelect
                    required
                    disabled={!isUserAdmin}
                    values={ownerOptions}
                    value={post.owner}
                    onItemRender={key => getString(key as TranslationStringKey)}
                    useAction
                    onSelect={key => post.owner = key} />
            </div>
        </div>
    );
}

export default observer(VehicleCharacteristics);