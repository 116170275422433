import React from "react";
import { RealEstateListStore } from "../real-estate-list-store";
import { observer } from "mobx-react";
import { RangePicker } from "components";
import { useTranslation } from "infrastructure/hooks";

interface Props {
    store: RealEstateListStore;
}

const FilterRooms = ({ store }: Props) => {
    const { getString } = useTranslation();

    const { rangeRooms, setRoomsRange } = store;

    return (
        <RangePicker 
            minValue={rangeRooms.min}
            maxValue={rangeRooms.max}
            min={rangeRooms.lowest}
            max={rangeRooms.greatest}
            label={getString("numberOfRooms")}
            useAction
            onChangeMin={min => rangeRooms.min = min}
            onChangeMax={max => rangeRooms.max = max}
            onChangeMinMax={setRoomsRange} />
    );
}

export default observer(FilterRooms);