import { CircularProgress } from "@material-ui/core";
import React, { createRef, useRef } from "react";
import { Image } from "..";
import styles from "./image-item.module.scss";

interface Props {
    image?: string;
    multipleFiles?: boolean;
    onUpload?: (files: FileList | null) => void;
    onDelete?: (image: string) => void;
}

let count = 0;

const ImageItem = ({ image, multipleFiles, onUpload, onDelete }: Props) => {
    const imageInput = createRef<HTMLInputElement>();
    const counter = useRef(count++);

    const uniqueId = `${styles.addWrapper}-${counter.current}`;

    const handleImageInputChange = () => {
        if (onUpload && imageInput && imageInput.current) {
            onUpload(imageInput.current.files);
        }
    }

    return (
        <div className={styles.imageItemWrapper}>
            {onUpload ? <>
                <input
                    accept="image/*"
                    id={uniqueId}
                    type="file"
                    multiple={multipleFiles}
                    ref={imageInput}
                    onChange={handleImageInputChange} />
                <label htmlFor={uniqueId}>
                    <div className={styles.addWrapper}>
                        <Image src="https://img.icons8.com/ios/96/ffffff/plus-math.png" />
                        <div className={styles.addOverlay}>
                            <Image src="https://img.icons8.com/ios/128/eaa524/plus-math.png" />
                        </div>
                    </div>
                </label>
            </> : image ? (
                <div className={styles.imageWrapper}>
                    <Image src={image} />
                    <div className={styles.remove} onClick={() => onDelete && onDelete(image)}>
                        <Image src="https://img.icons8.com/ios/24/ffffff/delete-sign.png" />
                    </div>
                </div>
            ) : (
                <div className={styles.spinnerWrapper}>
                    <CircularProgress className={styles.spinner} color="secondary" />
                </div>
            )}
        </div>
    );
}

export default ImageItem;