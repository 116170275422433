import { BasePost } from "../database/types";

export interface Coordinates {
    latitude: number;
    longitude: number;
}

export interface Location {
    country: string;
    county: string;
    city: string;
    zone: string;
    street: string;
}

export interface PostLocations extends BasePost {
    country?: string;
    county?: string;
    city?: string;
    zone?: string;
    street?: string;
}

const KEY_COUNTRY_NAME = "country";
const KEY_COUNTY_NAME = "administrative_area_level_1";
const KEY_CITY_NAME = "locality";
const KEY_ZONE_NAME = "neighborhood";
const KEY_STREET_NAME = "route";

const geocoder = new google.maps.Geocoder();

export const getLocation = (
    { latitude, longitude }: Coordinates,
    result: (location: Location) => void
) => geocoder.geocode({ location: {
        lat: latitude,
        lng: longitude
    }
}, (results, status) => {
    if (status !== "OK") {
        console.warn("Geodecoder failed:", status);
        return;
    }
    if (!results?.length) {
        console.warn(`No location found for latitude ${latitude} and longitude ${longitude}`);
        return;
    }

    const locationDetails = results[0];
    const zoneDetails = results.find(result => result.types.includes(KEY_ZONE_NAME));

    if (!locationDetails) {
        console.warn("Could not extract address details");
        return;
    }

    const getName = (key: string, fromDetails = locationDetails) => fromDetails?.address_components
        ?.find(address => address.types.includes(key))?.long_name ?? "";

    const streetName = getName(KEY_STREET_NAME);

    const resultLocation = {
        country: getName(KEY_COUNTRY_NAME),
        county: getName(KEY_COUNTY_NAME).replace(/ County/g, "").replace(/Jude.ul /g, ""),
        city: getName(KEY_CITY_NAME),
        zone: getName(KEY_ZONE_NAME, zoneDetails),
        street: streetName === "Unnamed Road" ? "" : streetName
    };
    
    result(resultLocation);
});

export const getLocationText = (post: PostLocations | Location, ignoreCountry = false) => {

    const { country, county, city, zone, street } = post;
    const details = [];

    !ignoreCountry && country && details.push(country);
    county && details.push(county);
    city && details.push(city);
    zone && details.push(zone);
    street && details.push(street);

    return details.map((detail, index) => (index ? " - " : "") + detail).join("");
}