import React, { useContext, useEffect } from "react";
import { useTranslation } from "infrastructure/hooks";
import { FullscreenDialog, CloseFormDialog } from "components";
import { RealEstateFormContext } from "./real-estate-form-store";
import PostForm from "../../components/post-form";
import { observer } from "mobx-react";
import RealEstateCharacteristics from "./real-estate-characteristics";
import { toJS } from "mobx";
import { EMPTY_REAL_ESTATE_POST, RealEstateFormPost, RealEstatePost } from "../types";

const MINIMUM_PRICE = 1000;
const PRICE_STEP = 100;

interface Props {
    isOpen: boolean;
    onClose: () => void;
    realEstatePost?: RealEstateFormPost | null;
    onSubmit?: (post: RealEstatePost) => void;
}

const RealEstateForm = ({ realEstatePost, isOpen, onClose, onSubmit }: Props) => {
    const { getString, isEnglish } = useTranslation();

    const realEstateStore = useContext(RealEstateFormContext);

    const {
        post,
        isConfirmationOpen,
        areCharacteristicsValid,
        arePostDetailsValid,

        setPost,
        openConfirmation,
        closeConfirmation,
        savePost,
        updatePost,
        reset
    } = realEstateStore;

    useEffect(() => {
        if (realEstatePost) {
            setPost(toJS(realEstatePost));
        } else {
            setPost(EMPTY_REAL_ESTATE_POST);
        }
    }, [realEstatePost, setPost]);

    const handleSaveClick = async () => {
        if (realEstatePost) {
            await updatePost();
            onSubmit && onSubmit(post as RealEstatePost);
        } else {
            await savePost();
        }
        onClose();
    }

    toJS(post);

    const title = isEnglish() ? realEstatePost?.titleText?.en : realEstatePost?.titleText?.ro;

    return (
        <FullscreenDialog
            open={isOpen}
            onClose={openConfirmation}
            title={realEstatePost ? getString("editThePost", title ?? "") : getString("addPostForRealEste")}>
            <PostForm
                isSaveDisabled={!arePostDetailsValid()}
                minimumPrice={MINIMUM_PRICE}
                post={post}
                onSaveClick={handleSaveClick}
                priceStep={PRICE_STEP}
                isEdit={!!realEstatePost}
                areCharacteristicsValid={!!areCharacteristicsValid()}
                isVisible={isOpen}
                enableBidding>
                <RealEstateCharacteristics post={post} />
            </PostForm>
            <CloseFormDialog
                open={isConfirmationOpen}
                onCancel={closeConfirmation}
                onConfirm={() => {
                    closeConfirmation();
                    onClose();
                    reset();
                }} />
        </FullscreenDialog>
    );
}

export default observer(RealEstateForm);