import React from "react";
import Hero from "./sections/hero";
import OurHelp from "./sections/our-help";
import Guide from "./sections/guide";
import Introduction from "./sections/introduction";
import WhyChooseUs from "./sections/why-choose-us";
// import OurTeam from "./sections/our-team";
// import CustomersReviews from "./sections/reviews";
import Contact from "./sections/contact";
import Footer from "./sections/footer";
import sharedStyles from "./home.module.scss";

export const Home = () => (
	<div className={sharedStyles.wrapperContainer}>
		<div className={sharedStyles.homeContainer}>
			<Hero />
			<Introduction />
			<OurHelp />
			<WhyChooseUs />
			<Guide />
			{/* <OurTeam /> */}
			{/* <CustomersReviews /> */}
			<Contact />
		</div>
		<Footer />
	</div>
);
