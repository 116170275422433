import logo from "../assets/images/logo.png";
import Compressor from "compressorjs";

const watermark = require("watermarkjs");

export const watermarkImage = (sourceImage: Blob) =>
    watermark([sourceImage, logo]).blob(watermark.image.center(.3));

export const readFile = async (file: Blob, fileReader = new FileReader()): Promise<string | any> => {
    let imageFile = await compressImageAsync(file);

    imageFile = await watermarkImage(imageFile);
    fileReader.readAsDataURL(imageFile);

    return new Promise(resolve => fileReader.onloadend = event => resolve(event?.target?.result));
}

export const compressImageAsync = async (file: Blob, quality: number = 0.8): Promise<Blob> =>
    new Promise((resolve, reject) => new Compressor(file, {
        quality: quality,
        success: resolve,
        error: reject
    }));