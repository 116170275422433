import { TranslationStrings } from "./types";

export const STATIC_STRINGS_RO: TranslationStrings = {
    siteName: "Mira Auction",
    realEstate: "Imobiliare",
    vehicles: "Autovehicule",
    login: "Logare",
    selectedLanguage: "Română",
    termsAndConditions: "Termeni & Condiții",
    language: "Limba",
    emailRequired: "Adresa de email este necesară",
    emailMustBeValid: "Adresa de email nu este validă",
    passwordRequired: "Parola este necesară",
    password: "Parolă",
    forgotPassword: "Ai uitat parola?",
    createAccount: "Creare cont",
    or: "Sau",
    close: "Închide",
    location: "Locație",
    images: "Imagini",
    bidsForPost: "Licitările postării",
    copyBidderId: "Copiază ID-ul licitatorului",
    termsAndConditionsText: `Bine ați venit pe website-ul miraauction.com, un serviciu oferit de Mira Auction S.R.L., CUI:  43267029,  Nr. Registru:  J12/3570/30.10.2020, Sediul: Municipiul Cluj-Napoca, str. Gruia, nr. 28A, et. 1, ap. 118, jud. Cluj. Prin utilizarea, răsfoirea și vizitarea acestui site, sunteți de acord cu valabilitatea prezentelor Termeni si Condiții pentru utilizarea secțiunii publice a paginii web miraauction.com.
 

    1. Introducere

    Acești termeni și condiții cât și politica noastră de confidențialitate sunt denumite colectiv "Termeni și condiții”.
    Politica de confidenţialitate se referă la colectarea şi utilizarea informaţiilor personale pe care ni le furnizaţi atunci când vizitaţi acest site.Website-ul nostru este în conformitate cu Regulamentul General de Protecție a Datelor (“GDPR”), (Regulament (EU)   2016/679) si LEGEA nr. 190 din 18 iulie 2018 privind măsuri de punere în aplicare a Regulamentului (UE) 2016/679 al Parlamentului European şi al Consiliului din 27 aprilie 2016 privind protecţia persoanelor fizice în ceea ce priveşte prelucrarea datelor cu caracter personal şi privind libera circulaţie a acestor date şi de abrogare a Directivei 95/46/CE (Regulamentul general privind protecţia datelor), care este un act legislativ obligatoriu. GDPR crează noi drepturi pentru indivizi și consolidează unele drepturi existente în conformitate cu legile Uniunii Europene și cu principiile stabilite în acest document.
     
    Prin accesarea www.miraauction.com sunteți de acord cu termenii de mai sus, care sunt folosiți pentru a ne asigura că site-ul funcționează pentru toată lumea.
    Miraauction.com  își rezervă dreptul de a modifica, schimba, înlătura sau adăuga termeni și condiții prezentului regulament, incluzând, dar nelimitându-se la aceastea: termeni, amenzi/taxe aplicabile, politici de funcționare/utilizare. Miraauction.com poate beneficia de acest drept fără notificări sau înștiințări prealabile. Indiferent de înștiințările prealabile, utilizarea continuă a serviciilor reprezintă acordul dumneavoastră asupra acestor modificări. Verificarea periodică a modificărilor și schimbărilor intervenite în prezentul Regulament, precum și corectitudinea datelor de contact furnizate de dumneavoastră sunt în întregime responsabilitatea dumneavoastră. Prin Completarea Formularului de înregistrare și ulterior activarea prin e-mailul trimis de către miraauction.com pe adresa furnizată de dumneavoastră și prin utilizarea acestui site presupune în mod automat ca sunteți de acord si veți respecta termenii si condițiile de utilizare.
    Orice noi caracteristici care îmbunătățesc sau extind Serviciul actual, vor fi supuse termenilor si condițiilor. Serviciul este furnizat "CA ATARE" și  Mira Auction SRL nu își asumă nicio responsabilitate pentru actualitatea, ștergerea, sau incapacitatea de a stoca orice notificare ale utilizatorului sau setările personalizate.
    DACĂ NU SUNTEȚI DE ACORD CU PREZENȚII TERMENI, NU COMPLETAȚI FORMULARUL DE ÎNREGISTRARE, NU VĂ AUTENTIFICAȚI CU CONTUL ȘI PAROLA INDIVIDUALĂ.
    

    2. Definiții

    Utilizator - reprezintă toate persoanele fizice în vârstă de cel puțin 18 ani, cu capacitate de exercițiu deplină, care accesează website-ul, fiind interesată de datele, informațiile, anunțurile și/sau serviciile Mira Auction S.R.L. și/sau juridice care accesează și folosesc site-ul miraauction.com, indiferent de motiv.
    Furnizor sau Mira Auction - societatea  Mira Auction S.R.L., persoana juridică română, cu sediul în Cluj-Napoca, str. Gruia, nr. 28A, et. 1, ap. 118, jud. Cluj  , înregistrată la Oficiul Registrului Comerțului de pe lângă Tribunalul Cluj-Napoca cu J12/3570/2020, CUI  43267029, e-mail: $0
    Anunț - ofertă publică lansată de un Utilizator cu scopul de a fi publicată de către Mira Auction S.R.L. prin intermediul website-ului cu privire la vânzarea sau închirierea unui bun mobil sau imobil.
    Bun Imobil – properietate imobiliară care poate fi comercializată conform legii și care nu încalcă limitele impuse prin acești Termeni și Condiții.
    Bun mobil – conf.  art. 539 alin. (1) noul C. civ., prin care se arată că „bunurile pe care legea nu le consideră imobile sunt bunuri mobile”. Astfel toate bunurile care nu sunt incluse în categoria bunurilor imobile, conform art. 537 noul C. civ., sunt bunuri mobile.
    Anunț plătit sau Promovat anunț care poate fi publicat de Utilizatori pe Pagina miraauction.com  doar prin plata unei taxe către Furnizor.
    Anunț gratuit - anunț care poate fi publicat de Utilizatori pe Website fără perceperea niciunei taxe de către Furnizor.
    Operatori/administratori - persoane special instruite de către Furnizor pentru verificarea/moderarea conținutului Anunțurilor publicate de către Utilizatori pe Website.
    

    3. Proprietate intelectuală

    a. Recunoașteți și sunteți de acord că Serviciul și orice software folosit in legatura cu Serviciul ("Software") conțin informații confidențiale și de proprietate care sunt protejate prin drepturile de proprietate intelectuală și alte legi. Recunoașteți în continuare și sunteti de acord ca orice conținut din reclamele sponsorului sau informația prezentată prin intermediul Serviciului sau agențiilor de publicitate sunt protejate prin drepturi de autor, mărci comerciale, mărci de servicii, patente sau alte drepturi de proprietate și legi. Cu excepția celor autorizate în mod expres de către Mira Auction S.R.L., sunteți de acord să nu modificați, închiriați, împrumutați, vindeți, distribui sau crea lucrări derivate pe baza Serviciului sau a Software-lui, în totalitate sau în parte.
    b. Fără a se limita la clauza 3.a), miraauction.com și logo-ul este marca noastră inregistrată. Nu aveți dreptul să utilizați sau să il copiați fără acordul nostru scris prealabil.
    c. Serviciul poate furniza, sau terțe părti pot furniza, link-uri către alte site-uri sau alte resurse. Deoarece Mira Auction SRL nu are nici un control asupra acestor site-uri și resurse, sunteți de acord că Mira Auction SRL nu este responsabilă pentru disponibilitatea acestor site-uri sau resurse externe, si nu garantează si nu este responsabilă sau raspunzătoare pentru orice conținut, publicitate, produse, sau alte materiale de pe sau disponibile pe aceste site-uri sau resurse. Mai mult, recunoasteți si sunteți de acord ca Mira Auction SRL nu va fi responsabilă sau răspunzătoare, direct sau indirect, pentru orice pagubă sau pierdere cauzată sau presupusă a fi fost cauzată de sau în legatură cu folosirea sau încrederea în orice astfel de conținut, bunuri sau servicii disponibile pe sau prin intermediul orice astfel de site-uri sau resurse.
    Detaliile cu privire la proprietățile disponibile pe acest site sunt furnizate nouă de către proprietari privați, agenții imobiliare și alte terțe părți doar pentru a va informa pe dumneavoastră. Noi nu putem verifica aceste detalii și, prin urmare, nu putem asigura nici o garanție cu privire la acuratețea sau integralitatea lor. Dacă vă bazați pe aceste detalii, faceți acest lucru pe propria dumneavoastră răspundere.
    
    4. Obligațiile și conduita dumneavoastră

    Întreaga răspundere civilă și penală pentru informațiile publicate și/sau transmise în Internet de către utilizator, precum și pentru consecințele care pot apărea în urma acestor activități, revine în exclusivitate utilizatorului.
    

    5. Utilizatorul are dreptul de a beneficia în întregime de Servicii, atât timp cât nu se constată o încalcare a prevederilor Termenilor și Condițiilor.

    5.1. Utilizatorul își asumă răspunderea pentru conținutul Anunțurilor publicate și declară că acestea corespund cu starea reală de fapt și de drept a Produsului ce face obiectul Anunțului și că nu încalcă drepturile Furnizorului și/sau ale unor terțe persoane.
    5.2. Utilizatorul declară pe propria răspundere că este titularul tuturor drepturilor de proprietate intelectuală, inclusiv al drepturilor de autor în privința conținutului publicat, inclusiv fotografii și este de acord ca acesta să fie pus la dispoziția publicului de către Furnizor, pe toate și oricare dintre platformele sale online, precum și prin alte mijloace de publicitate, cum ar fi: comunicate de presă, televiziune, bannere, panouri publicitare și Internet.
    5.3. Prin completarea formularului și/sau transmiterea Anunțului prin alte mijloace către Furnizor, spre punere la dispoziția publicului prin intermediul Website-ului miraauction.com, Utilizatorul declară că este de acord în totalitate și necondiționat cu Termenii și Conditiile Furnizorului.
    5.4. Prin crearea contului și acceptarea Termenilor și Condițiile Generale, Utilizatorul încheie un Acord cu Mira Auction S.R.L.. Acordul poate fi reziliat de către Utilizator, prin ștergerea contului.
    5.5. În afara informațiilor legate de propriul Anunț, Utilizatorului îi este strict interzis să preia orice informații de pe Website în vederea republicării totale sau parțiale, sub orice formă, fără a avea acordul prealabil scris al Furnizorului în acest sens.
    

    6. Nu pot fi publicate Anunțuri care conțin link-uri către alte website-uri concurente.

    6.1. Anunțurile, fie ele active pe site sau blocate la moderare, sunt verificate de către Furnizor, iar Anunțurile care nu respectă Termenii și Condițiile Website-ului vor fi șterse de pe Website, în cel mai scurt timp posibil, iar în anumite cazuri pot fi luate măsuri de blocare a contului din care s-au publicat.
     Mira Auction S.R.L. prin intermediul Website-ului miraauction.com poate verifica conformitatea Anunțurilor cu Termenii și Condițiile Website-ului, în funcție de propriile sale criterii de prioritizare.
    6.2. Ca urmare a constatării nerespectării oricăror dintre prevederile prezentului document de către oricare dintre Anunțurile publicate pe Website (inclusiv cele plătite pentru a fi promovate sau plătite deoarece depășesc numărul de Anunțuri gratuite permise în anumite categorii), Operatorii Furnizorului vor avea dreptul, în functie de situația concretă, de la caz la caz:
    fie să modifice parțial Anunțul, în măsura în care acest lucru este posibil, astfel încât să se evite ștergerea Anunțului respectiv de pe Website și să se îndrepte erorile sau inadvertențele constatate;
    fie să se întrerupă continuitatea publicării Anunțului, prin refuzarea lui. Refuzarea Anunțului înseamnă dezactivarea postării acestuia de pe Website.
    6.3. În cazul refuzării Anunţului şi ștergerii acestuia de pe Platforma miraauction.com, Furnizorul va notifica Utilizatorii profesioniști, cel târziu în momentul în care blocarea devine efectivă, sub forma unui e-mail, despre motivele refuzului, indicând conținutul blocat, motivele deciziei de blocare (inclusiv conținutul rapoartelor de abuz după anonimizarea acestora, dacă legea impune astfel), cu indicarea dispozițiilor legale sau contractuale încălcate.
    Ne rezervăm dreptul de a bloca accesul la acest Site, în mod permanent sau temporar, la discreția noastră. Orice astfel de utilizatori vor fi notificați și nu trebuie apoi sa încercați să utilizați acest site sub orice alt nume sau prin orice alt utilizator.
    

    7. Garantarea

    a. În timp ce ne străduim sa asiguram că orice material disponibil pentru descărcare de pe acest site nu este contaminat în nici un fel, nu garantăm că aceste materiale vor fi liber de infecții, virusi etc.
    b. Datorită naturii de software și internet, nu garantăm că accesul la, sau rularea acestui site va fi neîntreruptă sau fără erori. Noi nu vom fi raspunzatori dacă nu putem procesa detaliile din cauza unor circumstanțe dincolo de controlul nostru rezonabil.
    c. Informațiile furnizate pe acest site sunt de interes general și nu constituie recomandări specifice. În timp ce ne asigurăm că informațiile de pe site sunt corecte, complete și la zi nu vom face nici o garanție că acesta este cazul.
    d. Nu facem nici o garanție că Site-ul sau informațiile disponibile pe Site respectă, alte legi decât cele din Romania.
    

    8. Răspundere

    a. Nu vom fi raspunzatori pentru orice defecțiuni cauzate de software, erori ale internetului sau orice alte circumstanțe dincolo de controlul nostru rezonabil.
    b. S-ar putea pune în aplicare sisteme pentru a împiedica programele automate utilizate pentru a obține accesul neautorizat la sistemul nostru si a acestui site. Nu aveti voie să folositi programe automate pentru astfel de scopuri și orice astfel de utilizare sau încercarea de utilizare de catre dumneavoastra a acestor programe automate se face pe propriul risc. Nu vom fi responsabili pentru orice consecința care decurge din sau în legătură cu orice astfel de utilizare sau încercarea de a utiliza programe automate pentru a obține accesul neautorizat la sistemul nostru sau a acestui site.
    c. Nu vom accepta nici o responsabilitate pentru orice pierderi suferite ca urmare a utilizării acestui site sau bazarea pe orice informații furnizate pe acest site.
    d. Nu vom fi răspunzători față de dumneavoastră pentru:
    pierderea de profit;
    deteriorări sau alte pagube ascunse de către proprietar cu intenție sau fără intenție
    pierderea de afaceri;
    pierderea reputatiei;
    epuizarea fondului comercial;
     pierderea, deteriorarea sau coruperea datelor.
    

     9. Relațiile cu agenții de publicitate și agenți imobiliari

    Ințelegerile cu, sau participarea în promoții ale, agențiilor de publicitate și a agențiilor imobiliare găsite pe Site sau prin intermediul Serviciului, inclusiv plată și condițiile de livrare de servicii, si orice alți termeni si condiții, garanții sunt doar între dumneavoastră și acele agenții de publicitate sau agenții imobiliare. Sunteți de acord că Mira Auction S.R.L. nu va fi responsabilă sau răspunzătoare pentru orice pierdere sau prejudiciu de orice fel, survenite în rezultatul unor relații sau ca urmare a prezenței unor astfel de agenții de publicitate și agenții imobiliare de pe serviciu.
    

    10. Jurisdicție

    Legea Română se aplică acestor termeni si condiții. Sunteți irevocabil de acord că instanțele din România vor avea jurisdicție exclusivă de a soluționa orice litigiu care ar putea apărea din, în sau în legătură cu acești termeni și condiții.
    

    11. Notificări

    Toate notificările vor fi trimise către noi  prin poștă la adresa Mira Auction SRL, Cluj Napoca, str. Gruia, Nr. 28A, ap 118, Jud. Cluj și prin e-mail la adresa $0
    

    12. Revânzare a serviciilor

    Sunteți de acord să nu reproduceți, duplica, copia, vinde, revinde sau exploata pentru orice scop comercial, orice porțiune a Serviciului, folosirea Serviciului, sau accesul la serviciu.
    

    13. Înregistrarea și contul de utilizator

    Înregistrarea este gratuită. Odată cu înregistrarea, utilizatorul și Mira Auction S.R.L.  încheie un contract având ca obiect utilizarea secțiunii publice a paginii web miraauction.com (denumit in continuare "contract de utilizare"). Un utilizator nu are nicio pretenție pentru încheierea contractului de utilizare.
     Se pot înregistra numai persoanele juridice și persoanele fizice care dețin capacitatea deplină de a încheia acte juridice. In special, se interzice înregistrarea minorilor.
     Informațiile pe care trebuie să le furnizeze un participant în timpul înregistrării trebuie să fie complete și corecte. Inregistrarea necesită o adresă de e-mail validă. Utilizatorul poate să aleagă o parolă personală care trebuie păstrată secretă și nu trebuie dezvăluită terților.
     In cazul în care informațiile furnizate în timpul înregistrării se modifică ulterior, utilizatorul este obligat să actualizeze prompt respectivele informații conținute în contul de utilizator.
     Fiecare participant se poate înregistra o singură dată pe pagina web miraauction.com. Este interzisă înregistrarea simultană a mai mult de un cont de utilizator. Nu se permite eludarea acestei prevederi. Contul de utilizator nu este transferabil.
     Mira Auction S.R.L.  nu este raspunzătoare pentru informațiile eronate introduse de utilizatori, pentru lipsa capacității depline de a încheia acte juridice și, în general, pentru orice informație introdusă de utilizator și care nu corespunde realității.
     
    Datele personale
      Date personale înseamnă orice informație care poate fi legată de o persoană fizică identificată sau identificabilă (persoana subiect). Datele personale includ toate tipurile de informații directe sau indirecte (și anume utilizate în legătură cu alte date) ce se referă la persoana subiect, cum ar fi nume, prenume, adrese de e-mail, numere de telefon, etc., FARA  Cod Numeric Personal ( C.N.P.).
     
    Utilizarea datelor personale
             Vom utiliza datele personale numai pentru scopul în care au fost colectate și vom stoca datele numai atât cât este necesar pentru acel scop. Vom păstra informațiile clientului pentru perioada în care contul său este activ, pentru a îi oferi servicii, pentru a fi conforme cu obligațiile noastre legale sau pentru oricare din scopurile menționate mai sus. Accesul la datele personale este strict limitat personalului Mira Auction S.R.L. și asociaților care dețin autorizația necesară, contractuală și nevoia clar definită pentru utilizarea datelor, doar cu acordul și la solicitarea utilizatorului.
     

    15. Aspecte Generale

    Un Anunț va fi creat prin completarea unui formular, disponibil pe Website, care să conțină:
    descrierea reală a produsului care face obiectul Anunțului;
    prețul.
    Un Anunț NU poate să conțină, fără acordul Furnizorului, în Detalii, Titlu, Nume, Poze, următoarele:
    numere de telefon
    link-uri
    adrese de email
    Nu este permisă publicarea mai multor anunțuri pentru aceeași proprietate.
    Prin trimiterea Anunțului spre publicare către Furnizor, Utilizatorul declară că este de acord în totalitate și necondiționat cu prevederile acestor Termeni și Condiții, fiind pe deplin de acord că, în caz de nerespectare a acestora, să fie tras la raspundere conform prevederilor acestora. Utilizatorul își asumă în intregime răspunderea pentru conținutul Anunțului și pentru veridicitatea informațiilor publicate în Anunț și/sau comunicate Furnizorului.
    Anunțurile acceptate sunt doar cele ce prezintă vânzări sau închirieri de bunuri mobile sau imobile.
    Furnizorul își rezervă dreptul de a modifica sau șterge acele anunțuri ce nu respectă în totalitate acești Termeni și Condiții, cu sau fără notificarea Utilizatorului.
    Valabilitatea unui Anunț pe site este de 60 de zile pentru Vânzare și de 20 zile pentru Închiriere, cu posibilitatea de prelungire la sfârșitul acestui termen.
    Serviciile respectă legislația privind protecția consumatorului conform O.G. 21/1992, ale Legii 363/2002 privind comerțul electronic și O.G. 130/2000 privind protecția consumatorilor la încheierea și executarea contractelor la distanță.
    Serviciul de "Adaugă anunț" este destinat în totalitate proprietarilor de bunuri mobile sau imobile. Agențiile imobiliare și ansamblurile imobiliare nu au dreptul de a adăuga anunțuri imobiliare folosind acest serviciu decât prin acordul în scris al Furnizorului. În cazul în care Furnizorul constată nereguli în acest sens, Furnizorul își rezervă dreptul de a șterge toate aceste anunțuri fără notificarea în prealabil a utilizatorului. Furnizorul își rezervă dreptul de a limita numărul de anunțuri ce pot fi adăugate de către utilizatorii site-ului.
    
    
    16. Plată prin transfer bancar/depunere numerar la bancă

    Se poate face plata prin transfer bancar sau depunere numerar la bancă în contul nr. RO80BTRLRONCRT0571849301.
    Toate documentele legate de Tranzacții vor fi păstrate de către Mira Auction S.R.L. pe o perioadă nedeterminată în funcție de necesitate. Comisioane, Taxe, Plăți:
    Nu este perceput nici un comision suplimentar pentru tranzacții.
    
    
    17. Site-uri externe

    Mira Auction  S.R.L. nu este responsabilă pentru conținutul site-urilor de internet externe.
    
    
    18. Încălcarea

    Vă rugăm să raportați orice încălcare a Termenilor la echipa noastră de asistență pentru clienți prin intermediul secțiunii de contact.
    `,
    loginEmailAndPasswordError: "Autentificarea nu a putut fi realizată, email-ul și/sau parola sunt greșite, te rugăm să încerci din nou!",
    accountType: "Tipul contului",
    individual: "Persoană fizică",
    legalIdentity: "Persoană juridică",
    passwordMustBeValid: "Parola ar trebui să conțină cel puțin 8 caractere, o literă mică, o literă mare și un număr",
    confirmPasswordRequired: "Parola de confirmare este necesară",
    confirmPasswordMustBeValid: "Parola trebuie sa fie similară cu parola de confirmare",
    firstNameRequired: "Prenumele este necesar",
    lastNameRequired: "Numele este necesr",
    companyNameRequired: "Denumirea companiei este necesară",
    phoneNumberRequired: "Numărul de telefon este necesar",
    phoneNumberMustBeValid: "Numărul de telefon trebuie să conțină exact 10 cifre",
    personalIdentificationNumberRequired: "Codul unic personal este necesar",
    uniqueIdentificationCodeRequired: "Codul unic de identificare este necesar",
    addressRequired: "Adresa este necesară",
    confirmationPassword: "Confirmare parolă",
    firstName: "Prenume",
    lastName: "Nume",
    delete: "Șterge",
    phoneNumber: "Număr de telefon",
    companyName: "Numele companiei",
    personalIdentificationNumber: "Codul unic personal",
    uniqueIdentificationCode: "Codul unic de identificare",
    address: "Adresă",
    emailTaken: "Această adresă de email este deja înregistrată, loghează-te în contul existent sau alege o altă adresă de email!",
    somethingWentWrong: "Eroare neașteptată, te rugăm să mai încerci, iar dacă eroarea persistă, să ne contactezi!",
    fillInFieldsToUseAccount: "Pentru a folosi contul tău pe platforma noastră, te rugăm să completezi toate câmpurile!",
    personalIndentificationNumberMustBeValid: "Codul unic personal nu este valid",
    agreeToTermsAndConditions: "Am citit și sunt de acord cu termenii și condițiile.",
    logout: "Delogare",
    operations: "Operațiuni",
    userId: "ID-ul utilizatorului",
    settings: "Setări",
    notifications: "Notificări",
    adminPage: "Pagina de admin",
    noBidsForPost: "Nu există licitări pentru această postare",
    changeLanguageTo: "Schimbă limba în engleză",
    remainLoggedIn: "Doresc să rămân logat",
    verificationEmailSent: "Un email de verificare a fost trimis către adresa ta de email, pentru a verifica celălalte date ale contului, prima dată te rugăm sa accesezi link-ul din email-ul trimis!",
    resetPassword: "Resetare parolă",
    sendResetPasswordEmail: "Trimite email-ul de resetare a parolei",
    resetPasswordDetails: "Prin resetarea parolei, urmează sa primești un email care conține un link de resetare și prin accesarea acestuia, o să poți seta o nouă parolă. Parola curentă nu va fi modificată, numai după ce ai salvat parola nouă.",
    emailNotFound: "Email-ul pe care l-ai scris nu este asociat cu niciun cont!",
    resetEmailSent: "Email-ul pentru resetarea parolei a fost trimis cu succes, verifică-ți mesajele primite.",
    accountDetails: "Detaliile contului",
    cancel: "Anulare",
    save: "Salvare",
    currentPassword: "Parola curentă",
    bidFrom: "Licitează de la",
    price: "Preț",
    verified: "Verificat",
    notVerified: "Neverificat",
    searchForIdNameDescription: "Caută după ID, nume sau descriere",
    filters: "Filtre",
    all: "Toate",
    noPostMatchesSelectedFilters: "Nicio postare nu corespunde căutarii sau filtrelor selectate.",
    clearFilters: "Șterge filtrele",
    addPost: "Adaugă o postare",
    areYouSure: "Ești sigur?",
    closingPageAwareness: "Prin închiderea paginii, toate datele scrise care nu sunt salvate se vor pierde.",
    deletePostAwareness: "Această operațiune nu poate fi anulată",
    closeWithoutSaving: "Închide fără a salva",
    selectACategory: "Selectează o categorie",
    postTitle: "Titlul postării",
    romanianTitle: "Titlu în română",
    englishTitle: "Titlu în engleză",
    postDescription: "Descrierea postării",
    romanianDescription: "Descriere în română",
    englishDescription: "Descriere în engleză",
    selectExactLocation: "Selectează locația exactă",
    overview: "Rezumat",
    users: "Utilizatori",
    actions: "Acțiuni",
    active: 'Activ',
    banned: "Blocat",
    viewDetails: "Vezi detalii",
    verify: "Verifică",
    unverify: "Revocă verificarea",
    ban: "Blochează",
    unban: "Deblochează",
    userType: "Tipul utilizatorului",
    noUsers: "Nu există niciun utilizator",
    search: "Caută",
    confirmLocation: "Confirmă locația",
    mainImage: "Imaginea principală",
    chooseMainImage: "Alege imaginea principală",
    presentationImages: "Imaginile de prezentare (minim $0, maxim $1)",
    chooseImages: "Selectează imaginile",
    clearImages: "Șterge imaginile",
    characteristics: "Caracteristici",
    typeANumber: "Scrie un număr",
    selectAValue: "Selectează o valoare",
    thePrice: "Prețul",
    enableBiddingForThisPost: "Activează licitarea pentru această postare",
    startingPrice: "Preț de pornire",
    biddingEndDate: "Data de încheiere a licitației",
    addPostError: "Pentru a adăuga postarea, toate câmpurile trebuie comletate.",
    addThePost: "Adăugă postarea",
    updateThePost: "Actualizează postarea",
    updatePostError: "Pentru a actualiza postarea, toate câmpurile trebuie comletate.",
    daysLeft: "Zile rămase",
    hoursLeft: "Ore rămase",
    minutesLeft: "Minute rămase",
    lessThanOneMinute: "Mai puțin de un minut",
    sales: "Vânzări",
    bids: "Licitații",
    disable: "Dezactivează",
    activate: "Activează",
    deactivate: "Dezactivează",
    edit: "Editează",
    archive: "Arhivează",
    unarchive: "Dezarhivează",
    copyAuthorId: "Copiază ID-ul autorului",
    noPostFoundWithId: "Nicio postare nu a fost găsită cu ID-ul",
    offer: "Oferă",
    thePostIsAvailableUntil: "Postarea este valabilă până pe data de",
    totalBidsMadeForThisPost: "Numărul total de oferte făcute pentru această postare",
    youMustBeLoggedInToPLaceAnOffer: "Trebuie să fii autentificat în cont pentru a putea face o ofertă!",
    forMoreDetailsCallUs: "Pentru mai multe detalii, contactează-ne la numărul de mai jos",
    description: "Descriere",
    noCharacteristicsFoundForTheSearchedKeywords: "Nu a fost găsită nicio caracteristică pentru cuvintele cheie căutate.",
    yes: "Da",
    no: "Nu",
    biddingHasEnded: "Licitația pentru acestă postare a luat sfârșit. Vizionează postări similare în categoria",
    expired: "Expirat",
    inactive: "Inactiv",
    showZone: "Arată zona",
    hideZone: "Ascunde zona",
    noCategories: "Nu există categorii",
    posts: "Postări",
    categories: "Categorii",
    reviews: "Recenzii",
    name: "Nume",
    characteristicsNumber: "Numărul caracteristicilor",
    productsNumber: "Numărul produselor",
    createDate: "Data creării",
    status: "Status",
    accountIsBanned: "Contul este blocat",
    yourAccountIsBannedAndYouCannotUse: "Contul tău este blocat și nu poți folosi",
    reason: "Motiv",
    ifYouThinkThisIsAMistake: "Dacă consideri că aceasta este o greșeală sau ai nevoie de informații suplimentare, contactează-ne prin telefon",
    orEmail: "Sau email",
    unknown: "Necunoscut",
    askingPrice: "Prețul cerut",
    typeAnOffer: "Scrie o ofertă",
    send: "Trimite",
    house: "Casă",
    constructionYear: "Anul construcției",
    renovationYear: "Anul renovării",
    separated: "Decomandat",
    semiSeparated: "Semidecomandat",
    studio: "Garsonieră",
    circular: "Circular",
    duplex: "Duplex",
    partitioning: "Compartimentare",
    floor: "Etaj",
    semiBasement: "Demisol",
    groundFloor: "Parter",
    totalStories: "Total etaje",
    numberOfRooms: "Numărul camerelor",
    numberOfBathrooms: "Numărul băilor",
    numberOfBathroomsWithWindow: "Numărul bailor cu geam",
    numberOfBalconies: "Numărul balcoanelor",
    balconiesType: "Tipul balcoanelor",
    purpose: "Scop",
    usableArea: "Suprafața utilă",
    housing: "Locuință",
    office: "Birou",
    commercialSpace: "Spațiu comercial",
    without: "Fără",
    interior: "Interior",
    exterior: "Exterior",
    terrace: "Terasă",
    numberOfBedrooms: "Numărul dormitoarelor",
    joinDate: "Data înregistrării",
    userBiddings: "Licitațiile utilizatorului",
    productId: "ID-ul produsului",
    value: "Valoare",
    bidDate: "Data licitării",
    viewUserPosts: "Vezi postările utilizatorului",
    copy: "Copiază",
    generalInformation: "Informații generale",
    userDetails: "Detaliile utilizatorului",
    seePost: "Vezi postarea",
    noBidsForUser: "Acest utilizator nu a licitat încă",
    menu: "Meniu",
    closeMenu: "Închide meniu",
    back: "Înapoi",
    landingPageHeroHeading: "Hai să descoperim împreună exact ceea ce ai nevoie!",
    landingPageHeroSubtitle: "Noi te ajutăm să cumperi și să vinzi la un preț corect",
    buy: "Cumpără",
    sell: "Vinde",
    rent: "Închiriază",
    landingIntroductionTitle: "Mira Auction, pentru o viață fericită",
    ourHelpHeader: "Cum te putem ajuta noi",
    buyingAHomeTitle: "Cumperi o casă?",
    buyingAHomeDescription: "Avem peste $0 de postări de vânzare pe site-ul nostru",
    sellingAHomeTitle: "Vinzi o casă?",
    sellingAHomeDescription: "Îți putem garanta că vei obține un preț corect",
    rentingAHomeTitle: "Cauți o casă de închiriat?",
    rentingAHomeDescription: "Găsirea unei case de închiriat nu a fost niciodată mai ușoară",
    guideHeader: "Cum să",
    guideBid: "Licitez",
    guideSellRent: "Vând / Închiriez",
    guideBidSubtitle: "(Fără taxe de licitare)",
    guideFirstStep: "Asigură-te că ești logat",
    guideBidStep2: "Alege-ți casa",
    guideBidStep3: "Începe să licitezi",
    guideBidStep4: "Ai câștigat? Așteaptă să te contactăm!",
    guideLastStep: "Semnează contractul!",
    guideSellRentStep2: "Alege între a vinde și a închiria",
    guideSellRentStep3: "Alege prețul dorit",
    guideSellRentStep4: "Monitorizează ofertele",
    introductionHeader: "Mira Auction, pentru o viață fericită",
    introductionText: "Mira Auction își propune să vină în sprijinul tău cu o gamă largă de locuințe, atât rezidențiale, cât și comerciale. Având o experiență vastă în acest domeniu și cunoscând faptul că pentru alegerea unei locuințe consumăm o cantitate enormă de energie, ne dorim să venim în ajutorul tău prin facilitarea procesului de căutare a locuinței perfecte. Știm că uneori alegerea unei locuințe este un eveniment important, de aceea echipa noastră îți vine în sprijin pentru a face alegerea potrivită la o valoare de piață corectă. Licitează cu încredere. Ai câștigat licitația? Felicitări! Echipa noastră îți va face legătura cu proprietarul. Te-ai răzgândit? Nu este nimic, suntem siguri ca următorul ofertant se va bucura că i se mai acordă încă o șansă.",
    guideSellRentStep5: "Ești satisfăcut cu oferta obținută? Felicitări!",
    guideBidDisclaimer: "Înainte de a afla datele vânzătorului, va trebui să semnezi un contract de intermediere imobiliară cu Mira Auction. Conform contractului, Mira Auction va primi un comision de intermediere în valoare de 1% din oferta acceptată. Dacă dorești, echipa Mira Auction poate fi prezentă în momentul semnării contractului între cumpărător și vânzător, în prezența notarului public.",
    whyChooseUsHeader: "De ce să ne alegi pe noi?",
    whyChooseUsOption1Header: "Ușor de utilizat",
    whyChooseUsOption2Header: "Suport 24/7",
    whyChooseUsOption3Header: "Expertiză profesională",
    whyChooseUsOption1Text: "Interfața noastră simplă dar concisă a fost gândită pentru ușurința folosirii",
    whyChooseUsOption2Text: "Echipa noastră stă la dispoziția ta oricând, deci nu fi timid, sună-ne.",
    whyChooseUsOption3Text: "Postările noastre verificate îți oferă certitudinea ca ceea ce vezi este adevărat",
    ourTeamHeader: "Echipa noastră",
    customersReviewsHeader: "Recenziile clienților",
    contactUsHeader: "Contactează-ne",
    contactUs: "Contactează-ne",
    activateAdminRole: "Activează rolul de administrator",
    deactivateAdminRole: "Dezactivează rolul de administrator",
    copyId: "Copiază ID-ul",
    user: "Utilizator",
    admin: "Administrator",
    superAdmin: "Super Administrator",
    applyFilters: "Aplicați filtrele",
    county: "Județ",
    city: "Oraș",
    zone: "Zona",
    congratulationsForMoreDetailsCallUs: "Felicitări! Ești licitatorul cu cea mai mare ofertă pentru acestă postare și ai 8 zile la dispoziție să te decizi asupra achiziției. Pentru mai multe detalii, contactează-ne la numărul de mai jos:",
    thisPostIsNotAvailableAnymore: "Această postare nu mai e valabilă, pentru postări similare vizitează categoria",
    provideBanReason: "Oferă un motiv pentru blocarea acestui utilizator",
    banReasonInRomanian: "Scrie un motiv în română",
    banReasonInEnglish: "Scrie un motiv în engleză",
    parkingSpace: "Loc de parcare",
    hasElevator: "Dispune de lift",
    elevator: "Lift",
    forRent: "De închiriat",
    petFriendly: "Cu animale de companie",
    latitude: "Latitudine",
    longitude: "Longitudine",
    notSet: "Nesetat",
    pickLocation: "Alege locația",
    home: "Acasă",
    myPosts: "Postările mele",
    sort: "Ordonează",
    ascendingDate: "Crescător după dată",
    descendingDate: "Decrescător după dată",
    ascendingPrice: "Crescător după preț",
    descendingPrice: "Decrescător după preț",
    youtubeVideoLink: "Link video YouTube",
    youtubeUrl: "URL YouTube",
    videoPresentation: "Prezentare video",
    schedule: "Program",
    mondayToSaturday: "Luni - Sâmbătă",
    officeMiraAuction: "Birou Mira Auction",
    searchByUserId: "Caută după ID-ul utilizatorului",
    noBidsForUserId: "Nu există licitări cu ID-ul utilizatorului: $0",
    addPostForRealEste: "Adaugă o postare pentru imobiliare",
    addPostForRenting: "Adaugă o postare pentru chirii",
    editThePost: "Modifică postarea: $0",
    roundmeUrl: "URL Roundme",
    roundmePanoramaLink: "Link panorama Roundme",
    panoramicView: "Vedere panoramică",
    sold: "Vândut",
    changeLanguage: "Schimbă limba",
    siteIsUnderMaintenance: "Ne cerem scuze pentru inconvenienţă, site-ul web $0 se află în mentenanță.",
    siteMaintenanceTimeframe: "Vom reveni în curând, echipa noastră tehnică lucrează din greu să imbunătățescă experiența ta. Nefuncționarea este planificată să aibă loc de la $0 până la $1.",
    maintenanceContact: "Dacă aveți vreo problemă, nu ezitați să ne contactați la numărul de telefon: $0 sau email: $1.",
    extendBiddingDeadlines: "Extinde finalul licitărilor",
    extend: "Extinde",
    extendAllPostsBiddingsThatWillExpire: "Extinde toate licitările postărilor care vor expira în următoarele",
    daysUntil: "Zi(le) până pe",
    abarth: "Abarth",
    aixam: "Aixam",
    alfaRomeo: "Alfa Romeo",
    other: "Altul",
    aro: "Aro",
    astonMartin: "Aston Martin",
    audi: "Audi",
    bentley: "Bentley",
    bmw: "BMW",
    buick: "Buick",
    cadillac: "Cadillac",
    chevrolet: "Chevrolet",
    chrysler: "Chrysler",
    citroen: "Citroen",
    cupra: "Cupra",
    dacia: "Dacia",
    daewoo: "Daewoo",
    daihatsu: "Daihatsu",
    dodge: "Dodge",
    ds: "DS",
    ferrari: "Ferrari",
    fiat: "Fiat",
    ford: "Ford",
    gmc: "GMC",
    honda: "Honda",
    hummer: "Hummer",
    hyundai: "Hyundai",
    infiniti: "Infiniti",
    isuzu: "Isuzu",
    iveco: "Iveco",
    jaguar: "Jaguar",
    jeep: "Jeep",
    kia: "Kia",
    lada: "Lada",
    lamborghini: "Lamborghini",
    lancia: "Lancia",
    landRover: "Land Rover",
    lexus: "Lexus",
    lincoln: "Lincoln",
    maserati: "Maserati",
    maybach: "Maybach",
    mazda: "Mazda",
    mclaren: "McLaren",
    mercedesBenz: "Mercedes-Benz",
    mini: "Mini",
    mitsubishi: "Mitsubishi",
    nissan: "Nissan",
    oldsmobile: "Oldsmobile",
    opel: "Opel",
    peugeot: "Peugeot",
    pontiac: "Pontiac",
    porsche: "Porsche",
    renault: "Renault",
    rollsRoyce: "Rolls-Royce",
    rover: "Rover",
    saab: "Saab",
    saturn: "Saturn",
    seat: "Seat",
    skoda: "Skoda",
    smart: "Smart",
    ssangyong: "SsangYong",
    subaru: "Subaru",
    suzuki: "Suzuki",
    tata: "Tata",
    tesla: "Tesla",
    toyota: "Toyota",
    volkswagen: "Volkswagen",
    volvo: "Volvo",
    wartburg: "Wartburg",
    used: "Utilizat",
    new: "Nou",
    petrol: "Benzină",
    diesel: "Diesel",
    lpg: "GPL",
    electric: "Electric",
    cabrio: "Cabrio",
    berline: "Berlină",
    coupe: "Coupe",
    pickup: "Pickup",
    hatchback: "Hatchback",
    break: "Break",
    offRoad: "Off road",
    minibus: "Minibus",
    monovolum: "Monovolum",
    suv: "SUV",
    white: "Alb",
    black: "Negru",
    gray: "Gri",
    silver: "Argintiu",
    red: "Roșu",
    blue: "Albastru",
    brown: "Maro",
    green: "Verde",
    beige: "Bej",
    orange: "Portocaliu",
    gold: "Auriu",
    yellow: "Galben",
    purple: "Mov",
    manual: "Manuală",
    automatic: "Automată",
    private: "Privat",
    firm: "Firmă",
    brand: "Marcă",
    model: "Model",
    fabricationYear: "Anul fabricației",
    mileage: "Rulaj",
    engineCapacity: "Capacitate motor",
    condition: "Stare",
    fuel: "Combustibil",
    body: "Caroserie",
    color: "Culoare",
    gearbox: "Cutie de viteze",
    privateOrFirm: "Privat/firmă",
    addPostForVehicles: "Adaugă o postare pentru autovehicule",
    partners: "Parteneri",
    buyingCreditOption: "Acest vehicul se poate achiziționa și în rate prin intermediarul",
    loanOptionReminder: "Vă ajutăm pentru intermedierea oricărui tip de credite, fără comision.",
    today: "Astăzi",
    yesterday: "Ieri",
    bidOn: "a licitat pe",
    yourPost: "postarea ta",
    aPost: "o postare",
    seeBidder: "Vezi licitatorul",
    seeAuthor: "Vezi autorul",
    noNotificationsYet: "Nicio notificare inca 💤",
    notificationsSound: "Sunet pentru notificari"
};
