import React from "react";
import { RealEstateListStore } from "../real-estate-list-store";
import { observer } from "mobx-react";
import { RangePicker } from "components";
import { useTranslation } from "infrastructure/hooks";

interface Props {
    store: RealEstateListStore;
}

const FilterBathroomsWithWindow = ({ store }: Props) => {
    const { getString } = useTranslation();

    const { rangeBathroomsWithWindow, setBathroomsWithWindowRange } = store;

    return (
        <RangePicker 
            minValue={rangeBathroomsWithWindow.min}
            maxValue={rangeBathroomsWithWindow.max}
            min={rangeBathroomsWithWindow.lowest}
            max={rangeBathroomsWithWindow.greatest}
            label={getString("numberOfBathroomsWithWindow")}
            useAction
            onChangeMin={min => rangeBathroomsWithWindow.min = min}
            onChangeMax={max => rangeBathroomsWithWindow.max = max}
            onChangeMinMax={setBathroomsWithWindowRange} />
    );
}

export default observer(FilterBathroomsWithWindow);