import React, { useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import Navigation from "./components/admin-navigation";
import Users from "./views/users";
import Posts from "./views/posts";
import sharedStyles from "./admin.module.scss";
import { useNavigation } from "utils/router-utils";
import { observer } from "mobx-react";
import { loadingService, useUserDetails } from "infrastructure/services";

const Admin = () => {
    const { goHome } = useNavigation();
    const { isInitialized, isUserAdmin } = useUserDetails();

    useEffect(() => {
        loadingService.set(!isInitialized);
    }, [isInitialized]);

    if (isInitialized && !isUserAdmin) {
        goHome();
    }

    return (
        <div className={sharedStyles.adminWrapper}>
            <Navigation />
            <div className={sharedStyles.panels}>
                <Routes>
                    <Route path="/users" element={<Users />} />
                    <Route path="/posts" element={<Posts />} />
                </Routes>
            </div>
        </div>
    );
}

export default observer(Admin);
