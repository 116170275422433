import { makeAutoObservable, runInAction, toJS } from "mobx";
import { createContext } from "react";
import { createBid, getRealtimeBidsForPost } from "database/api/bid";
import { setPost } from "database/api/post";
import { getAllUsers, isAdmin } from "database/api/user";
import { auth } from "database/firebase";
import { Bid, User } from "database/types";
import { getMomentFromTime, hasPassedMoment } from "utils/date-utils";
import { PostDetailsStoreType } from "../../types";
import { getPostWithText } from "../api";
import { VehiclePost } from "../types";
import { loadingService, notificationService } from "infrastructure/services";

export class VehiclesDetailsStore implements PostDetailsStoreType {
    public isVehicleEditVisible: boolean = false;
    public user: User | null = null;
    public post: VehiclePost | null = null;
    public searchCharacteristics: string = "";
    public areBidsLoading: boolean = true;
    public bids: Bid[] = [];
    private unsubscribe?: () => void;

    constructor() {
        makeAutoObservable(this);
    }

    public setPost = (post: VehiclePost) => this.post = post;

    public fetchPost = async (postId: string) => {
        loadingService.set(true);
        const post = await getPostWithText(postId) as VehiclePost;

        if (!post) {
            loadingService.set(false);
            return;
        }

        const users = await getAllUsers();
        const user = users.find(user => user.id === auth.getCurrentUser()?.uid);

        if (post.isDeleted && !isAdmin(user)) {
            runInAction(() => {
                this.user = user || null;
            });
            loadingService.set(false);
            return;
        }

        if (!this.unsubscribe) {
            this.unsubscribe = getRealtimeBidsForPost(postId, bids => runInAction(() => {
                this.bids = isAdmin(user) ? bids : bids.map(bid => ({
                    ...bid,
                    byUser: users.find(user => user.id === bid.byUserId)
                }));

                this.areBidsLoading = false;
            }));
        }

        runInAction(() => {
            this.user = user || null;
            this.post = post;
        });
        loadingService.set(false);
    }

    public setVehicleEditVisibility = (isVisible: boolean) => this.isVehicleEditVisible = isVisible;

    public placeBid = async (bidValue: number) => {
        if (hasPassedMoment(getMomentFromTime(this.post?.biddingEndDate))) {
            return;
        }

        this.areBidsLoading = true;
        const post = toJS(this.post!);
        await createBid({
            byUserId: "",
            forPostId: this.post!.id,
            id: "",
            time: new Date(),
            value: bidValue,
            forPost: this.post!
        });
        notificationService.pushBiddingNotification(post, bidValue);
    }

    public setActive = async (isActive: boolean) => {
        if (!this.post) {
            return;
        }
        loadingService.set(true);
        await setPost({
            ...this.post,
            isActive
        });

        runInAction(() => {
            this.post!.isActive = isActive;
        });
        loadingService.set(false);
    }

    public setDeleted = async (isDeleted: boolean) => {
        if (!this.post) {
            return;
        }
        loadingService.set(true);
        await setPost({
            ...this.post,
            isDeleted
        });
        
        runInAction(() => {
            this.post!.isDeleted = isDeleted;
        });
        loadingService.set(false);
    }

    public setVerified = async (isVerified: boolean) => {
        if (!this.post) {
            return;
        }
        loadingService.set(true);
        await setPost({
            ...this.post,
            isVerified
        });
        
        runInAction(() => {
            this.post!.isVerified = isVerified;
        });
        loadingService.set(false);
    }

    public reset = () => {
        this.post = null;
        this.searchCharacteristics = "";
        this.areBidsLoading = true;
        this.bids = [];
        this.user = null;
        if (this.unsubscribe) {
            this.unsubscribe();
            this.unsubscribe = undefined;
        }
    }
}

export const vehiclesDetailsStore = new VehiclesDetailsStore();
export const VehiclesDetailsContext = createContext(vehiclesDetailsStore);