import React from "react";
import { RealEstateListStore } from "../real-estate-list-store";
import { observer } from "mobx-react";
import { RangePicker } from "components";
import { useTranslation } from "infrastructure/hooks";

interface Props {
    store: RealEstateListStore;
}

const FilterArea = ({ store }: Props) => {
    const { getString } = useTranslation();

    const { rangeArea, setAreaRange } = store;

    return (
        <RangePicker 
            minValue={rangeArea.min}
            maxValue={rangeArea.max}
            min={rangeArea.lowest}
            max={rangeArea.greatest}
            label={<>{getString("usableArea")} (m<sup>2</sup>)</>}
            useAction
            onChangeMin={min => rangeArea.min = min}
            onChangeMax={max => rangeArea.max = max}
            onChangeMinMax={setAreaRange} />
    );
}

export default observer(FilterArea);