import React, { useEffect } from "react";
import styles from "./user-biddings.module.scss";
import { useTranslation } from "infrastructure/hooks";
import { User } from "database/types";
import UserBids from "./components/user-bids";
import { includePosts } from "database/api/bid";
import { observer } from "mobx-react";
import { runInAction } from "mobx";

interface Props {
    user: User;
}

const UserBiddings = ({ user }: Props) => {

    const { getString } = useTranslation();

    useEffect(() => {
        const fetchPostForBids = async () => {
            const bids = await includePosts(user.bids!);
            
            runInAction(() => user.bids = bids);
        }

        user.bids?.length && fetchPostForBids();
    }, [user]);

    return (
        <div className={styles.userBiddings}>
            <h3>{getString("userBiddings")}</h3>
            {user.bidsIds.length ? (
                <table>
                    <thead>
                        <tr>
                            <th>{getString("productId")}</th>
                            <th>{getString("value")}</th>
                            <th>{getString("bidDate")}</th>
                            <th>{getString("actions")}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <UserBids userBids={user.bids!} />
                    </tbody>
                </table>
            ) : (
                <h3 className={styles.noBidsWarning}>{getString("noBidsForUser")}</h3>
            )}
        </div>
    )
}

export default observer(UserBiddings);