import { observer } from "mobx-react";
import React from "react";
import { RangePicker } from "components";
import { useTranslation } from "infrastructure/hooks";
import { VehiclesListStore } from "../vehicles-list-store";

interface Props {
    store: VehiclesListStore;
}

const FilterPrice = ({ store }: Props) => {
    const { getString } = useTranslation();

    const { priceRange, setPriceRange } = store;

    return (
        <RangePicker 
            minValue={priceRange.min}
            maxValue={priceRange.max}
            min={priceRange.lowest}
            step={100}
            max={priceRange.greatest}
            label={getString("price")}
            onChangeMin={min => priceRange.min = min}
            onChangeMax={max => priceRange.max = max}
            useAction
            onChangeMinMax={setPriceRange} />
    );
}

export default observer(FilterPrice);