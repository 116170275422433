import React, { useState, PropsWithChildren } from "react";
import { ElevationScroll, SingleSelect } from "components";
import {
    AppBar,
    TextField,
    InputAdornment,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions
} from "@material-ui/core";
import { useTranslation } from "infrastructure/hooks";
import {
    SearchOutlined,
    FilterListOutlined,
    PhotoAlbumOutlined,
    ImportExportOutlined
} from "@material-ui/icons";
import { observer } from "mobx-react";
import styles from "./posts-filter-bar.module.scss";
import classNames from "classnames";
import { auth } from "database/firebase";
import {
    SORT_BY_ASCENDING_DATE,
    SORT_BY_ASCENDING_PRICE,
    SORT_BY_DESCENDING_DATE,
    SORT_BY_DESCENDING_PRICE
} from "../../types";
import { TranslationStringKey } from "utils/translation/types";
import { runInAction } from "mobx";

interface Props {
    store: {
        search: string,
        sortingBy: string,
        filterMyPosts: boolean,
        clearFilters: () => void
    };
}

const PostFilterBar = ({ store, children }: PropsWithChildren<Props>) => {
    const { getString } = useTranslation();

    const [isDialogOpen, setDialogOpen] = useState(false);

    return <>
        <ElevationScroll>
            <AppBar className={styles.filterBar}>
                <div>
                    <TextField
                        color="secondary"
                        autoFocus
                        value={store.search}
                        className={styles.searchField}
                        placeholder={getString("searchForIdNameDescription")}
                        type="search"
                        onChange={event => runInAction(() => store.search = event.target.value)}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchOutlined color="secondary" />
                                </InputAdornment>
                            )
                        }} />
                    <SingleSelect
                        value={store.sortingBy}
                        values={[
                            SORT_BY_ASCENDING_DATE,
                            SORT_BY_DESCENDING_DATE,
                            SORT_BY_ASCENDING_PRICE,
                            SORT_BY_DESCENDING_PRICE
                        ]}
                        onSelect={value => runInAction(() => store.sortingBy = value)}
                        onItemRender={value => (
                            <span className={styles.whiteText}>
                                {getString(value as TranslationStringKey)}
                            </span>
                        )}
                        secondaryColor
                        startImage={<ImportExportOutlined color="secondary" />} />
                    <Button
                        className={styles.buttonFilters}
                        color="secondary"
                        startIcon={<FilterListOutlined color="secondary" />}
                        onClick={() => setDialogOpen(true)} >
                        {getString("filters")}
                    </Button>
                    {auth.getCurrentUser() && (
                        <Button
                            className={classNames(styles.myPostsButton, {
                                [styles.activeButton]: store.filterMyPosts
                            })}
                            color="secondary"
                            startIcon={
                                <PhotoAlbumOutlined
                                    color={store.filterMyPosts ? "primary" : "secondary"} />
                            }
                            variant={store.filterMyPosts ? "contained" : "text"}
                            onClick={() => 
                                runInAction(() => store.filterMyPosts = !store.filterMyPosts)
                            }>
                            {getString("myPosts")}
                        </Button>
                    )}
                </div>
            </AppBar>
        </ElevationScroll>
        <Dialog
            maxWidth="lg"
            open={isDialogOpen}
            keepMounted
            onClose={() => setDialogOpen(false)}>
            <DialogTitle>{getString("filters")}</DialogTitle>
            <DialogContent>
                <div className={styles.dialogFiltersContainer}>
                    {children}
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={store.clearFilters}>
                    {getString("clearFilters")}
                </Button>
                <Button color="primary" onClick={() => setDialogOpen(false)}>
                    {getString("applyFilters")}
                </Button>
            </DialogActions>
        </Dialog>
    </>;
}

export default observer(PostFilterBar);