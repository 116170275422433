import { EMPTY_REAL_ESTATE_POST, RealEstateFormPost, RealEstatePost } from "../real-estate/types";
import { CATEGORY_FOR_RENT } from "../../../utils/constants";

export interface ForRentPost extends RealEstatePost {
    petFriendly: boolean;
}

export interface ForRentFormPost extends RealEstateFormPost {
    petFriendly: boolean;
}

export const EMPTY_FOR_RENT_POST: ForRentFormPost = {
    ...EMPTY_REAL_ESTATE_POST,
    category: CATEGORY_FOR_RENT,
    petFriendly: false
}