import { getAllSuperAdmins } from "../user";

let superAdminIds: string[] = [];

export const getSuperAdminIds = async () => {
    if (superAdminIds.length) {
        return superAdminIds;
    }

    const superAdmins = await getAllSuperAdmins();
    return superAdminIds = superAdmins.map(user => user.id);
}