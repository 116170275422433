import { makeAutoObservable } from "mobx";
import { createContext } from "react";

export class DialogAddStore {
    public isRealEstateAddVisible: boolean = false;
    public isForRentAddVisible: boolean = false;
    public isVehiclesAddVisible: boolean = false;

    constructor() {
        makeAutoObservable(this);
    }

    public openRealEstateAdd = () => this.isRealEstateAddVisible = true;

    public closeRealEstateAdd = () => this.isRealEstateAddVisible = false;

    public openForRentAdd = () => this.isForRentAddVisible = true;

    public closeForRentAdd = () => this.isForRentAddVisible = false;

    public openVehiclesAdd = () => this.isVehiclesAddVisible = true;

    public closeVehiclesAdd = () => this.isVehiclesAddVisible = false;
}

export const dialogAddStore = new DialogAddStore();
export const DialogAddContext = createContext(dialogAddStore);
