import { RealEstatePost } from "./types";
import { CATEGORY_REAL_ESTATE } from "../../../utils/constants";
import * as PostApi from "../../../database/api/post";

const options: PostApi.PostOptions = {
    category: CATEGORY_REAL_ESTATE,
    showAvailable: true
};

export const getPost = (postId: string) =>
    PostApi.getPost(postId, CATEGORY_REAL_ESTATE) as Promise<RealEstatePost | null>;

export const getAllPosts = () =>
    PostApi.getAllPosts(options) as Promise<RealEstatePost[]>;

export const getAllPostsWithText = (posts?: RealEstatePost[]) =>
    PostApi.getAllPostsWithText(posts, options) as Promise<RealEstatePost[]>;

export const getAllPostsWithBids = (posts?: RealEstatePost[]) =>
    PostApi.getAllPostsWithBids(posts, options) as Promise<RealEstatePost[]>;

export const getPostWithText = (postId: string) => 
    PostApi.getPostWithText(postId) as Promise<RealEstatePost | null>;

export const createPost = (post: RealEstatePost, postDocument = PostApi.generatePostDocument()) =>
    PostApi.createPost(post, postDocument);