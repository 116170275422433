import React, { useCallback, useEffect, useRef, useState } from "react";
import styles from "./location-picker.module.scss";
import LocationPickerService from "location-picker";
import { useTranslation } from "infrastructure/hooks";
import classNames from "classnames";
import { BoldText } from "..";
import { Button, IconButton, Tooltip } from "@material-ui/core";
import { CheckOutlined, CloseOutlined, LocationSearchingOutlined } from "@material-ui/icons";

interface Props {
    latitude: number;
    longitude: number;
    isVisible: boolean;
    onPick: (latitude: number, longitude: number) => void;
}

let count = 0;
let locationPicker: LocationPickerService;

const LocationPicker = ({ latitude, longitude, isVisible, onPick }: Props) => {
    const { getString } = useTranslation();

    const [isChangeable, setChangeable] = useState(false);
    const counter = useRef(count++);

    const uniqueId = `${styles.locationPicker}-${counter.current}`;

    const setLocation = useCallback(() => {
        locationPicker.setLocation(latitude || 45.9432, longitude || 24.9668);
        setChangeable(false);
    }, [latitude, longitude]);

    useEffect(() => {
        if (isVisible) {
            locationPicker = new LocationPickerService(uniqueId, undefined, {
                zoom: 6
            });
        }
    }, [isVisible, uniqueId]);

    useEffect(setLocation, [setLocation]);

    const handleConfirmLocationClick = () => {
        const { lat, lng } = locationPicker.getMarkerPosition();
        onPick(lat, lng);
    }

    const isLocationSet = latitude || longitude;

    return (
        <div className={styles.mapWrapper}>
            <div
                id={uniqueId}
                className={classNames("location-picker", styles.locationPicker)} />
            <div className={classNames(styles.viewOverlay, {
                [styles.show]: !isChangeable
            })}>
                <span>
                    <BoldText className={styles.label} variant="h6">
                        {getString("latitude")}:
                    </BoldText>
                    <BoldText variant="h6" color={isLocationSet ? "primary" : "error"}>
                        {isLocationSet ? latitude : getString("notSet")}
                    </BoldText>
                </span>
                <span>
                    <BoldText className={styles.label} variant="h6">
                        {getString("longitude")}:
                    </BoldText>
                    <BoldText variant="h6" color={isLocationSet ? "primary" : "error"}>
                        {isLocationSet ? longitude : getString("notSet")}
                    </BoldText>
                </span>
                <Button
                    variant="contained"
                    color="primary"
                    startIcon={<LocationSearchingOutlined />}
                    onClick={() => setChangeable(true)}>
                    {getString("pickLocation")}
                </Button>
            </div>
            <div className={classNames(styles.editOverlay, {
                [styles.show]: isChangeable
            })}>
                <Tooltip title={getString("cancel")}>
                    <IconButton color="primary" onClick={setLocation}>
                        <CloseOutlined />
                    </IconButton>
                </Tooltip>
                <Tooltip title={getString("pickLocation")} onClick={handleConfirmLocationClick}>
                    <IconButton color="primary">
                        <CheckOutlined />
                    </IconButton>
                </Tooltip>
            </div>
        </div>
    );
}

export default LocationPicker;