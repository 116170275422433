import React, { useContext } from "react";
import "mira-auction.scss";
import { MiraAuctionContext } from "mira-auction-store";
import { useRunOnce } from "utils/ui-utils";
import { auth } from "database/firebase";
import { NavigationRoutes, UnderMaintenancePage } from "skeleton";
import { observer } from "mobx-react";

const MiraAuction = () => {
    const miraAuctionStore = useContext(MiraAuctionContext);

    const {
        maintenanceStatus,
        loadMaintenanceStatus,
        isUnderMaintenance
    } = miraAuctionStore;

    useRunOnce(() => {
        auth.onAuthStateChanged(async () => {
            if (await loadMaintenanceStatus()) {
                return;
            }
        });
    });

    return isUnderMaintenance() ? (
        <UnderMaintenancePage maintenance={maintenanceStatus!} />
    ) : (
        <NavigationRoutes />
    );
}

export default observer(MiraAuction);