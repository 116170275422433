import React from "react";
import { Bid } from "database/types";
import { formatMoment, getMomentFromTime } from "utils/date-utils";
import { useTranslation } from "infrastructure/hooks";
import { getFormattedPrice } from "utils/number-utils";
import styles from "./user-bids.module.scss";
import { sortBy } from "utils/array-utils";
import { useNavigation } from "utils/router-utils";

interface Props {
    postId: string;
    postBids: Bid[];
    postCategory: string;
}

const BidsForPost = ({ postId, postBids, postCategory }: Props) => {
    const { getString } = useTranslation();
    const { goTo } = useNavigation();

    const values: JSX.Element[] = [];
    const dates: JSX.Element[] = [];

    sortBy(postBids, bid => bid.value, true).forEach(bid => {
        values.push(
            <span key={bid.id}>
                {getFormattedPrice(bid.value)}
            </span>
        );
        dates.push(
            <span key={bid.id}>
                {formatMoment(getMomentFromTime(bid.time))}
            </span>
        );
    });

    return (
        <tr className={styles.bidsForPost} key={postId}>
            <td>{postId}</td>
            <td>{values}</td>
            <td>{dates}</td>
            <td>
                <div
                    className={styles.seePost}
                    onClick={() => goTo(`/${postCategory}/${postId}`)}
                >
                    {getString("seePost")}
                </div>
            </td>
        </tr>
    );
}

export default BidsForPost;