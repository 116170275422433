import React, { useContext } from "react";
import { Backdrop, CircularProgress } from "@material-ui/core";
import styles from "./loading-overlay.module.scss";
import { observer } from "mobx-react";
import { LoadingOverlayContext } from "./loading-overlay-store";

const LoadingOverlay = () => { 
    const { activeLoadings } = useContext(LoadingOverlayContext);
    
    return (
        <Backdrop className={styles.elevatedLoadingOverlay} open={!!activeLoadings}>
            <CircularProgress color="secondary" />
        </Backdrop>
    );
}

export default observer(LoadingOverlay);