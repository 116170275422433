import React, { useState } from "react";
import styles from "./image-picker.module.scss";
import ImageItem from "./image-item";
import { readFile } from "../../utils/image-utils";
import { fill } from "../../utils/array-utils";
import BoldText from "../basic/bold-text";

interface Props {
    images: string[];
    min: number;
    max: number;
    onChange: (images: string[]) => void;
}

const ImagePicker = ({ images, min, max, onChange }: Props) => {

    if (min > max) {
        console.warn(`ImagePicker component: Minimum (${min}) is bigger than maximum (${max}),
            using as minimum '0' and as max '${max}'`);
    }

    const [totalImages, setTotalImages] = useState(images.length);

    const handleUpload = async (files: FileList | null) => {
        if (!files) {
            return;
        }

        setTotalImages(images.length + files.length);
        const uploadedImages = [];

        for (let i = 0; i < files.length; i++) {
            uploadedImages.push(readFile(files[i]));
        }

        onChange(images.concat(await Promise.all(uploadedImages)));
    }

    const handleDelete = (index: number) => {
        const imagesToModify = images;

        imagesToModify.splice(index, 1);
        setTotalImages(imagesToModify.length);

        onChange(imagesToModify);
    }

    const fillLoadingImages = () => {
        if (totalImages <= images.length) {
            return null;
        }

        const loadingImages = fill(totalImages - images.length, images.length);

        return loadingImages.map(key => (
            <ImageItem key={key} />
        ));
    }

    return (
        <div className={styles.imagePickerWrapper}>
            <BoldText
                variant="caption"
                className={styles.imageCounter}
                color={totalImages < min || totalImages > max ? "error" : "initial"}>
                {totalImages}
                {(totalImages < min || totalImages > max) && ` / ${totalImages < min ? min : max}`}
            </BoldText>
            <div className={styles.images}>
                {images.map((image, index) => (
                    <ImageItem key={index} image={image} onDelete={() => handleDelete(index)} />
                ))}
                {fillLoadingImages()}
                {totalImages < max && (
                    <ImageItem multipleFiles={max - totalImages > 1} onUpload={handleUpload} />
                )}
            </div>
        </div>
    );
}

export default ImagePicker;