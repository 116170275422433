import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Typography
} from "@material-ui/core";
import React from "react";
import sharedStyles from "../post-form.module.scss";
import { ImagePicker } from "components";
import { useTranslation } from "infrastructure/hooks";
import { BasePost } from "database/types";
import { MAXIMUM_IMAGES, MINIMUM_IMAGES } from "utils/constants";
import { observer } from "mobx-react";
import classNames from "classnames";
import { runInAction, toJS } from "mobx";

interface Props {
    step: number;
    stepsNumber: number;
    post: BasePost;
}

const ImagesSection = ({ step, stepsNumber, post }: Props) => {

    const { getString } = useTranslation();

    const imagesError = !post.mainImage || post.images.length < MINIMUM_IMAGES;

    const handleMainImageUpload = (images: string[]) => 
        runInAction(() => post.mainImage = images.length ? images[0] : "");

    return (
        <Accordion className={sharedStyles.section}>
            <AccordionSummary>
                <div className={sharedStyles.sectionHeader}>
                    <Typography className={sharedStyles.sectionLabel} variant="h6">
                        {getString("images")}
                    </Typography>
                    <div
                        className={classNames(sharedStyles.stepWrapper, {
                            [sharedStyles.valid]: !imagesError
                        })}>
                        <Typography className={sharedStyles.sectionLabel} variant="h6">
                            {`${step} / ${stepsNumber}`}
                        </Typography>
                    </div>
                </div>
            </AccordionSummary>
            <AccordionDetails>
                <div className={sharedStyles.sectionContent}>
                    <div className={sharedStyles.subsection}>
                        <Typography className={sharedStyles.subsectionLabel} variant="h6">
                            {getString("mainImage")}
                        </Typography>
                        <div className={sharedStyles.subsectionContent}>
                            <ImagePicker
                                images={post.mainImage ? [post.mainImage] : []}
                                max={1}
                                min={1}
                                onChange={handleMainImageUpload} />
                        </div>
                    </div>
                    <div className={sharedStyles.subsection}>
                        <Typography className={sharedStyles.subsectionLabel} variant="h6">
                            {getString("presentationImages", MINIMUM_IMAGES, MAXIMUM_IMAGES)}
                        </Typography>
                        <div className={sharedStyles.subsectionContent}>
                            <ImagePicker
                                images={toJS(post.images)}
                                min={MINIMUM_IMAGES}
                                max={MAXIMUM_IMAGES}
                                onChange={images => runInAction(() => post.images = images)} />
                        </div>
                    </div>
                </div>
            </AccordionDetails>
        </Accordion>
    );
}

export default observer(ImagesSection);