import { createContext } from "react";
import { runInAction, makeObservable, observable, action } from "mobx";
import { RealEstatePost, EMPTY_REAL_ESTATE_POST, RealEstateFormPost } from "../types";
import { MINIMUM_IMAGES, MAXIMUM_IMAGES } from "utils/constants";
import { createPost } from "../api";
import { getLocationAsync } from "utils/thread-utils";
import { uploadImage } from "database/storage";
import { generatePostDocument, setPost as updatePost } from "database/api/post";
import { getLocationText } from "utils/location-utils";
import { isUrl } from "utils/string-utils";
import { getCurrentTimeFormatted } from "utils/date-utils";
import { loadingService } from "infrastructure/services";

export class RealEstateFormStore {
    post: RealEstateFormPost = EMPTY_REAL_ESTATE_POST;
    isConfirmationOpen: boolean = false;
    initialLatitude: number = 0;
    initialLongitude: number = 0; 

    constructor() {
        makeObservable(this, {
            post: observable,
            isConfirmationOpen: observable,
            initialLatitude: observable,
            initialLongitude: observable,
            setPost: action,
            openConfirmation: action,
            closeConfirmation: action,
            arePostDetailsValid: action,
            areCharacteristicsValid: action,
            updatePost: action
        });
    }

    public setPost = (post: RealEstateFormPost) => {
        this.post = post;
        this.initialLatitude = post.latitude;
        this.initialLongitude = post.longitude;
    }

    public openConfirmation = () => this.isConfirmationOpen = true;

    public closeConfirmation = () => this.isConfirmationOpen = false;

    public arePostDetailsValid = () => (this.post.descriptionText?.en || this.post.descriptionText?.ro) &&
        (!this.post.hasBidding || this.post.biddingEndDate) &&
        this.post.images.length >= MINIMUM_IMAGES && this.post.images.length <= MAXIMUM_IMAGES &&
        this.post.latitude && this.post.longitude && this.post.mainImage && this.post.price &&
        (this.post.titleText?.en || this.post.titleText?.ro) && this.areCharacteristicsValid();

    public areCharacteristicsValid = () => this.post.constructionYear &&
        this.post.renovationYear && this.post.partitioning && this.post.floor &&
        (this.post.floor === "house" || this.post.levels !== null) && this.post.numberOfRooms &&
        this.post.numberOfBedrooms && this.post.numberOfBathrooms &&
        this.post.numberOfBathroomsWithWindow !== null && this.post.surface &&
        this.post.numberOfBalconies !== null && this.post.purpose &&
        (!this.post.numberOfBalconies || this.post.balconiesType.length);

    public savePost = action(async () => {
        loadingService.set(true);

        const postDocument = generatePostDocument();
        const locationDetails = await getLocationAsync({ ...this.post });
        const { country, county, city, zone, street } = locationDetails;

        await createPost({
            ...(this.post as RealEstatePost),
            ...await this.uploadImages(postDocument.id),
            country,
            county,
            city,
            zone,
            street,
            location: getLocationText(locationDetails, true)
        }, postDocument);
        loadingService.set(false);
    });

    public updatePost = async () => {
        loadingService.set(true);

        this.post.lastUpdateDate = new Date();

        if (this.post.latitude !== this.initialLatitude ||
            this.post.longitude !== this.initialLongitude) {
            const locationDetails = await getLocationAsync({ ...this.post });
            const { country, county, city, zone, street } = locationDetails;

            this.post.country = country;
            this.post.county = county;
            this.post.city = city;
            this.post.zone = zone;
            this.post.street = street;
            this.post.location = getLocationText(locationDetails, true);
        }

        await this.uploadNewImages();
        await updatePost(this.post);
        loadingService.set(false);
    }

    public reset = action(() => {
        this.post = EMPTY_REAL_ESTATE_POST;
        this.isConfirmationOpen = false;
    });

    protected uploadImages = async (id: string) => {
        const mainImage = await uploadImage(id, this.post.mainImage);
        const images = await Promise.all(this.post.images.map((image, index) =>
            uploadImage(`${id}_${index}`, image)
        ));

        return {
            mainImage,
            images
        };
    }

    protected uploadNewImages = async () => {
        const date = getCurrentTimeFormatted();
        const id = this.post.id;

        let mainImage = this.post.mainImage;
        if (!isUrl(mainImage)) {
            mainImage = await uploadImage(`${id}_${date}`, this.post.mainImage);
        }

        const images = await Promise.all(this.post.images.map((image, index) =>
            isUrl(image) ? image : uploadImage(`${id}_${index}_${date}`, image)));

        runInAction(() => {
            this.post.mainImage = mainImage;
            this.post.images = images;
        });
    }
}

export const realEstateFormStore = new RealEstateFormStore();
export const RealEstateFormContext = createContext(realEstateFormStore);