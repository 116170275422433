import { makeAutoObservable, runInAction, toJS } from "mobx";
import { createContext } from "react";
import { EMAIL_REGEX, PASSWORD_REGEX, PHONE_NUMBER_REGEX } from "utils/constants";
import { EMPTY_ACCOUNT } from "../account/types";
import { User } from "database/types";
import {
    getAccountForCurrentUser,
    setAccountEmail,
    setAccountPassword,
    setAccountDetails
} from "database/api/account";
import { setWithNotificationSound, translationService } from "infrastructure/services";

export class SettingStore {
    public currentAccount: User = EMPTY_ACCOUNT;
    public newAccount: User = EMPTY_ACCOUNT;
    public emailError: string = "";
    public password: string = "";
    public passwordError: string = "";
    public oldPassword: string = "";
    public oldPasswordError: string = "";
    public confirmPassword: string = "";
    public confirmPasswordError: string = "";
    public firstNameError: string = "";
    public lastNameError: string = "";
    public companyNameError: string = "";
    public phoneNumberError: string = "";
    public uniqueIdentificationCodeError: string = "";

    constructor() {
        makeAutoObservable(this);
    }

    public validateFields = () => {
        this.validateEmail();
        this.validatePassword();
        this.validateOldPassword();
        this.validateConfirmPassword();
        this.validatePhoneNumber();
        this.validateFirstName();
        this.validateLastName();
    }

    public fetchAccountDetails = async () => {
        const currentAccount = await getAccountForCurrentUser();
        if (!currentAccount) {
            return;
        }

        runInAction(() => {
            this.currentAccount = currentAccount;
            this.newAccount = toJS(currentAccount);
        });
    }

    public setEmail = (email: string) => {
        this.newAccount.email = email;
        this.validateEmail();
    }

    public setPassword = (password: string) => {
        this.password = password;
        this.validatePassword();
    }

    public setOldPassword = (oldPassword: string) => {
        this.oldPassword = oldPassword;
        this.validateOldPassword();
    }

    public setConfirmPassword = (confirmPassword: string) => {
        this.confirmPassword = confirmPassword;
        this.validateConfirmPassword();
    }

    public setFirstName = (firstName: string) => {
        this.newAccount.firstName = firstName;
        this.validateFirstName();
    }

    public setLastName = (lastName: string) => {
        this.newAccount.lastName = lastName;
        this.validateLastName();
    }

    public setCompanyName = (companyName: string) => {
        this.newAccount.companyName = companyName;
        this.validateCompanyName();
    }

    public setPhoneNumber = (phoneNumber: string) => {
        this.newAccount.phoneNumber = phoneNumber;
        this.validatePhoneNumber();
    }

    public setUniqueIdentificationCode = (uniqueIdentificationCode: string) => {
        this.newAccount.uniqueIdentificationCode = uniqueIdentificationCode;
        this.validateUniqueIdentificationCode();
    }

    public resetFields = () => {
        this.newAccount = toJS(this.currentAccount);
        this.emailError = "";
        this.password = "";
        this.passwordError = "";
        this.oldPassword = "";
        this.oldPasswordError = "";
        this.confirmPassword = "";
        this.confirmPasswordError = "";
        this.firstNameError = "";
        this.lastNameError = "";
        this.companyNameError = "";
        this.phoneNumberError = "";
        this.uniqueIdentificationCodeError = "";
    }

    public validateEmail = () => {
        if (!this.newAccount.email) {
            this.emailError = translationService.getString("emailRequired");
            return;
        }
        if (!EMAIL_REGEX.test(this.newAccount.email.toLowerCase())) {
            this.emailError = translationService.getString("emailMustBeValid");
            return;
        }
        this.emailError = "";
    }

    public validatePassword = () => {
        if (!this.password) {
            this.passwordError = translationService.getString("passwordRequired");
            return;
        }
        if (!PASSWORD_REGEX.test(this.password)) {
            this.passwordError = translationService.getString("passwordMustBeValid");
            return;
        }
        this.passwordError = "";
    }

    public validateOldPassword = () => {
        if (!this.oldPassword) {
            this.oldPasswordError = translationService.getString("passwordRequired");
            return;
        }
        this.oldPasswordError = "";
    }

    public validateConfirmPassword = () => {
        if (!this.confirmPassword) {
            this.confirmPasswordError = translationService.getString("confirmPasswordRequired");
            return;
        }
        if (this.confirmPassword !== this.password) {
            this.confirmPasswordError = translationService.getString("confirmPasswordMustBeValid");
            return;
        }
        this.confirmPasswordError = "";
    }

    public validateFirstName = () => {
        if (!this.newAccount.firstName) {
            this.firstNameError = translationService.getString("firstNameRequired");
            return;
        }
        this.firstNameError = "";
    }

    public validateLastName = () => {
        if (!this.newAccount.lastName) {
            this.lastNameError = translationService.getString("lastNameRequired");
            return;
        }
        this.lastNameError = "";
    }

    public validateCompanyName = () => {
        if (!this.newAccount.companyName) {
            this.companyNameError = translationService.getString("companyNameRequired");
            return;
        }
        this.companyNameError = "";
    }

    public validatePhoneNumber = () => {
        if (!this.newAccount.phoneNumber) {
            this.phoneNumberError = translationService.getString("phoneNumberRequired");
            return;
        }
        if (!PHONE_NUMBER_REGEX.test(this.newAccount.phoneNumber)) {
            this.phoneNumberError = translationService.getString("phoneNumberMustBeValid");
            return;
        }
        this.phoneNumberError = "";
    }

    public validateUniqueIdentificationCode = () => {
        if (!this.newAccount.uniqueIdentificationCode) {
            this.uniqueIdentificationCodeError = translationService.getString("uniqueIdentificationCodeRequired");
            return;
        }
        this.uniqueIdentificationCodeError = "";
    }

    public saveEmail = async () => {
        if (this.emailError || this.currentAccount.email === this.newAccount.email) {
            return;
        }

        await setAccountEmail(this.newAccount, this.oldPassword);

        this.resetFields();
        await this.fetchAccountDetails();
    }

    public savePassword = async () => {
        if (this.oldPasswordError || this.passwordError || this.confirmPasswordError || this.oldPassword === this.password) {
            return;
        }

        await setAccountPassword(this.oldPassword, this.password);

        this.resetFields();
    }

    public saveAccountDetails = async (property: string) => {
        if (!property) {
            return;
        }

        await setAccountDetails(this.newAccount);

        this.resetFields();
        await this.fetchAccountDetails();
    }

    public saveWithNotificationSound = async (withSound: boolean) => {
        this.newAccount.withNotificationSound = withSound;
        
        setWithNotificationSound(withSound);
        await setAccountDetails(this.newAccount);
        await this.fetchAccountDetails();
    }

    public reset = () => {
        this.resetFields();
        this.currentAccount = EMPTY_ACCOUNT;
        this.newAccount = EMPTY_ACCOUNT;
    }

}

export const settingStore = new SettingStore();
export const SettingContext = createContext(settingStore);