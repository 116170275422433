import React, { useContext, useEffect } from "react";
import { PostsGrid, PostsFilterBar } from "../../components/posts-grid";
import { CATEGORY_REAL_ESTATE } from "utils/constants";
import { RealEstateListContext } from "./real-estate-list-store";
import { observer } from "mobx-react";
import RealEstateListFilters from "./real-estate-list-filters";
import { RealEstatePost } from "../types";
import { searchFields } from "utils/string-utils";
import { useTranslation } from "infrastructure/hooks";
import { isNotEmpty, sortBy } from "utils/array-utils";
import { isInRange } from "utils/number-utils";
import {
    Range,
    MultiSelect,
    SORT_BY_ASCENDING_DATE,
    SORT_BY_DESCENDING_DATE,
    SORT_BY_DESCENDING_PRICE
} from "../../types";
import { auth } from "database/firebase";
import { loadingService } from "infrastructure/services";

const isValueInRange = (num: number, range: Range) =>
    isInRange(num, range.min, range.max);

const isValueInSelected = (value: string, select: MultiSelect) =>
    !select.selected.length || select.selected.includes(value);

const areValuesInSelected = (values: string[], select: MultiSelect) =>
    !select.selected.length || select.selected.some(value => values.includes(value));

const isValueSelected = (value: boolean, select?: boolean) =>
    select === undefined || value === select;

const getPostPrice = (post: RealEstatePost) => isNotEmpty(post.bids) ?
    post?.bids![post?.bids!.length - 1].value : post.price;

const RealEstateList = () => {
    const { getText } = useTranslation();

    const realEstateStore = useContext(RealEstateListContext);

    const {
        allPosts,
        fetchPosts,

        search,
        sortingBy,
        filterMyPosts,
        priceRange,
        selectedCounties,
        selectedCities,
        selectedZones,
        rangeConstructionYear,
        rangeRenovationYear,
        selectedPartitions,
        selectedFloors,
        rangeStories,
        rangeRooms,
        rangeBedrooms,
        rangeBathrooms,
        rangeBathroomsWithWindow,
        rangeBalconies,
        selectedBalconyTypes,
        selectedPurposes,
        rangeArea,
        withParkingSpace,
        withElevator
    } = realEstateStore;

    useEffect(() => {
        fetchPosts();
    }, [fetchPosts]);

    const onlyInTheCurrentLanguage = (post: RealEstatePost) =>
        getText(post.titleText) && getText(post.descriptionText);

    const searchPost = (post: RealEstatePost) => searchFields(
        search,
        post.id,
        post.titleText!.en,
        post.titleText!.ro,
        post.descriptionText!.en,
        post.descriptionText!.ro
    );

    const filterPost = (post: RealEstatePost) => {
        const price = getPostPrice(post);

        return filterMyPosts === (post.createdByUserId === auth.getCurrentUser()?.uid) &&
            isValueInRange(price, priceRange) &&
            isValueInSelected(post.county, selectedCounties) &&
            isValueInSelected(post.city, selectedCities) &&
            isValueInSelected(post.zone, selectedZones) &&
            isValueInRange(post.constructionYear, rangeConstructionYear) &&
            isValueInRange(post.renovationYear, rangeRenovationYear) &&
            isValueInSelected(post.partitioning, selectedPartitions) &&
            isValueInSelected(post.floor, selectedFloors) && (
                post.floor === "house" || isValueInRange(post.levels, rangeStories)
            ) && isValueInRange(post.numberOfRooms, rangeRooms) &&
            isValueInRange(post.numberOfBedrooms, rangeBedrooms) &&
            isValueInRange(post.numberOfBathrooms, rangeBathrooms) &&
            isValueInRange(post.numberOfBathroomsWithWindow, rangeBathroomsWithWindow) &&
            isValueInRange(post.numberOfBalconies, rangeBalconies) && (
                !post.numberOfBalconies ||
                areValuesInSelected(post.balconiesType, selectedBalconyTypes)
            ) && isValueInSelected(post.purpose, selectedPurposes) &&
            isValueInRange(post.surface, rangeArea) &&
            isValueSelected(post.parkingSpace, withParkingSpace) &&
            isValueSelected(post.hasElevator, withElevator);
    }

    const searchAndFilterPost = (post: RealEstatePost) => 
        onlyInTheCurrentLanguage(post) && searchPost(post) && filterPost(post);

    const filteredProducts = sortBy(allPosts.filter(searchAndFilterPost), post =>
        sortingBy === SORT_BY_ASCENDING_DATE || sortingBy === SORT_BY_DESCENDING_DATE ?
            post.createDate : getPostPrice(post),
        sortingBy === SORT_BY_DESCENDING_DATE || sortingBy === SORT_BY_DESCENDING_PRICE
    );

    return <>
        <PostsFilterBar store={realEstateStore}>
            <RealEstateListFilters store={realEstateStore} />
        </PostsFilterBar>
        {!loadingService.activeLoadings && (
            <PostsGrid
                hide={!!loadingService.activeLoadings}
                posts={filteredProducts}
                postCategory={CATEGORY_REAL_ESTATE} />
        )}
    </>;
}

export default observer(RealEstateList);