import React from "react";
import { Routes, Route } from "react-router-dom";
import ForRentDetails from "./for-rent-details";
import ForRentList from "./for-rent-list";

const ForRentCategory = () => (
    <Routes>
        <Route path="/:id" element={<ForRentDetails />} />
        <Route path="/" element={<ForRentList />} />
    </Routes>
);

export default ForRentCategory;