import { setTimeoutAsync } from "./thread-utils";
import { useRef, useLayoutEffect } from "react";

export type Alignment = "start" | "center" | "end" | "nearest"

const DEFAULT = "nearest";

export const scrollIntoView = async (
    selector: string,
    vertical: Alignment = DEFAULT,
    horizontal: Alignment = DEFAULT
) => await setTimeoutAsync(() => document.querySelectorAll(selector).forEach(element => element.scrollIntoView({
    behavior: "smooth",
    block: vertical,
    inline: horizontal
})), 50);

export const scrollVerticallyIntoView = async (selector: string, alignment: Alignment = DEFAULT) =>
    await scrollIntoView(selector, alignment, DEFAULT);

export const scrollHorizontallyIntoView = async (selector: string, alignment: Alignment = DEFAULT) =>
    await scrollIntoView(selector, DEFAULT, alignment);

export const useRunOnce = (callback: () => void) => {
    const ranOnce = useRef(false);

    if (!ranOnce.current) {
        ranOnce.current = true;
        callback();
    }
}

export const useScrollTopCallback = (selector: string | undefined, callback: () => void) => {
    useLayoutEffect(() => {

        if (!selector) {
            callback();
            return;
        }

        const element = document.querySelector(selector);
        const topPosition = element?.getBoundingClientRect().top;

        if (!topPosition)
            return;

        const onScroll = () => {
            const scrollPosition = window.scrollY + window.innerHeight;
            if (topPosition + 100 < scrollPosition) {
                callback();
                window.removeEventListener("scroll", onScroll);
            }
        }

        window.addEventListener("scroll", onScroll);

        return () => window.removeEventListener("scroll", onScroll);
    }, [selector, callback])
}

export const scrollToTop = () => window.scrollTo({
    top: 0,
    behavior: "smooth"
})