import React from "react";
import { Button } from "@material-ui/core";
import { useTranslation } from "infrastructure/hooks";
import { ScrollTopTransition } from "components";
import { Image } from "components";
import sharedStyles from "../home.module.scss";
import styles from "./hero.module.scss";
import classNames from "classnames";
import { useNavigation } from "utils/router-utils";
import PartnerList from "skeleton/partners/partner-list";
import { ALL_PARTNERS } from "skeleton/partners/constants";

const Hero = () => {
    const { getString } = useTranslation();
    const { goTo } = useNavigation();

    return (
        <div className={classNames(sharedStyles.section, styles.hero)}>
            <ScrollTopTransition timeout={500} classNames="fade-in">
                <div className={styles.heading}>
                    <h1>{getString("landingPageHeroHeading")}</h1>
                    <h3>{getString("landingPageHeroSubtitle")}</h3>
                    <div className={styles.actions}>
                        <Button
                            onClick={() => goTo("/realEstate")}
                            className={styles.actionButton}>
                            {getString("buy")}
                        </Button>
                        <Button onClick={() => goTo("/forRent")} className={styles.actionButton}>
                            {getString("rent")}
                        </Button>
                        <Button onClick={() => goTo("/vehicles")} className={styles.actionButton}>
                            {getString("vehicles")}
                        </Button>
                    </div>
                    <div className={styles.partners}>
                        <h2>{getString("partners")}</h2>
                        <PartnerList partners={ALL_PARTNERS} />
                    </div>
                </div>
            </ScrollTopTransition>
            <ScrollTopTransition timeout={0} classNames="slide">
                <div className={styles.heroOverlay} />
            </ScrollTopTransition>
            <div className={styles.imageWrapper}>
                <Image src="https://images.pexels.com/photos/534151/pexels-photo-534151.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260" />
            </div>
        </div>
    )
}

export default Hero;