import { makeAutoObservable, runInAction } from "mobx";
import { createContext } from "react";
import { User } from "database/types";
import { getAllUsers, getUser } from "database/api/user";
import { searchFields } from "utils/string-utils";
import { auth } from "database/firebase";
import { translationService } from "infrastructure/services";
import { loadingService } from "infrastructure/services";

export class UsersStore {
    public users: User[] = [];
    public activeOptionIndex: number = 0
    public userTypeOptionIndex: number = 0;
    public verifiedOptionIndex: number = 0;
    public searchValue: string = "";
    public activeOptions: string[] = [];
    public userTypeOptions: string[] = [];
    public verifiedOptions: string[] = [];
    public currentAdmin: User | null = null;

    constructor() {
        makeAutoObservable(this);
    }

    public getFilteredUsers = () => {
        const matchActiveOption = (user: User) => {
            if (!this.activeOptionIndex)
                return true;
            if (this.activeOptionIndex === 1 && !user.banned)
                return true;
            if (this.activeOptionIndex === 2 && user.banned)
                return true;
            return false;
        }

        const matchUserTypeOption = (user: User) => {
            if (!this.userTypeOptionIndex)
                return true;
            if (this.userTypeOptionIndex === 1 && user.isIndividual)
                return true;
            if (this.userTypeOptionIndex === 2 && !user.isIndividual)
                return true;
            return false;
        }

        const matchVerifiedOption = (user: User) => {
            if (!this.verifiedOptionIndex)
                return true;
            if (this.verifiedOptionIndex === 1 && user.verified)
                return true;
            if (this.verifiedOptionIndex === 2 && !user.verified)
                return true;
            return false;
        }

        const matchSearch = (user: User) =>
            searchFields(this.searchValue, user.id, user.firstName, user.lastName, user.email);

        return this.users.filter(user =>
            matchSearch(user) &&
            matchActiveOption(user) &&
            matchUserTypeOption(user) &&
            matchVerifiedOption(user)
        );
    }

    public setActiveOptionIndex = (value: number) => this.activeOptionIndex = value;

    public setUserTypeOptionIndex = (value: number) => this.userTypeOptionIndex = value;

    public setVerifiedOptionIndex = (value: number) => this.verifiedOptionIndex = value;

    public setSearchValue = (value: string) => this.searchValue = value;

    public fetchUsers = async () => {
        const users = await getAllUsers();
        return users;
    }

    public fetchCurrentAdmin = async () => {
        const user = await getUser(auth.getCurrentUser()?.uid!);
        return user;
    }

    public fetchData = async () => {
        loadingService.set(true);
        const users = await this.fetchUsers();
        const currentAdmin = await this.fetchCurrentAdmin();

        runInAction(() => {
            this.users = users;
            this.setOptions();
            this.setActiveOptionIndex(0);
            this.setUserTypeOptionIndex(0);
            this.setVerifiedOptionIndex(0);
            this.currentAdmin = currentAdmin;
        });
        loadingService.set(false);
    }

    public setOptions = () => {
        this.activeOptions = [
            translationService.getString("all"),
            translationService.getString("active"),
            translationService.getString("banned")
        ];
        this.userTypeOptions = [
            translationService.getString("all"),
            translationService.getString("individual"),
            translationService.getString("legalIdentity")
        ];
        this.verifiedOptions = [
            translationService.getString("all"),
            translationService.getString("verified"),
            translationService.getString("notVerified")
        ];
    }
}

export const usersStore = new UsersStore();
export const UsersContext = createContext(usersStore);
