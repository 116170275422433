import React from "react";
import GavelIcon from "@material-ui/icons/Gavel";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import { useTranslation } from "infrastructure/hooks";
import { ScrollTopTransition } from "components";
import styles from "./guide.module.scss";
import sharedStyles from "./../home.module.scss";
import classNames from "classnames";

const Guide = () => {

    const { getString } = useTranslation();

    return (
        <div className={classNames(sharedStyles.section, styles.guide)}>
            <h1 className={sharedStyles.header}>{getString("guideHeader")}</h1>
            <ScrollTopTransition selector=".guide-item-2" timeout={400} classNames="fade-in">
                <div className={classNames(styles.guideItem, styles.flipCard, "guide-item-2")}>
                    <div className={styles.flipCardInner}>
                        <div className={styles.flipCardFront}>
                            <h1>{getString("guideBid")}</h1>
                            <h2 className={styles.subtitle}>{getString("guideBidSubtitle")}</h2>
                            <div className={styles.stepsWrapper}>
                                <GavelIcon className={styles.stepsIcon} />
                                <div className={styles.steps}>
                                    <div className={styles.step}>
                                        <div className={styles.stepNumber}>
                                            <h2>1</h2>
                                        </div>
                                        <div className={styles.stepDetails}>
                                            {getString("guideFirstStep")}
                                        </div>
                                    </div>
                                    <div className={styles.step}>
                                        <div className={styles.stepNumber}>
                                            <h2>2</h2>
                                        </div>
                                        <div className={styles.stepDetails}>
                                            {getString("guideBidStep2")}
                                        </div>
                                    </div>
                                    <div className={styles.step}>
                                        <div className={styles.stepNumber}>
                                            <h2>3</h2>
                                        </div>
                                        <div className={styles.stepDetails}>
                                            {getString("guideBidStep3")}
                                        </div>
                                    </div>
                                    <div className={styles.step}>
                                        <div className={styles.stepNumber}>
                                            <h2>4</h2>
                                        </div>
                                        <div className={styles.stepDetails}>
                                            {getString("guideBidStep4")}
                                        </div>
                                    </div>
                                    <div className={styles.step}>
                                        <div className={styles.stepNumber}>
                                            <h2>5</h2>
                                        </div>
                                        <div className={styles.stepDetails}>
                                            {getString("guideLastStep")}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={styles.flipCardBack}>
                            {getString("guideBidDisclaimer")}
                        </div>
                    </div>
                </div>
            </ScrollTopTransition>
            <ScrollTopTransition selector=".guide-item-1" timeout={600} classNames="fade-in">
                <div className={classNames(sharedStyles.hoverableCard, styles.guideItem, "guide-item-1")}>
                <h1>{getString("guideSellRent")}</h1>
                    <div className={styles.stepsWrapper}>
                        <MonetizationOnIcon className={styles.stepsIcon} />
                        <div className={styles.steps}>
                            <div className={styles.step}>
                                <div className={styles.stepNumber}>
                                    <h2>1</h2>
                                </div>
                                <div className={styles.stepDetails}>
                                    {getString("guideFirstStep")}
                                </div>
                            </div>
                            <div className={styles.step}>
                                <div className={styles.stepNumber}>
                                    <h2>2</h2>
                                </div>
                                <div className={styles.stepDetails}>
                                    {getString("guideSellRentStep2")}
                                </div>
                            </div>
                            <div className={styles.step}>
                                <div className={styles.stepNumber}>
                                    <h2>3</h2>
                                </div>
                                <div className={styles.stepDetails}>
                                    {getString("guideSellRentStep3")}
                                </div>
                            </div>
                            <div className={styles.step}>
                                <div className={styles.stepNumber}>
                                    <h2>4</h2>
                                </div>
                                <div className={styles.stepDetails}>
                                    {getString("guideSellRentStep4")}
                                </div>
                            </div>
                            <div className={styles.step}>
                                <div className={styles.stepNumber}>
                                    <h2>5</h2>
                                </div>
                                <div className={styles.stepDetails}>
                                    {getString("guideSellRentStep5")}
                                </div>
                            </div>
                            <div className={styles.step}>
                                <div className={styles.stepNumber}>
                                    <h2>6</h2>
                                </div>
                                <div className={styles.stepDetails}>
                                    {getString("guideLastStep")}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ScrollTopTransition>
        </div>
    )
}

export default Guide;