import { Typography } from "@material-ui/core";
import { AuctionNotificationType, StateNotificationType } from "database/types";
import React, { useState } from "react";
import styles from "./notification-element.module.scss";
import { getMomentFromTime, momentUtil } from "utils/date-utils";
import { useTranslation } from "infrastructure/hooks";
import classNames from "classnames";
import AuctionNotificationElement from "./auction";

interface Props {
    notification: StateNotificationType;
    compact?: boolean;
    onClick?: (notification: StateNotificationType) => void;
    onHover?: (notification: StateNotificationType) => void;
}

const TWO_SECONDS = 2000;

const NotificationElement = ({ notification, compact, onClick, onHover }: Props) => {
    const { getString, getCurrentLanguageCode } = useTranslation();
    const timestamp = getMomentFromTime(notification.timestamp).locale(getCurrentLanguageCode());
    const [hoverTimeoutId, setHoverTimeoutId] = useState<NodeJS.Timeout | null>(null);

    const formatDayAndTime = () => {
        const pastDays = momentUtil(timestamp, "d");
        const timeFormat = timestamp.format("HH:mm");

        switch (pastDays) {
            case 0:
                return getString("today") + " " + timeFormat;
            case -1:
                return getString("yesterday") + " " + timeFormat;
            default:
                return timestamp.format("dddd HH:mm");
        }
    }

    const handleMouseEnter = () => {
        if (onHover) {
            setHoverTimeoutId(setTimeout(() => onHover(notification), TWO_SECONDS));
        }
    }

    const handleMouseLeave = () => {
        if (hoverTimeoutId) {
            clearTimeout(hoverTimeoutId);
            setHoverTimeoutId(null);
        }
    }

    return (
        <div
            className={classNames(styles.container, {
                [styles.compact]: compact
            })}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            onClick={() => onClick?.(notification)}>
            <AuctionNotificationElement 
                notification={notification as unknown as AuctionNotificationType} 
                isAdmin={notification.isAdmin}
                compact={compact} />
            {compact || <>
                <div className={styles.timestamp}>
                    <Typography variant="subtitle2">{formatDayAndTime()}</Typography>
                    <Typography variant="subtitle2">{timestamp.format("DD MMM, YYYY").replaceAll(".", "")}</Typography>
                </div>
                {notification.isSeen || (
                    <div className={styles.unreadDot} />
                )}
            </>}
        </div>
    );
}

export default NotificationElement;