import { createContext } from "react";
import { runInAction, makeObservable, observable, action } from "mobx";
import { getAllPostsWithText } from "../api";
import { RealEstateListStore } from "../../real-estate/real-estate-list/real-estate-list-store";
import { ForRentPost } from "../types";
import { loadingService } from "infrastructure/services";

export class ForRentListStore extends RealEstateListStore {
    public allPosts: ForRentPost[] = [];
    public withPetFriendly?: boolean = undefined;

    constructor() {
        super();
        makeObservable(this, {
            withPetFriendly: observable
        });
    }

    public fetchPosts = action(async () => {
        loadingService.set(true);
        const posts = await getAllPostsWithText();

        runInAction(() => {
            this.allPosts = posts;
            this.initializeFilters();
        });
        loadingService.set(false);
    });

    private initialClearFilters = this.clearFilters;

    public clearFilters = action(() => {
        this.initialClearFilters();
        this.withPetFriendly = undefined;
    });
}

export const forRentListStore = new ForRentListStore();
export const ForRentListContext = createContext(forRentListStore);