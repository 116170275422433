import React, { useContext } from "react";
import {
    Card, 
    CardContent, 
    Button,
    TextField, 
    Typography
} from "@material-ui/core";
import { observer } from "mobx-react";
import { ForgotPasswordContext } from "./forgot-password-store";
import { auth } from "database/firebase";
import { useTranslation } from "infrastructure/hooks";
import { useNavigation } from "utils/router-utils";
import { useRunOnce } from "utils/ui-utils";
import styles from "./forgot-password.module.scss";
import { loadingService, toastService } from "infrastructure/services";
import BottomControls from "../components/bottom-controls";

const ForgotPasswordPage = () => {
    const { getString } = useTranslation();
    const { goHome } = useNavigation();

    const {
        email,
        emailError,
        setEmail,
        reset
    } = useContext(ForgotPasswordContext);

    useRunOnce(() => {
        if (auth.getCurrentUser()) {
            goHome();
            return null;
        }
    });

    const handleSendClick = async () => {
        loadingService.set(true);
        try {
            await auth.sendPasswordResetEmail(email);
            toastService.success(getString("resetEmailSent"));
            reset();
            goHome();
        } catch {
            toastService.error(getString("emailNotFound"));
        };
        loadingService.set(false);
    }

    const headerText = <>
        <Typography className={styles.textSiteName} variant="h5" gutterBottom>
            {getString("siteName")}
        </Typography>
        <Typography variant="h5" gutterBottom>
            {getString("resetPassword")}
        </Typography>
    </>;

    const inputFields = <>
        <TextField
            autoFocus
            required
            error={!!emailError}
            helperText={emailError}
            className={styles.emailField}
            label="Email"
            type="email"
            autoComplete="email"
            variant="outlined"
            value={email}
            onKeyPress={event => event.key === "Enter" && !emailError && handleSendClick()}
            onChange={event => setEmail(event.target.value)}
        />
        <Typography className={styles.textDetails} variant="subtitle1" gutterBottom>
            {getString("resetPasswordDetails")}
        </Typography>
    </>;

    const accountButtons = <>
        <Button 
            variant="contained" 
            color="primary" 
            onClick={handleSendClick}
            disabled={!!emailError || !email}>
            {getString("resetPassword")}
        </Button>
    </>;

    const card = (
        <Card variant="outlined">
            <CardContent className={styles.mainCard}>
                {headerText}
                {inputFields}
                {accountButtons}
            </CardContent>
        </Card>
    );

    return (
        <div className={styles.centeringContainer}>
            {card}
            <BottomControls />
        </div>
    );
}

export default observer(ForgotPasswordPage);