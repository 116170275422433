import { TranslationStrings } from "./types";

export const STATIC_STRINGS_EN: TranslationStrings = {
    siteName: "Mira Auction",
    realEstate: "Real Estate",
    vehicles: "Vehicles",
    login: "Login",
    selectedLanguage: "English",
    termsAndConditions: "Terms & Conditions",
    language: "Language",
    emailRequired: "Email is required",
    emailMustBeValid: "Email must be valid",
    passwordRequired: "Password is required",
    password: "Password",
    forgotPassword: "Forgot password?",
    createAccount: "Create account",
    or: "Or",
    close: "Close",
    location: "Location",
    images: "Images",
    bidsForPost: "Bids for post",
    copyBidderId: "Copy bidder's ID",
    termsAndConditionsText: `
    Welcome to the miraauction.com website, a service offered by Mira Auction S.R.L., CUI: 43267029, Registered under no.: J12/3570/30.10.2020, registered office: Municipality of Cluj-Napoca, Str. Gruia, nr. 28A, et.1, ap. 118, jud. Cluj. By using, browsing and visiting this website, you agree to the validity of these Terms and Conditions when using the public section of miraauction.com.
    
    
    1. Introduction
    
    These terms and conditions as well as our privacy policy are collectively referred to as “Terms and conditions”.
    The privacy policy refers to the collection and use of the personal information you provide for us when you visit this site. Our website complies with the General Data Protection Regulation (“GDPR”), the (EU Regulation 2016/679) and with the LAW no. 190 of June 18, 2018 regarding the measures to apply the EU Regulation 2016/679 of the European Parliament and Council of April 27, 2016 concerning the protection of natural persons when processing personal data and the free circulation of such data and repealing the Directive 95/46/EC (General data protection regulation), which is a compulsory legal document. GDPR gives birth to new rights for individuals and consolidates certain existing rights in accordance with the laws of the European Union and with the principles set forth in this document.
    By accessing www.miraauction.com you agree with the above-mentioned terms, which are used in order to ensure that this website is functional for all parties involved.
    Miraauction.com reserves the right to amend, change, remove or add terms and conditions to this regulation, including but not limited to: terms, applicable fines/fees, policies of function/use. Miraauction.com may benefit from this right without any prior notifications or communications. Regardless of the prior notifications, the continual usage of the services represents your agreement with these modifications. The periodical verification of the amendments and changes that intervene in this Regulation as well as the accuracy of the contact data you have provided are entirely your responsibility. By filling out the registration form and the subsequent activation based on the e-mail sent by miraauction.com to the address you have provided and the use of this site implies that you automatically agree with and that you will comply with the terms and conditions of use.
    Any new features that improve or extend the current Service, shall be subjected to the terms and conditions. The Service provided “AS SUCH” and Mira Auction SRL disclaim any liability with regard to the update, deletion or incapacity to store any notification from the user or the customized settings.
    
    IF YOU DO NOT AGREE WITH THESE TERMS, DO NOT FILL OUT THE REGISTRATION FORM, DO NOT LOG IN WITH YOUR INDIVIDUAL ACCOUNT AND PASSWORD.

    
    2. Definitions 
    
    ● User – shall mean any natural persons of minimum 18 years of age, with full capacity of exercise, who access the website, interested in the data, information, announcements and/or services of Mira Auction S.R.L. and/or any legal persons who access and use miraauction.com, for whatever purpose.
    
    ● Provider or Mira Auction – the company Mira Auction S.R.L., the Romanian legal person, with the registered office in Cluj-Napoca, Str. Gruia, nr. 28A, et.1, ap. 118, jud. Cluj, registered in the Trade Register Office attached to the Law Court of Cluj-Napoca under no. J12/3570/2020, CUI 43267029, e-mail: $0.
    
    ● Announcement – the public offer launched by a User in order to be published by Mira Auction S.R.L. through the website with regard to the sale or lease of a movable or immovable property.
    
    ● Immovable property – real estate that may be traded pursuant to the law and which does not violate the limits imposed by these Terms and Conditions.
    
    ● Movable property – according to art.539 para (1) of the new Civil code, which says that the “property which is not construed as immovable in the eyes of the law is a movable property”. As such, all properties which are not classified as immovable property, in accordance with art. 537 of the new Civil Code, are movable properties.
    
    ● Paid announcement or promoted announcement that may be published by the Users on miraauction.com only based on a fee paid to the Provider.
    
    ● Free of charge announcement – announcement that may be published by the Users on the website without the necessity to pay any fee to the Provider.
    
    ● Operators/administrators –persons that were specially trained by the Provider in order to check/moderate the content of the Announcements published by the Users on the website.
    
    
    3. Intellectual Property
    
    a. You acknowledge and you agree that the Service and any software used in connection with the Service (“Software”) contain confidential and proprietary information protected by rights to intellectual property and other laws. You further acknowledge and agree that any content in the sponsor’s advertisements or the information presented through the Service and the advertising agencies are protected by copyright, trademarks, service marks, patents or other proprietary rights and laws. Except otherwise expressly authorized by Mira Auction S.R.L., you agree not to amend, lease, loan, sell, distribute or create any derived works partially or fully based on the Service or Software.
    
    b. Without limiting to clause 3.a) miraauction.com and the logo is our registered trademark. You do not have the right to use it or copy it without our prior written consent.
    
    c. The service may provide, or third parties may provide, links to other websites or to other resources. Since Mira Auction SRL has no control over these websites and resources, you agree that Mira Auction SRL is not liable for the availability of these websites or external resources, and does not guarantee and is not responsible or liable for any content, publicity, products, or other materials displayed on or available on these websites or resources. Moreover, you acknowledge and you agree that Mira Auction SRL shall not be responsible or liable, directly or indirectly, for any damage or loss caused or supposedly caused by or in connection with the use or trust in any such content, properties or services available on or through any such websites or resources.
    
    The details with regard to the properties available on this website are provided for us by private owners, real estate agencies and other third parties with the sole purpose of informing you. We cannot verify such details and therefore, we cannot offer any guarantees with regard to the accuracy or integrality of such details. Should you count on these details, you do so on your own account.
    
    4. Your obligations and conduct
    
    The full civil and penal responsibility for the information published and/or transmitted on the Internet by the user, as well as for the consequences which may arise from these activities, is exclusively the user’s responsibility. 
    
    
    5. The user has the right to fully benefit from the Services insofar as no breach of the provisions set in the Terms and Conditions is found. 
    
    5.1 The user takes responsibility for the content of the announcements published and declares that these comply with the fact and law of the Product which is the subject matter of the Announcement and do not infringe the Provider’s rights and/or any third parties’ rights.
    
    5.2 The user take it upon themselves to declare that they are the owner of all intellectual proprietary rights, including copyright with regard to the published content, including photographs and agrees that it be made available to the public by the Provider, on all and any of its online platforms, as well as by other means of advertising, such as: press releases, television, banners, hoardings and the Internet.
    
    5.3 By filling out the form and/or transmitting the Announcement by other means to the Provider, in order to be made available for the public through miraauction.com, the User declares to fully and unconditionally agree with the Provider’s Terms and Conditions.
    
    5.4. By creating the account and by accepting the General Terms and Conditions, the User concludes an Agreement with Mira Auction S.R.L. The Agreement may be terminated by the User, by deleting the account.
    
    5.5. Except for the information related to their own Announcement, the User is strictly prohibited to take any information from the website with the purpose of full or partial republishing it, under any form, without the Provider’s prior written consent to that end.
    
    
    6. No Announcements containing links to other competitor websites may be published.
    
    6.1. The Announcements, be those active on the website or blocked when moderated, are verified by the Provider, and the Announcements which do not comply with the Terms and Conditions of the website shall be deleted from the website, as soon as possible, and under certain circumstances measures shall be taken to block that account from which such announcements were published.
    
    Mira Auction S.R.L.  through miraauction.com may verify the conformity of the Announcements with the Terms and Conditions of the website, depending on its own prioritization criteria.
    
    6.2. Subsequent to finding inconsistencies in complying with the provisions of this document by any of the Announcements published on the Website (including those paid to be promoted or paid because they exceed the number of free Announcements allowed for certain categories), the Provider’s Operators shall be allowed, depending on the actual situation, on a case by case basis:
    
    ● either to partially modify the Announcement, insofar as such modification is possible, so as to avoid the deletion of the Announcement from the Website and to remedy the errors and inconsistencies found;
    
    ● or to interrupt the continuity of publishing the Announcement, by rejecting it. Rejecting the Announcement means deactivating its post from the website.
    
    6.3. Should the Announcement be rejected or deleted from the miraauction.com Platform, the Provider shall notify the professional Users, when the blocking becomes effective at the latest, based on an e-mail, presenting the reasons for rejection, indicating the blocked content, the grounds for the decision to block it (including the content of the abuse reports after making them anonymous, should the law require so) indicating the legal or contractual provisions that have been breached.
    We reserve the right to block the access to this Website, permanently or temporarily, as we see fit. Any such users shall be notified and afterwards you shall not try to use this website under any other name or through any other user. 
    
    
    7. Warranty
    
    a. As we strive to make sure that no material available on this website for download is in any way contaminated, we do not offer guarantees that these materials shall be free of infections, viruses, etc.
    
    b. Due to the nature of the software and of the internet, we do not offer guarantees that access to or the use of this website shall be uninterrupted or error free. We cannot be held responsible unless we can process the details due to circumstances beyond our reasonable control.
    
    c. The information provided on this website is of general interest and does not represent specific recommendations. As we make sure that the information on this website is correct, complete and updated we shall offer no guarantees that this is the case.
    
    d. We offer no guarantees that the Website or the information available on the Website complies with laws other than the Romanian laws.
    
    
    8. Liability
    
    a. We shall not be liable for any malfunctions caused by the software, internet errors or any other circumstances beyond our reasonable control.
    
    b. Systems may be enabled to prevent the automatic programmes used to gain unauthorized access to our system and to this website. You are not allowed to use automatic programmes for such purposes and any such use or attempt made by you to use these automatic programmes shall be made at your own risk. We shall not be liable for any consequence derived from or in connection to any such use or attempt to use automatic programmes in order to gain unauthorized access to our system or to that of this site. 
    
    c. We shall not take upon ourselves any liability for any losses caused by the use of this website or if such loss was based on any information provided on this website,
    
    d. We shall not be liable to you for:
    
    ● loss of profit;
    
    ● deteriorations or other damages that were intentionally or unintentionally hidden by the owner;
    
    ●loss of businesses;
    
    ● loss of reputation;
    
    ● depletion of goodwill;
    
    ● loss, deterioration or corruption of data.
    
    
    9. Relationship with advertising agencies and real estate agents
    
    Any agreements with, or participation in promotions of the advertising agencies and of real estate agencies found on the website and through the Service, including payment and terms for service delivery, and any other terms and conditions, guarantees are only between you and those advertising or real estate agencies. You agree that Mira Auction S.R.L. shall not be responsible or liable for any loss or prejudice of any kind caused due to a relationship or as a result of the presence of such advertising or real estate agencies in service.
    
    
    10. Jurisdiction
    
    The Romanian law shall apply to these terms and conditions. You irrevocably agree that the Romanian courts shall have exclusive jurisdiction in solving any disputes that may arise from or in connection to these terms and conditions.
    
    
    11. Notifications
    
    Any notifications shall be mailed to the address Mira Auction SRL, Cluj Napoca, str. Gruia, Nr. 28A, ap.118, Jud. Cluj and e-mailed to $0
    
    
    12. Service resale
    
    You agree not to reproduce, duplicate, copy, sell, resell or exploit to any commercial end, any portion of the Service, use of the Service, or access to the service.
    
    
    13. Registration and the user account
    
    13.1 The registration process is free of charge. Once registered, the user and Mira Auction S.R.L. conclude an agreement the subject matter of which is the use of the public section of miraauction.com (hereinafter referred to “membership agreement”). A user shall have no claim in order to conclude the membership agreement.
    
    13.2. Only legal and natural persons with full capacity to conclude legal documents may register. The registration of underage children is specifically prohibited.
    
    13.3 The information required from a participant during registration must be complete and accurate. The registration process requires a valid e-mail address. The user may choose a personal password that must be kept secret and which must not be revealed to any third parties.
    
    13.4. Should the information provided during registration be subsequently amended, the user has the obligation to promptly update that information contained in the user account. 
    
    13.5. Each participant may register on miraauction.com only once. The simultaneous registration of more than one user account is prohibited. Eluding this provision is prohibited. The user account is not transferable.
    
    13.6. Mira Auction S.R.L. is not responsible for the erroneous information introduced by the users, for the lack of full capacity to conclude legal documents and, in general, for any information introduced by the user and which does not correlate with the reality.
    
    
    14. Personal data
    
    14.1. Personal data shall mean any information that may be related to any natural person identified or identifiable (subject person). Personal data includes all types of information direct or indirect (namely used in relation with other data) referring to the subject person such as surname, name, e-mail addresses, phone numbers, etc., WITHOUT Personal Identification Number (C.N.P.).
    
    14.2. Use of personal data
    
    We shall use the personal data only for the purposes for which it was collected and we shall store the data only as long as necessary to achieve such purpose. We shall keep the client’s information throughout the time the user’s account is active, to offer services, to comply with our legal obligations or for any of the above-mentioned purposes. Access to personal data is strictly limited to the personnel of Mira Auction S.R.L. and to the partners holding the necessary contractual authorization and the clearly defined need to use such data, only with the user’s consent and upon the user’s request.
    
    
    15. General matters
    
    ● By filling out a Form, available on the website, an announcement that shall contain the following will be created:
    o the real description of the product which is the subject matter of the    Announcement;
    o the price.
    ● An Announcement MAY NOT contain, without the Provider’s consent, in detail the title, name, photographs, the following:
    o telephone numbers
    o links
    o e-mail addresses 
    ● It is prohibited to publish several announcements for the same property.
    ● By sending the Provider the Announcement to be published, the User declares that they fully and unconditionally agree with the provisions of these Terms and Conditions, fully agreeing that in case of incompliance with such provisions, they may be held liable according to the provisions herein. The User takes full responsibility for the content of the Announcement and for the accuracy of the information published in the Announcement and/or communicated to the Provider.
    ● The approved Announcements shall be only those presenting sales or lease of movable or immovable property.
    ● The Provider reserves the right to amend or delete those announcements which do not fully comply with these Terms and Conditions, with or without notifying the User.
    ● The validity of an Announcement on the website is of 60 days for Sale and of 20 days for Lease, with the possibility of extension until the end of this term.
    ● The Services comply with the legislation on the consumer affairs pursuant to G.O 21/1992, to Law 363/2002 on the electronic commerce and G.O. 130/2000 on the consumer affairs with the conclusion and execution of remote agreements.
    ● The “Add announcement” Service is destined entirely to owners of movable and immovable property. The real estate agencies and the real estate groups are forbidden to add real estate announcements using this service unless they have the Provider’s written consent to do so. Should the Provider find irregularities in this regard, the Provider reserves the right to delete all these announcements without the prior notification of the user. The Provider reserves the right to limit the number of announcements that may be added by the users of the website.
    

    16.  Payment by bank transfer/cash deposit at the bank  
    
    ● The payment may be made by bank transfer or by cash deposit in the account no. RO80BTRLRONCRT0571849301.
    All documents related to the Transactions shall be kept by Mira Auction S.R.L. for an unspecified period of time depending on necessities.
    Commissions, Fees, Payments:
    
    o No additional fee shall be charged for transactions.
    
    
    17. External websites
    
    Mira Auction S.R.L. shall not be responsible for the content of any external internet websites.
    
    
    18. Breach

    Please report any breach of these Terms to our client service team using the contact section.
      
    `,
    loginEmailAndPasswordError: "Could not login, email and/or password are wrong, please try again!",
    accountType: "Account type",
    individual: "Individual",
    legalIdentity: "Legal identity",
    passwordMustBeValid: "The password must be at least 8 characters, contain at least one small letter, one capital letter and a number",
    confirmPasswordRequired: "Confirm password is required",
    confirmPasswordMustBeValid: "The password must be similar to the confirm password",
    firstNameRequired: "First name is required",
    lastNameRequired: "Last name is required",
    companyNameRequired: "Company name is required",
    phoneNumberRequired: "Phone number is required",
    phoneNumberMustBeValid: "The phone number must be formed of 10 digits",
    personalIdentificationNumberRequired: "Personal identification number is required",
    uniqueIdentificationCodeRequired: "Unique identification code is required",
    addressRequired: "Address is required",
    confirmationPassword: "Confirmation password",
    firstName: "First name",
    lastName: "Last name",
    delete: "Delete",
    phoneNumber: "Phone number",
    companyName: "Company name",
    personalIdentificationNumber: "Personal identification number",
    uniqueIdentificationCode: "Unique identification code",
    address: "Address",
    emailTaken: "Email already taken, login in your existing account or choose another email address!",
    somethingWentWrong: "Something went wrong, please try again and if the error persists, contact us!",
    fillInFieldsToUseAccount: "In order to use your account on our platform, please provide this important data!",
    personalIndentificationNumberMustBeValid: "Personal identification number must be valid",
    agreeToTermsAndConditions: "I have read and I agree with the terms and conditions.",
    logout: "Logout",
    settings: "Settings",
    notifications: "Notifications",
    adminPage: "Admin page",
    changeLanguageTo: "Change language to Romanian",
    remainLoggedIn: "Remain logged in",
    verificationEmailSent: "An email verification link was sent to your email address, in order for your account to get verified, first, please verify your email!",
    resetPassword: "Reset password",
    sendResetPasswordEmail: "Send the reset password email",
    resetPasswordDetails: "By resetting your password, you will receive an email containing a reset link and by accessing it you will be able to set a new password. Your current password will not be modified until you save the new password.",
    emailNotFound: "The email you submitted is not associated with any account!",
    resetEmailSent: "Password reset email sent successfully, check your inbox.",
    accountDetails: "Account details",
    cancel: "Cancel",
    save: "Save",
    currentPassword: "Current password",
    bidFrom: "Bid from",
    price: "Price",
    verified: "Verified",
    notVerified: "Not verified",
    searchForIdNameDescription: "Search for id, name or description",
    filters: "Filters",
    all: "All",
    noPostMatchesSelectedFilters: "No posts found for searched keyword or the selected filters.",
    clearFilters: "Clear filters",
    addPost: "Add a post",
    areYouSure: "Are you sure?",
    closingPageAwareness: "By closing the page, all the written data which was not saved will be lost.",
    deletePostAwareness: "This operation cannot be undone.",
    closeWithoutSaving: "Close without saving",
    selectACategory: "Select a category",
    postTitle: "Post title",
    romanianTitle: "Romanian title",
    englishTitle: "English title",
    postDescription: "Post description",
    romanianDescription: "Romanian description",
    englishDescription: "English description",
    selectExactLocation: "Select exact location",
    overview: "Overview",
    users: "Users",
    actions: "Actions",
    active: "Active",
    banned: "Banned",
    viewDetails: "View details",
    verify: "Verify",
    unverify: "Unverify",
    ban: "Ban",
    unban: "Unban",
    userType: "User type",
    noUsers: "No users",
    search: "Search",
    confirmLocation: "Confirm location",
    mainImage: "Main image",
    chooseMainImage: "Choose main image",
    presentationImages: "Presentation Images ($0 minimum, $1 maximum)",
    chooseImages: "Choose images",
    clearImages: "Clear images",
    characteristics: "Characteristics",
    typeANumber: "Type a number",
    selectAValue: "Select a value",
    thePrice: "The price",
    enableBiddingForThisPost: "Enable bidding for this post",
    startingPrice: "Starting price",
    biddingEndDate: "Bidding end date",
    addPostError: "In order to add the post, all fields must be filled in, the location has to be confirmed using the button underneath the map, upload a main image and respect the requirements for the presentaion images.",
    addThePost: "Add the post",
    updateThePost: "Update the post",
    updatePostError: "In order to update the post, all fields must be filled in, the location has to be confirmed using the button underneath the map, upload a main image and respect the requirements for the presentaion images.",
    daysLeft: "Days left",
    hoursLeft: "Hours left",
    minutesLeft: "Minutes left",
    lessThanOneMinute: "Less than one minute",
    bids: "Bids",
    sales: "Sales",
    disable: "Disable",
    activate: "Activate",
    deactivate: "Deactivate",
    edit: "Edit",
    archive: "Archive",
    unarchive: "Unarchive",
    copyAuthorId: "Copy author ID",
    noPostFoundWithId: "No post found with the ID",
    offer: "Offer",
    thePostIsAvailableUntil: "The post is availabale until",
    totalBidsMadeForThisPost: "Total bids made for this post",
    youMustBeLoggedInToPLaceAnOffer: "You must be logged into your account in order to place an offer!",
    forMoreDetailsCallUs: "For more details, call us at the number below",
    description: "Description",
    noCharacteristicsFoundForTheSearchedKeywords: "No characteristics found for the searched keywords.",
    yes: "Yes",
    no: "No",
    biddingHasEnded: "Bidding for ths post has ended. Check for similar posts in the category",
    expired: "Expired",
    inactive: "Inactive",
    showZone: "Show zone",
    hideZone: "Hide zone",
    noCategories: "No categories",
    posts: "Posts",
    categories: "Categories",
    reviews: "Reviews",
    name: "Name",
    characteristicsNumber: "Characteristics number",
    productsNumber: "Products number",
    createDate: "Create date",
    status: "Status",
    accountIsBanned: "Account is banned",
    yourAccountIsBannedAndYouCannotUse: "Your account is banned and you cannot use",
    reason: "Reason",
    ifYouThinkThisIsAMistake: "If you think this is a mistake or you need further information, contact us by phone",
    orEmail: "Or email",
    unknown: "Unknown",
    askingPrice: "Asking price",
    typeAnOffer: "Type an offer",
    send: "Send",
    house: "House",
    constructionYear: "Construction year",
    renovationYear: "Renovation year",
    separated: "Separated",
    semiSeparated: "Semi-separated",
    studio: "Studio",
    circular: "Circular",
    duplex: "Duplex",
    partitioning: "Partitioning",
    floor: "Floor",
    semiBasement: "Semi-basement",
    groundFloor: "Ground floor",
    totalStories: "Total stories",
    numberOfRooms: "Number of rooms",
    numberOfBathrooms: "Number of bathrooms",
    numberOfBathroomsWithWindow: "Number of bathrooms with window",
    numberOfBalconies: "Number of balconies",
    balconiesType: "Balconies type",
    purpose: "Purpose",
    usableArea: "Usable area",
    housing: "Housing",
    office: "Office",
    commercialSpace: "Commercial space",
    without: "Without",
    interior: "Interior",
    exterior: "Exterior",
    terrace: "Terrace",
    numberOfBedrooms: "Number of bedrooms",
    joinDate: "Join date",
    userBiddings: "User's biddings",
    productId: "Product ID",
    value: "Value",
    bidDate: "Bid date",
    viewUserPosts: "View user's posts",
    copy: "Copy",
    generalInformation: "General information",
    userDetails: "User details",
    seePost: "See post",
    noBidsForUser: "This user didn't bid yet",
    menu: "Menu",
    closeMenu: "Close menu",
    back: "Back",
    landingPageHeroHeading: "Let's discover together exactly what you need!",
    landingPageHeroSubtitle: "We help you buy and sell for a correct price",
    buy: "Buy",
    sell: "Sell",
    rent: "Rent",
    landingIntroductionTitle: "Mira Auction, for a happy life",
    ourHelpHeader: "How can we help",
    buyingAHomeTitle: "Buying a home?",
    buyingAHomeDescription: "We have over $0 homes for sale on our website",
    sellingAHomeTitle: "Selling a home?",
    sellingAHomeDescription: "We can guarantee that you will sell for a correct price",
    rentingAHomeTitle: "Looking to rent a home?",
    rentingAHomeDescription: "Finding a home for rent has never been easier",
    guideHeader: "How do I",
    guideBid: "Bid",
    guideSellRent: "Sell / Rent",
    guideBidSubtitle: "(Without any fees)",
    guideFirstStep: "Make sure you are signed in",
    guideBidStep2: "Choose your house",
    guideBidStep3: "Start bidding",
    guideBidStep4: "You won the auction? Wait for our contact!",
    guideLastStep: "Sign the contract!",
    guideSellRentStep2: "Choose between selling and renting",
    guideSellRentStep3: "Choose your price",
    guideSellRentStep4: "Monitor offers",
    guideSellRentStep5: "Satisfied with the biggest offer? Congrats!",
    guideBidDisclaimer: "Before receiving the seller’s contact information, you will have to sign a real estate brokerage contract with Mira Auction. According to the contract, Mira Auction will get a brokerage commission of 1% of the value of the accepted offer. At your request, the Mira Auction team will be present when the buyer and seller sign the contract, in the presence of a public notary.",
    introductionHeader: "Mira Auction, for a happy life",
    introductionText: "Mira Auction aims to give you a hand by presenting you with a wide range of real estates, both for living and commercial purposes. Having a great deal of experience in the field and knowing that the process of choosing a home is overwhelming sometimes, our aim is to facilitate this process. As we know, picking and buying a home is a special event, thus our team’s job is to help you make the right deal for a fair price. Bid confidently. You won the auction?! Congratulations! Our team will help you get in touch with the seller. Have you changed your mind? You have nothing to worry about as we’re sure that the next bidder will be happy for getting a second chance.",
    whyChooseUsHeader: "Why choose us?",
    whyChooseUsOption1Header: "User friendly",
    whyChooseUsOption2Header: "Support 24/7",
    whyChooseUsOption3Header: "Professional expertise",
    whyChooseUsOption1Text: "Our simple but concise interface was designed for your ease of use",
    whyChooseUsOption2Text: "Our team is at your service anytime, so don't be shy, contact us!",
    whyChooseUsOption3Text: "Our verified posts give you the confidence that what you see is truthful",
    ourTeamHeader: "Our Team",
    customersReviewsHeader: "Customers Reviews",
    contactUsHeader: "Contact Us",
    contactUs: "Contact us",
    activateAdminRole: "Activate admin role",
    deactivateAdminRole: "Deactivate admin role",
    copyId: "Copy ID",
    user: "User",
    admin: "Admininstrator",
    superAdmin: "Super Administrator",
    applyFilters: "Apply filters",
    county: "County",
    city: "City",
    noBidsForPost: "No bids for post",
    userId: "User ID",
    operations: "Operations",
    zone: "Zone",
    congratulationsForMoreDetailsCallUs: "Congratulations! You are the bidder with the highest amount for this post and you have 8 days to decide upon this offer. For more details, call us at the number below:",
    thisPostIsNotAvailableAnymore: "This post is not available anymore, for similar posts visit the category",
    provideBanReason: "Provide a reason for banning this user",
    banReasonInRomanian: "Type a reason in Romanian",
    banReasonInEnglish: "Type a reason in English",
    parkingSpace: "Parking space",
    hasElevator: "Has elevator",
    elevator: "Elevator",
    forRent: "For rent",
    petFriendly: "Pet friendly",
    latitude: "Latitude",
    longitude: "Longitude",
    notSet: "Not set",
    pickLocation: "Pick location",
    home: "Home",
    myPosts: "My posts",
    sort: "Sort",
    ascendingDate: "Ascending by date",
    descendingDate: "Descending by date",
    ascendingPrice: "Ascending by price",
    descendingPrice: "Descending by price",
    youtubeVideoLink: "YouTube video link",
    youtubeUrl: "YouTube URL",
    videoPresentation: "Video presentation",
    schedule: "Schedule",
    mondayToSaturday: "Monday - Saturday",
    officeMiraAuction: "Office Mira Auction",
    searchByUserId: "Search by user ID",
    noBidsForUserId: "No bids for user ID: $0",
    addPostForRealEste: "Add a post in real estate",
    addPostForRenting: "Add a post in renting",
    editThePost: "Edit the post: $0",
    roundmeUrl: "Roundme URL",
    roundmePanoramaLink: "Roundme panorama link",
    panoramicView: "Panoramic view",
    sold: "Sold",
    changeLanguage: "Change language",
    siteIsUnderMaintenance: "Sorry for the inconvenience, the $0 website is under maintenance.",
    siteMaintenanceTimeframe: "We will be back shortly, our technical team is working hard to improve your experience. The downtime is planned to take place between $0 and $1.",
    maintenanceContact: "If you have an issue, don't hesitate to contact us by phone: $0 or by email: $1.",
    extendBiddingDeadlines: "Extend bidding deadlines",
    extend: "Extend",
    extendAllPostsBiddingsThatWillExpire: "Extend all posts' biddings that will expire in the next",
    daysUntil: "Day(s) until",
    abarth: "Abarth",
    aixam: "Aixam",
    alfaRomeo: "Alfa Romeo",
    other: "Other",
    aro: "Aro",
    astonMartin: "Aston Martin",
    audi: "Audi",
    bentley: "Bentley",
    bmw: "BMW",
    buick: "Buick",
    cadillac: "Cadillac",
    chevrolet: "Chevrolet",
    chrysler: "Chrysler",
    citroen: "Citroen",
    cupra: "Cupra",
    dacia: "Dacia",
    daewoo: "Daewoo",
    daihatsu: "Daihatsu",
    dodge: "Dodge",
    ds: "DS",
    ferrari: "Ferrari",
    fiat: "Fiat",
    ford: "Ford",
    gmc: "GMC",
    honda: "Honda",
    hummer: "Hummer",
    hyundai: "Hyundai",
    infiniti: "Infiniti",
    isuzu: "Isuzu",
    iveco: "Iveco",
    jaguar: "Jaguar",
    jeep: "Jeep",
    kia: "Kia",
    lada: "Lada",
    lamborghini: "Lamborghini",
    lancia: "Lancia",
    landRover: "Land Rover",
    lexus: "Lexus",
    lincoln: "Lincoln",
    maserati: "Maserati",
    maybach: "Maybach",
    mazda: "Mazda",
    mclaren: "McLaren",
    mercedesBenz: "Mercedes-Benz",
    mini: "Mini",
    mitsubishi: "Mitsubishi",
    nissan: "Nissan",
    oldsmobile: "Oldsmobile",
    opel: "Opel",
    peugeot: "Peugeot",
    pontiac: "Pontiac",
    porsche: "Porsche",
    renault: "Renault",
    rollsRoyce: "Rolls-Royce",
    rover: "Rover",
    saab: "Saab",
    saturn: "Saturn",
    seat: "Seat",
    skoda: "Skoda",
    smart: "Smart",
    ssangyong: "SsangYong",
    subaru: "Subaru",
    suzuki: "Suzuki",
    tata: "Tata",
    tesla: "Tesla",
    toyota: "Toyota",
    volkswagen: "Volkswagen",
    volvo: "Volvo",
    wartburg: "Wartburg",
    used: "Used",
    new: "New",
    petrol: "Petrol",
    diesel: "Diesel",
    lpg: "LPG",
    electric: "Electric",
    cabrio: "Cabrio",
    berline: "Berline",
    coupe: "Coupe",
    pickup: "Pickup",
    hatchback: "Hatchback",
    break: "Break",
    offRoad: "Off road",
    minibus: "Minibus",
    monovolum: "Monovolum",
    suv: "SUV",
    white: "White",
    black: "Black",
    gray: "Gray",
    silver: "Silver",
    red: "Red",
    blue: "Blue",
    brown: "Brown",
    green: "Green",
    beige: "Beige",
    orange: "Orange",
    gold: "Gold",
    yellow: "Yellow",
    purple: "Purple",
    manual: "Manual",
    automatic: "Automatic",
    private: "Private",
    firm: "Firm",
    brand: "Brand",
    model: "Model",
    fabricationYear: "Fabrication year",
    mileage: "Mileage",
    engineCapacity: "Engine capacity",
    condition: "Condition",
    fuel: "Fuel",
    body: "Body",
    color: "Color",
    gearbox: "Gearbox",
    privateOrFirm: "Private/Firm",
    addPostForVehicles: "Add a post in vehicles",
    partners: "Partners",
    buyingCreditOption: "This vehicle can also be purchased in installments through the intermediary",
    loanOptionReminder: "We offer loan brokerage support, without commission.",
    today: "Today",
    yesterday: "Yesterday",
    bidOn: "bid on",
    yourPost: "your post",
    aPost: "a post",
    seeBidder: "See bidder",
    seeAuthor: "See author",
    noNotificationsYet: "No notifications yet 💤",
    notificationsSound: "Notifications sound"
};