import React from "react";
import { useScrollTrigger } from "@material-ui/core";

interface Props {
    children: React.ReactElement;
    window?: () => Window;
}

const ElevationScroll = ({ children, window }: Props) => {
    const trigger = useScrollTrigger({
        disableHysteresis: true,
        threshold: 0,
        target: window && window()
    });

    return React.cloneElement(children, {
        elevation: trigger ? 4 : 0
    });
}

export default ElevationScroll;