import { auth } from "../firebase";
import { FIRESTORE_BIDS } from "../constants";
import { Bid, BasePost } from "../types";
import { getUser, setUser } from "./user";
import { toJS } from "mobx";
import { firestorePosts, getPost, setPost } from "./post";
import { sortBy, unique } from "utils/array-utils";
import getFirestoreFetch from "database/firestore-fetch";

type PostsMap = {
    [key: string]: BasePost;
}

const firestoreBids = getFirestoreFetch<Bid>(FIRESTORE_BIDS);

export const getBid = (bidId: string) => firestoreBids.get(bidId);

export const getRealtimeBidsForPost = (postId: string, onChange: (bids: Bid[]) => void) =>
    firestoreBids.subscribeAll(bids => onChange(sortBy(bids, bid => bid.value)), {
        property: "forPostId",
        condition: "==",
        value: postId
    });

export const getBidForPost = (postId: string) => firestoreBids.getAll({
        property: "forPostId",
        condition: "==",
        value: postId
    });

export const includePosts = async (bids: Bid[]) => {
    const postsIds = unique(bids.map(bid => bid.forPostId));

    if (!postsIds.length) {
        return [];
    }

    const posts: PostsMap = {};

    (await firestorePosts.where("id", "in", postsIds).get()).forEach(doc => {
        const post = doc.data() as BasePost;

        posts[post.id] = post;
    });

    return bids.map(bid => ({
        ...bid,
        forPost: posts[bid.forPostId]
    }));
}

export const getBidsForUser = (userId: string) => firestoreBids.getAll({
    property: "byUserId",
    condition: "==",
    value: userId
});

export const includeUser = async (bid: Bid) => {
    const user = await getUser(bid.byUserId);
    if (user) {
        bid.byUser = user;
    }

    return bid;
}
    
export const includePost = async (bid: Bid) => {
    bid.forPost = await getPost(bid.forPostId) || undefined;

    return bid;
}

export const setBid = (bid: Bid) => firestoreBids.set(bid.id, stripBid(bid));

export const generateBidDocument = () => firestoreBids.newIdDocument();

export const createBid = async (bid: Bid, generatedBidIdDocument = generateBidDocument()) => {
    const timestamp = new Date();

    bid.id = generatedBidIdDocument.id;
    bid.time = timestamp;
    bid.byUserId = auth.getCurrentUser()?.uid!;

    if (!bid.forPost) {
        bid = await includePost(bid);
    }

    bid.forPost?.bidsIds.push(bid.id);

    if (!bid.byUser) {
        bid = await includeUser(bid);
    }

    bid.byUser?.bidsIds.push(bid.id);

    await setPost(bid.forPost!);
    await setUser(bid.byUser!);
    await generatedBidIdDocument.set(stripBid(bid));

    return bid;
}

const stripBid = (bid: Bid) => {
    const strippedBid = toJS(bid);

    delete strippedBid.byUser;
    delete strippedBid.forPost;
    
    return strippedBid;
}