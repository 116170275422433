import React from "react";
import UserDetailsItem from "./components/user-details-item";
import { useTranslation } from "infrastructure/hooks";
import { TranslationStringKey } from "utils/translation/types";
import { formatMoment, getMomentFromTime } from "utils/date-utils";
import styles from "./general-information.module.scss";
import { User } from "database/types";
import { observer } from "mobx-react";

interface Props {
    user: User;
}

const GeneralInformation = ({ user }: Props) => {

    const { getString } = useTranslation();

    return (
        <div className={styles.generalInformation}>
            <h3>{getString("generalInformation")}</h3>
            <UserDetailsItem userKey="ID"
                value={user.id}
                tooltipTitle={getString("copyId")} />
            <UserDetailsItem
                userKey={getString("firstName")}
                value={user.firstName}
                tooltipTitle={`${getString("copy")} ${getString("firstName")}`} />
            <UserDetailsItem
                userKey={getString("lastName")}
                value={user.lastName}
                tooltipTitle={`${getString("copy")} ${getString("lastName")}`} />
            <UserDetailsItem
                userKey={getString("phoneNumber")}
                value={user.phoneNumber}
                tooltipTitle={`${getString("copy")} ${getString("phoneNumber")}`} />
            <UserDetailsItem
                userKey="Email"
                value={user.email}
                tooltipTitle={`${getString("copy")} Email`} />
            <UserDetailsItem
                userKey={getString("userType")}
                value={getString(user.isIndividual ? "individual" : "legalIdentity")} />
            <UserDetailsItem
                userKey={getString("verified")}
                value={getString(user.verified ? "yes" : "no")} />
            <UserDetailsItem
                userKey={getString("accountType")}
                value={getString(user.accountType as TranslationStringKey)} />
            <UserDetailsItem
                userKey={getString("banned")}
                value={getString(user.banned ? "yes" : "no")} />
            <UserDetailsItem
                userKey={getString("joinDate")}
                value={formatMoment(getMomentFromTime(user.joinDate))} />
        </div>
    )
};

export default observer(GeneralInformation);