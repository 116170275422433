import classNames from "classnames";
import React, { useEffect } from "react";
import { Image } from "..";
import { scrollHorizontallyIntoView } from "../../utils/ui-utils";
import styles from "./image-carousel.module.scss";

interface Props {
    images: string[];
    selectedImage: number;
    onClick: (index: number) => void;
}

const scrollToSelected = () =>
    scrollHorizontallyIntoView(`img.${styles.imageItem}.${styles.selected}`, "center");

const ImageCarousel = ({ images, selectedImage, onClick }: Props) => {
    useEffect(() => {
        scrollToSelected();
    }, [selectedImage]);

    return (
        <div className={styles.imagesCarousel}>
            {images.map((image, index) => (
                <Image
                    key={index}
                    src={image}
                    className={classNames(styles.imageItem, {
                        [styles.selected]: selectedImage === index
                    })}
                    onClick={() => onClick(index)} />
            ))}
        </div>
    );
}

export default ImageCarousel;