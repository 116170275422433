import { useCallback, useContext } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { LANGUAGE_CODE_EN, LANGUAGE_CODE_RO, QUERY_PARAMETER_LANGUAGE } from "utils/translation/constants";
import { STATIC_STRINGS_EN } from "utils/translation/strings-en";
import { STATIC_STRINGS_RO } from "utils/translation/strings-ro";
import { LanguageType, TranslationStringKey } from "utils/translation/types";
import { getAppendedQueryParams } from "utils/url-utils";
import { Text } from "database/types";
import { TranslationContext } from "../services/translation-store";

const useTranslation = () => {
    const [searchParams] = useSearchParams();
    const { pathname, search } = useLocation();
    const navigate = useNavigate();
    const { initialize, markForUpdate } = useContext(TranslationContext);

    const language = searchParams.get(QUERY_PARAMETER_LANGUAGE);

    const isEnglish = useCallback(() => language === LANGUAGE_CODE_EN, [language]);

    const isRomanian = useCallback(() =>
        language === LANGUAGE_CODE_RO || language !== LANGUAGE_CODE_EN, [language]);

    const getCurrentLanguageCode = () => isEnglish() ? LANGUAGE_CODE_EN : LANGUAGE_CODE_RO;

    const changeLanguageTo = (useLanguage: LanguageType) => {
        const queryParams = getAppendedQueryParams(search, [{key: "lang", value: useLanguage}]);
        markForUpdate();
        navigate({
            pathname,
            search: queryParams
        });
    }

    const getString = useCallback((key: TranslationStringKey, ...values: (string | number)[]) => {
        let string = (isEnglish() ? STATIC_STRINGS_EN : STATIC_STRINGS_RO)[key];

        values.forEach((value, index) => {
            const regex = new RegExp(`\\$${index}`, "g");
            if (!regex.test(string)) {
                console.warn(`Placeholder $${index} not found in '${string}',` +
                    ` value '${value}' cold not be inserted!`, values);
                return;
            }

            string = string.replace(regex, value.toString());
        })

        return string;
    }, [isEnglish]);

    const getText = useCallback((text?: Text, defaultValue: string = "") => {
        if (!text) {
            return defaultValue;
        }
        if (isEnglish()) {
            return text.en;
        }
        return text.ro;
    }, [isEnglish]);

    initialize(getString);

    return {
        isEnglish,
        isRomanian,
        getCurrentLanguageCode,
        getString,
        getText,
        changeLanguageTo
    };
};

export default useTranslation;