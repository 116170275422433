import React from "react";
import { Image } from "components/index";
import { Typography } from "@material-ui/core";
import { useTranslation } from "infrastructure/hooks";
import { TranslationStringKey } from "utils/translation/types";
import styles from "./characteristic-item.module.scss";

export interface CharacteristicItemProps {
    icon: string;
    name: TranslationStringKey;
    value: string | number;
    nameElement?: JSX.Element;
    valueElement?: JSX.Element;
}

export const CharacteristicItem = ({
    icon,
    name,
    value,
    nameElement,
    valueElement
}: CharacteristicItemProps) => {
    const { getString } = useTranslation();

    const renderedValue = valueElement || value;

    return (
        <div className={styles.characteristicItemWrapper}>
            <Image src={icon} />
            <Typography align="center" color="primary">{renderedValue}</Typography>
            <div className={styles.details}>
                <Typography align="center">{nameElement || getString(name)}</Typography>
                <Typography align="center" color="primary">{renderedValue}</Typography>
            </div>
        </div>
    );
}