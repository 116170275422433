import React from "react";
import { BoldText } from "components";
import { useTranslation } from "infrastructure/hooks";
import styles from "./section-panorama-view.module.scss";

interface Props {
    panoramaUrl?: string;
}

const iframeProperties = {
    mozallowfullscreen: "true",
    webkitallowfullscreen: "true"
}

const extractPanoramaId = (panorama?: string) => {
    if (!panorama) {
        return;
    }
    if (panorama.startsWith("https://roundme.com/tour/")) {
        return panorama.substring(25, 31);
    }
    console.warn(`Could not extract the ID from the URL "${panorama}"`);
}

const SectionPanoramaView = ({ panoramaUrl }: Props) => {
    const { getString } = useTranslation();

    const panoramaId = extractPanoramaId(panoramaUrl);

    if (!panoramaId) {
        return null;
    }

    return (
        <div className={styles.container}>
            <BoldText variant="h5">{getString("panoramicView")}</BoldText>
            <div className={styles.panoramaWrapper}>
                <iframe
                    {...iframeProperties}
                    title={getString("panoramicView")}
                    width="100%"
                    height="480px"
                    src={`https://roundme.com/embed/${panoramaId}`}
                    frameBorder="0"
                    allowFullScreen
                    onWheel={() => {}} />
            </div>
        </div>
    );
}

export default SectionPanoramaView;