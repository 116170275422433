import React from "react";
import classNames from "classnames";
import { auth } from "database/firebase";
import { Typography, Chip, Link } from "@material-ui/core";
import { useTranslation } from "infrastructure/hooks";
import SectionBidding from "./section-bidding";
import { TranslationStringKey } from "utils/translation/types";
import { useNavigation } from "utils/router-utils";
import { Bid, BasePost } from "database/types";
import {
    getMomentFromTime,
    hasPassedMoment,
    getMomentAfterDays
} from "utils/date-utils";
import styles from "./section-contact-and-pricing.module.scss";
import { CONTACT_PHONE } from "utils/constants";
import { getFormattedPrice } from "utils/number-utils";
import { BoldText } from "components";

interface Props {
    store: {
        post: BasePost;
        areBidsLoading: boolean;
        bids: Bid[];
        placeBid: (bid: number) => void;
    };
    minPrice?: number;
    maxPrice?: number;
    className?: string;
    additionalPostDetails?: string | JSX.Element;
    loanReminder?: boolean;
}

const SectionContactAndPricing = ({
    store,
    minPrice,
    maxPrice,
    className,
    additionalPostDetails,
    loanReminder
}: Props) => {
    const { goTo } = useNavigation();
    const { getString } = useTranslation();
    const { post, bids } = store;

    const lastBid = bids.length ? bids[bids.length - 1] : null;
    const biddingEndDate = getMomentFromTime(post.biddingEndDate);

    const handleCategoryClick = () => {
        goTo(`/${post?.category}`);
    }

    const getPaymentDetails = () => <>
        {loanReminder && (
            <BoldText align="center">{getString("loanOptionReminder")}</BoldText>
        )}
        {additionalPostDetails}
    </>;

    const getContactDetails = () => <>
        <BoldText color="primary" variant="h5" align="center">
            {getString("price")}: {getFormattedPrice(post.price)}
        </BoldText>
        {getPaymentDetails()}
        <Typography className={styles.callUs} variant="subtitle1" align="center">
            {getString("forMoreDetailsCallUs")}
        </Typography>
        <Chip className={styles.phoneChip} label={CONTACT_PHONE} color="primary" />
    </>;

    const getWinnerResult = () => <>
        {getPaymentDetails()}
        <Typography className={styles.callUs} variant="subtitle1" align="center">
            {getString("congratulationsForMoreDetailsCallUs")}
        </Typography>
        <Chip className={styles.phoneChip} label={CONTACT_PHONE} color="primary" />
    </>;

    const getInactiveMessage = () => <>
        <div className={styles.soldBanner}>{getString("sold")}</div>
        <Typography align="center" color="error">
            {getString("thisPostIsNotAvailableAnymore")}: <Link onClick={handleCategoryClick}>
                {getString(post.category as TranslationStringKey)}
            </Link>
        </Typography>
    </>;

    const getEndOfBiddingDetails = () => {
        if (hasPassedMoment(getMomentAfterDays(8, biddingEndDate))) {
            return getInactiveMessage();
        }
        if (lastBid && lastBid.byUserId === auth.getCurrentUser()?.uid) {
            return getWinnerResult();
        }
        return getInactiveMessage();
    }

    const getActivePostDetails = () => {
        if (!post.hasBidding) {
            return getContactDetails();
        }
        if (hasPassedMoment(biddingEndDate)) {
            return getEndOfBiddingDetails();
        }
        if (minPrice === undefined || maxPrice === undefined) {
            throw new Error("Bidding needs a minimum and maximum price!");
        }
        return <>
            {additionalPostDetails}
            <SectionBidding
                store={store}
                minPrice={minPrice}
                maxPrice={maxPrice} />
        </>;
    }

    const getPostDetails = () => {
        if (post.isActive) {
            return getActivePostDetails();
        }
        return getInactiveMessage();
    }

    return (
        <div className={classNames(styles.sectionContactAndPricingWrapper, className)}>
            {getPostDetails()}
        </div>
    );
}

export default SectionContactAndPricing;