import React from "react";
import { Controller, Control, FieldValues } from "react-hook-form";
import { useTranslation } from "infrastructure/hooks";
import { TranslationStringKey } from "utils/translation/types";
import { FieldWithValidationRules } from "pages/account/types";

interface FieldProps {
	control: Control<FieldValues> | undefined;
	name: string;
	component: React.FunctionComponent<any>;
	rules?: FieldWithValidationRules<any>;
  defaultValue?: string;
	[key: string]: any;
}

const Field = ({
	control,
	name,
	rules,
  component: Component,
	defaultValue = "",
	...rest
}: FieldProps) => {
    const { getString } = useTranslation();

	return (
		<Controller
			control={control}
			name={name as any}
			rules={rules as any}
			defaultValue={defaultValue}
			render={({
				field: { value, onChange, onBlur, name },
				fieldState: { error, invalid },
			}) => (
				<Component
          			name={name}
					value={value}
					onChange={onChange}
					onBlur={onBlur}
					error={rules ? invalid : null}
					helperText={getString(error?.message as TranslationStringKey)}
					{...rest} />
			)} />
	);
};

export default Field;