import { makeUserNotificationSeen, pushAuctionNotification, receiveNotifications } from "database/api/notification";
import { BasePost, NotificationType, StateNotificationType } from "database/types";
import { makeAutoObservable, runInAction } from "mobx";
import { createContext, MutableRefObject } from "react";
import { toastService } from "..";
import { getUserDetails } from "../user-details/user-details-store";

export class NotificationsStore {
    public notifications: StateNotificationType[] = [];
    public isPaneOpen: boolean = false;
    public buttonReference: MutableRefObject<null> = { current: null};

    constructor() {
        makeAutoObservable(this);
    }

    public subscribe = (
        currentUserId: string, 
        onNewNotification: (notification: StateNotificationType) => void) => 
        receiveNotifications(currentUserId, notifications => {
            runInAction(() => this.notifications.unshift(...notifications));
            if (notifications.length === 1) {
                onNewNotification(notifications[0]);
            }
        });

    public setIsPaneOpen = (isOpen: boolean) => this.isPaneOpen = isOpen;

    public setButtonReference = (reference: MutableRefObject<null>) => this.buttonReference = reference;

    public getUnseenCount = () => this.notifications.count(notification => !notification.isSeen);

    public markNotificationRead = async (userNotificationId: string) => {
        const indexToEdit = this.notifications.findIndex(notification => 
            notification.userNotificationId === userNotificationId);
        const notification = this.notifications[indexToEdit];
        if (notification.isSeen) {
            return;
        }

        await makeUserNotificationSeen(userNotificationId);
        runInAction(() => this.notifications[indexToEdit] = {
            ...notification,
            isSeen: true
        });
    }

    public pushBiddingNotification = (post: BasePost, bidValue: number) => {
        const userDetails = getUserDetails();
        if (!userDetails) {
            toastService.error("Something went wrong...");
            return;
        }

        pushAuctionNotification({
            amount: bidValue,
            postId: post.id,
            postCategory: post.category,
            postMainImage: post.mainImage,
            postTitleEn: post.titleText?.en || "",
            postTitleRo: post.titleText?.ro || "",
            timestamp: new Date(),
            type: NotificationType.AUCTION,
            userFirstName: userDetails.firstName,
            userId: userDetails.id,
            userLastName: userDetails.lastName
        }, post.createdByUserId, post.bids?.map(bid => bid.byUserId) || []);
    }

    public reset = () => {
        this.notifications = [];
        this.isPaneOpen = false;
    }
}

export const notificationsStore = new NotificationsStore();
export const NotificationsContext = createContext(notificationsStore);

export const notificationService = { 
    pushBiddingNotification: notificationsStore.pushBiddingNotification
};