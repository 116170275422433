import { useContext } from "react";
import { DialogAddContext } from "./dialog-store";

const useAddDialog = () => {
    const dialogAddStore = useContext(DialogAddContext);

    const {
        isRealEstateAddVisible,
        isForRentAddVisible,
        isVehiclesAddVisible,
        openRealEstateAdd,
        closeRealEstateAdd,
        openForRentAdd,
        closeForRentAdd,
        openVehiclesAdd,
        closeVehiclesAdd
    } = dialogAddStore;

    return {
        isRealEstateAddVisible,
        openRealEstateAdd,
        closeRealEstateAdd,
        isForRentAddVisible,
        openForRentAdd,
        closeForRentAdd,
        isVehiclesAddVisible,
        openVehiclesAdd,
        closeVehiclesAdd
    };
}

export default useAddDialog;