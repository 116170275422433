import { FIRESTORE_TEXTS } from "../constants";
import { Text } from "../types";
import { getFirestoreCollection } from "../utils";

const firestoreTexts = getFirestoreCollection(FIRESTORE_TEXTS);

export const getText = async (textId: string) => 
    (await firestoreTexts.doc(textId).get()).data() as Text;

export const deleteText = async (textId: string) =>
    await firestoreTexts.doc(textId).delete();

export const setText = async (text: Text) => 
    await firestoreTexts.doc(text.id).set(text);

export const createText = async (text: Text) => {
    const newText = firestoreTexts.generate();
    text.id = newText.id;

    await newText.set(text);

    return text;
};