import getFirestoreFetch from "database/firestore-fetch";
import { FIRESTORE_APPLICATION, FIRESTORE_APPLICATION_MAINTENANCE } from "../constants";
import { Maintenance } from "../types";

const firestoreApplication = getFirestoreFetch<Maintenance>(FIRESTORE_APPLICATION);

export const getMaintenanceStatus = async () => {
    try {
        return await firestoreApplication.get(FIRESTORE_APPLICATION_MAINTENANCE);
    } catch {
        return null;
    }
}