import React, { PropsWithChildren } from "react";
import { useTranslation } from "infrastructure/hooks";
import { Button } from "@material-ui/core";
import { observer } from "mobx-react";
import { BasePost } from "database/types";
import styles from "./post-form.module.scss";
import {
    GeneralInformationSection,
    LocationSection,
    ImagesSection,
    CharacteristicsSection,
    PriceSection
} from "./sections";
import { EDIT_POST_WITH_BIDDING_SECTIONS, POST_SECTIONS } from "utils/constants";

interface Props<T> {
    post: T;
    minimumPrice: number;
    priceStep: number;
    isSaveDisabled: boolean;
    onSaveClick: () => void;
    areCharacteristicsValid: boolean;
    isEdit: boolean;
    isVisible: boolean;
    enableBidding?: boolean;
}

const PostForm = <T extends BasePost>({
    post,
    minimumPrice,
    priceStep,
    isSaveDisabled,
    onSaveClick,
    areCharacteristicsValid,
    isEdit,
    isVisible,
    enableBidding,
    children
}: PropsWithChildren<Props<T>>) => {
    const { getString } = useTranslation();

    const stepsNumber = isEdit ? (
        post.hasBidding ? EDIT_POST_WITH_BIDDING_SECTIONS : POST_SECTIONS
    ) : POST_SECTIONS;

    return (
        <div className={styles.formWrapper}>
            <GeneralInformationSection
                step={1}
                stepsNumber={stepsNumber}
                post={post} />
            <LocationSection
                step={2}
                stepsNumber={stepsNumber}
                post={post}
                isVisible={isVisible} />
            <ImagesSection
                step={3}
                stepsNumber={stepsNumber}
                post={post} />
            <CharacteristicsSection
                step={4}
                stepsNumber={stepsNumber}
                error={!areCharacteristicsValid}>
                {children}
            </CharacteristicsSection>
            {(isEdit && post.hasBidding) || (
                <PriceSection
                    step={5}
                    stepsNumber={stepsNumber}
                    post={post}
                    minimumPrice={minimumPrice}
                    priceStep={priceStep}
                    enableBidding={enableBidding} />
            )}
            <div className={styles.formButtonWrapper}>
                <span
                    title={
                        isSaveDisabled ?
                            getString(isEdit ? "updatePostError" : "addPostError") :
                            undefined
                    }>
                    <Button
                        color="primary"
                        variant="contained"
                        disabled={isSaveDisabled}
                        onClick={onSaveClick}>
                        {getString(isEdit ? "updateThePost" : "addThePost")}
                    </Button>
                </span>
            </div>
        </div>
    );
}

export default observer(PostForm);