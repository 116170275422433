import React, { useEffect, ChangeEvent, useContext } from "react";
import { observer } from "mobx-react";
import Dropdown from "../../components/dropdown";
import Table from "./components/table";
import { UsersContext } from "./users-store";
import sharedStyles from "../../admin.module.scss";
import classNames from "classnames";
import { useNavigation } from "utils/router-utils";
import { useUserDetails } from "infrastructure/services";
import { useTranslation } from "infrastructure/hooks";
import { debounce } from "@material-ui/core";
import { useSearchParams } from "react-router-dom";
import { QUERY_SEARCH } from "utils/constants";

const Users = () => {
    const { getString } = useTranslation();
    const { goHome } = useNavigation();
    const [searchParams, setSearchParams] = useSearchParams();
    const { isInitialized, isUserSuperAdmin } = useUserDetails();

    const usersStore = useContext(UsersContext);

    const {
        setActiveOptionIndex,
        setUserTypeOptionIndex,
        setVerifiedOptionIndex,
        setSearchValue,
        fetchData,
        
        activeOptionIndex,
        userTypeOptionIndex,
        verifiedOptionIndex,
        activeOptions,
        userTypeOptions,
        verifiedOptions,
    } = usersStore;

    const searchParam = searchParams.get(QUERY_SEARCH) || "";

    const handleChange = (event: ChangeEvent<HTMLInputElement>) =>
        debounce(() => {
            searchParams.set(QUERY_SEARCH, event.target.value);
            setSearchParams(searchParams);
        }, 500)();

    useEffect(() => {
        fetchData();
        
    }, [fetchData]);

    useEffect(() => {
        setSearchValue(searchParam)
    }, [setSearchValue, searchParam]);

    if (isInitialized && !isUserSuperAdmin) {
        goHome();
    }

    return <>
            <div className={sharedStyles.header}>
                <div className={classNames(sharedStyles.searchBar, sharedStyles.miraCard)}>
                    <input type="text" defaultValue={searchParam} onChange={handleChange} placeholder={`${getString("search")}...`} />
                </div>
                <Dropdown value={activeOptionIndex} onChange={(newValue: number) => setActiveOptionIndex(newValue)} items={activeOptions} />
                <Dropdown value={userTypeOptionIndex} onChange={(newValue: number) => setUserTypeOptionIndex(newValue)} items={userTypeOptions} />
                <Dropdown value={verifiedOptionIndex} onChange={(newValue: number) => setVerifiedOptionIndex(newValue)} items={verifiedOptions} />
            </div>
            <Table usersStore={usersStore} />
        </>
}

export default observer(Users);
