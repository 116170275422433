import React from "react";
import BidsForPost from "./bids-for-post";
import { Bid } from "../../../../../../../../../database/types";
import { observer } from "mobx-react";
import { runInAction } from "mobx";

interface Props {
    userBids: Bid[];
}

type MapObject = {
    [key: string]: Bid[];
}

const UserBids = ({ userBids }: Props) => {

    if (!userBids)
        return null;

    let bids: MapObject = {};

    runInAction(() => userBids.forEach((bid: Bid) => {
        if (!bids[bid.forPostId]) {
            bids[bid.forPostId] = [bid];
        } else {
            bids[bid.forPostId].push(bid);
        }
    }));

    const postIds = Object.keys(bids);

    return <>
        {postIds.map(postId => {
            const postWithCategory = bids[postId][0].forPost;
            return (postWithCategory &&
                <BidsForPost
                    key={postId}
                    postBids={bids[postId]}
                    postId={postId}
                    postCategory={postWithCategory.category} />)
        })}
    </>;
};

export default observer(UserBids);
